import {GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_REQUEST, GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_SUCCESS, GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_FAIL} from "../../../../../../../Utils/constants/HighschoolConstants/Counsellor/StudentCareerListConstants"

export const ListOfCareerSimulationReducer = (state={}, action) => {
    switch(action.type){
        case GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_REQUEST:
            return {
                loading_list_career: true
            }
        case GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_SUCCESS:
            return {
                ...state,
                loading_list_career: false,
                list_career_success: true,
                listCareerSimulation: action.payload
            }
        case GET_LIST_OF_CAREER_SIMULATION_OF_A_STUDENTS_FAIL:
             return {
                 ...state,
                 loading_list_career: false,
                 list_career_success: false,
                 list_career_error: action.payload
             }
         default:
             return state
    }
 }