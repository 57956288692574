import "./dashboardLayout.css";
const CompletedAplication = () => {
    return (
        <div className="card card-width col-md-4" style={{height: '152px'}}>
              <div className="card-body">
                  <span>0</span>
                  <p>Completed Applications</p>
              </div>
        </div>
    )
}

export default CompletedAplication;