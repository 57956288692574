import Swipers from "./Swiper";
import Jumbotron from "./Jumbotron";
import StartCareerSimulation from "./StartCareerSimulation"
// import StartCollegeApplication from "./StartCollegeApplication"
import CollegeApplicationResult from "./CollegeApplicationResult";
import CareerSimulationResult from "./CareerSimulationResult";
import StartCollegeSimulation from "./StartCollegeSimulation";
// import StartCollegeApplication from "./StartCollegeApplication";

const Overview = ({info, collegeSimulationsCounts, careerSimulationCount, user_detail, college_data}) => {
  return  (
        <div
        className="site-layout-background"
        style={{
          minHeight: "100vh",
        }}
      >
        <Jumbotron
            jumbotron_header="Welcome to your Dashboard"
            jumbotron_content=" The Future belongs to those that dares to be different, creative, and innovative. At Trav4college, we strive to invent cutting-edge technology solutions for college application and educational advancement in sub-Sahara Africa."
            jumbotron_img="/Saly-16.png"
            jumbotron_btn2="Update Profile"
            jumbotron_btn="Complete Profile"
            info={info}
            user_detail={user_detail}
        />
        <div className={careerSimulationCount && careerSimulationCount.data === 0 ? "container-dashboard" : "  career_simulate_dashboard"}>
          <div className="row">
              {careerSimulationCount && careerSimulationCount.data > 0 ? <CareerSimulationResult /> :  <StartCareerSimulation start_career="start_career"/>}

              {collegeSimulationsCounts && collegeSimulationsCounts.data >  0 ? 
              <>
                 {
                  college_data && college_data.length > 0 ? <CollegeApplicationResult college_data={college_data}/>
                   :
                  ""
              }
              </>
              : 
              <StartCollegeSimulation start_college="start_college"/>
              }
          </div>
        </div>
        <div className="container d-none">
          <Swipers />
        </div>
      </div>
  )
}

export default Overview;
