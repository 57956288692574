import { GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_REQUEST, GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_SUCCESS, GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_FAIL } from "../../../Utils/constants/HighschoolConstants/HighschoolCounsellorConstants";

export const HighschoolCounsellorReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_REQUEST:
        return { 
            highschool_loading: true 
            };
      case GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_SUCCESS:
        return { 
            highschool_loading: false, 
            highschool_counsellor: action.payload, 
            highschool_success: true 
           };
      case GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_FAIL:
        return { 
            highschool_loading: false, 
            highschool_error: action.payload, 
            highschool_success: false 
          };
      default:
        return state;
    }
  };
  