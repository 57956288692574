import {
    COUNT_GUARDIAN_MESSAGE_REQUEST,
    COUNT_GUARDIAN_MESSAGE_SUCCESS,
    COUNT_GUARDIAN_MESSAGE_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Message/CountGuardianMessageConstants";
  
  export const CountMessageReducer = (state = {}, action) => {
    switch (action.type) {
      case COUNT_GUARDIAN_MESSAGE_REQUEST:
        return { loading_message_count: true };
      case COUNT_GUARDIAN_MESSAGE_SUCCESS:
        return {
          ...state,
          loading_message_count: false,
          count_message: action.payload,
          count_message_success: true
        };
      case COUNT_GUARDIAN_MESSAGE_FAIL:
        return {
          ...state,
          loading_message_count: false,
          count_message_error: action.payload,
          count_message_success: false
        };
      default:
        return state;
    }
  };