const NoStudentPage = ({name}) => {
    return <>
     <div className="row">
                  <div className="col-md-12">   
                     <div className="card">
<div className="card-header" style={{ background: "none" }}><h3 style={{ color: "#0480FC", fontWeight: "bolder" }}>{name}</h3></div>
                        <div className="card-body">
                            <h4 className="text-center">NO STUDENT HERE YET</h4>
                        </div>
                      </div> 
                     
                  </div>
              </div>
    </>
}

export default NoStudentPage;