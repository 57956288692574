import { GET_PAYSTACK_COMPLETE_PAYMENT_REQUEST, GET_PAYSTACK_COMPLETE_PAYMENT_SUCCESS, GET_PAYSTACK_COMPLETE_PAYMENT_FAIL } from "../../../Utils/constants/Payment/CompletePaymentConstants";

export const CompletePaymentWithPayStackReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PAYSTACK_COMPLETE_PAYMENT_REQUEST:
        return { payment_loading: true };
      case GET_PAYSTACK_COMPLETE_PAYMENT_SUCCESS:
        return {
          ...state,
          payment_loading: false,
          payment_complete: action.payload,
          complete_success: true
        };
      case GET_PAYSTACK_COMPLETE_PAYMENT_FAIL:
        return {
          ...state,
          payment_loading: false,
          payment_error: action.payload,
          complete_success: false
        };
      default:
        return state;
    }
  };