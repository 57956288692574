import './existingaccount.css'
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { listHighschoolsAction } from '../../../../../Redux/actions/HighschoolActions/listHighschoolsAction';
import '../../../../Register/register.css'
import { checkExistingProfile } from './Redux/exitingAccountAction';
import { useHistory } from 'react-router-dom';

const ExistingAccount = () => {
    const dispatch = useDispatch();
    const {highschools} = useSelector(state => state.listHighschools);
    
    const data = highschools && highschools?.data;
    const {updated_profile = {}, profile_update_success} = useSelector(state => state.existingacc);
    const {data:profile_data = {}} = updated_profile;
    const {message = {}} = profile_data;
    const [userType, setUserType] = useState("")
    const [schoolId, setSchoolId] = useState(null)
    const history = useHistory()
    
    const handleSubmit = () => {
      const dataToSubmit = {user_type: userType, highschool_id: schoolId}
      dispatch(checkExistingProfile(dataToSubmit))
    }
      
      useEffect(() => {
        if (profile_update_success) {
          let jsonInfo = JSON.parse(localStorage.getItem("tokenInfo"));
          // let newUserInfo = jsonInfo?.data?.highSchoolProfile;
          if (message !== null) {
            jsonInfo.data.highSchoolProfile = message; 
            jsonInfo.data.latestCareerSimulation = {message: "No career simulation for this user"}; 
            jsonInfo.data.latestCollegeSimulation = {message: "No college simulation for this user"}; 
            // const checkHighSchool = newUserInfo === null ? {} : newUserInfo
            //  jsonInfo = Object.assign(checkHighSchool, message);
            localStorage.setItem("tokenInfo", JSON.stringify(jsonInfo))
              history.push('/dashboard/')
           }
           else{
              return false;
           }
        }
    }, [profile_update_success, message, history])

      useEffect(() => {
          dispatch(listHighschoolsAction())
      }, [dispatch])

    return <>
<div className='container' id='mydiv'>
  <div className='window '>
    <div className='content'>
      <div className='welcome mb-3' style={{fontSize: '25px', fontWeight: 'bolder', color: '#0480fc'}}>Ooops! Looks like you don't have a highschool profile yet.</div>
      <div className='subtitle mb-3'>We're almost done. Create one now!</div>
      <div className='input-fields'>
            <select  className="select_input mb-4" style={{ width: 350}}onChange={(e) =>setUserType(e.target.value)} name="user_type">
                  <option>User type?</option>
                     <option value='student'>STUDENT</option>
                     <option value='parent'>PARENT</option>
                     <option value='counsellor'>COUNSELLOR</option>
               </select>
      </div>
      <div className='input-fields'>

                <select  className="select_input mb-4" style={{ width: 350}} onChange={(e) =>setSchoolId(e.target.value)} name="school_id">
                  <option>Select highschool</option>
                 { data &&
                   data.map(({school_name, id}) => (             
                     <option key={id} value={id}>{school_name}</option>
                   ))
                 }
               </select>
    </div>

    <div className='input-fields'>

    <button type="primary" className='create_profile' onClick={handleSubmit}>Create Profile</button>
</div>

    </div>
  </div>
</div>
    </>
}

export default ExistingAccount