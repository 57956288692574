import {
    VIEW_GUARDIAN_NOTIFICATION_FAIL,
    VIEW_GUARDIAN_NOTIFICATION_REQUEST,
    VIEW_GUARDIAN_NOTIFICATION_SUCCESS,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/ViewNotificationConstants";
  
  export const ViewNotificationReducer = (state = {}, action) => {
    switch (action.type) {
      case VIEW_GUARDIAN_NOTIFICATION_REQUEST:
        return { viewed_loading: true };
      case VIEW_GUARDIAN_NOTIFICATION_SUCCESS:
        return {
          ...state,
          viewed_loading: false,
          viewed_result: action.payload,
          success: true
        };
      case VIEW_GUARDIAN_NOTIFICATION_FAIL:
        return {
          ...state,
          viewed_loading: false,
          viewed_result_error: action.payload,
          success: false
        };
      default:
        return state;
    }
  };