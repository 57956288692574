import {
    VIEW_GUARDIAN_MESSAGE_REQUEST,
    VIEW_GUARDIAN_MESSAGE_SUCCESS,
    VIEW_GUARDIAN_MESSAGE_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Message/ViewMessageConstants";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const ViewMessageAction =
    (id) => async (dispatch, getState) => {
      try {
        dispatch({ type: VIEW_GUARDIAN_MESSAGE_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.put(
          `/highschools/updateNotifs/message/${id}`,
          config
          );
        dispatch({ type: VIEW_GUARDIAN_MESSAGE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: VIEW_GUARDIAN_MESSAGE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };