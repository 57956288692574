import { createContext, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getStudentProfileAction } from "../../../Redux/actions/getProfileAction";
import { userAction } from "../../../Redux/actions/userAction";
import { listHighschoolsAction } from "../../../Redux/actions/HighschoolActions/listHighschoolsAction";
import { countCareerSimulation } from "../../../Redux/actions/SimulationActions/CareerSimulationActions/countCareerSimulation"
import { getCareerSimulations } from "../../../Redux/actions/SimulationActions/CareerSimulationActions/getCareerSimulation"
import { countCollegeSimulation } from "../../../Redux/actions/SimulationActions/CollegeSimulationAction/countCollegeSimulations"
import { getCollegeSimulations } from "../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeSimulations"
import { getCollegeLatestSimulation } from "../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeLatestSimulation";
import { getScholarshipProfileAction } from "../../../Redux/actions/SimulationActions/ScholarshipActions/ScholarshipProfileAction";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const dispatch = useDispatch();
    const local_data = JSON.parse(localStorage.getItem('tokenInfo'));
    const user_email = local_data?.data?.email;
    const local_user = local_data?.data?.id;
    const user_highschool_profile = local_data?.data?.highSchoolProfile;
    const { loading: user_loading, error: user_error, success: user_success, user } = useSelector(state => state.getUser);
    const [userError, setError] = useState(user_error)
    const {loading, profile, success, error} = useSelector(state => state.getStudentProfile)

    const {loading: highschools_loading, highschools, success: highschools_sucess, error: highschools_error} = useSelector(state => state.listHighschools);

    const {loading_college_latest_simulation, college_latest_simulation_error,collegeLatestSimulationsResult = {}} = useSelector(state => state.latestCollegeSimulation);

    const userId = user && user?.data?._id;
    const user_highschool = user && user?.data?.highSchoolProfile;

    const {college_count_loading,collegeSimulationsCounts,college_count_success,college_count_error} = useSelector(state => state.countStudentCollegeSimulation)

    const {college_loading, collegeSimulations, college_success, college_error} = useSelector(state => state.getStudentCollegeSimulation)

    const {loading_career, careerSimulations, career_success, career_error} = useSelector(state => state.getStudentCareerSimulation)

    const { career_loading_count,  careerSimulationsCounts,career_count_success, career_count_error} = useSelector(state => state.countStudentCareerSimulation);

    const {scholar_loading, scholar_profile, scholar_success, scholar_error} = useSelector(state => state.scholarshipProfile)

    setTimeout(() => {
        setError("")
    }, 3000);

    useEffect(() => {
      dispatch(getStudentProfileAction(userId))
      dispatch(getCollegeLatestSimulation(userId))
    }, [dispatch, userId])

    useEffect(() => {
        dispatch(userAction())
        dispatch(countCollegeSimulation())
        dispatch(getCollegeSimulations())
        dispatch(getCareerSimulations())
        dispatch(countCareerSimulation())
        dispatch(listHighschoolsAction())
        dispatch(getScholarshipProfileAction())
    }, [dispatch])

    useEffect(() => {
       if (user_highschool?.parent_email && user_highschool?.parent_name !== null) {
        localStorage.setItem('parent_', JSON.stringify({parent_name: user_highschool?.parent_name, parent_email: user_highschool?.parent_email}))
       } else {
         console.log('')
       }
    }, [user_highschool])

      const { Provider } = AuthContext;
      return <Provider value={{ local_user, user_highschool_profile, user_loading, user_error, userError, user_success, user, loading, profile, success, error, college_count_loading,collegeSimulationsCounts,college_count_success,college_count_error, college_loading, collegeSimulations, college_success, college_error, loading_career, careerSimulations, career_success, career_error, career_loading_count,  careerSimulationsCounts,career_count_success, career_count_error, highschools, highschools_error, highschools_loading, highschools_sucess, loading_college_latest_simulation, collegeLatestSimulationsResult, college_latest_simulation_error, scholar_error, scholar_loading, scholar_profile, scholar_success, user_email}}>{children}</Provider>;
}

export default AuthProvider;
