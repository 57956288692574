const StudentPersonalInfo = ({student_data}) => {
    return (
       
           <div className="card card_personal_info_counsellor">
               <div className="card_header">Personal Information</div>
               <hr />
               <div className="card-body">
                   <div className="row">
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                            <p>Email Address<br /> <span>{student_data?.email ? student_data?.email : "nil"}</span></p>
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Phone Number<br/><span>{student_data?.phone ? student_data?.phone : "nil"}</span></p>
                           
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Date of Birth<br/><span>{student_data?.high_school_profile?.age ? student_data?.high_school_profile?.age.slice(0,4) : ""}   ({student_data?.high_school_profile?.age ? new Date().getFullYear() - student_data?.high_school_profile?.age.slice(0,4) : 0})Years</span></p>
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Gender<br/><span>{student_data?.high_school_profile?.gender ? student_data?.high_school_profile?.gender : "nil"}</span></p>
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Parent’s Name<br/><span>{student_data?.high_school_profile?.parent_name ? student_data?.high_school_profile?.parent_name : "nil"}</span></p>
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Parent’s Email<br/><span>{student_data?.high_school_profile?.parent_email ? student_data?.high_school_profile?.parent_email : "nil"}</span></p>
                       </div>
                   </div>
               </div>
        </div>
    )
}


export default StudentPersonalInfo;