import { GET_UNPAID_APPL_FAIL, GET_UNPAID_APPL_REQUEST, GET_UNPAID_APPL_SUCCESS } from "../../../Utils/constants/Payment/UnpaidApplConstants";
import axiosInstance from "../../../Utils/helper/axiosInstance";

export const UnpaidApplAction = (ref) => async(dispatch, getState) => {
    try {
        dispatch({type: GET_UNPAID_APPL_REQUEST});
        const {
            tokenAuth: { tokenInfo },
          } = getState();
          const config = {
            headers: {
              Authorization: `Bearer ${tokenInfo.data.userToken}`,
              "Content-Type": "application/json",
            },
          };
        const {data} = await axiosInstance.get(
            `/product/college_applications/getStudentCollegeUnpaidApplist/${ref}`,
           config  
        );
        dispatch({type: GET_UNPAID_APPL_SUCCESS, payload: data});
    } catch (error)  {
         let message = error.response && error.response.data ? error.response.data : error.message;
         dispatch({type: GET_UNPAID_APPL_FAIL, payload: message});
    }
}