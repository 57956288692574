import {STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS, STUDENT_LIST_FAIL} from '../../../../../../../Utils/constants/HighschoolConstants/Counsellor/StudentListConstants';

export const studentListReducer = (state={}, action) => {
    switch(action.type){
        case STUDENT_LIST_REQUEST:
            return {
                student_loading: true
            }
        case STUDENT_LIST_SUCCESS:
            return {
                ...state,
                student_loading: false,
                student_list_success: action.payload
            }
        case STUDENT_LIST_FAIL:
            return {
                ...state,
                student_loading: false,
                student_list_error: action.payload
            }
        default:
            return state
    }
}