import {GET_CAREER_SIMULATION_BY_SIMULATION_ID_FAIL, GET_CAREER_SIMULATION_BY_SIMULATION_ID_REQUEST, GET_CAREER_SIMULATION_BY_SIMULATION_ID_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/getCareerSimulationBySimulationIdConstant";

export const getCareerSimulationBySimulationId = (state = {}, action) => {
    switch (action.type) {
        case GET_CAREER_SIMULATION_BY_SIMULATION_ID_REQUEST:
            return {
                loading_simulationId: true
            }
        case GET_CAREER_SIMULATION_BY_SIMULATION_ID_SUCCESS:
            return {
                    ...state,
                    loading_simulationId: false,
                    careerResult: action.payload,
                    loading_simulationId_sucess: true,
                    loading_simulationId_error: false

            }
        case GET_CAREER_SIMULATION_BY_SIMULATION_ID_FAIL:
            return {
                ...state,
                loading_simulationId: false,
                careerResult_error: action.payload,
                loading_simulationId_sucess: false,
                loading_simulationId_error: true
            }        
        default:
            return state;
    }
}