import { STUDENT_COLLEGE_SIMULATION_FAIL, STUDENT_COLLEGE_SIMULATION_REQUEST, STUDENT_COLLEGE_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/getCollegeSimulationConstant"
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getCollegeSimulations = () => async(dispatch, getState) => {
    try {
        dispatch({type: STUDENT_COLLEGE_SIMULATION_REQUEST})
        const {
            tokenAuth: {tokenInfo}
        } = getState()
        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        }
        const {data} = await axiosInstance.get(
            "/highschools/college_simulations/student_collegesimulations",
            config
        )
        dispatch({type: STUDENT_COLLEGE_SIMULATION_SUCCESS, payload: data})
    } catch (error) {
        let message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: STUDENT_COLLEGE_SIMULATION_FAIL, payload: message});
    }
}