import { GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_REQUEST, GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_SUCCESS, GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_FAIL } from "../../../Utils/constants/Payment/ScholarshipCompletePayConstants";

export const ScholarCompletePaymentReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_REQUEST:
        return { scholarPay_loading: true };
      case GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_SUCCESS:
        return {
          ...state,
          scholarPay_loading: false,
          scholarPay_complete: action.payload,
          scholar_complete_success: true
        };
      case GET_PAYSTACK_SCHOLARSHIP_COMPLETE_PAYMENT_FAIL:
        return {
          ...state,
          scholarPay_loading: false,
          scholar_payment_error: action.payload,
          scholar_complete_success: false
        };
      default:
        return state;
    }
  };