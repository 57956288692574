import { COUNT_STUDENT_COLLEGE_SIMULATION_FAIL, COUNT_STUDENT_COLLEGE_SIMULATION_REQUEST, COUNT_STUDENT_COLLEGE_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/countCollegeSimulationConstant"

export const countCollegeSimulationReducer = (state = {}, action) => {
    switch (action.type) {
        case COUNT_STUDENT_COLLEGE_SIMULATION_REQUEST:
            return {
                college_count_loading: true
            }
        case COUNT_STUDENT_COLLEGE_SIMULATION_SUCCESS:
            return {
                ...state,
                college_count_loading: false,
                collegeSimulationsCounts: action.payload,
                college_count_success: true
                }
        case COUNT_STUDENT_COLLEGE_SIMULATION_FAIL:
            return {
                ...state,
                college_count_loading: false,
                college_count_error: action.payload,
                college_count_success: false
                }
        default:
            return state;
    }
}