import { UPDATE_GUARDIAN_MESSAGE_REQUEST, UPDATE_GUARDIAN_MESSAGE_SUCCESS, UPDATE_GUARDIAN_MESSAGE_FAIL } from "../../../Utils/constants/HighschoolConstants/Message/UpdateTokenConstant";

export const UpdateReferenceReducer = (state = {}, action) => {
    switch (action.type) {
      case UPDATE_GUARDIAN_MESSAGE_REQUEST:
        return { update_loading: true };
      case UPDATE_GUARDIAN_MESSAGE_SUCCESS:
        return {
          ...state,
          update_loading: false,
          updated_reference: action.payload,
          update_success: true
        };
      case UPDATE_GUARDIAN_MESSAGE_FAIL:
        return {
          ...state,
          update_loading: false,
          updated_reference_error: action.payload,
          update_success: false
        };
      default:
        return state;
    }
  };