import { Link, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { AiFillDashboard } from "react-icons/ai";
import { FcWorkflow } from "react-icons/fc";
import "./simulator.css";
const SimulatorHeader = ({ name, logo }) => {
   const history = useHistory()

   const backToDashboard = () => {
     history.push("/dashboard/")
     localStorage.setItem("keys","overview");
   }
  return (
    <div className="college_simulator">
      <div className="college_sim_logo">
        <Link to="/dashboard/">
          <img src={logo} alt="trav4college logo" style={{width: '135px'}}/>
        </Link>
      </div>
      <div className="name_link">{name && <h3>{<span style={{fontSize: '30px'}} title={name}><FcWorkflow/></span> }</h3>}</div>
        <div className="back_link" onClick={backToDashboard}>
        <span title="Back to Dashboard" style={{fontSize: '30px'}}><AiFillDashboard/></span>
        </div>
    </div>
  );
};

export default SimulatorHeader;

SimulatorHeader.propTypes = {
  name: PropTypes.string || PropTypes.number,
  logo: PropTypes.string,
};
