import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./dashboardLayout.css"


export default function Swipers() {
  const settings = {
    arrows: true,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: true,
          infinite: true,
           autoplay: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          infinite: true,
           autoplay: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          infinite: true,
           autoplay: true,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="my-4">

         <Slider {...settings}>
        <div className="card card-swiper">
        <div className="card-body">
          <div className="graduate">
            <img src="/Group1.png" alt="grad1" className="grad" />
            <h3>College Application</h3>
          </div>
          <p className="text-desc my-3">
            Apply to several universities and courses with a single application
          </p>
          <Link to="/start-application" className="start_application">
            Start Application
          </Link>
          </div>
        </div>
        <div className="card card-swiper">
        <div className="card-body">
          <div className="graduate">
            <img src="/Group2.png" alt="grad2" className="grad" />
            <h3>Scholarship</h3>
          </div>
          <p className="text-desc my-3">
            Your College Journey starts right here.
          </p>
          <Link to="/start-application" className="start_application">
            Find Scholarships
          </Link>
          </div>
        </div>
        <div className="card card-swiper">
          <div className="card-body">
          <div className="graduate">
            <img src="/Group3.png" alt="grad3" className="grad" />
            <h3>College Savings</h3>
          </div>
          <p className="text-desc my-3">
            Save money ahead for your College Education
          </p>
          <Link to="/start-application" className="start_application">
            Start Saving
          </Link>
          </div>
        </div>
        <div className="card card-swiper">
        <div className="card-body">
          <div className="graduate">
            <img src="/Group2.png" alt="grad2" className="grad" />
            <h3>Scholarship</h3>
          </div>
          <p className="text-desc my-3">
            Your College Journey starts right here.
          </p>
          <Link to="/start-application" className="start_application">
            Start Application
          </Link>
          </div>
        </div>
      {/* </Swiper> */}
      </Slider>
    </div>
  );
}
