import { GET_SCHOOL_SCHOLARSHIP_FAIL, GET_SCHOOL_SCHOLARSHIP_REQUEST, GET_SCHOOL_SCHOLARSHIP_SUCCESS } from "../../../Utils/constants/Payment/SchoolSchorships/SchoolScholarshipConstants";

export const SchoolScholarshipReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_SCHOOL_SCHOLARSHIP_REQUEST:
        return { school_scholar_loading: true };
      case GET_SCHOOL_SCHOLARSHIP_SUCCESS:
        return {
          ...state,
          school_scholar_loading: false,
          school_scholar_result: action.payload,
          school_scholar_success: true
        };
      case GET_SCHOOL_SCHOLARSHIP_FAIL:
        return {
          ...state,
          school_scholar_loading: false,
          school_scholar_error: action.payload,
          school_scholar_success: false
        };
      default:
        return state;
    }
  };