import arc from "../../../../../assets/career-arc.png";
import bag from "../../../../../assets/bag.png";
import {Link} from "react-router-dom";

const StudentCareerView = ({type = [], suggested_careers, simulation_id}) => {
    console.log("types", type) 
    // type?.map(type => console.log("types", type.personality_type))   
    return (
            <>
                <div className="col-12 col-xs-12 col-sm-12 col-lg-6 col-md-6">
                    <div className="card career_card shadow">
                        <div className="card-body">
                            <div className="career_simualte">
                                <h4 className="career_title">MY CAREER SIMULATION</h4>
                                <p className="result_header">RESULT</p>
                                <h1 className="realistic_header" text="View Career Reccommendations that can help with career journey." style={{textTransform: "capitalize"}}>{type && type?.map(type => type?.personality_type).join(", ")}</h1>
                                {
                                    suggested_careers && suggested_careers?.slice(0, 3)?.map((career, index) =>
                                <p className="career_text" key={index}><b>{career.careers}</b></p>
                                      )
                                }
                                <Link to={`/career-simulator/result/${simulation_id}`} className="career_recommendations">View Full Result & Recommendations</Link>
                            </div>
                        </div>
                        <img src={arc} alt="career_arc" className="bg_career_img"/>
                        <div className="career_bag"><img src={bag} alt="bag"/></div>
                    </div>
                </div>
            </>
        )
}

export default StudentCareerView;