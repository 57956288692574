import { Alert } from "antd";
const Alerts = ({ message, type, description, onClose }) => {
  return (
    <div>
      <Alert
        message={message}
        description={description}
        type={type}
        closable
        onClose={onClose}
      />
    </div>
  );
};
export default Alerts;
