import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { getScholarshipsAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/GetScholarshipsAction";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BsFillBookmarkFill } from "react-icons/bs";
import Loader from "../../../LoadersPage";

const ViewScholarship = () => {
    const {id} = useParams()
    const dispatch = useDispatch();
    const {get_scholar_loading, get_scholar_profile = {}} = useSelector(state => state.getScholarships);
    const {data: scholar_data = {}} = get_scholar_profile
    const {data = []} = scholar_data;
    const [obj, setObj] = useState({});
    // const save_to_local = localStorage.setItem('scholarship', JSON.stringify(scholarship))
    const scholarship = data && data.find((scholarship) => scholarship.id === +id)
    
    useEffect(() => {
        if (get_scholar_loading) console.log("");
        setObj(scholarship)
        localStorage.setItem('scholarship', JSON.stringify(obj))
    }, [get_scholar_loading, data, id, obj, scholarship])
    console.log('');
    // const {college_name, compensation, external_link, deadline,needs_admission, contact_details, scholarship_name, program_types, scholarship_type, information} = scholarship;
    useEffect(() => {
        dispatch(getScholarshipsAction())
    }, [dispatch])

    return (
    <>
    {
        get_scholar_loading ? <Loader loading={get_scholar_loading}/> :
         <div className="container">
            <div className="row">
                <div className="col-md-4"><AiOutlineArrowLeft/></div>
                <div className="col-md-4">{obj?.scholarship_name ?? null}</div>
                <div className="col-md-4"><BsFillBookmarkFill/><span>My scholarship</span></div>
            </div>
         </div>
    }
    </>
    )
}
export default ViewScholarship 