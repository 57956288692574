import { CAREER_QUESTION_FAIL, CAREER_QUESTION_REQUEST, CAREER_QUESTION_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CareerQuestionnaire/CareerQuestionConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";

export const listQuestions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: CAREER_QUESTION_REQUEST});
    const {
      tokenAuth: { tokenInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${tokenInfo.data.userToken}`,
        "Content-Type": "application/json",
      
      },
    };
    const { data } = await axiosInstance.get(
      "/highschools/career_simulations",
     
      config
    );
    dispatch({ type: CAREER_QUESTION_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data
        ? error.response.data
        : error.message;
    dispatch({ type: CAREER_QUESTION_FAIL, payload: message });
  }
};
