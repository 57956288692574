import { useDispatch, useSelector } from "react-redux";
import { useEffect} from "react";
import { countStudentCareerSimulations } from "../Redux/actions/CountStudentCareerSimulations";
import { StudentCareerSimulationsDetails } from "../Redux/actions/GetListOfStudentsCareerSimulations";
import { getStudentsCollegeSimulationCount } from "../Redux/actions/CollegeActions/CountStudentCollegeSimulation";
import { StudentscollegeSimulationsDetails } from "../Redux/actions/CollegeActions/GetListOfStudentsCollegeSimulations";
import { Link } from "react-router-dom"
import "../counsellor.css"
const Simulators = () => {

    const dispatch = useDispatch();

    // for career simulations
    const {careerSimulationsCounts} = useSelector(state => state.studentsCareerSimulationCount);
    const {career_loading_detail, careerSimulationDetails} = useSelector(state => state.careerDetails);
    const info = careerSimulationsCounts && careerSimulationsCounts?.data;

    const studentDetails = careerSimulationDetails && careerSimulationDetails?.data;
    // console.log("studentDetails", studentDetails);

    // for college simulation
    const { collegeSimulationsCounts = {}} = useSelector(state => state.collegeSimulationCount);
    const { collegeSimulationDetails } = useSelector(state => state.collegeDetails);
    const collegeDetails = collegeSimulationDetails && collegeSimulationDetails?.data;
    const {data} = collegeSimulationsCounts;

    // console.log("collegeSimulationsCounts", data);
    
    useEffect(() => {
        dispatch(countStudentCareerSimulations())
        dispatch(StudentCareerSimulationsDetails())
        dispatch(getStudentsCollegeSimulationCount())
        dispatch(StudentscollegeSimulationsDetails())
    }, [dispatch])

    return (
        <div className="row_alignment">
            <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card simulators_details">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <div className="simulator_header">
                                    Career Simulation
                                    {career_loading_detail &&      
                                        <div
                                        className="spinner-border text-dark"
                                        role="status"
                                        style={{ width: "5rem", height: "5rem" }}
                                        >
                                        <span className="visually-hidden">Loading...</span>
                                        </div>
                                    }
                                    </div>
                                      <p className="total_simulation"><strong>{info}:</strong> Total simulations</p>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 column_details">
                                    <img src="/perspaleta1_0005.png" alt="perspaleta1_0005" className="img_simulate"/>
                                </div>
                            </div>
                            {
                            studentDetails && studentDetails.slice(0, 4).map(student => 
                                <div className="simulator_detail_desc" key={student.id}>
                                    <div className="name_desc">{student?.user?.first_name} {student?.user?.last_name}</div>
                                    <div className="view_more_desc">
                                        {/* <button onClick={()=>handleViewSimulation(student?.user?.id)}>View result</button> */}
                                        
                                      <Link to={`/career-simulator/result/${student?.id}`}>
                                        <span>View result</span> 
                                       </Link>
                                    </div>
                                </div>
                            )
                            }
            
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card simulators_details">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-8 col-md-8 col-lg-8">
                                    <div className="simulator_header">
                                        College Simulation
                                    </div>
                                    <p className="total_simulation"><strong>{data}:</strong> Total simulations</p>
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 column_details">
                                    <img src="/perspaleta1_0001.png" alt=" perspaleta1_0001" className="img_simulate"/>
                                </div>
                            </div>
                              {collegeDetails && collegeDetails?.slice(0,4).map(student => 
                                <div className="simulator_detail_desc" key={student?.id}>
                                    <div className="name_desc">{student?.user?.first_name} {student?.user?.last_name}</div>
                                    <div className="view_more_desc">
                                    <Link to={`/college-simulator/result/${student?.id}`}>
                                        <span>View results</span> 
                                       </Link>
                                    </div>
                                </div>
                               )}
                                {/* <div className="view_more_desc" onClick={()=>handleViewSimulation(9, "college")}>View results</div> */}
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Simulators;