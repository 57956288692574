
  import { GET_COUNTRIES_FAIL, GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CountryConstant";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";
  
  export const listCountries = () => async (dispatch) => {
    try {
      dispatch({ type: GET_COUNTRIES_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        `/metadata/countries`,
        config
      );
      dispatch({ type: GET_COUNTRIES_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_COUNTRIES_FAIL, payload: message });
    }
  };
  