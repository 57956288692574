import { Card, Avatar, Skeleton, Row, Col } from 'antd';
const { Meta } = Card;
const CountCareerForStudents = ({career_loading_count, info}) => {
    return (
        <>
          {
             career_loading_count ? 
                <Row gutter={16}>
                <Col span={8}>
                    <Card loading={career_loading_count}>
                        <Skeleton loading={career_loading_count} avatar active>
                            <Meta
                            avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                            title="Card title"
                            description="This is the description"
                            />
                        </Skeleton>
                    </Card>
                </Col>
        
                </Row>
                :

                <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3 mb-4">
                        <div className="card application_details">
                            <div className="card-body">
                                <div className="student_add_section">
                                    <div className="detail_no">
                                    {info ? info : "0"}
                                        {/* {career_loading_count &&      
                                            <div
                                            className="spinner-border text-dark"
                                            role="status"
                                            style={{ width: "5rem", height: "5rem" }}
                                            >
                                            <span className="visually-hidden">Loading...</span>
                                            </div>
                                        } */}
                                    </div>
                                    {/* <div className="btn btn-primary" onClick={handleShowAddStudent}>Add a Student</div> */}
                                </div>
                                <div className="application_info">
                                    NUMBER OF STUDENTS
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
          }
        </>
    )
}

export default CountCareerForStudents;