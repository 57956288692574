import { GET_PAGE_REQUEST, GET_PAGE_FAIL, GET_PAGE_SUCCESS } from "../../../Utils/constants/PaginationConstants.js/GetPagesConstants";

export const GetPageReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PAGE_REQUEST:
        return { get_page_loading: true };
      case GET_PAGE_SUCCESS:
        return {
          ...state,
          get_page_loading: false,
          get_page: action.payload,
          get_page_success: true
        };
      case GET_PAGE_FAIL:
        return {
          ...state,
          get_page_loading: false,
          get_page_error: action.payload,
          get_page_success: false
        };
      default:
        return state;
    }
  };