import {
    SEARCH_COURSES_FAIL,
    SEARCH_COURSES_REQUEST,
    SEARCH_COURSES_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CourseConstants";
  
  export const searchCourses = (state = { courses: [] }, action) => {
    switch (action.type) {
      case SEARCH_COURSES_REQUEST:
        return { loading: true };
      case SEARCH_COURSES_SUCCESS:
        return { ...state, loading: false, courses: action.payload };
      case SEARCH_COURSES_FAIL:
        return { ...state, loading: false, error: action.payload };
      default:
        return state;
    }
  };
  