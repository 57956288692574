import { Card, Avatar, Skeleton, Row, Col } from 'antd';
const { Meta } = Card;

const CountCollegeForStudents = ({countCollege, loading}) => {
    return (
        <>
        {
             loading ? 
             <Row gutter={16}>
             <Col span={8}>
                 <Card loading={loading}>
                     <Skeleton loading={loading} avatar active>
                         <Meta
                         avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                         title="Card title"
                         description="This is the description"
                         />
                     </Skeleton>
                 </Card>
             </Col>
     
             </Row>
             :   
             <>
                <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                        <div className="card application_details">
                            <div className="card-body">
                                <div className="student_add_section">
                                    <div className="detail_no">
                                    {countCollege ? countCollege : "0"}
                                    </div>
                                    {/* <div className="btn btn-primary" onClick={handleShowAddStudent}>Add a Student</div> */}
                                </div>
                                <div className="application_info">
                                    NUMBER OF STUDENTS
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
             </>
        }
        </>
    )
}

export default CountCollegeForStudents;