import { STUDENT_COLLEGE_SIMULATION_COUNT_REQUEST, STUDENT_COLLEGE_SIMULATION_COUNT_SUCCESS, STUDENT_COLLEGE_SIMULATION_COUNT_FAIL } from "../../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CollegeConstants/CountStudentCollegeSimulationConstant";

export const countStudentsCollegeSimulationReducer = (state={}, action)  => {
    switch(action.type){
        case STUDENT_COLLEGE_SIMULATION_COUNT_REQUEST:
            return {college_count_loading: true}
        case STUDENT_COLLEGE_SIMULATION_COUNT_SUCCESS:
            return {
                ...state,
                college_count_loading: false,
                collegeSimulationsCounts: action.payload,
                college_count_success: true
            }
        case STUDENT_COLLEGE_SIMULATION_COUNT_FAIL:
            return {
                ...state,
                college_count_loading: false,
                college_count_error: action.payload,
                college_count_success: false
            }
        default:
            return state
    }
}