import { GET_COLLEGE_APPL_COUNT_FAIL, GET_COLLEGE_APPL_COUNT_REQUEST, GET_COLLEGE_APPL_COUNT_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplCountConstants";

export const countStudentsCollegeApplReducer = (state={}, action)  => {
    switch(action.type){
        case GET_COLLEGE_APPL_COUNT_REQUEST:
            return {loading_count: true}
        case GET_COLLEGE_APPL_COUNT_SUCCESS:
            return {
                ...state,
                loading_count: false,
                collegeAppl_count: action.payload,
                collegeAppl_count_success: true
            }
        case GET_COLLEGE_APPL_COUNT_FAIL:
            return {
                ...state,
                loading_count: false,
                collegeAppl_count_error: action.payload,
                collegeAppl_count_success: false
            }
        default:
            return state
    }
}