import { Link } from "react-router-dom"
import "../CareerPage/career.css"
const StartCareerSimulation = ({start_career}) => {
    return (
        <div className={`col-xs-12 col-12  col-sm-12 col-md-6 essential_new_user   ${start_career}`}>
            <Link to="/get-started-career">
              <img
                src="/perspaleta1_0005.png"
                alt="essential"
                className="essential"
              />
              <h3 className="py-2">Simulate my Career</h3>
              <p>
              Welcome to career simulator. As you prepare for a great career ahead, the simulators help you relate your interest to the
world of work and, in turn, help you decide which colleges and careers you might want to
explore after high school. The simulators help you determine the best school and program for
you after graduation by answering simple questions accurately. Here you would most likely
be confident of a bright and compelling future.

              </p>
              <p>
                <button className="get_started_link">Get Started</button>
              </p>
              </Link>
            </div>
    )
}

export default StartCareerSimulation
