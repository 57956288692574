import "../../ParentsDashboard/css/parent.css"
const PersonalInfo = ({user}) => {
    const {data={}} = user;
    const { email, phone, highSchoolProfile ={}} = data;
    const {gender, age, parent_name, parent_email, user_type} = highSchoolProfile;
    // console.log("highschoolProfile", data);
    
    return (
        <div className="container">
           <div className="card card_personal_info">
               <div className="card_header">Personal Information</div>
               <hr />
               <div className="card-body">
                   <div className="row">
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Email Address<br /> <span>{email ? email : "nil"}</span></p>
                          
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                           <p>Phone Number<br/><span>{phone ? phone : "nil"} </span></p>
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                               <p>Date of Birth<br/><span>{age ? age.slice(0,4) : ""}   ({age ? new Date().getFullYear() - age.slice(0,4) : 0})Years </span></p>
                       </div>
                        <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                                <p>Gender<br/><span>{gender ? gender : "nil"}</span></p>
                        </div>
                       {
                           user_type && user_type === "student" && 
                           <>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                                <p>Parent’s Name<br/><span>{parent_name ? parent_name : "nil"}</span></p>
                            </div>
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 card_personal_details">
                                <p>Parent’s Email<br/><span>{parent_email ? parent_email : "nil"}</span></p>
                            </div>
                          </>  
                       }
                       
                   </div>
               </div>
           </div>
        </div>
    )
}


export default PersonalInfo;