import {
    GET_COLLEGES_FAIL,
    GET_COLLEGES_SUCCESS,
    GET_COLLEGES_REQUEST,
  } from "../../../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/CollegeApplicationConstants";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const listColleges = (countries) => async (dispatch) => {
    try {
      dispatch({ type: GET_COLLEGES_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        `/colleges?countries=${countries}`,
        config
      );
      dispatch({ type: GET_COLLEGES_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_COLLEGES_FAIL, payload: message });
    }
  };
  