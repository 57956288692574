import "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/student.css";

const Paginate = ({studentPerPage, totalStudents, paginate}) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalStudents / studentPerPage); i++) {
        pageNumbers.push(i)
    }
    return (
    <div className="paginate-page">
<div className="page-shown">Showing - {studentPerPage} of {totalStudents}</div>
        
        <div className="page-numbering">
        {
            pageNumbers.map(number => 
                <span className="page-active" key={number} onClick={() => {paginate(number)}}>{number}</span>
           )
        }
        {/* <div className="next-page"><span>next</span><FaChevronRight className="next-button"/></div> */}
             </div>
    </div>
    )
}
export default Paginate