import "./style.css"
import { Link } from "react-router-dom";
import { Button } from "antd";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getScholarshipsAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/GetScholarshipsAction";
import Loader from "../../../LoadersPage";

const ScholarshipProfile = ({data = {}}) => {
    const {commMode = [], degree_type = {}, scholarProfileFields = [], scholarProfileCountries = []} = data;
    const dispatch = useDispatch()
    const {get_scholar_profile = {}, get_scholar_loading} = useSelector(state => state.getScholarships);
    const {data: scholar_data = {}} = get_scholar_profile

    const {hasScholarshipProduct} = scholar_data;

    useEffect(() => {
        dispatch(getScholarshipsAction())
    }, [dispatch])
    return (
        <>

        {
            get_scholar_loading  ? <Loader loading={get_scholar_loading}/> :

            <>
                {
                    !hasScholarshipProduct ? 
                        <div className="container">
                            <div className="row">
                                <div className="card-container col-md-6 mb-5" style={{minHeight: '300px'}}>
                    <h1 style={{ color: "#fff" }}>{degree_type?.degree_name}</h1>

                    <h6 style={{ color: "#fff" }} >COMMUNICATION MODE</h6>
                    <div className="mb-4 row justify-content-center text-center">
                        {commMode && commMode.map(comm => 
                    <div className="primary ghost m-1 col-md-3">{comm?.communication_mode}</div>
                        )}
                    </div>

                    <h6 style={{ color: "#fff" }}>COURSE(S)</h6>
                    <div className="mb-4 row mx-auto justify-content-center text-center">
                        {scholarProfileFields && scholarProfileFields.map(({field}) => 
                    <div className="primary ghost m-1 col-md-3">{field?.name}s</div>
                        )}
                    </div>

                    <h6 style={{ color: "#fff" }} >COUNTRY</h6>
                    <div className="mb-4 row justify-content-center text-center">
                        {scholarProfileCountries && scholarProfileCountries.map(({country}) => 
                    <div className="primary ghost m-1 col-md-3">{country?.country}</div>
                        )}
                    </div>

                    <div className="m-3">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="/dashboard/my-scholarshipslists" style={{ textDecoration: "none" }}> <Button className="p-3 mb-3 text-white">View scholarships</Button></Link>
                            </div>

                            <div className="col-md-6">
                                <Link to={`/scholarship-questionnaire/${'update'}`} style={{ textDecoration: "none" }}> <Button className="p-3 mb-3 text-white">Update Preferences</Button></Link>
                            </div>
                        </div>
                    </div>       

                                    </div>
                                    
                                
                                        <div className="col-md-6 row2-container mb-5" style={{height: '300px'}}>
                                                <div className="box orange">
                                                    <h2>Welcome to Trav4college Scholarships</h2>
                                                    <p>Get access to full tuition scholarships suited for your financial needs</p>
                                                    <img className="scholar_img" src="https://cdn-icons-png.flaticon.com/128/201/201597.png" alt="hello"/>
                                                    {data && !data.message ? <Link to="/dashboard/subscriptions"><button className="btn">Subscribe for scholarship</button> </Link>: <button className="btn"> Setup your profile</button>}
                                                </div>
                                        </div> 
                                    

                            </div>
                        </div> 
                        :
                         <div className="container">
                                                <div className="row mx-auto justify-content-center">
                                                    <div className="card-container col-md-7 mb-5" style={{minHeight: '300px'}}>
                                        <h1 style={{ color: "#fff" }}>{degree_type.degree_name}</h1>

                                        <h6 style={{ color: "#fff" }} >COMMUNICATION MODE</h6>
                                        <div className="mb-4 row justify-content-center text-center">
                                            {commMode && commMode.map(comm => 
                                        <div className="primary ghost m-1 col-md-3">{comm.communication_mode}</div>
                                            )}
                                        </div>

                                        <h6 style={{ color: "#fff" }}>COURSE(S)</h6>
                                        <div className="mb-4 row justify-content-center text-center">
                                            {scholarProfileFields && scholarProfileFields.map(({field}) => 
                                        <div className="primary ghost m-1 col-md-3" key={field.name}>{field.name}</div>
                                            )}
                                        </div>

                                        <h6 style={{ color: "#fff" }} >COUNTRY</h6>
                                        <div className="mb-4 row justify-content-center text-center">
                                            {scholarProfileCountries && scholarProfileCountries.map(({country}) => 
                                        <div className="primary ghost m-1 col-md-3" key={country.country}>{country.country}</div>
                                            )}
                                        </div>

                                        <div className="m-3">
                                        <Link to="/dashboard/my-scholarshipslists" style={{ textDecoration: "none" }}> <Button className="p-3 mb-3 text-white">View scholarships</Button></Link>

                                        <Link to={`/scholarship-questionnaire/${'update'}`} style={{ textDecoration: "none" }}> <Button className="p-3 mb-3 text-white">Update scholarships</Button></Link>
                                        </div>       

                                                        </div>
                                                        </div>
                                                        </div>
                        } 
            </>

            
        }

        </>
    )
}

export default ScholarshipProfile;