import {
    UPDATE_GUARDIAN_MESSAGE_REQUEST,
    UPDATE_GUARDIAN_MESSAGE_SUCCESS,
    UPDATE_GUARDIAN_MESSAGE_FAIL,
  } from "../../../Utils/constants/HighschoolConstants/Message/UpdateTokenConstant";
import axiosInstance from "../../../Utils/helper/axiosInstance";
  
  export const UpdateReferenceToken =
    (id, token) => async (dispatch, getState) => {
      try {
        dispatch({ type: UPDATE_GUARDIAN_MESSAGE_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.put(
          `/reference/updateToken/${id}/${token}`,
          config
        );
        dispatch({ type: UPDATE_GUARDIAN_MESSAGE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: UPDATE_GUARDIAN_MESSAGE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };