import { GET_CITY_FAIL, GET_CITY_REQUEST, GET_CITY_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CityConstants";
 
export const listCitiesReducer = (state = { cities: [] }, action) => {
    switch (action.type) {
      case GET_CITY_REQUEST:
        return { cities_loading: true };
      case GET_CITY_SUCCESS:
        return { cities_loading: false, cities: action.payload, cities_success: true };
      case GET_CITY_FAIL:
        return { cities_loading: false, cities_error: action.payload, cities_success: false };
      default:
        return state;
    }
  };
  