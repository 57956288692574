import SimulatorHeader from "../../SimulatorHeader"
import ResultDetails from "./ResultDetails"
import Regions from './Regions';
import RecommendedCollege from './RecommendedCollege';
// import RecommendedCourses from "./RecommendedCourse";
import {useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useContext } from 'react';
import { getStudentCollegeBySimulationId } from "../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeSimulationBySimulationId";
import { Spin } from "antd";
import { AuthContext } from "../../../AuthContext/AuthContext";

const CollegeResult = () => {
    const {highschools} = useContext(AuthContext)
    const dispatch = useDispatch();
    const {simulation_id} = useParams();
    const {collegeResult_error = {}, collegeResult = {}, loading_simulationId} = useSelector(state => state.getStudentCollegebySimulationId)
    const {data={}} = collegeResult;
    const {message:college_error_message} = collegeResult_error
    const {student_details = {}} = data;
    const {user} = student_details;
    const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
    const {data:local_info = {}} = tokenInfo;
    const {highSchoolProfile:highschoolprofile_info = {}} = local_info;
    const {user_type:profile_type} = highschoolprofile_info
    
    useEffect(() => {
        dispatch(getStudentCollegeBySimulationId(simulation_id))
    }, [dispatch, simulation_id])

    return (
        <>
          {
             loading_simulationId ? <Spin size="large" className="mt-5"/> :
             <>
                <div className="result__college_result_wrapper">
                    <div className="result__page recommended__college">
                        {" "}
                        <div className="container">
                            <SimulatorHeader
                                name="College Simulator"
                                logo="/trav4college_logo.png"
                            />
                        </div>
                            <ResultDetails user_details={user} profile_type={profile_type} simulation_id={simulation_id} highschools={highschools}/>
                        <div className="result_region">
                        <Regions region_result={data} region_error={college_error_message}/>
                        </div>
                        <div className="recommended_college">
                            <RecommendedCollege college_result={data} college_error={college_error_message}/>
                        </div>
                        {/* <div className="recommended_courses">
                            <RecommendedCourses course_result={data} course_error={college_error_message}/>
                        </div> */}
                    </div>
                </div>
             </>
          }
        </>
    )
}

export default CollegeResult