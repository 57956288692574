import {SEND_HELPDESK_REQUEST, SEND_HELPDESK_SUCCESS, SEND_HELPDESK_FAIL} from "../../Utils/constants/HelpdeskConstants";
import axiosInstance from "../../Utils/helper/axiosInstance";

export const helpDeskAction = (helpdeskData) => async (dispatch, getState) => {
    try {
       dispatch({type: SEND_HELPDESK_REQUEST})
       const {
           tokenAuth: { tokenInfo }
       } = getState();

    const config = {
        headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json"
        }
    };

    const data = await axiosInstance.post(
        `/forms/help_support`, helpdeskData,
        config
    )
    if(data.status === "failed"){
        return dispatch({type: SEND_HELPDESK_FAIL, payload: data.reason.message})
    }
        dispatch({type: SEND_HELPDESK_SUCCESS, payload: data})
    
    } catch (error) {
        dispatch({type: SEND_HELPDESK_FAIL, payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message})
    }
}