import { GET_COLLEGE_APPLICATION_BY_ID_REQUEST, GET_COLLEGE_APPLICATION_BY_ID_SUCCESS, GET_COLLEGE_APPLICATION_BY_ID_FAIL } from "../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplByIdConstants";

export const getStudentCollegeApplByIdReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLEGE_APPLICATION_BY_ID_REQUEST:
            return {
                loading_collegeappl: true
            }
        case GET_COLLEGE_APPLICATION_BY_ID_SUCCESS:
            return {
                    ...state,
                    loading_collegeappl: false,
                    collegeApplResult: action.payload,
                    loading_collegeappl_sucess: true
            }
        case GET_COLLEGE_APPLICATION_BY_ID_FAIL:
            return {
                ...state,
                loading_collegeappl: false,
                collegeapplResult_error: action.payload,
                loading_collegeappl_sucess: false
            }        
        default:
            return state;
    }
}