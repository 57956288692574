import { GET_COLLEGE_APPL_LIST_FAIL, GET_COLLEGE_APPL_LIST_REQUEST, GET_COLLEGE_APPL_LIST_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplistsConstant";

export const getStudentsCollegeApplReducer = (state={}, action)  => {
    switch(action.type){
        case GET_COLLEGE_APPL_LIST_REQUEST:
            return {loading: true}
        case GET_COLLEGE_APPL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                collegeAppl_list: action.payload,
                collegeAppl_list_success: true
            }
        case GET_COLLEGE_APPL_LIST_FAIL:
            return {
                ...state,
                loading: false,
                collegeAppl_list_error: action.payload,
                collegeAppl_list_success: false
            }
        default:
            return state
    }
}