import { UPDATE_PROFILE_FAIL, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS } from "../../../../../../../Utils/constants/StudentProfileContants/updateProfileConstants";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";

export const updateProfileAction =
(dataToSubmit) => async (dispatch, getState) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });
    const {
      tokenAuth: { tokenInfo },
    } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${tokenInfo.data.userToken}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axiosInstance.put(
      "/highschools/student_profile",
      dataToSubmit,
      config
    );
    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
