import "antd/dist/antd.css";
// import {Redirect} from "react-router-dom";
// import {useContext} from "react";
// import { AuthContext } from "./components/Pages/AuthContext/AuthContext";
import DashboardLayout from "./components/Pages/DashboardLayoutPage/index";
import {HIGHSCHOOL_LIVE_URL} from "./Utils/constants/Local_URL"
import { Redirect } from "react-router-dom";

function App() {
    const getUserToken = localStorage.getItem("tokenInfo");

    if (!getUserToken) {

      window.location.href = HIGHSCHOOL_LIVE_URL;
    } 
  return (
    <>
    {getUserToken && getUserToken && <Redirect to="/dashboard/"/>}
    {getUserToken && getUserToken && <DashboardLayout />}
      {/* <DashboardLayout userData={userData}/> */}
    </>
  );
}

export default App;
