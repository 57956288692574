import {
    VIEW_GUARDIAN_MESSAGE_REQUEST,
    VIEW_GUARDIAN_MESSAGE_SUCCESS,
    VIEW_GUARDIAN_MESSAGE_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Message/ViewMessageConstants";

  export const ViewMessageReducer = (state = {}, action) => {
    switch (action.type) {
      case VIEW_GUARDIAN_MESSAGE_REQUEST:
        return { loading_views: true };
      case VIEW_GUARDIAN_MESSAGE_SUCCESS:
        return {
          ...state,
          loading_views: false,
          viewed_message: action.payload,
          view_success: true
        };
      case VIEW_GUARDIAN_MESSAGE_FAIL:
        return {
          ...state,
          loading_views: false,
          viewed_message_error: action.payload,
          view_success: false
        };
      default:
        return state;
    }
  };