import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getStudentCareerBySimulationId } from '../../../Redux/actions/SimulationActions/CareerSimulationActions/getCareerSimulationBySimulationId';
import Loader from '../LoadersPage';
import SimulatorHeader from '../SimulatorPage/SimulatorHeader';
import './viewmore.css'
const ViewMoreCareers = () => {
    const {simulation_id} = useParams()
    const dispatch = useDispatch();
    const {loading_simulationId, careerResult = {}} = useSelector(state => state.getStudentCareerbySimulationId)
    const {data = {}} = careerResult;
    const {type = [],suggested_careers = []} = data;

 
  useEffect(() => {
    dispatch(getStudentCareerBySimulationId(simulation_id))
  }, [dispatch, simulation_id])


  return (
    <>
    {loading_simulationId && <Loader loading={loading_simulationId}/>}
    {loading_simulationId && <Loader loading={loading_simulationId}/>}
    {loading_simulationId && <Loader loading={loading_simulationId}/>}
    {
        loading_simulationId ? <Loader loading={loading_simulationId}/> : 
        <>
         <div className="container">
         <SimulatorHeader  name="Career list" logo="/trav4college_logo.png" />

         <div className="row">
             <div className="text-center" style={{ fontSize: "1.7em" }}>CAREER LIST OF A <b style={{textTransform: 'uppercase'}}>{type && type.map(type => type.personality_type).join(", ")}</b></div>
            {suggested_careers && suggested_careers?.map(careers => 
                    <div className='col-md-4 mb-4 mt-4'>
                        <div className="card m-1 shadow careers-list" style={{height: '320px'}}>
                            <div className="card-body career-text">
                                <h5 className="card-title" style={{ color: "#0480FC", textTransform: 'uppercase', fontWeight: '900'}}>{careers.careers}</h5>
            <p className="card-text career-text" style={{fontWeight: '600'}}>{careers.description ? careers.description : "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s"}</p>
                            </div>
                        </div>
                        </div>
            )}
        </div>
        </div>
        </>
      
       
    }
   
    </>
  );
}

export default ViewMoreCareers;
