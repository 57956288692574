import { useContext, useState } from "react";
import PersonalProfileUpdate from "./PersonalProfileUpdate";
import "../../../ParentsDashboard/css/parent.css";
import { AuthContext } from "../../../../../AuthContext/AuthContext";
import { Layout } from "antd";
const { Content } = Layout;
// import Document from "./Document";
// import Educational from "./Educational";
// import Setting from "./Setting";
const ProfileUpdate = () => {
    const {user, user_error}  = useContext(AuthContext)
    const [error, setError] = useState(user_error)
    const [tabIndex, setTabIndex] = useState(0);
 
    const tabs = [
        {name: "Personal", content: <PersonalProfileUpdate user_detail={user}/>}, 
        // {name: "Educational", content: <Educational />},
        // {name: "Documents", content: <Document />},
        // {name: "Settings", content: <Setting />}
    ]

    const handleTabsChange = (index) => {
        setTabIndex(index)
    }
    setTimeout(() => {
        setError("")
    }, 5000)
    return (
        <Layout className="ant-layout">
         
            <Content style={{ margin: "4px 16px 0" }}>
            <div>
                <div className="tabs__container">
                <ul>
                        {
                            tabs && tabs.map((tab, index) => (
                            
                                <li  key={index} className={tabIndex === index ? `tabs_link   active_tabs` : `tabs_link`} onClick={() => handleTabsChange(index)}>{tab.name}</li>
                            
                            ))
                        }
                    </ul>
                </div>
                {error && <p className="text-center text-danger">{error.message}</p>}
                {tabIndex === 0 && tabs[0].content}
                {/* {tabIndex === 1 && tabs[1].content}
                {tabIndex === 2 && tabs[2].content}
                {tabIndex === 3 && tabs[3].content} */}
            </div>
            </Content>
         
        </Layout>
    )
}


export default ProfileUpdate