import { Link, useHistory } from "react-router-dom";

const Jumbotron = ({
    jumbotron_header,
    jumbotron_content,
    jumbotron_btn2,
    jumbotron_btn,
    jumbotron_img,
    user_detail
  }) => {
  
    const userData = user_detail && user_detail?.data
    const getIsComplete = userData?.highSchoolProfile && userData?.highSchoolProfile.is_complete
    const history = useHistory();
    
    const goToProfile = () => {
      history.push("/dashboard/profile/profile-update")
      window.location.reload()
    }

    const goToProfileUpdate = () => {
      history.push("/dashboard/profile/profile-update")
      window.location.reload()
    }
    return (
      <div className="new_jumbotron">
        <div className="row">
          <div className="col-md-8 col-12">
            <h2>{jumbotron_header}</h2>
            <p>{jumbotron_content}</p>
            <div className="btns">
            
               <div className="btn btn-secondary btn-complete">
               {getIsComplete ? <Link to="" onClick={goToProfile}>{jumbotron_btn2}</Link> : <Link to="" onClick={goToProfileUpdate}>{jumbotron_btn}</Link>}
                    
              </div>
    
            </div>
          </div>
          <div className="col-md-4 col-12 new_user">
            <img
              src={jumbotron_img}
              alt="dashboard-pics"
              className="d-xs-none d-sm-none d-md-block "
            />
          </div>
        </div>
      </div>
    );
  };
  
  export default Jumbotron;
  