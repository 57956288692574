import { PARENT_WARD_CONNECTION_REQUEST, PARENT_WARD_CONNECTION_SUCCESS, PARENT_WARD_CONNECTION_FAIL } from "../../../../../../../Utils/constants/HighschoolConstants/ParentConstants/ParentWardConstants";

export const ParentWardLinkReducer = (state={}, action) => {
    switch (action.type) {
        case PARENT_WARD_CONNECTION_REQUEST:
          return {
              link_loading: true
          }
        case PARENT_WARD_CONNECTION_SUCCESS:
            return {
                ...state,
                link_loading: true,
                ward: action.payload
             }  
        case PARENT_WARD_CONNECTION_FAIL:
                return {
                    ...state,
                    link_loading: true,
                    link_error: action.payload
                 }      
    
        default:
            return state;
    }
}