import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../simulator.css";
import {Link, useHistory, useParams} from "react-router-dom"
// import { collegeSimulationCreate } from '../../SimulatorPage/Redux/action/CollegeQuestionActions/SubmitCollegeSimulationAction';
import ScholarshipAnswerForm from "./Answer_folder/Index";
import SimulatorHeader from "../../SimulatorHeader";
import { listContinents } from "../../Redux/action/CollegeQuestionActions/GetContinentAction";
import { listMatchedCountries } from "../../Redux/action/CollegeQuestionActions/GetCountryAction";
import { AuthContext } from "../../../AuthContext/AuthContext";
import { CompletePaymentWithPayStack } from "../../../../../Redux/actions/Payments/CompletePaymentAction";
// import { PayWithPayStack } from "../../../../../Redux/actions/Payments/PaymentAction";
import { listColleges } from "../../Redux/action/CollegeQuestionActions/GetCollegesAction";
import { GetCollegeProductAction } from "../../../../../Redux/actions/HighschoolActions/GetProductAction";
import { getDegreesAction } from "../../Redux/action/CollegeQuestionActions/GetDegreeAction";
import { HIGHSCHOOL_LIVE_URL } from "../../../../../Utils/constants/Local_URL";
import { getCourses } from "../../Redux/action/CollegeQuestionActions/GetCoursesAction";
import { postScholarshipProfileAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/SubmitScholarShipProfileAction";

const ScholarshipApplicationChoices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {type} = useParams();
  // const [codeReference, setCodeReference] = useState("");
  const {user = {}} = useContext(AuthContext)
  const {data:user_data = {}} = user;
  const {phone, email, first_name:name} = user_data;
  const { loading, continents, error } = useSelector(
    (state) => state.continentLists
  );
  const { product_college = {}} = useSelector(
    (state) => state.college_product
  );
  const communication_modes = ['sms', 'email', 'push_notification', 'whatsapp'];
  const {data:college_product_data = []} = product_college;
  const filter_college_product = college_product_data.find(u => u);
  const stringed_amount = filter_college_product?.amount;
  const padded_amount = stringed_amount?.toString().padEnd(7, '000');
  const amount = +padded_amount;

  const { college_loading, college_list, college_error } = useSelector(
    (state) => state.colleges
  );
  const {payment_result = {}, payment_loading} = useSelector(state => state.payment);
  const {data:payment_data = {}, status:initial_success} = payment_result;
  const {reference} = payment_data;
  const {scholar_loading} = useSelector(state => state.submitScholarshipProfile)

  const {
    loading: countryLoad,
    countries,
    error: countryError,
  } = useSelector((state) => state.countryLists);
  const {courses,loading: loadCourses,error: courseError} = useSelector((state) => state.coursesSearch);
  const {degrees = {}} = useSelector(state => state.degree);
  const {data:degreeData = []} = degrees;
  const [page, setPage] = useState(1);
  const [getValue, setGetValue] = useState([]);
  const [country, setCountry] = useState([]);
  const [college, setCollege] = useState([]);
  const [tempCourse, setTempCourse] = useState([]);
  const [errorMessage, setErrorMessage] = useState("")
  
  const [commModes, setCommModes] = useState([]);
  const [tempCommModes, setTempCommModes] = useState("");
  const [continent, setContinent] = useState([]);
  const [level, setLevel] = useState("");
  const [values, setValues] = useState([]);
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("")

  const selectedCountry = country;
  const selectedCollege = college;
  // console.log("college_list", college_list?.data.slice(0, 30));
  const handleChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData([...data, parseInt(e.target.value)]);
  };
  const handleChangeCommunication = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData([...data, e.target.value]);
  };
  const handleInputChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData(e.target.value);
  };
  const remove = (name, data, setData) => {
    const cont = data.filter((c) => c !== name);
    setData(cont);
  };

  const handleCompletePayment = (reference) => {
    dispatch(CompletePaymentWithPayStack({reference: reference.reference}))
    history.push("/dashboard/my-scholarships")
  }

  const getCourse = tempCourse && tempCourse.map(course => course.id)

// Payment 
const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_DEVELOPMENT;

const dataToSubmit = {
  countries: country,
  fields: getCourse,
  degree_type_id: level,
  communication_modes: commModes
};

const componentProps = {
  publicKey,
  text: `Pay N${stringed_amount?.toLocaleString()}`,
  email,
  amount,
  reference,
  metadata: {
    name,
    phone,
  },
  onSuccess: (reference) => handleCompletePayment(reference)
  ,
  onClose: console.log("button closed!"),
}
const { loading: create_simulator_loading, error: create_simulator_error, success,  result={}} = useSelector(
  state => state.createSimulator
  );
  
  const {data:result_data = {}} = result;
  const {data:my_data = {}} = result_data;
  const {id:simulation_id} = my_data;
  
  useEffect(() => {
    if (create_simulator_loading) {
      console.log("")
    } else if (success){
      const {data:result_data = {}} = result;
      const {data:my_data = {}} = result_data;
      const {id:simulation_id} = my_data;
      let path = `/college-simulator/result/${simulation_id}`; 
      history.push(path);
    }
  }, [create_simulator_loading, success, history, result])
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!level || !countries || !commModes || !tempCourse){
      setErrorMessage("Fields must not be empty!")
    } else {
      dispatch(postScholarshipProfileAction(dataToSubmit, type))
      if (scholar_loading) {
        return "loading...";
      } else {
         history.push("/dashboard/my-scholarships");
      }
    }
  };
  
  const filterName = (id, data) => {
    return data && data.filter((c) => c.id === id);
  };

  const filterCommunication = (id, data) => {
    return data && data.filter((c) => c === id);
  };
  
  function onChange(value) {
    setGetValue(value);
  }

  function onAfterChange(value) {
    setGetValue(value);
  }
  
  const goToNextPage = () => {
    setPage(page + 1);
  };
  const goToPrevPage = () => {
    if (page < 1) {
      setPage(page);
    }
    setPage(page - 1);
  };
  
  const getUserToken = localStorage.getItem("tokenInfo");
  let getTokenJson = {};
  
  if (!getUserToken) {
    window.location.href = HIGHSCHOOL_LIVE_URL;
  } else {
    getTokenJson = getUserToken && JSON.parse(getUserToken);
  }
  // console.log("courses", getValue)
  
  useEffect(() => {
    dispatch(listContinents());
    dispatch(listColleges());
    dispatch(GetCollegeProductAction())
    dispatch(getDegreesAction())
  }, [dispatch]);

  useEffect(() => {
    dispatch(listMatchedCountries(continent.join(",")));
  }, [dispatch, continent]);

  useEffect(() => {
    dispatch(getCourses(search));
  }, [dispatch, search]);

  const setRecentValue = (val) => {
    
     setSearch(val)
     setDisplay(false)
  }

  const getSelectedCourseData = (data) => {
    setTempCourse([...tempCourse, data])
  }
  return (
    <>
      {page === 6 ? (
        <div className="result__wrapper">
          <div className="result__page">
            {" "}
            <div className="container">
              <SimulatorHeader
                name="College Application test"
                logo="/trav4college_logo.png"
              />
              <ScholarshipAnswerForm
                page={page}
                setPage={setPage}
                errorMessage={errorMessage}
                commMode={communication_modes}
                handleChangeCommunication={handleChangeCommunication}
                setCommMode={setCommModes}
                communications={commModes}
                level={level}
                selectedCountry={selectedCountry}
                setLevel={setLevel}
                country={country}
                setCountry={setCountry}
                college={college}
                setCollege={setCollege}
                college_list={college_list}
                getValue={getValue}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCommModes={tempCommModes}
                setTempCommModes={setTempCommModes}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                filterName={filterName}
                filterCommunication={filterCommunication}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                college_error={college_error}
                college_loading={college_loading}
                selectedCollege={selectedCollege}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
                college_product={filter_college_product}
                degrees={degreeData}
                componentProps={componentProps}
                initial_success={initial_success}
                payment_loading={payment_loading}
                scholar_loading={scholar_loading}
              />
            </div>
          </div>
            <Link to={`/college-simulator/result/${simulation_id}`}></Link>
          {/* <div className="recommended_college">

            <CollegeSimulationResult college_simulation_id={simulation_id}/>
          </div> */}
        </div>
      ) : (
        <div className="choices_wrapper">
          <div className="container">
            <SimulatorHeader
              name="College Simulator"
              logo="/trav4college_logo.png"
            />
             <ScholarshipAnswerForm
                page={page}
                setPage={setPage}
                commMode={communication_modes}
                setCommMode={setCommModes}
                communications={commModes}
                handleChangeCommunication={handleChangeCommunication}
                level={level}
                selectedCountry={selectedCountry}
                setLevel={setLevel}
                country={country}
                setCountry={setCountry}
                college={college}
                setCollege={setCollege}
                college_list={college_list}
                getValue={getValue}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCommModes={tempCommModes}
                setTempCommModes={setTempCommModes}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                filterName={filterName}
                filterCommunication={filterCommunication}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                selectedCollege={selectedCollege}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
                college_product={filter_college_product}
                degrees={degreeData}
                componentProps={componentProps}
                initial_success={initial_success}
                payment_loading={payment_loading}
                scholar_loading={scholar_loading}
              />
          </div>
        </div>
      )}
    </>
  );
};

export default ScholarshipApplicationChoices;
