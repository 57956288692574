import AllStudents from "./ListOfStudents"
import NumberOfStudents from "./NumberOfStudents"

const StudentPage = ({showAddStudent,handleShowAddStudent}) => {
    return (
        <div className="container container-width">
            <NumberOfStudents showAddStudent={showAddStudent} handleShowAddStudent={handleShowAddStudent}/>
            <AllStudents />
        </div>
    )
}

export default StudentPage