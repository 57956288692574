import { FETCH_COLLEGE_TUITION_FAIL, FETCH_COLLEGE_TUITION_REQUEST, FETCH_COLLEGE_TUITION_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/TuitionRangeConstants";
 
export const fetchTuitionRange = (state = {}, action) => {
    switch (action.type) {
      case FETCH_COLLEGE_TUITION_REQUEST:
        return { tuition_loading: true };
      case FETCH_COLLEGE_TUITION_SUCCESS:
        return { tuition_loading: false, tuitions: action.payload, tuition_success: true };
      case FETCH_COLLEGE_TUITION_FAIL:
        return { tuition_loading: false, tuitions_error: action.payload, tuition_success: false };
      default:
        return state;
    }
  };
  