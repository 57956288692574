import axiosInstance from "../../../../Utils/helper/axiosInstance";
import { POST_SCHOLARSHIP_PROFILE_REQUEST, POST_SCHOLARSHIP_PROFILE_SUCCESS, POST_SCHOLARSHIP_PROFILE_FAIL } from "../../../../Utils/constants/SimulationConstants/Scholarships/SubmitScholarShipProfileConstant";

export const postScholarshipProfileAction = (dataToSubmit, type) => async (dispatch, getState) => {
    try {
 
     dispatch({type: POST_SCHOLARSHIP_PROFILE_REQUEST});
     const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
 
       if (type === 'create') {
        const { data } = await axiosInstance.post(
          `/scholarshipsprofile`,
          dataToSubmit,
          config
        );
       dispatch({type: POST_SCHOLARSHIP_PROFILE_SUCCESS, payload: data})

       } else if (type === 'update') {
          const { data } = await axiosInstance.put(
            `/scholarshipsprofile`,
            dataToSubmit,
            config
          );
        dispatch({type: POST_SCHOLARSHIP_PROFILE_SUCCESS, payload: data})
       }
      //  const { data } = await axiosInstance.post(
      //    `/scholarshipsprofile`,
      //    dataToSubmit,
      //    config
      //  );
 
      //  dispatch({type: POST_SCHOLARSHIP_PROFILE_SUCCESS, payload: data})
        
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data
       : error.message;
       dispatch({type: POST_SCHOLARSHIP_PROFILE_FAIL, payload: message})
    }
 }