import "./style.css";
import { useEffect } from "react";
import Loader from "../../../LoadersPage";
import { useDispatch, useSelector } from "react-redux";
import { getScholarshipProfileAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/ScholarshipProfileAction";
import ScholarshipProfile from "./ScholarshipProfile";
// import { Link } from "react-router-dom";
import StarterPage from "./StarterPage";

const ScholarshipIndexPage = () => {
    const dispatch = useDispatch();
    const {scholar_profile = {}, scholar_loading} = useSelector(state => state.scholarshipProfile);
    const {data = {}} = scholar_profile

    useEffect(() => {
      dispatch(getScholarshipProfileAction())
    }, [dispatch])
    return (
        <>
        {console.log(scholar_loading)}
        {
            scholar_loading ? <Loader loading={scholar_loading}/>
            :
        <>
        {
            data && data.message ? 
            <div className="container mt-5">
                <div className="row mx-auto justify-content-center">
                        <StarterPage userType={'student'}/>
                </div>
            </div> : <ScholarshipProfile data={data}/>
        }
        </>
        }
        </>
    )
}
export default ScholarshipIndexPage