import { REGISTER_USER_REQUEST, REGISTER_USER_SUCCESS, REGISTER_USER_FAIL } from "../../../../Utils/constants/registerConstants";
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const registerUserAction = (userData) => async (dispatch) => {
    try {
        dispatch({type: REGISTER_USER_REQUEST})
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.post(
            "/register", userData, config
        );
        dispatch({type: REGISTER_USER_SUCCESS, payload: data});
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: REGISTER_USER_FAIL, payload: message})
    }
}