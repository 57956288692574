import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom"
import { getAuthToken } from "../../../Redux/actions/authActions"
import {userAction} from "../../../Redux/actions/userAction"
import {Redirect} from "react-router-dom"
// import { getLocalData } from "./Protected/auth";
import "./auth.css";
import Alerts from "../../Alerts/Alert"
import {HIGHSCHOOL_LIVE_URL} from "../../../Utils/constants/Local_URL"
const AuthPage = () => {
    const dispatch = useDispatch();
    const tokenAuth = useSelector(state => state.tokenAuth);
    const search = useLocation().search;
    const pt = new URLSearchParams(search).get("pt");
    let { loading, error, status, success } = tokenAuth;
    success = success && success;
    const localUser = JSON.parse(localStorage.getItem('tokenInfo'))?.data?.highSchoolProfile
    useEffect(() => {
        dispatch(userAction())
    }, [dispatch])

    useEffect(() => {
        dispatch(getAuthToken(pt));
    }, [dispatch, pt]);

    const onClose = () => {
        window.location.href = HIGHSCHOOL_LIVE_URL;
    };

    return (
        <>
            <div className="auth_page">
                {loading && (
                        <div
                        className="spinner-border text-dark"
                        role="status"
                        style={{ width: "5rem", height: "5rem" }}
                        >
                        <span className="visually-hidden">Loading...</span>
                        </div>
                )}
                {error && error && (
                        <div>
                        <Alerts
                            description={error}
                            type="error"
                            message={status}
                            closable
                            onClose={onClose}
                        />
                        </div>
                )}
      {/* {success &&  userData?.user_type && userData?.user_type === "counsellor" && <CounsellorOverview/>} */}

        {localUser === null && <Redirect to="/existing"/>}
        {success && localUser && <Redirect to="/dashboard/"/>}
            </div>
        </>
    );

}

export default AuthPage;
