import { GET_SCHOLARSHIP_PROFILE_REQUEST, GET_SCHOLARSHIP_PROFILE_SUCCESS, GET_SCHOLARSHIP_PROFILE_FAIL } from "../../../../Utils/constants/SimulationConstants/Scholarships/ScholarshipProfileConstants";
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getScholarshipProfileAction = () => async (dispatch, getState) => {
    try {
 
     dispatch({type: GET_SCHOLARSHIP_PROFILE_REQUEST});
     const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
 
       const { data } = await axiosInstance.get(
         `/scholarshipsprofile`,
         config
       );
 
       dispatch({type: GET_SCHOLARSHIP_PROFILE_SUCCESS, payload: data})
        
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data
       : error.message;
       dispatch({type: GET_SCHOLARSHIP_PROFILE_FAIL, payload: message})
    }
 }