import { useSelector, useDispatch } from "react-redux";
import "./style.css"
import { Redirect, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { GetScholarshipProductAction } from "../../../../../Redux/actions/HighschoolActions/GetProductAction";
import { ScholarPayWithPayStack } from "../../../../../Redux/actions/Payments/ScholarshipPayAction";
import { PaystackButton } from "react-paystack"
import { ScholarCompletePaymentWithPayStack } from "../../../../../Redux/actions/Payments/CompleteScholarshipPayAction";
import { getScholarshipsAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/GetScholarshipsAction";

const ScholarPaymentPage = () => {
    const { scholar_product_college = {}} = useSelector(
        (state) => state.scholar_product
      );
    const {data:scholar_product_data = []} = scholar_product_college;
    let dollarUSLocale = Intl.NumberFormat('en-US');
    const dispatch = useDispatch();
    const [amount, setAmount] = useState("");
    const {scholarPay_init_complete = {}} = useSelector(state => state.scholarInitPayment);
    const {data:payment_data = {}} = scholarPay_init_complete;
    const {reference} = payment_data;
    const [displayOptions, setDisplayOption] = useState({id: null})
    const localUser = JSON.parse(localStorage.getItem('tokenInfo'));
    const {data: localData = {}} = localUser
    const {email: localEmail} = localData
    const myamount = amount.toString().length <= 4 ? "0" + amount : amount;
    const padded_amount = myamount?.toString().padEnd(7, '000');
    const amounts = +padded_amount
    const history = useHistory()
    const [ref, setRef] = useState(false)
    const {scholarPay_complete = {}} = useSelector(state => state.scholarCompletePayment);
    const {data: success_data} = scholarPay_complete
    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_DEVELOPMENT;

    // ------ Redirecting --------//

    const {get_scholar_profile = {}} = useSelector(state => state.getScholarships);
    const {data: scholar_data = {}} = get_scholar_profile
    const {hasScholarshipProduct} = scholar_data;

    useEffect(() => {
        dispatch(getScholarshipsAction())
    }, [dispatch])

    const handleSubmit = (e,id, amount) => {
      e.preventDefault()
      setAmount(amount)
      setDisplayOption({id: id})
      dispatch(ScholarPayWithPayStack({product_id: id}))
      setRef(true)
    };

    const config = {
      reference: reference,
      email: localEmail,
      amount: amounts,
      publicKey,
    };

    useEffect(() => {
            if(success_data){
              history.push("/dashboard/my-scholarshipslists");
            } else {
              console.log("")
              }
    }, [success_data, history])

    useEffect(() => {
        dispatch(GetScholarshipProductAction())
    }, [dispatch]);

      // you can call this function anything
  const handleSuccess = (reference) => {
    // Implementation for whatever you want to do with reference and after success call.
    if (reference.reference) {
      setRef(false)
      dispatch(ScholarCompletePaymentWithPayStack({reference: reference.reference}))
    } else{
      return false;
    }
  };

  // you can call this function anything
  const handleClose = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    console.log('closed')
    setRef(false)
    setDisplayOption({id: null})
  }

  const componentProps = {
    ...config,
    text: `Pay N${dollarUSLocale.format(+amount)}`,
    onSuccess: (reference) => handleSuccess(reference),
    onClose: handleClose
};

   return (
       <>
          <div className="container">
          {
                     hasScholarshipProduct && <Redirect to="/dashboard/my-scholarshipslists"/> 
                    }
              <div className="row">
                    <div className="col-md-12 mb-5" style={{ display: "grid", placeItems: "center" }}>
                            
                            { 
                                scholar_product_data && scholar_product_data?.map(({amount, id, name, display_name, description}) => 
                                <>
                                <div className="box orange col-md-6 mb-5 text-center" key={id}>
                                    <p style={{ color: "#0480FC", fontWeight: "800" }}>{display_name}</p>
                               <span>
                                <h3><b>N{dollarUSLocale.format(+amount)}</b>/{description}</h3>
                                </span>
                                {
                displayOptions.id === id ? <span>
                  {
                    ref ? <PaystackButton className="paystack-button" {...componentProps} />: null
                  }
                </span>  :  <button className="btn col-md-12 mb-2" onClick={(e) => handleSubmit(e,id, amount)}>Subscribe now
                </button>  
              }
                                </div>
                                </>
                                )
                            }

                    </div>
              </div>
          </div>
       </>
   )
}

export default ScholarPaymentPage;