import ListNews from "./ListNews";
import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getNewsListAction } from "../../../../../Redux/actions/getNewsListAction";
import Loader from "../../../LoadersPage";
import { Pagination } from "antd";

const NewsPage = () => {
    const dispatch = useDispatch()

    // const news = ['1','2','3'];
    const { news_loading, error, news = [] } = useSelector(state => state.newsLists)
    const { get_page = {}, get_page_loading } = useSelector(state => state.pages);
    const { data: news_data = {} } = news
    const { data: all_news = [], total, current_page, per_page } = news_data;

    useEffect(() => {
        dispatch(getNewsListAction(1))
    }, [dispatch])

    const onChange = useCallback((pageNumber) => {
        dispatch(getNewsListAction(pageNumber))
    }, [dispatch])

    useEffect(() => {
        onChange(1)
    }, [onChange])

    return (
        <>
            {
                news_loading ? <Loader loading={news_loading} />
                    :
                    <>
                        {all_news.map((news) => <ListNews data={news} />)}
                        <Pagination style={{ display: "flex", justifyContent: "center" }} simple showQuickJumper className="mb-5 mt-4" defaultPageSize={per_page} defaultCurrent={current_page} total={total} onChange={onChange} />
                    </>
            }
        </>
    );
}

export default NewsPage; 