import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useContext } from "react";
import { GetCollegeApplicationsAction } from "../../../../../SimulatorPage/CollegeApplicationSimulator/Redux/actions/GetCollegeApplicationsAction";
import { Spin } from "antd";
import SimulatorHeader from "../../../../../SimulatorPage/SimulatorHeader";
import { getStudentProfileAction } from "../../../../../../../Redux/actions/getProfileAction";
import CollegeResultDetails from "./CollegeResultDetails";
import { AuthContext } from "../../../../../AuthContext/AuthContext";
import FirstChoiceCourse from "./FirstChoiceCourse";
import CollegeApplied from "./CollegeApplied";
import { getStudentCollegeApplById } from "../../../../../SimulatorPage/CollegeApplicationSimulator/Redux/actions/GetCollegeApplByIdAction";

const FullCollegeApplResult = () => {
    const {simulation_id} = useParams()
    const dispatch = useDispatch()
    const {highschools} = useContext(AuthContext)
    const {collegeApplListError: college_error} = useSelector(state => state.college_appl)
    const {collegeApplResult = {}, loading_collegeappl} = useSelector(state => state.student_college_appl);
    const {data:student_colege_appl = {}} = collegeApplResult;
    const {courses = [], colleges = [], student_details = {}} = student_colege_appl;
    const {id: student_id} = student_details
    // const {data = {}} = collegeApplList; 
    // const {data:college_data = []} = data; 
    // const [user_data, setUserData] = useState({})
    // const check_college_id = college_data.find(result_id => result_id.id === +simulation_id)
    // const user_id = check_college_id?.user_id
    const { user ={}} = useSelector(state => state.getUser);
    const {data:user_info = {}} = user;
    const {highSchoolProfile = {}} = user_info;
    const {user_type} = highSchoolProfile;
    // console.log("collegeApplResult", user_id)

    useEffect(() => {
      dispatch(GetCollegeApplicationsAction())
    }, [dispatch])

    useEffect(() => {
       dispatch(getStudentProfileAction(student_id))
    }, [dispatch, student_id])

    useEffect(() => {
        dispatch(getStudentCollegeApplById(simulation_id))
    }, [dispatch, simulation_id])

   return <>
        {
             loading_collegeappl ? <Spin size="large" className="mt-5"/> :
             <>
                <div className="result__college_result_wrapper">
                    <div className="result__page recommended__college">
                        {" "}
                        <div className="container">
                            <SimulatorHeader
                                name="College Application Simulator"
                                logo="/trav4college_logo.png"
                            />
                        </div>
                            <CollegeResultDetails user_details={student_id} profile_type={user_type} simulation_id={simulation_id} highschools={highschools}/>
                        <div className="recommended_college">
                            <CollegeApplied college_result={colleges} college_error={college_error}/>
                        </div>
                        <div className="recommended_courses">
                            <FirstChoiceCourse course_result={courses} college_error={college_error}/>
                        </div>
                    </div>
                </div>
             </>
          }
   </>
}

export default FullCollegeApplResult;


