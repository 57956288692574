import { CAREER_ANSWER_FAIL, CAREER_ANSWER_REQUEST, CAREER_ANSWER_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CareerQuestionnaire/CareerAnswersConstant";

export const careerAnswerReducer = (state={}, action) => {
    switch(action.type){
        case CAREER_ANSWER_REQUEST:
            return {
                loading: true,
            }
        case CAREER_ANSWER_SUCCESS: 
            return {
                ...state,
                loading: false,
                answers: action.payload,
                success: true
            }
        case CAREER_ANSWER_FAIL: 
            return {
                ...state,
                loading: false,
                error: action.payload,
                success: false
            }
        default:
            return state
    }
}