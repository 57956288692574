import { useParams } from 'react-router-dom';
import {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import SimulationResultsPage from "../../ViewSimulationList/Index";
import {getListOfStudentCareerSimulation} from "../../Redux/actions/getListOfStudentCareerList";

const CareerSimulationLists = () => {
    let { user_id, type } = useParams();
    const dispatch = useDispatch();
    
    const {listCareerSimulation, loading_list_career} = useSelector(state => state.listOfCareerStudents)
    // console.log("listCareerSimulation", listCareerSimulation);

    useEffect(() => {
        if(type === "career"){
            dispatch(getListOfStudentCareerSimulation(user_id))
        } else {
            return;
        }
    }, [dispatch, type, user_id])

   return (
       <>
            <SimulationResultsPage simulations={listCareerSimulation} loading={loading_list_career} type={type}/>
       </>
   )
}

export default CareerSimulationLists
