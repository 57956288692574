import { Row, Col } from 'antd';
import "./register.css";

const UserType = ({category, updateCategory}) => {

    return(
        <div className="category_container">
            <h2>Create Account</h2>
            <span>Let’s get started</span>
            <Row className="category_section">
                <Col className="gutter-row" span={8} onClick={() => updateCategory(1)}>
                    <div className={category === 1 ? `grid active_grid` : `grid`}>Student</div>
                </Col>
                <Col className="gutter-row" span={8} onClick={() => updateCategory(2)}>
                    <div className={category === 2 ? `grid active_grid single_grid` : `grid single_grid`}>Parent</div>
                </Col>
                <Col className="gutter-row" span={8} onClick={() => updateCategory(3)}>
                    <div className={category === 3 ? `grid active_grid single_grid2` : `grid single_grid2`}>Counsellor</div>
                </Col>
                
            </Row>
        </div>
    )
}
export default UserType;