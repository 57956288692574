import {
    GET_CONTINENTS_FAIL,
    GET_CONTINENTS_REQUEST,
    GET_CONTINENTS_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/ContinentsConstant";
  
  export const listContinentsReducer = (state = { continents: [] }, action) => {
    switch (action.type) {
      case GET_CONTINENTS_REQUEST:
        return { loading: true };
      case GET_CONTINENTS_SUCCESS:
        return { loading: false, continents: action.payload, success: true };
      case GET_CONTINENTS_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };
  