import "./simulationlist.css"
import Paginate from "../../../../../../Utils/helper/Paginate";
import {useState} from "react";
import { useParams, Link } from 'react-router-dom';
import Loader from "../../../../LoadersPage";

const SimulationLists = ({simulationList, loading}) => {
    let { type } = useParams();
    const filters = simulationList && simulationList.find(u => u)
  console.log("simulations", simulationList)
      

    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5)

    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = simulationList && simulationList.slice(indexOffirstStudent, indexOflastStudent);

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <>
         {loading && <Loader loading={loading}/>}
         {
            loading ? <Loader loading={loading}/> :
            <>
                     <div className="applicants_info_style">
        <div className="card">
           <div className="card-headers">
    <div className="student-header">{filters?.first_name} {filters?.last_name}</div>
           </div>
           <div className="divider"></div>

           <div className="table-responsive">
                   <table className="table table-hover">
                    <thead>
                        <tr align="middle" style={{ fontWeight: "700" }}>
                            <td>SIMULATIONS</td>
                            <td>ACTION</td>
                        </tr>
                    </thead>
                    <tbody className="table_body">
                    
                    {currentStudents?.map((simulation, index) => {
                                return (

                                <tr align="middle" key={index} className="shadow-sm">
                    <td>Simulation {index + 1}</td>
                                    <td className="simulation_status">
                                        {
                                            type === "career" ? <Link to={`/career-simulator/result/${simulation.simulation_id}`}><span className="application_status m-1">View result</span></Link>
                                            :
                                            <>
                                                    {
                                                        type === 'college-application' ? <Link to={`/college_appl-simulator/result/${simulation.id}`} className="application_status m-1">View result</Link> : <Link to={`/college-simulator/result/${simulation.simulation_id}`}><span className="application_status m-1">View result</span></Link>
                                                    }
                                            </>
                                            // 
                                        }
                                        {/* <span className="application_status m-1" onClick={() => handleViewSimulation(simulation.simulation_id, type)}>View result</span>  */}
                                    </td>
                                </tr>
                                )
                            }
                              )
                            }    
                         
                    </tbody>
                 </table> 
               </div>
         
            </div> {
                simulationList?.length >= 5 ?<Paginate studentPerPage={studentsPerPage} totalStudents={simulationList?.length} paginate={paginate} /> : null
            }
           
        </div>
            </>
         }
        </>
       
    )
}

export default SimulationLists
