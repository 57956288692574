import { useDispatch, useSelector } from "react-redux";
import {useEffect} from "react";
import {numberOfStudentsUnderACounsellor} from "../Redux/actions/NumberOfStudentsUnderCounsellor";
import { Card, Avatar, Skeleton, Row, Col } from 'antd';
const { Meta } = Card;

const NumberOfStudents = () => {

    const dispatch = useDispatch()

    const {count_loading, number_of_students} = useSelector(state => state.getStudentCount);

    const info = number_of_students && number_of_students?.data;
    console.log("count_studentss", info)

    useEffect(() => {
        dispatch(numberOfStudentsUnderACounsellor())
    }, [dispatch])

    return (
        <>
        {
            count_loading ? 
            <Row gutter={16}>
            <Col span={8}>
                <Card loading={count_loading}>
                    <Skeleton loading={count_loading} avatar active>
                        <Meta
                        avatar={<Avatar src="https://joeschmoe.io/api/v1/random" />}
                        title="Card title"
                        description="This is the description"
                        />
                    </Skeleton>
                </Card>
            </Col>
    
            </Row>
            : 
            <>
                <div className="row">
                    <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                        <div className="card application_details">
                            <div className="card-body">
                                <div className="student_add_section">
                                    <div className="detail_no">
                                    {info ? info : "0"}
                                    </div>
                                    {/* <div className="btn btn-primary" onClick={handleShowAddStudent}>Add a Student</div> */}
                                </div>
                                <div className="application_info">
                                    NUMBER OF STUDENTS
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
            </>
        }
        </>
    )
}

export default NumberOfStudents;