import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Button } from "antd"
import { useEffect, useState} from "react"
// import { Link } from "react-router-dom"
import "./register.css";
import { registerUserAction } from "./Redux/actions/registerAction"
import { listHighschoolsAction } from "../../Redux/actions/HighschoolActions/listHighschoolsAction"
import UserType from "./UserType";
import Alerts from "../Alerts/Alert"
import {HIGHSCHOOL_LIVE_URL} from "../../Utils/constants/Local_URL"
const Register = () => {

  const dispatch = useDispatch()
  const initialValues = {first_name: "", last_name: "", email: "", phone: "", password: "", password_confirmation: "", school_id: null}
  const [fieldsValue, setFieldsValue] = useState(initialValues)
  const [category, setCategory] = useState(1);
  const [student, setStudent] = useState("");
 
  const updateCategory = (index) => {
    setCategory(index)
 }

  const highSchoolLogin = () => {
  const links = {
      highschool_login: HIGHSCHOOL_LIVE_URL
  }
  window.location.replace(links.highschool_login)
}

  const {loading, user, error} = useSelector(state => state.registerUser);
  const {loading: highschoolsLoading, highschools, error: highschoolsError} = useSelector(state => state.listHighschools)

  let device_name, userType;
      if(category === 1){
         userType = "student";
      }else if(category === 2){
        userType = "parent"
      }else if(category === 3) {
        userType = "counsellor";
      }
  const choice = [
    { value: 'Yes' },
    {  value: 'No' },
  ];

  const {first_name, last_name, email, password, password_confirmation, phone, school_id} = fieldsValue;
  device_name = navigator.userAgent;

//  console.log("Return result when seen: ", user)

  const handleChange = (e) => {
       const { name, value } = e.target
       setFieldsValue({...fieldsValue, [name]: value});
  }
  const dataToSubmit = {first_name, last_name, email, password, password_confirmation, phone, device_name, user_type: userType, school_id, requested_app: "highschool", referee_app: "highschool"}


  let allError = <ul>
    {error?.errors.first_name && <li>{error.errors.first_name[0]}</li>}
    {error?.errors.last_name && <li>{error.errors.last_name[0]}</li>}
    {error?.errors.email && <li>{error.errors.email[0]}</li>}
    {error?.errors.phone &&  <li>{error.errors.phone[0]}</li>}
    {error?.errors.password &&  <li>{error.errors.password[0]}</li>}
  </ul>
  console.log(error)

  
  const registerUser = (e) => {
     e.preventDefault();
     if (!first_name || !last_name || !email || !password || !password_confirmation || !phone || !school_id) {
        return;
     }
     dispatch(registerUserAction(dataToSubmit))
     localStorage.removeItem('parent_')
  }

  useEffect(() => {
      dispatch(listHighschoolsAction())
  }, [dispatch])

  return (
   <div>
        {/* <Link to="admin/login" className="admin_login">Admin Login</Link> */}
      <div className="register">

      {user?.status && user?.status === "success" && <div style={{width: "70%"}}><Alerts description={user.status} message="message" closable type="success"/></div>}
      {user?.status === "success" && window.location.replace(HIGHSCHOOL_LIVE_URL)}
      <UserType category={category} updateCategory={updateCategory}/>
      <Form
        name="register"
        wrapperCol={{
          span: 18,
        }}
        className="register_form"
      >
        
         <div className="names">

            <Form.Item className="full_name">
                <Input placeholder="First Name" type="text" value={first_name} onChange={handleChange} name="first_name" required/>
            </Form.Item>
              <Form.Item className="full_name">
              <Input placeholder="Last Name" type="text" onChange={handleChange}  value={last_name} name="last_name"/>
      
          </Form.Item>
         </div>
        <Form.Item>
          <Input placeholder="Email Address" type="email" value={email} onChange={handleChange} name="email"/>
        </Form.Item>

        <Form.Item>
          <Input placeholder="Phone Number" type="text" onChange={handleChange} name="phone" value={phone} />
        </Form.Item>

        <Form.Item>
          <Input.Password placeholder="Password" type="password" className="password" onChange={handleChange} name="password" value={password} />
        </Form.Item>

        <Form.Item>
          <Input.Password placeholder="Confirm password" type="password" className="password" onChange={handleChange} name="password_confirmation" value={password_confirmation} />
        </Form.Item>
        <div className="check_school">
   {
     category !== 1 ? null : <Form.Item>
         <label>Are you a High School Student?</label>
          <select  className="select_input" onChange={(e) => setStudent(e.target.value)}  name="students">
          <option></option>
  
             {
               choice.map(({value}, index) => (             
                  <option key={index} value={value}>{value}</option>
               ))
             }
          </select>
          
        </Form.Item>
   }
  
      </div>

      {student === "Yes" && category === 1 ?
         <Form.Item>
              {highschoolsLoading ?  (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ width: "1rem", height: "1rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : highschoolsError ? (<div style={{width: "75%"}}>
              <Alerts
                description={highschoolsError}
                type="error"
                message={highschoolsError.message}
                closable
              />
            </div>) : (
                <select  className="select_input" onChange={handleChange} name="school_id">
                  <option>Select highschool</option>
                 { highschools?.data &&
                   highschools.data.map(({school_name, id}) => (             
                     <option key={id} value={id}>{school_name}</option>
                   ))
                 }
               </select>
            )}
        </Form.Item> : (category === 2 || category === 3) && <Form.Item>
        {highschoolsLoading ?  (
                <div
                  className="spinner-border text-dark"
                  role="status"
                  style={{ width: "1rem", height: "1rem" }}
                >
                  <span className="visually-hidden">Loading...</span>
                </div>
              ) : highschoolsError ? (<div style={{width: "75%"}}>
              <Alerts
                description={highschoolsError}
                type="error"
                message={highschoolsError.message}
                closable
              />
            </div>) : (
                <select  className="select_input" onChange={handleChange} name="school_id">
                  <option>Select highschool</option>
                 { highschools?.data &&
                   highschools.data.map(({school_name, id}) => (             
                     <option key={id} value={id}>{school_name}</option>
                   ))
                 }
               </select>
            )}
        </Form.Item>        
        }


        <Form.Item
          wrapperCol={{
            span: 18,
          }}
        >
            {error && error && (
              <div style={{width: "75%"}}>
                <Alerts
                  description={allError}
                  type="error"
                  message={error.message}
                  closable
                />
              </div>
            )}

          <Button type="primary" htmlType="submit" onClick={registerUser}>
          {loading ? (
        <div
          className="spinner-border text-dark"
          role="status"
          style={{ width: "1rem", height: "1rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : "create Account"}
          </Button>
        </Form.Item>
        <p className="account_login_link">Already had an account? 
        <button type="button" style={{ background: "none", border: "none" }} onClick={highSchoolLogin} className="login_link">
          Log in
            </button>
            </p>
        {/* <Link to=""  className="login_link">Log in</Link></p> */}
      </Form>
      </div>
      </div>
  )

}

export default Register;

