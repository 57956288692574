import {
    GET_GUARDIAN_NOTIFICATION_FAIL,
    GET_GUARDIAN_NOTIFICATION_REQUEST,
    GET_GUARDIAN_NOTIFICATION_SUCCESS,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/GetGuardianNotifiationConstant";
  
  export const GetNotificationReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_GUARDIAN_NOTIFICATION_REQUEST:
        return { get_notifs_loading: true };
      case GET_GUARDIAN_NOTIFICATION_SUCCESS:
        return {
          ...state,
          get_notifs_loading: false,
          notifs_result: action.payload,
          notifs_success: true
        };
      case GET_GUARDIAN_NOTIFICATION_FAIL:
        return {
          ...state,
          get_notifs_loading: false,
          notifs_error: action.payload,
          notifs_success: false
        };
      default:
        return state;
    }
  };