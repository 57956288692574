import {BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Layout } from 'antd';
import App from "../../App";
import AuthPage from "../../components/Pages/AuthPage/AuthPage";
import RegisterPage from "../../components/Register/RegisterPage";
import CareerResultRecommendation from "../../components/Pages/SimulatorPage/CareerSimulatorsPage/CareerResult";
import CollegeResult from "../../components/Pages/SimulatorPage/CollegeSimulatorsPage/CollegeResultAddons/index";
import CollegeSimulationResult from "../../components/Pages/SimulatorPage/CollegeSimulatorsPage/Questionnaire_College/Questionnaire_Result/index";
import CollegeSimulatorGettingStarted from "../../components/Pages/SimulatorPage/CollegeSimulatorsPage/GetStarted/index";
import CareerSimulatorGettingStarted from "../../components/Pages/SimulatorPage/CareerSimulatorsPage/CareerGetStartedAddons/index";
import CareerChoices from "../../components/Pages/SimulatorPage/CareerSimulatorsPage/Questionnaire/index"
import CollegeChoices from "../../components/Pages/SimulatorPage/CollegeSimulatorsPage/Questionnaire_College/index"
import DashboardLayout from "../../components/Pages/DashboardLayoutPage/index";
import ViewMoreCareers from "../../components/Pages/MoreCareers/ViewMoreCareer";
import CollegeApplicationChoices from "../../components/Pages/SimulatorPage/CollegeApplicationSimulator";
import CollegeApplicationGettingStarted from "../../components/Pages/SimulatorPage/CollegeApplicationSimulator/Get_started";
import FullCollegeApplResult from "../../components/Pages/DashboardLayoutPage/Contents/CollegeApplicationPage/CollegeApplicationLists/FullResultDetails/Index";
import ScholarshipApplicationChoices from "../../components/Pages/SimulatorPage/ScholarshipSimulatorPage/Scholarship_Questionnaire/Index";
import ExistingAccount from "../../components/Pages/DashboardLayoutPage/Contents/ExistingAccount/ExistingAccount";
import NotFound from "../../components/404";

const Routers = () => {
  return (
     <Router>
          <Switch>
              <>
                <Layout>
                    <Route exact path="/" render={(props) => <App {...props} />} />
                    <Route exact path="/dashboard/*" ><DashboardLayout/></Route>
                    <Route exact path="/auth" render={(props) => <AuthPage {...props} />} />
                    <Route exact path="/existing" ><ExistingAccount/></Route>

                    <Route exact path="/signup" component={RegisterPage} />

                    {/* ROUTES OUTSIDE DASHBOARDS */}

                    {/* ROUTES FOR RESULT */}
                    <Route exact path="/career-simulator/result/:simulation_id"  render={(props) => <CareerResultRecommendation {...props}/>} />
                    <Route exact path="/college-simulator/result/:simulation_id"  render={(props) => <CollegeResult {...props}/>} />
                    <Route exact path="/college_appl-simulator/result/:simulation_id"  render={(props) => <FullCollegeApplResult {...props}/>} />
                     
                     {/* ROUTES FOR QUESTIONNAIRE */}
                    <Route exact path="/career-simulator/questionnaire" ><CareerChoices/></Route>
                    <Route exact path="/college-simulator/questionnaire" ><CollegeChoices/></Route>
                    {/* <Route exact path="/career-simulator/questionnaire"  render={(props) => <CareerChoices {...props}/>} /> */}
                    {/* <Route exact path="/college-simulator/questionnaire"  render={(props) => <CollegeChoices {...props}/>} /> */}
                    <Route exact path="/get-started"  render={(props) => <CollegeSimulatorGettingStarted {...props}/>} />
                    <Route exact path="/get-started-career"  render={(props) => <CareerSimulatorGettingStarted {...props}/>} />
                    <Route exact path="/college-simulator-result"  render={(props) => <CollegeSimulationResult {...props}/>} />

                    {/* SCHOLARSHIPS */}
                    <Route exact path="/scholarship-questionnaire/:type"  render={(props) => <ScholarshipApplicationChoices {...props}/>} />

                    {/* ROUTE TO VIEW MORE SUGGESTED CAREERS */}
                    <Route exact path="/view-more-careers/:simulation_id"  render={(props) => <ViewMoreCareers {...props}/>} />
                    <Route exact path="/college-application/questionnaire"  render={(props) => <CollegeApplicationChoices {...props}/>} />
                    <Route exact path="/get-started-college"  render={(props) => <CollegeApplicationGettingStarted {...props}/>} />
                </Layout>
                </>
                    <Route component={NotFound}/>
            </Switch>
     </Router>
  );
};

export default Routers;
