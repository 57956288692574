// import SimulatorHeader from "../../../SimulatorHeader"
// import ResultDetails from "../../CollegeResultAddons/ResultDetails"
import Regions from '../../CollegeResultAddons/Regions';
import RecommendedCollege from '../../CollegeResultAddons/RecommendedCollege';
import RecommendedCourses from "../../CollegeResultAddons/RecommendedCourse";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import { getCollegeLatestSimulation } from '../../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeLatestSimulation';
import SimulatorHeader from '../../../SimulatorHeader';
import ResultDetails from '../../CollegeResultAddons/ResultDetails';

const CollegeSimulationResult = () => {

    const dispatch = useDispatch();

    const {collegeLatestSimulationsResult = {}, college_latest_simulation_error ={}} = useSelector(state => state.latestCollegeSimulation)
    const {data={}} = collegeLatestSimulationsResult;
    const {data:another_data={}} = data;
    let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
    const {data:local_data = {}} = localResult;
    const {id:student_id} = local_data;
    const {message:college_latest_simulation_error_messaage} = college_latest_simulation_error
    // console.log("collegeResults", another_data);

    useEffect(() => {
            dispatch(getCollegeLatestSimulation(student_id))
    }, [dispatch, student_id])

    return (
        <div className="result__college_result_wrapper">
            <div className="result__page recommended__college">
                {" "}
                <div className="container">
                    <SimulatorHeader
                        name="College Simulator"
                        logo="/trav4college_logo.png"
                    />
                </div>
                <ResultDetails user_details={local_data}/>
                <div className="result_region">
                   <Regions region_result={another_data} region_error={college_latest_simulation_error_messaage}/>
                </div>
                <div className="recommended_college">
                    <RecommendedCollege college_result={another_data} college_error={college_latest_simulation_error_messaage}/>
                </div>
                <div className="recommended_courses">
                    <RecommendedCourses course_result={another_data} course_error={college_latest_simulation_error_messaage}/>
                </div>
            </div>
        </div>
    )
}

export default CollegeSimulationResult