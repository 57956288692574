import {GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_FAIL, GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_REQUEST, GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/getCollegeSimulationBySimulationIdConstant";

export const getCollegeSimulationBySimulationId = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_REQUEST:
            return {
                loading_simulationId: true
            }
        case GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_SUCCESS:
            return {
                    ...state,
                    loading_simulationId: false,
                    collegeResult: action.payload,
                    loading_simulationId_sucess: true
            }
        case GET_COLLEGE_SIMULATION_BY_SIMULATION_ID_FAIL:
            return {
                ...state,
                loading_simulationId: false,
                collegeResult_error: action.payload,
                loading_simulationId_sucess: false
            }        
        default:
            return state;
    }
}