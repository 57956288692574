import arc from "../../../../../assets/career-arc.png";
import {Link} from "react-router-dom";

const StudentCollegeView = ({regions, cities, course, simulation_id}) => {
    
        return (
            <>
                 <div className="col-12 col-xs-12 col-sm-12 col-lg-6 col-md-6">
                    <div className="card career_card shadow">
                        <div className="card-body">
                            <div className="career_simualte">
                                <h4 className="career_title">MY COLLEGE SIMULATION</h4>
                                    <div className="all-city-simulated-container">
                                        <span className="all-city-simulated-header">REGION(S)</span>
                                        <span className="all-city-simulated text-uppercase">{regions && regions.map(region => region.continent.continent).join(", ")} </span>
                                    </div>
                                    <div className="all-city-simulated-container">
                                        <span className="all-city-simulated-header">CITY</span>
                                            <span className="all-city-simulated text-uppercase">
                                            {/* {cities && cities.map(city => city.city_name).join(", ")}   */}
                                            {cities && cities.map(({city}) => city.city).join(", ")}
                                        </span>       
                                    </div>
                                    <div className="all-city-simulated-container">
                                        <span className="all-city-simulated-header">COURSE</span>
                                        <span className="all-city-simulated text-uppercase">
                                            {course && course.map(course => course.field.name).join(", ")}
                                        </span>
                                    </div>
                                <Link to={`/college-simulator/result/${simulation_id}`} className="career_recommendations">View Full Result & Recommendations</Link>
                            </div>
                        </div>
                        <img src={arc} alt="career_arc" className="bg_career_img"/>
                        <div className="career_bag"><img src="/perspaleta1_0001.png" alt="bag"/></div>
                    </div>
                </div>
            </>
        )
}

export default StudentCollegeView;