const SubscribedPage = () => 
{
    return <>
    <div className="card">
             <div className="text-right cross"> <i className="fa fa-times"></i> </div>
             <div className="card-body text-center"> <img src="https://img.icons8.com/bubbles/200/000000/trophy.png" alt="subscribed"/>
                 <h4>Subscription made!</h4>
                 <p>Your school will have access to scholarship listings for one year</p> 
             </div>
         </div>
    </>
}

export default SubscribedPage;