import { AppstoreOutlined } from "@ant-design/icons";
import { AiOutlinePieChart } from "react-icons/ai"
import { HiOutlineUser } from "react-icons/hi";
import { IoBriefcaseOutline } from "react-icons/io5";
//   import { FaRegEnvelope, FaGraduationCap } from "react-icons/fa";
  import { CgPhone } from "react-icons/cg";
  import { BiChart } from "react-icons/bi";
import { Link } from "react-router-dom"
import { Menu } from "antd";
import { useState } from "react";


const CounsellorSidebar = () => {
    let localKeys = localStorage.getItem("keys");
    const [keys, setKeys] = useState({current: localKeys});
    const handleClick = e => {
        setKeys({current: e.key})
         localStorage.setItem("keys", e.key)
    };
    const {current} = keys;
    return (
      <Menu theme="light" mode="inline" selectedKeys={[current]}>
                <Menu.Item key="overview"  onClick={handleClick} icon={<AppstoreOutlined />} >
                    <Link to="/dashboard/">Overview</Link>
                </Menu.Item>
                <Menu.Item key="profile"  onClick={handleClick} icon={<HiOutlineUser />}>
                    <Link to="/dashboard/profile">Profile</Link>
                </Menu.Item>
                <Menu.Item key="students"  onClick={handleClick} icon={<BiChart />} >
                    <Link to="/dashboard/counsellor/students">Students</Link>
                </Menu.Item>
                <Menu.Item key="college"  onClick={handleClick} icon={<IoBriefcaseOutline />}>
                     <Link to="/dashboard/counsellor/college">College Simulations</Link> 
                </Menu.Item>
                <Menu.Item key="career"  onClick={handleClick} icon={<AiOutlinePieChart/>}>
                   <Link to="/dashboard/counsellor/career">Career Simulations</Link> 
                </Menu.Item>
                <Menu.Item key="collegeapplications"  onClick={handleClick} icon={<AiOutlinePieChart/>}>
                   <Link to="/dashboard/counsellor/collegeapplpage">Applications</Link> 
                </Menu.Item>
                <Menu.Item key="scholarship_page"  onClick={handleClick} icon={<AiOutlinePieChart/>}>
                   <Link to="/dashboard/counsellor/scholarship_page">Scholarship</Link> 
                </Menu.Item>
                {/* <Menu.Item key="6" icon={<CgLoadbarDoc/>}>
                Applications
                </Menu.Item>
                <Menu.Item key="7" icon={<DatabaseOutlined/>}>
                    Notes
                </Menu.Item> */}
                {/* <Menu.Item key="8" icon={<FaRegEnvelope />}>
                    Chat <span className="badges_chat" >2</span>
                </Menu.Item> */}
                  <Menu.Item icon={<CgPhone />} key="help-desk"  onClick={handleClick}>
                    <Link to="/dashboard/help-desk">Help & Support</Link>
                  </Menu.Item> 
        </Menu>
    )
}

export default CounsellorSidebar;