import { Link } from "react-router-dom";
import { format } from "date-fns";

const ListNews = ({data}) => {
    return (
        <>
            <div className="container mt-2">
                <div class="row" style={{
                    background: "var(--bg-white)",
                    padding: "1rem",
                    borderRadius: "12px",
                    width: "100% !important"
                }}>
                    <div className="col-md-4">
                        <img src={data.image} width="100%"/>
                    </div>
                    <div className="col-md-8">
                        <h3 class="">{data.news_header}</h3>
                        <b><img src="/news_source.png" style={{ width:'20px' }} /> {data.source} | <i>Published on { data.created_at ? format(new Date(data.created_at), 'PPP') : ''} </i></b>
                        <p className="mt-3" dangerouslySetInnerHTML={{ __html: data.news_content.substring(0, 200) }}></p>
                        <div>
                            <Link to={`/dashboard/news/${data.id}`} style={{ textDecoration: "none" }}>  <button className="btn btn btn-primary">Read More</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListNews;