// import { useState,useContext } from 'react';
// import {AuthContext} from "../../../AuthContext/AuthContext";

const Regions = ({region_result, region_error}) => {

  // const {user_error} = useContext(AuthContext);
  const { course, regions, country} = region_result;
//   const [userError, setUserError] = useState(user_error);
//   const [simulationError, setSimulationError] = useState(region_error)
  
// setTimeout(() => {
//     setUserError("");
//     setSimulationError("");
// }, 3000);

  return (
    <div className="container regions_result">
        <div className="row mb-5 mx-1 p-1">
        <div className="col-sm-6 col-md-6 mb-2">
          <p className="region__header">REGION(S)</p>
          <h6 style={{ textTransform: "uppercase" }}>
          {regions && regions.map(region => region.continent.continent).join(", ")}
          </h6>
        </div>
        <div className="col-sm-6 col-md-6">
          <p className="region__header">COUNTRY(IES)</p>
          <h6 style={{ textTransform: "uppercase" }}>
              {country && country.map(country => country.country.country).join(", ")}
          </h6>
        </div>
      </div>

      <div className="row mb-5">
        {/* <div className="col-sm-6 col-md-6">
            <p className="region__header">CITY</p>
            <h6 style={{ textTransform: "uppercase" }}>
              {cities && cities.map(({city}) => city.city).join(", ")}
            </h6>
          </div> */}
          {/* <div className="col-sm-6 col-md-6">
            <p className="region__header">TUITION RANGE</p>
            <h6>
              N{tuition_range?.from} - N{tuition_range?.to}
            </h6>
            <small>Tuition fee per Annum</small>
          </div> */}
      </div>

      <div className='row mx-auto justify-content-center p-2'>
            <div className="col-sm-12 col-md-12 justify-content-center">
              <p className="region__header">COURSE(S)</p>
              <h6 className="d-flex" style={{ textTransform: "uppercase" }}>  {course && course.map(course => course.field.name).join(", ")}</h6>
          </div>
      </div>
   
    </div>
  );
};
export default Regions;
