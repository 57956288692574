import { useParams } from 'react-router-dom';
import {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import SimulationResultsPage from "../../ViewSimulationList/Index";
import {getListOfStudentCollegeSimulation} from "../../Redux/actions/CollegeActions/getListOfStudentCollegeListAction";

const CollegeSimulationLists = () => {
    let { user_id, type } = useParams();
    // console.log("user_id", user_id)
    const dispatch = useDispatch();
    const {listCollegeSimulation = {}, loading_list_college} = useSelector(state => state.listOfCollegeStudents)
     console.log("listCollegeSimulation", listCollegeSimulation);
    useEffect(() => {
        if (type === "college") {
            dispatch(getListOfStudentCollegeSimulation(user_id))
        } else {
            return;
        }
    }, [dispatch, type, user_id])

   return (
       <>
            <SimulationResultsPage loading={loading_list_college} simulations={listCollegeSimulation} type={type}/>
       </>
   )
}

export default CollegeSimulationLists
