import {Link} from "react-router-dom"
import {useState} from "react"
import Paginate from "../../../../../../Utils/helper/Paginate"
import "./college.css"
import Loader from "../../../../LoadersPage"
import NoStudentPage from "../NoStudentFile"

const CollegeSimulator = ({college, loading}) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5)

    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = college?.slice(indexOffirstStudent, indexOflastStudent);
    // console.log("currentStudents", currentStudents);
    
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <div className="applicants_info_style">
            {loading && <Loader loading={loading}/>}
            {
                  loading ?    
                      <Loader loading={loading}/>
                      :
                      <>
                        {
                            college?.length >= 1 ? 
                            <>
                                     <div className="card">
                        <div className="card-headers">
                            <div className="student-header">COLLEGE SIMULATIONS</div>
                        </div>
           <div className="divider"></div>
                            
                            <div className="card-body">
                                <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr align="middle" style={{ fontWeight: "700" }}>
                                            <td>STUDENT NAME</td>
                                            {/* <th scope="col">Date of Simulation</th> */}
                                            <td>ACTION</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        currentStudents && currentStudents.map(college => 
                                            <tr align="middle" className="shadow-sm align_student_table simulate_details_college" key={college.id}>
                                                <td>{college?.user?.first_name} {college?.user?.last_name}</td>
                                                <td className="view_results">
                                                <Link to={`/dashboard/counsellor/collegelists/${college?.user?.id}/college`}>
                                                    <span>View results</span> 
                                                </Link>
                                                </td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                        {
                            college.length > 5 ? <Paginate studentPerPage={studentsPerPage} totalStudents={college?.length} paginate={paginate} /> : null
                        }
                                </div>
                            </div>
                            {/* <div className="text-center parent_view_more pb-4"><Link to="/view-more">View more</Link></div> */}
                        
                        </div>
                            </>
                            :
                            <NoStudentPage name={"COLLEGE SIMULATIONS"}/>
                        }
                      </>
            }
        </div>
    )
}

export default CollegeSimulator