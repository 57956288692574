import { STUDENT_CAREER_SIMULATION_COUNT_REQUEST, STUDENT_CAREER_SIMULATION_COUNT_SUCCESS, STUDENT_CAREER_SIMULATION_COUNT_FAIL } from "../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CountStudentCareerSimulationsConstant";

export const countStudentsCareerSimulationReducer = (state={}, action)  => {
    switch(action.type){
        case STUDENT_CAREER_SIMULATION_COUNT_REQUEST:
            return {loading_count: true, career_loading_count: true}
        case STUDENT_CAREER_SIMULATION_COUNT_SUCCESS:
            return {
                ...state,
                career_loading_count: false,
                careerSimulationsCounts: action.payload,
                career_count_success: true
            }
        case STUDENT_CAREER_SIMULATION_COUNT_FAIL:
            return {
                ...state,
                career_loading_count: false,
                career_count_error: action.payload,
                career_count_success: false
            }
        default:
            return state
    }
}