import { Progress, Modal,Card } from "antd";
import Level from "./Level";
import "../../simulator.css";
import { RiArrowRightFill, RiArrowLeftFill } from "react-icons/ri";
import Country from "./Country";
import College from "./College";
import Courses from "./Courses";
// import PriceSelection from "../../CollegeSimulatorsPage/Questionnaire_College/Answer_Folder/PriceSelection";
// import Result from "../../CollegeSimulatorsPage/Questionnaire_College/Answer_Folder/Result";
import Alerts from '../../../../Alerts/Alert';
// import { Link } from "react-router-dom";
import { useState } from "react";
// import { PaystackButton } from "react-paystack";
import SpeakToACounsellor from "./Consultant";

const ApplicationAnswerForm = ({
  page,
  handleChange,
  choooseConsultant,
  speaktoconsultant,
  consultant,
  setSpeakToConsultant,
  onChangeConsultant,
  handleInputChange,
  goToNextPage,
  goToPrevPage,
  countries,
  level,
  setLevel,
  remove,
  continent,
  selectedCountry,
  loading,
  // setCity,
  // city,
  tempCity,
  setTempCity,
  course,
  tempCourse,
  setTempCourse,
  filterName,
  country,
  setCountry,
  countryLoad,
  countryError,
  loadCourses,
  courseError,
  options,
  display,
  setDisplay,
  setRecentValue,
  search,
  setSearch,
  getSelectedCourseData,
  removeCourses,
  errorMessage,
  create_simulator_error,
  create_simulator_loading,
  result_loading,
  handleSubmit,
  college_loading,
  college_error,
  college,
  setCollege,
  selectedCollege,
  college_list,
  college_product,
  degrees,
  initial_success,
  payment_loading
}) => {

  // const {simulation_id} = result;
  const [isModalVisible, setIsModalVisible] = useState(false);
  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const pageSwitch = () => {
    switch (page) {
      case 1:
        return (
          <Country
            handleChange={handleChange}
            remove={remove}
            handleInputChange={handleInputChange}
            country={country}
            continent={continent}
            setCountry={setCountry}
            filterName={filterName}
            selectedCountry={selectedCountry}
            countries={countries}
            countryLoad={countryLoad}
            countryError={countryError}
            
          />
        );
        case 2:
        return (
          <Level
            handleInputChange={handleInputChange}
            remove={remove}
            setLevel={setLevel}
            level={level}
            tempCollege={tempCity}
            setTempCollege={setTempCity}
            loading={loading}
            degrees={degrees}
          />
        );
        case 3:
          return (
            <SpeakToACounsellor
            choooseConsultant={choooseConsultant}
            speaktoconsultant={speaktoconsultant}
            setSpeakToConsultant={setSpeakToConsultant}
            onChangeConsultant={onChangeConsultant}
            />
          )
      case 4:
        return (
          <>
            <College
            consultant={consultant}
            handleChange={handleChange}
            remove={remove}
            handleInputChange={handleInputChange}
            college={college}
            setCollege={setCollege}
            filterName={filterName}
            selectedCollege={selectedCollege}
            collegeLoad={college_loading}
            collegeError={college_error}
            college_list={college_list}
            /> 
          </>
        );
      case 5:
        return (
          <Courses
            handleInputChange={handleInputChange}
            remove={remove}
            course={course}
            tempCourse={tempCourse}
            setTempCourse={setTempCourse}
            loadCourses={loadCourses}
            courseError={courseError}
            options={options}
            display={display}
            setDisplay={setDisplay}
            setRecentValue={setRecentValue}
            search={search}
            setSearch={setSearch}
            getSelectedCourseData={getSelectedCourseData}
            removeCourses={removeCourses}
          />
        );
    
      case 6:
        return setIsModalVisible(true);
      default:
        return;
    }
  };
  const updateProgress = (page) => {
    if (page < 1) return;
    if (page === 1) {
      return (page = 20);
    } else if (page === 2) {
      return (page = 45);
    } 
    else if (page === 3) {
      return (page = 65);
    } else if (page === 4) {
      return (page = 80);
    } else if (page === 5) {
      return (page = 100);
    } 
  }; 
  
  return (
    <>
      {pageSwitch()}
      {errorMessage && <div><Alerts description={errorMessage} message="Error" type="error" closable /></div>}
      {create_simulator_error && (
        <div>
          {" "}
          <Alerts description={create_simulator_error} type="error" message={create_simulator_error} closable />
        </div>
      )} 
       {/* {result && <div><Alerts description={result.status} message={result.status} type="success" closable /></div>} */}
       
       
      {page === 6 ? (
        ""
      ) : (
        <div className="progress_bg">
          { 
          page === 1 ? " " :  <div className="btnPrev" onClick={goToPrevPage}>
            <button>
              <RiArrowLeftFill className="icon" />
            </button>
          </div>}
 
            
          {
            (page === 4 && consultant === "no") &&
            <div className="progress_bar" style={{ width: "100%" }}>
            <Progress
              percent={updateProgress(page)}
              size="small"
              showInfo={false}
              status="active"
              max={100}
            />
            <small
              style={{
                position: "relative",
                color: "#E5E5E5",
                top: "-0.8rem",
                marginLeft: "5px",
              }}
            >
              {updateProgress(page)}% Complete Keep it up!
            </small>
          </div>
          
          }  
          <div className="progress_bar" style={{ width: "100%" }}>
            <Progress
              percent={updateProgress(page)}
              size="small"
              showInfo={false}
              status="active"
              max={100}
            />
            <small
              style={{
                position: "relative",
                color: "#E5E5E5",
                top: "-0.8rem",
                marginLeft: "5px",
              }}
            >
              {updateProgress(page)}% Complete Keep it up!
            </small>
          </div>

          {
            page === 5 ? ( 
            <>
              <div className="btnNext" onClick={(e)=>handleSubmit(e)}>
                  <button>
                  {result_loading ? (
                  <div
                    className="spinner-border text-dark"
                    role="status"
                    style={{ width: "2rem", height: "2rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                </div>
                  ) : <>
                  <span className="next_question">SEND TO PARENT</span>{" "}
                  </>
                  }
                    
                  </button>
              </div>
            </>
          )
          :
          ( 
              <div className="btnNext" onClick={goToNextPage}>
                <button>
                  <span className="next_question">NEXT QUESTION</span>{" "}
                  <RiArrowRightFill className="icon" />
                </button>
              </div>
           
          )
          }
          
         
        </div>
      )}

<Modal title={`Read Carefully`}
  bodyStyle={{ overflow: 'scroll' }} className="simulation-modal" visible={isModalVisible}  footer={null}
       width={700}
       onCancel={handleCancel}
        cancelButtonProps={{
            style: {
              display: "block",
              height: "40px",
            },
          }}
        okButtonProps={{
            style: {
              display: "block",
              height: "40px",
              marginLeft: "2px"
            },
          }} 
        >
          <div className="row">      
              <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <Card title="Payments" className="shadow" extra={"Pay" }>

        {/* <Card title="Payments" className="shadow" extra={ initial_success ? <PaystackButton {...componentProps} className="paystack-button"/> : <button style={{backgroundColor: "#0480FC", color: "#ffffff", border: "1px solid #0480FC"}} onClick={(e)=>handleSubmit(e)}>{payment_loading ? <Spin/> : "Apply Now!"}</button>}> */}
                        <p className="py-2" style={{ fontWeight: "bolder" }}>You are required to pay the sum of N{college_product?.amount.toLocaleString()} for your college application process. The services include:</p>
                        <p>
                        Assigning a consultant to an applicant for counseling based on educational degree, country of interest and financial capacity. A detailed breakdown of the documents required for the admission application.
                        </p>

                        <h6 style={{ fontWeight: "bolder" }}>TERMS AND CONDITIONS</h6>
                        <p style={{ fontWeight: "bold" }}>
                        Trav4college Fees
                        </p>

                        <h6>Non-Transferable</h6>

                        <p>
                        Application fees paid to Trav4college and its benefits cannot be transferred to another person or exchanged for other services provided by Trav4college. In any case of attempting to transfer your rights as a Trav4college student, Trav4college or any of its staff will not be liable for any loss as a result.
                        </p>

                        <h6>Non-refundable</h6>

                        <p>
                        There will be no refund of application fees in any case unless under extreme and unforeseen unavoidable circumstances. Refunds will NOT be issued for any of the following cases:
                        <p>(a) An applicant fails to provide any required documentation to process the application.</p>
                        <p>(b) An applicant’s visa application is rejected; or.</p>
                        <p>(c) An applicant cancels his or her application before the admission decision was received.</p>
                        </p>

                        <h6>In-House Credit</h6>

                        <p>
                        Trav4college provides in-house credit for Trave4college registered students to change their location of study to any other location of their choosing so long as they meet the country’s requirements. This in-house credit is available for up to 6 months from point of registration. Trav4college will allow you to apply to another college at no additional cost if your application is rejected by your selected school because of your nationality.
                        </p>
                </Card>
              </div>
          </div>
      </Modal>
    </>
  );
};

export default ApplicationAnswerForm;
