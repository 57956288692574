import {Route} from "react-router-dom"
import CounsellorOverview from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Overview/index";
import StudentPage from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Students/Index";
// import CareerSimulationLists from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Students/Career/StudentCareerSimulationList";
// import CollegeSimulationLists from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Students/College/StudentCollegeSimulationList";
import CareerlandingPage from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/StudentCareerPage/Index";
import CollegeLandingPage from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/StudentCollegePage/Index";
import NotificationPage from "../../../components/Pages/DashboardLayoutPage/Contents/NotificationPage";
import CollegeApplPage from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/StudentCollegeApplpage";
import SchoolScholarShipPage from "../../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Scholarships";

const CounsellorRoutes = () => {
    return (
        <>
         <Route exact path="/dashboard/"><CounsellorOverview/></Route>
         <Route exact path="/dashboard/counsellor/students"><StudentPage/></Route>
         <Route exact path="/dashboard/counsellor/career"><CareerlandingPage/></Route>
         <Route exact path="/dashboard/counsellor/college"><CollegeLandingPage/></Route>
         <Route exact path="/dashboard/notifications"><NotificationPage/></Route>
         <Route exact path="/dashboard/counsellor/collegeapplpage"><CollegeApplPage/></Route>
         <Route exact path="/dashboard/counsellor/scholarship_page"><SchoolScholarShipPage/></Route>
        </>
    )
}

export default CounsellorRoutes;