import {GET_CAREER_SIMULATION_LIST_FAIL, GET_CAREER_SIMULATION_LIST_SUCCESS, GET_CAREER_SIMULATION_LIST_REQUEST} from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/CareerSimulationListsConstant"

export const studentCareerSimulationListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_CAREER_SIMULATION_LIST_REQUEST:
            return {
                career_list_loading: true,
            }
        case GET_CAREER_SIMULATION_LIST_SUCCESS:
            return {
                ...state,
                career_list_loading: false,
                careerSimulationList: action.payload,
                career_list_success: true 
            }    
        case GET_CAREER_SIMULATION_LIST_FAIL:
            return {
                ...state,
                career_list_loading: false,
                careerSimulationList_error: action.payload,
                career_list_success: false    
            }
        default:
            return state;
    }
}