import {
    GET_COLLEGES_FAIL,
    GET_COLLEGES_REQUEST,
    GET_COLLEGES_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/CollegeApplicationConstants";
  
  export const listCollegesReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_COLLEGES_REQUEST:
        return { college_loading: true };
      case GET_COLLEGES_SUCCESS:
        return { college_loading: false, college_list: action.payload, success: true };
      case GET_COLLEGES_FAIL:
        return { college_loading: false, college_error: action.payload, success: false };
      default:
        return state;
    }
  };
  