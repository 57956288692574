import CountCollegeForStudents from "./CountStudentCollegeSimulations"
import CollegeSimulator from "./CollegeSimulatorLists"
import "./college.css";
import {getStudentsCollegeSimulationCount} from "../Redux/actions/CollegeActions/CountStudentCollegeSimulation"
import {StudentscollegeSimulationsDetails} from "../Redux/actions/CollegeActions/GetListOfStudentsCollegeSimulations"
import { useDispatch, useSelector } from "react-redux";
import {useEffect} from "react";

const CollegeLandingPage = () => {
    const dispatch = useDispatch();

    const {college_count_loading, collegeSimulationsCounts = {}} = useSelector(state => state.collegeSimulationCount);
    const {data:count_college} = collegeSimulationsCounts
 
    
    const {collegeSimulationDetails = {}} = useSelector(state => state.collegeDetails);
    const {data = []} = collegeSimulationDetails
    // console.log("college", collegeSimulationDetails);
    

    useEffect(() => {
        dispatch(getStudentsCollegeSimulationCount())
        dispatch(StudentscollegeSimulationsDetails())
    }, [dispatch])

    return (
        <div className="container container-width">
            <CountCollegeForStudents countCollege={count_college} loading={college_count_loading}/>
           <CollegeSimulator college={data} loading={college_count_loading}/>
        </div>
    )
}

export default CollegeLandingPage