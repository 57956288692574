import { GET_LIST_OF_HIGHSCHOOL_REQUEST, GET_LIST_OF_HIGHSCHOOL_SUCCESS, GET_LIST_OF_HIGHSCHOOL_FAIL } from "../../../Utils/constants/HighschoolConstants/listHighschoolsConstant";
import axiosInstance from "../../../Utils/helper/axiosInstance";

export const listHighschoolsAction = () => async(dispatch) => {
    try {
        dispatch({type: GET_LIST_OF_HIGHSCHOOL_REQUEST});
        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }
        const {data} = await axiosInstance.get(
            "/highschools",
           config  
        );
        dispatch({type: GET_LIST_OF_HIGHSCHOOL_SUCCESS, payload: data});
    } catch (error)  {
         let message = error.response && error.response.data ? error.response.data : error.message;
         dispatch({type: GET_LIST_OF_HIGHSCHOOL_FAIL, payload: message});
    }
}