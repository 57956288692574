import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { singleNewsAction } from "../../../../../Redux/actions/singleNewsAction";
import Loader from "../../../LoadersPage";
import './style.css';
import { format } from "date-fns";


const SingleNews = () => {
    const dispatch = useDispatch()
    const params = useParams();

    useEffect(() => {
        dispatch(singleNewsAction(params.news_id))
    }, [dispatch])

    const { news_loading, error, news = [] } = useSelector(state => state.singleNews)
    const { data: data = [] } = news

    return (
        <>
            {
                news_loading ? <Loader loading={news_loading} />
                :
                <>
                    <div className="container mt-2">
                        <div class="row" style={{
                            background: "var(--bg-white)",
                            padding: "1rem",
                            borderRadius: "12px",
                            width: "100% !important"
                        }}>
                            <div className="row justify-content-md-center">
                                <div className="col-md-8 text-center">
                                    <h3 class="">{data.news_header}</h3>
                                </div>
                            </div>
                            <div className="row justify-content-md-center">
                                <div className="col-md-12">
                                    <img src={data.image} width="100%" />
                                </div>
                            </div>
                            <div className="row justify-content-md-center mt-1">
                                <div className="col-md-12">
                                    <span><b>Source : </b>{data.source}</span>
                                    <br />
                                    <i>Published on { data.created_at ? format(new Date(data.created_at), 'PPP') : ''} </i>
                                </div>
                            </div>
                            <div className="row justify-content-md-center mt-4">
                                <div className="col-md-12 full_content">
                                <p dangerouslySetInnerHTML={{ __html: data.news_content }}></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default SingleNews;