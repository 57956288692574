import { CHECK_HIGHSCHOOL_PROFILE_FAIL, CHECK_HIGHSCHOOL_PROFILE_REQUEST, CHECK_HIGHSCHOOL_PROFILE_SUCCESS } from "../../../../../../Utils/constants/ExistingAccount/CheckHighSchoolConstant";

export const checkExistingProfileReducer = (state={}, action) => {
    switch(action.type){
        case CHECK_HIGHSCHOOL_PROFILE_REQUEST:
            return {
                profile_update_loading: true
            }
        case CHECK_HIGHSCHOOL_PROFILE_SUCCESS:
            return {
                ...state,
                profile_update_loading: false,
                profile_update_success: true,
                updated_profile: action.payload
            }
        case CHECK_HIGHSCHOOL_PROFILE_FAIL:
            return {
                ...state,
                profile_update_loading: false,
                profile_update_success: false,
                update_profile_error: action.payload
            }
        default:
            return state;
    }
}