import {GET_LIST_OF_CAREER_SIMULATION_STUDENTS_REQUEST, GET_LIST_OF_CAREER_SIMULATION_STUDENTS_SUCCESS, GET_LIST_OF_CAREER_SIMULATION_STUDENTS_FAIL} from "../../../../../../../Utils/constants/HighschoolConstants/Counsellor/GetListOfStudentsCareerSimulationsConstant"
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance"

export const StudentCareerSimulationsDetails = () => async (dispatch,getState) => {
    try {
      dispatch({ type: GET_LIST_OF_CAREER_SIMULATION_STUDENTS_REQUEST });
      const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
      const { data } = await axiosInstance.get(
        "/highschools/aggregates/student_careersimulations",
        config
      );
      dispatch({ type: GET_LIST_OF_CAREER_SIMULATION_STUDENTS_SUCCESS, payload: data });
      
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_LIST_OF_CAREER_SIMULATION_STUDENTS_FAIL, payload: message });
    }
  };