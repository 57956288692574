import {
    COLLEGE_SIMULATION_FAIL,
    COLLEGE_SIMULATION_REQUEST,
    COLLEGE_SIMULATION_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CollegeQuestionConstants";
  
  export const simulationReducer = (state = {}, action) => {
    switch (action.type) {
      case COLLEGE_SIMULATION_REQUEST:
        return { loading: true };
      case COLLEGE_SIMULATION_SUCCESS:
        return {
          ...state,
          loading: false,
          result: action.payload,
          success: true
        };
      case COLLEGE_SIMULATION_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
          success: false
        };
      default:
        return state;
    }
  };