import "../../../simulator.css";
import { FaTimes } from "react-icons/fa";

const Country = ({
  handleChange,
  remove,
  filterName,
  country,
  setCountry,
  countries,
  countryLoad,
  countryError,
}) => {
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 1</p>
      <h2>Select Country(ies) you are interested in Studying </h2>
      {countryError && <div>{countryError}</div>}
      {countryLoad ? (
        <div
          className="spinner-border text-dark"
          role="status"
          style={{ width: "5rem", height: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <select
          className="form-select form-select-lg"
          aria-label=".form-select-lg example"
          onChange={(e) => handleChange(e, country, setCountry)}
        >
          <option>Select Country</option>
          {countries?.data &&
            countries?.data?.map((country, index) => (
              <option key={index} value={country.id}>
                {country.country}
              </option>
            ))}
        </select>
      )}
      <ul className="my-4 list_tags">
        {country?.map((item, index) => {
          const values = filterName(item, countries.data);
          return (
            <li key={index}>
              {values && values.map((c) => c.country)}{" "}
              <span onClick={() => remove(item, country, setCountry)}>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          );
        })}
      </ul>

      <span className="text-center text-def">
        You can select up to <strong>3 countries</strong>
      </span>
    </div>
  );
};

export default Country;
