import {COUNT_STUDENT_CAREER_SIMULATION_FAIL, COUNT_STUDENT_CAREER_SIMULATION_SUCCESS, COUNT_STUDENT_CAREER_SIMULATION_REQUEST} from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/countCareerSimulationConstants";

export const countCareerSimulationReducer = (state = {}, action) => {
    switch (action.type) {
        case COUNT_STUDENT_CAREER_SIMULATION_REQUEST:
            return {
                loading_count: true
            }
        case COUNT_STUDENT_CAREER_SIMULATION_SUCCESS:
            return {
                ...state,
                career_loading_count: false,
                careerSimulationsCounts: action.payload,
                career_count_success: true 
                }
        case COUNT_STUDENT_CAREER_SIMULATION_FAIL:
            return {
                ...state,
                career_loading_count: false,
                career_count_error: action.payload,
                career_count_success: false    
                }
        default:
            return state;
    }
}