import { GET_UNPAID_APPL_FAIL, GET_UNPAID_APPL_REQUEST, GET_UNPAID_APPL_SUCCESS } from "../../../Utils/constants/Payment/UnpaidApplConstants";

export const unpaidApplReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_UNPAID_APPL_REQUEST:
        return { 
            loading: true 
            };
      case GET_UNPAID_APPL_SUCCESS:
        return { 
            loading: false, 
            result: action.payload, 
            success: true 
           };
      case GET_UNPAID_APPL_FAIL:
        return { 
            loading: false, 
            error: action.payload, 
            success: false 
          };
      default:
        return state;
    }
  };
  