import ResultCount from "./CountResult"
import SimulationLists from "./ListOfSimulations";

const SimulationResultsPage = ({ simulations = {}, type, loading }) => {

  let simulationArray = [];
  let message;
  const {data} = simulations;
  if (data?.message || data === null) {
     message = data?.message ? data?.message : "No simulation for this student"; 
    } else {
    const {data:newsimulationArray = []} = simulations
    simulationArray = newsimulationArray;
  }
  // console.log('simulationArray', simulationArray);

    return (
        <div className="container container-width">
            {
                message ? 
                  <h2 className="text-danger text-center"> No {type} simulation for this student </h2>
                :
                <>
                    <ResultCount totalSimulations={simulationArray?.length} loading={loading} type={type}/>
                    <SimulationLists simulationList={simulationArray} loading={loading}/>
                </>
            }
        </div>
    )
}

export default SimulationResultsPage