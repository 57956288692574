import {Link} from "react-router-dom";
import pathArrow from "../../../../../assets/Path-arrow.png";
import "../CareerPage/career.css";
import MySimulation from "../../Contents/StudentDashboard/MySimulation";
// import StartCollegeApplication from '../StudentDashboard/StartCollegeApplication';
import { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "../../../AuthContext/AuthContext";
import {getCollegeLatestSimulation} from "../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeLatestSimulation";
import {getStudentCollegeSimulationLists} from "../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/collegeSimulationListsAction"
import ListCollegeSimulationTable from "./CollegeSimulationLists";

import { Layout } from "antd";
import Loader from "../../../LoadersPage";
import StartCollegeSimulation from "../StudentDashboard/StartCollegeSimulation";
const {Content } = Layout;

const SimulateCollege = () => {
    const dispatch = useDispatch()
    const {collegeSimulationsCounts} = useContext(AuthContext)
    // const {data={}} = user;
    // const {id} = data;
    
    const count = collegeSimulationsCounts && collegeSimulationsCounts?.data;
    const {collegeLatestSimulationsResult, loading_college_latest_simulation} = useSelector(state => state.latestCollegeSimulation)

    const {collegeSimulationList = []} = useSelector(state => state.studentCollegeLists)
    let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
    const checkCollegeResult = localResult?.data.latestCollegeSimulation;
    const student_id = localResult?.data.id;
    const new_college_list = collegeSimulationList?.find(u => u);
    useEffect(() => {
      dispatch(getCollegeLatestSimulation(student_id))
    }, [dispatch, student_id])

    useEffect(() => {
      dispatch(getStudentCollegeSimulationLists(student_id))
    }, [dispatch, student_id])

    return (
        <Layout className="ant-design-guide">
  
          <Content style={{ margin: "24px 16px 0" }}>
        <div className="site-layout-background-career"
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          minHeight: "100vh",
        }}
      >
          {(count > 0 || !checkCollegeResult.message) ? 
          <>
          
          <MySimulation simulated_result_college="simulated_result_college" collegeSimulations={collegeLatestSimulationsResult}/> 
          {loading_college_latest_simulation ? <Loader loading={loading_college_latest_simulation}/> : <ListCollegeSimulationTable college_simulation_list={new_college_list}/>}
          </>
          : 
          <>
          <div className="row">
              <StartCollegeSimulation />
              <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div className="restart_simulation">
                      <div className="simulation_text">{count > 0 ? <Link to="/get-started">Restart<br /> Simulation</Link>: <Link to="/get-started">Start<br /> Simulation</Link>}</div>
                      <div className="path_arrow"><img src={pathArrow} alt="arrow_path" /></div>
                  </div>
              </div>
          </div> 
          </>
          }
          
      </div>
      </Content>

      </Layout>
    )
}

export default SimulateCollege