import { FETCH_COLLEGE_TUITION_FAIL, FETCH_COLLEGE_TUITION_REQUEST, FETCH_COLLEGE_TUITION_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/TuitionRangeConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
    
    export const FetchTuitionRange = () => async (dispatch, getState) => {
      try {
        dispatch({ type: FETCH_COLLEGE_TUITION_REQUEST });
        const {
            tokenAuth: { tokenInfo },
          } = getState();
          const config = {
            headers: {
              Authorization: `Bearer ${tokenInfo.data.userToken}`,
              "Content-Type": "application/json",
            
            },
          };
        const { data } = await axiosInstance.get(
            `/highschools/college_simulations/tuition_range`,
          config
        );
        dispatch({ type: FETCH_COLLEGE_TUITION_SUCCESS, payload: data });
      } catch (error) {
        const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
        dispatch({ type: FETCH_COLLEGE_TUITION_FAIL, payload: message });
      }
    };
    