import axiosInstance from "../../../../../../../../Utils/helper/axiosInstance"
import {GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_REQUEST, GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_SUCCESS, GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_FAIL} from "../../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CollegeConstants/StudentCollegeListConstants"


export const getListOfStudentCollegeSimulation = (user_id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_REQUEST});
        const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
       const { data } = await axiosInstance.get(
         `/highschools/student/collegeStudentSimulationlist/${user_id}`,
         config
       );
       dispatch({type: GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_SUCCESS, payload: data});
       if(data.status === "failed"){
        dispatch({
          type: GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_FAIL,
          payload: data.reason.message
        });
       }
    } catch (error) {
     dispatch({
         type: GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }    
  }