import Loader from "../../../LoadersPage";
import { Link, useHistory } from "react-router-dom";

const CollegeApplicationResult = ({college_data = [], loading}) => {
    const nested_college = college_data.find(u => u);
     const counts = college_data.reduce( (acc, val) => acc + val.college_applied.length, 0);
     const history = useHistory();
     const goToCollegeApplPage = () => {
        history.push("/dashboard/my-college-application")
        window.location.reload()
    }
    return (
        <>
        {
            loading ? <Loader loading={loading}/> : 
            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div className="card career_card">
                        <div className="card-body">
                            <div className="career_simualte">
                                <div className="header__section_simulate">
                                <p className="career__title">College Applications</p>
                                <p className="career_number_application">Number of Applications ({counts})</p>
                                </div>
                                {
                                    nested_college && nested_college.college_applied.map((college = {}) =>
                                        <>
                                        <div className="list__schools" key={college.college.id}>
                                <div className="img_school_text"><img src={college.college.logo || "/School_logo.png"} alt="School_logo" className="school__logo"/>&nbsp;<span>{college.college.college_name}</span></div>
                                            <button className="btn_ongoing">{college.status}</button>
                                        </div>
                                        </>
                                    )
                                }
                            </div>
                            <Link to="#" onClick={goToCollegeApplPage} style={{ textDecoration: "none" }}><div className="view_all_application">View all college Applications</div></Link>
                        </div>
                    </div>
                </div>
        }
        </>
    )
}

export default CollegeApplicationResult;