import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
// import { countStudentsColegeAppl } from "../Redux/actions/countCollegeAppl"
import { getStudentCollegeApplist } from "../Redux/actions/getCollegeAppllist"
import CountCollegeApplForStudents from './ApplicationCount'
import CollegeApplSimulator from "./ApplicationList"

const  CollegeApplPage = () => {
    const dispatch = useDispatch()
    // const {college_appl, collegeAppl_count = {}} = useSelector((state) => state.countColegeAppl)
    // const {data = []} = collegeAppl_count;
    const {loading, collegeAppl_list = {}} = useSelector((state) => state.getCollegeApplist)
    const {data:college_list = []} = collegeAppl_list;
    useEffect(() => {
        // dispatch(countStudentsColegeAppl())
        dispatch(getStudentCollegeApplist())
    }, [dispatch])

  return (
      <>
      <div className="container container-width">
        {/* <CountCollegeApplForStudents loading={college_appl} info={data}/> */}
        <CountCollegeApplForStudents loading={loading} info={college_list}/>
        <CollegeApplSimulator loading={loading} studentCareers={college_list}/>
      </div>
      </>
  )
}

export default CollegeApplPage
