import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";
import { STUDENT_CAREER_SIMULATION_COUNT_REQUEST, STUDENT_CAREER_SIMULATION_COUNT_SUCCESS, STUDENT_CAREER_SIMULATION_COUNT_FAIL } from "../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CountStudentCareerSimulationsConstant";

export const countStudentCareerSimulations = () => async (dispatch,getState) => {
    try {
      dispatch({ type: STUDENT_CAREER_SIMULATION_COUNT_REQUEST });
      const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          
          },
        };
      const { data } = await axiosInstance.get(
        "/highschools/aggregates/count_studentcareersimulations",
        config
      );
      dispatch({ type: STUDENT_CAREER_SIMULATION_COUNT_SUCCESS, payload: data });
      console.log(data);
      
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: STUDENT_CAREER_SIMULATION_COUNT_FAIL, payload: message });
    }
  };