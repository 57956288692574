import {STUDENT_COLLEGE_LATEST_SIMULATION_FAIL, STUDENT_COLLEGE_LATEST_SIMULATION_REQUEST, STUDENT_COLLEGE_LATEST_SIMULATION_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/collegeLatestSimulationConstants";
 
export const getLatestCollegeSimulationReducer = (state={}, action) => {
    switch (action.type) {
        case STUDENT_COLLEGE_LATEST_SIMULATION_REQUEST:
            return {loading_college_latest_simulation: true}
        case STUDENT_COLLEGE_LATEST_SIMULATION_SUCCESS:
            return {
                ...state,
                loading_college_latest_simulation: false,
                loading_college_latest_simulation_success: true,
                collegeLatestSimulationsResult: action.payload,
            }
        case STUDENT_COLLEGE_LATEST_SIMULATION_FAIL:
            return {
                ...state,
                loading_college_latest_simulation: false,
                loading_college_latest_simulation_success: false,
                college_latest_simulation_error: action.payload,
            }
        default:
            return state;
    }
}