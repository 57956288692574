import { useParams } from 'react-router-dom';
import {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import StudentCollegeView from "./StudentCollegeView"
import StudentCareerView from "./StudentCareerView"
import {getProfileAction} from "../ProfilePage/Redux/actions/GetProfileAction"
// import CounsellorDocument from "./Document"
// import CounsellorEducationalInfo from "./EducationalInfo"
import StudentPersonalInfo from "./PersonalInfo"
import ProfileSecondaryNav from "./SecondNavbar"
import {latestStudentCareerSimulation} from "../../../../../Redux/actions/SimulationActions/CareerSimulationActions/getLatestCareerSImulationAction"
import {getCollegeLatestSimulation} from "../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeLatestSimulation";
import Loader from '../../../LoadersPage';

const StudentProfilePage = () => {
    const {student_id} = useParams()
    const dispatch = useDispatch()
    const {profile = {}, loading} = useSelector(state => state.profileGet)
    const {data = {}} = profile;
    
    const {latestCareerSimulation = {}} = useSelector(state => state.latestCareerSimulation)

    const {data:career = {}} = latestCareerSimulation;
    const {simulation_id, type = [], suggested_careers = []} = career;

    const {collegeLatestSimulationsResult = {}} = useSelector(state => state.latestCollegeSimulation)
    const {data:college_error = {}} = collegeLatestSimulationsResult
    const {data:college_error_message = {}} = college_error
    console.log("error", type);
    
    const {data:college = {}} = collegeLatestSimulationsResult
    const {data:college_result = {}} = college
    const {simulation_id: college_simulation_id, regions = [], cities=[], course = []} = college_result

    useEffect(() => {
        dispatch(getProfileAction(student_id))
    }, [dispatch, student_id])

    useEffect(() => {
        dispatch(latestStudentCareerSimulation(student_id))
    }, [dispatch, student_id])

    useEffect(() => {
        dispatch(getCollegeLatestSimulation(student_id))
      }, [dispatch, student_id])

    return (
        <>
            {loading && <Loader loading={loading}/>}
            {loading && <Loader loading={loading}/>}
            {
                loading ? <Loader loading={loading}/> :
                    <div className="container container-width">
                        <ProfileSecondaryNav student_data={data}/>
                        <StudentPersonalInfo student_data={data}/>
                        {/* <CounsellorEducationalInfo /> */}
                        {/* <CounsellorDocument /> */}
                        <div className="row counsellors_details_row">

                        {career?.message ? null : latestCareerSimulation && latestCareerSimulation && <StudentCareerView type={type} simulation_id={simulation_id} suggested_careers={suggested_careers}/>} 

                        {college_error_message?.message ?  null : collegeLatestSimulationsResult && collegeLatestSimulationsResult && <StudentCollegeView simulation_id={college_simulation_id} cities={cities} regions={regions} course={course}/>}
                            
                        </div>
                
                        {/* {checkStudentCareer < 1 ? null : } */}
                        
                    </div>
            }
        </>
    )
}

export default StudentProfilePage