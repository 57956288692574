import {GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_REQUEST, GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_SUCCESS, GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_FAIL} from "../../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CollegeConstants/StudentCollegeListConstants"

export const ListOfCollegeSimulationReducer = (state={}, action) => {
    switch(action.type){
        case GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_REQUEST:
            return {
                loading_list_college: true
            }
        case GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_SUCCESS:
            return {
                ...state,
                loading_list_college: false,
                list_college_success: true,
                listCollegeSimulation: action.payload
            }
        case GET_LIST_OF_COLLEGE_SIMULATION_OF_A_STUDENTS_FAIL:
             return {
                 ...state,
                 loading_list_college: false,
                 list_college_success: false,
                 list_college_error: action.payload
             }
         default:
             return state
    }
 }