import { GET_COLLEGE_PRODUCT_REQUEST, GET_COLLEGE_PRODUCT_SUCCESS, GET_COLLEGE_PRODUCT_FAIL, GET_SCHOLARSHIP_PRODUCT_FAIL, GET_SCHOLARSHIP_PRODUCT_SUCCESS, GET_SCHOLARSHIP_PRODUCT_REQUEST } from "../../../Utils/constants/HighschoolConstants/ProductConstants";
import { GET_SCHOOL_PRODUCT_SCHOLARSHIP_FAIL, GET_SCHOOL_PRODUCT_SCHOLARSHIP_REQUEST, GET_SCHOOL_PRODUCT_SCHOLARSHIP_SUCCESS } from "../../../Utils/constants/Payment/SchoolSchorships/SchoolScholarshipProductConstant";

export const GetCollegeProductReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_COLLEGE_PRODUCT_REQUEST:
        return { 
            product_loading: true 
            };
      case GET_COLLEGE_PRODUCT_SUCCESS:
        return { 
            product_loading: false, 
            product_college: action.payload, 
            product_success: true 
           };
      case GET_COLLEGE_PRODUCT_FAIL:
        return { 
            product_loading: false, 
            product_error: action.payload, 
            product_success: false 
          };
      default:
        return state;
    }
  };


  export const GetScholarshipProductReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_SCHOLARSHIP_PRODUCT_REQUEST:
        return { 
            scholar_product_loading: true 
            };
      case GET_SCHOLARSHIP_PRODUCT_SUCCESS:
        return { 
            scholar_product_loading: false, 
            scholar_product_college: action.payload, 
            scholar_product_success: true 
           };
      case GET_SCHOLARSHIP_PRODUCT_FAIL:
        return { 
            scholar_product_loading: false, 
            scholar_product_error: action.payload, 
            scholar_product_success: false 
          };
      default:
        return state;
    }
  };


  export const GetSchoolScholarshipProductReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_SCHOOL_PRODUCT_SCHOLARSHIP_REQUEST:
        return { 
            school_scholar_product_loading: true 
            };
      case GET_SCHOOL_PRODUCT_SCHOLARSHIP_SUCCESS:
        return { 
            school_scholar_product_loading: false, 
            school_scholar_product_college: action.payload, 
            school_scholar_product_success: true 
           };
      case GET_SCHOOL_PRODUCT_SCHOLARSHIP_FAIL:
        return { 
            school_scholar_product_loading: false, 
            school_scholar_product_error: action.payload, 
            school_scholar_product_success: false 
          };
      default:
        return state;
    }
  };
  