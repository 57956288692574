import {SEND_HELPDESK_REQUEST, SEND_HELPDESK_SUCCESS, SEND_HELPDESK_FAIL} from "../../Utils/constants/HelpdeskConstants";

export const helpDeskReducer = (state = {}, action) => {
    switch (action.type) {
        case SEND_HELPDESK_REQUEST:
            return {
                loading_helpdesk: true
            }
        case SEND_HELPDESK_SUCCESS:
            return {
                    ...state,
                    loading_helpdesk: false,
                    helpdeskresponse: action.payload,
                    success: true,
            }
        case SEND_HELPDESK_FAIL:
            return {
                ...state,
                loading_helpdesk: false,
                helpdesk_error: action.payload,
                success: false,
            }        
        default:
            return state;
    }
}