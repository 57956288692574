import {GET_CAREER_SIMULATION_BY_SIMULATION_ID_FAIL, GET_CAREER_SIMULATION_BY_SIMULATION_ID_REQUEST, GET_CAREER_SIMULATION_BY_SIMULATION_ID_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/getCareerSimulationBySimulationIdConstant";
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getStudentCareerBySimulationId = (simulation_id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_CAREER_SIMULATION_BY_SIMULATION_ID_REQUEST})
        const {
            tokenAuth: { tokenInfo }
        } = getState();
 
        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        }
 
        const {data} = await axiosInstance.get(
            `/highschools/student/careerSimulationById/${simulation_id}`,
            config
        );
        console.log("data", data);
       dispatch({type: GET_CAREER_SIMULATION_BY_SIMULATION_ID_SUCCESS, payload: data});
       if(data.status === "failed"){
         dispatch({
           type: GET_CAREER_SIMULATION_BY_SIMULATION_ID_FAIL,
           payload: data.reason.message
         });
        }
    } catch (error) {
     dispatch({
         type: GET_CAREER_SIMULATION_BY_SIMULATION_ID_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }
 }