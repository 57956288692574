import { useState, useEffect } from "react";
import SimulatorHeader from "../../SimulatorHeader";
import { useDispatch, useSelector } from "react-redux";
import "../../simulator.css";
import AnswerForm from "./Answer_Folder/Index";
import {Link, useHistory} from "react-router-dom"
import { listContinents } from "../../../SimulatorPage/Redux/action/CollegeQuestionActions/GetContinentAction";
import { listMatchedCountries } from "../../../SimulatorPage/Redux/action/CollegeQuestionActions/GetCountryAction";
import { collegeSimulationCreate } from '../../../SimulatorPage/Redux/action/CollegeQuestionActions/SubmitCollegeSimulationAction';
import { getCourses } from '../../../SimulatorPage/Redux/action/CollegeQuestionActions/GetCoursesAction';
import { HIGHSCHOOL_LIVE_URL } from "../../../../../Utils/constants/Local_URL";
import { listMatchedCities } from "../../Redux/action/CollegeQuestionActions/GetCityAction";
import { FetchTuitionRange } from "../../Redux/action/CollegeQuestionActions/FetchTuitionRange";
import { GetUserTuitionRangeInNaira } from "../../Redux/action/CollegeQuestionActions/GetTuitionRanges";

const CollegeChoices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { loading, continents, error } = useSelector(
    (state) => state.continentLists
  );
  const {
    loading: countryLoad,
    countries,
    error: countryError,
  } = useSelector((state) => state.countryLists);

  const {
    cities_loading,
    cities,
    cities_error,
  } = useSelector((state) => state.getCities);

  const {courses,loading: loadCourses,error: courseError} = useSelector((state) => state.coursesSearch);
  
  const [page, setPage] = useState(1);
  const [getValue, setGetValue] = useState([]);
  const [country, setCountry] = useState([]);
  const [tempCourse, setTempCourse] = useState([]);
  const [errorMessage, setErrorMessage] = useState("")
  
  const [city, setCity] = useState([]);
  const [tempCity, setTempCity] = useState([]);
  const [continent, setContinent] = useState([]);
  const [values, setValues] = useState([]);
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("")


  const handleChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData([...data, parseInt(e.target.value)]);
  };
  const handleInputChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData(e.target.value);
  };
  const remove = (name, data, setData) => {
    const cont = data.filter((c) => c !== name);
    setData(cont);
  };

  const getCourse = tempCourse && tempCourse.map(course => course.id)
  const getCity = tempCity && tempCity.map(city => city.id)

  // console.log("citiiii", getCity);
  const dataToSubmit = {
    regions: continent,
    countries: country,
    cities: getCity,
    courses: getCourse,
    tuition_range_from: getValue[0] ?? null,
    tuition_range_to: getValue[1] ?? null,
  };

  const { loading: create_simulator_loading, error: create_simulator_error, success,  result={}} = useSelector(
    state => state.createSimulator
  );

  
  useEffect(() => {
    const selected_tuition = {
      'currency': 'usd',
      'tuition_range_from': getValue[0],
      'tuition_range_to': getValue[1],
    }
    dispatch(GetUserTuitionRangeInNaira(selected_tuition))
  }, [dispatch, getValue])

  const {range_converter} = useSelector(state => state.range_converter)
  const {min: range_min, max: range_max} = range_converter?.data || {}

  const {data:result_data = {}} = result;
  const {data:my_data = {}} = result_data;
  const {id:simulation_id} = my_data;
  
    useEffect(() => {
      if (create_simulator_loading) {
         console.log("")
      } else if (success){
        const {data:result_data = {}} = result;
        const {data:my_data = {}} = result_data;
        const {id:simulation_id} = my_data;
        let path = `/college-simulator/result/${simulation_id}`; 
        history.push(path);
      }
    }, [create_simulator_loading, success, history, result])

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!continent || !countries || !city || !tempCourse){
      setErrorMessage("Fields must not be empty!")
    } else {
      dispatch(collegeSimulationCreate(dataToSubmit));
    }
  };
  
  const filterName = (id, data) => {
    return data && data.filter((c) => c.id === id);
  };
  
  function onChange(value) {
    setGetValue(value);
  }

  function onAfterChange(value) {
    setGetValue(value);
  }
  
  const goToNextPage = () => {
    setPage(page + 1);
  };
  const goToPrevPage = () => {
    if (page < 1) {
      setPage(page);
    }
    setPage(page - 1);
  };
  
  const getUserToken = localStorage.getItem("tokenInfo");
  let getTokenJson = {};
  
  if (!getUserToken) {
    window.location.href = HIGHSCHOOL_LIVE_URL;
  } else {
    getTokenJson = getUserToken && JSON.parse(getUserToken);
  }
  // console.log("courses", getValue[0].toString())
  
  useEffect(() => {
    dispatch(listContinents());
    dispatch(FetchTuitionRange())
  }, [dispatch]);

  const {tuitions} = useSelector(state => state.tuition_ranges)
 const fetch_tution_data = tuitions?.data || {}
 const {amount_in_naira_max, amount_in_naira_min, min, max} = fetch_tution_data; 


  useEffect(() => {
    dispatch(listMatchedCountries(continent.join(",")));
    // dispatch(listMatchedCities(country.join(",")));
  }, [dispatch, continent]);

  useEffect(() => {
    dispatch(listMatchedCities(country.join(",")));
  }, [dispatch, country]);

  // console.log("county", country);

  useEffect(() => {
    dispatch(getCourses(search));
  }, [dispatch, search]);

  const setRecentValue = (val) => {
    
     setSearch(val)
     setDisplay(false)
  }

  const getSelectedCourseData = (data) => {
    setTempCourse([...tempCourse, data])
  }

  const getSelectedCityData = (data) => {
    setTempCity([...tempCity, data])
  }
  return (
    <>
      {page === 6 ? (
        <div className="result__wrapper">
          <div className="result__page">
            {" "}
            <div className="container">
              <SimulatorHeader
                name="College Simulator"
                logo="/trav4college_logo.png"
              />
              <AnswerForm
                page={page}
                setPage={setPage}
                errorMessage={errorMessage}
                city={city}
                cities={cities}
                cities_loading={cities_loading}
                cities_error={cities_error}
                setCity={setCity}
                country={country}
                setCountry={setCountry}
                getValue={getValue}
                amount_in_naira_max={amount_in_naira_max}
                amount_in_naira_min={amount_in_naira_min}
                maximum={max}
                minimum={min}
                range_min={range_min}
                range_max={range_max}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCity={tempCity}
                setTempCity={setTempCity}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                getSelectedCityData={getSelectedCityData}
                filterName={filterName}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
              />
            </div>
          </div>
            <Link to={`/college-simulator/result/${simulation_id}`}></Link>
          {/* <div className="recommended_college">

            <CollegeSimulationResult college_simulation_id={simulation_id}/>
          </div> */}
        </div>
      ) : (
        <div className="choices_wrapper">
          <div className="container">
            <SimulatorHeader
              name="College Simulator"
              logo="/trav4college_logo.png"
            />
             <AnswerForm
                page={page}
                setPage={setPage}
                city={city}
                cities={cities}
                cities_loading={cities_loading}
                cities_error={cities_error}
                setCity={setCity}
                country={country}
                setCountry={setCountry}
                getValue={getValue}
                amount_in_naira_max={amount_in_naira_max}
                amount_in_naira_min={amount_in_naira_min}
                maximum={max}
                minimum={min}
                range_min={range_min}
                range_max={range_max}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCity={tempCity}
                setTempCity={setTempCity}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                filterName={filterName}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getSelectedCityData={getSelectedCityData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
              />
          </div>
        </div>
      )}
    </>
  );
};

export default CollegeChoices;
