import { GET_WARD_COLLEGE_APPL_COUNT_FAIL, GET_WARD_COLLEGE_APPL_COUNT_REQUEST, GET_WARD_COLLEGE_APPL_COUNT_SUCCESS } from "../../../../../../../Utils/constants/ParentConstant/GetCountOfCollegeAppl";

export const ParentWardCollegeApplCountReducer = (state={}, action) => {
    switch (action.type) {
        case GET_WARD_COLLEGE_APPL_COUNT_REQUEST:
          return {
              loading_count: true
          }
        case GET_WARD_COLLEGE_APPL_COUNT_SUCCESS:
            return {
                ...state,
                loading_count: false,
                wardCollegeApplCount: action.payload
             }  
        case GET_WARD_COLLEGE_APPL_COUNT_FAIL:
                return {
                    ...state,
                    loading_count: false,
                    collegApplCount_error: action.payload
                 }      
    
        default:
            return state;
    }
}