import {GET_COLLEGE_SIMULATION_LIST_FAIL, GET_COLLEGE_SIMULATION_LIST_REQUEST, GET_COLLEGE_SIMULATION_LIST_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/CollegeSimulationListsConstants"
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getStudentCollegeSimulationLists = (student_id) => async(dispatch, getState) => {
    try {
        dispatch({type: GET_COLLEGE_SIMULATION_LIST_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/highschools/student/collegeStudentSimulationlist/${student_id}`,
            config
        )
        dispatch({type: GET_COLLEGE_SIMULATION_LIST_SUCCESS, payload: [data]})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_COLLEGE_SIMULATION_LIST_FAIL, payload: message})
    }
}