import axiosInstance from "../../../Utils/helper/axiosInstance";
import { GET_PAGE_REQUEST, GET_PAGE_SUCCESS, GET_PAGE_FAIL } from "../../../Utils/constants/PaginationConstants.js/GetPagesConstants";

export const GetPageAction =
    (page) => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_PAGE_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.get(
          `/scholarships?page=${page}`,
          config
        );
        dispatch({ type: GET_PAGE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: GET_PAGE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };