import { Link } from "react-router-dom";
const StartCollegeSimulation = ({start_college}) => {
      return (
        <div className={`col-xs-12 col-12  col-sm-12 col-md-12  essential_new_user ${start_college}`}>
        <Link to="/get-started">
          <img
            src="/perspaleta1_0001.png"
            alt="over_essential"
            className="essential"
          />
          <h3 className="my-2">College Simulation</h3>
          <p>   
            By answering simple questions accurately, the college simulator helps you determine the best school for you after graduation from high school.
          </p>
          <p>
            <button className="get_started_link">Get Started</button>
          </p>
        </Link>
      </div>
      )
}

export default StartCollegeSimulation