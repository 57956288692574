import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL } from "../../Utils/constants/getProfileConstants"
import axiosInstance from "../../Utils/helper/axiosInstance";

export const getStudentProfileAction = (id) => async (dispatch, getState) => {
    try {
 
     dispatch({type: GET_PROFILE_REQUEST});
     const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
 
       const { data } = await axiosInstance.get(
         `/highschools/student_profile/${id}`,
         config
       );
 
       dispatch({type: GET_PROFILE_SUCCESS, payload: data})
        
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data
       : error.message;
       dispatch({type: GET_PROFILE_FAIL, payload: message})
    }
 }