import {
    GET_GUARDIAN_NOTIFICATION_REQUEST,
    GET_GUARDIAN_NOTIFICATION_SUCCESS,
    GET_GUARDIAN_NOTIFICATION_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/GetGuardianNotifiationConstant";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const GetNotificationAction =
    () => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_GUARDIAN_NOTIFICATION_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.get(
          "/highschools/notifications",
          config
        );
        dispatch({ type: GET_GUARDIAN_NOTIFICATION_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: GET_GUARDIAN_NOTIFICATION_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };