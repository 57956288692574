import { GET_UNPAID_APPL_COUNT_FAIL, GET_UNPAID_APPL_COUNT_REQUEST, GET_UNPAID_APPL_COUNT_SUCCESS } from "../../../Utils/constants/Payment/CountUnpaidApplConstants";

export const countUnpaidApplReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_UNPAID_APPL_COUNT_REQUEST:
        return { 
            loading: true 
            };
      case GET_UNPAID_APPL_COUNT_SUCCESS:
        return { 
            loading: false, 
            result: action.payload, 
            success: true 
           };
      case GET_UNPAID_APPL_COUNT_FAIL:
        return { 
            loading: false, 
            error: action.payload, 
            success: false 
          };
      default:
        return state;
    }
  };
  