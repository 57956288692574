import { REGISTER_USER_FAIL, REGISTER_USER_SUCCESS, REGISTER_USER_REQUEST } from "../../../../Utils/constants/registerConstants";

export const registerUserReduer = (state={}, action) => {
    switch (action.type) {
        case REGISTER_USER_REQUEST:
          return {
              loading: true
          }
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: true,
                user: action.payload
             }  
        case REGISTER_USER_FAIL:
                return {
                    ...state,
                    loading: true,
                    error: action.payload
                 }      
    
        default:
            return state;
    }
}