import {Link} from "react-router-dom";
import pathArrow from "../../../../../assets/Path-arrow.png";
import "../CareerPage/career.css";
import StartCollegeApplication from '../StudentDashboard/StartCollegeApplication';
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { Layout } from "antd";
import { GetCollegeApplicationsAction } from "../../../SimulatorPage/CollegeApplicationSimulator/Redux/actions/GetCollegeApplicationsAction";
// import CollegeApplicationIndexPage from "./CollegeApplicationLists/Index";
import Loader from "../../../LoadersPage";
import ListCollegeApplTable from "./CollegeApplicationLists/CollegeApplicationList";
import { AuthContext } from "../../../AuthContext/AuthContext";
import { countUnpaidApplAction } from "../../../../../Redux/actions/Payments/CountUnpaidApplAction";
import { getListOfStudentCollegeApplSimulation } from "../CounsellorDashboard/Redux/actions/getListOfCollegeApplists";
const {Content } = Layout;

const SimulateCollegeApplication = () => {
    const dispatch = useDispatch()
    const [countUnpaidAppl, setCountUnpaidAppl] = useState(null);
    const {local_user} = useContext(AuthContext);
    const {college_appl_loading, collegeApplList = {}} = useSelector(state => state.college_appl)
    const {data = {}} = collegeApplList; 
    const {data:college_data = []} = data; 
    const {loading, result = {}, success} = useSelector(state => state.countUnpaidAppl);
    const {data: unpaidApplCount} = result
    const {listCollegeApplSimulation = {}, loading:new_loading} = useSelector(state => state.getStudentCollegeApplist)
    const {data: unpaid_datas = []}= listCollegeApplSimulation

    useEffect(() => {
      dispatch(GetCollegeApplicationsAction())
      dispatch(countUnpaidApplAction(local_user))
    }, [dispatch, local_user])

    useEffect(() => {
      dispatch(getListOfStudentCollegeApplSimulation(local_user))
    }, [dispatch, local_user])

    useEffect(() => {
      if (loading && new_loading) {
         console.log('')
      } else if (success) {
        setCountUnpaidAppl(unpaidApplCount)
      }
    }, [loading, result, success, unpaidApplCount, new_loading])

    return (
      <>
          <div className="conatiner mb-5 mx-3">
            <div className="row ">
            <div className="col-md-6 mb-4">
                 <div className="card shadow-sm">
                    <h3 className="float-start">
                      <strong style={{color: '#004583'}} className="mx-3">Pending Applications: <span className="text-danger">{countUnpaidAppl}</span> </strong> 
                    </h3>
                 </div>
              </div>
              <div className="col-md-6">
              <div className="card shadow-sm m-1">

                  <h3 className="float-end mx-3">
                    <strong style={{color: '#004583'}}><a href="https://trav4college.com/partners#colleges" target="_blank" rel="noreferrer"> EXPLORE COLLEGES <BsFillArrowRightCircleFill className="mx-3"/></a></strong> 
                  </h3>
                  </div>
              </div>
            </div>
          </div>
        <Layout className="ant-design-guide">
  
          <Content style={{ margin: "24px 16px 0" }}>
        <div className="site-layout-background-career"
        style={{
          paddingLeft: 24,
          paddingRight: 24,
          minHeight: "100vh",
        }}
      >
          <>
          {
            college_appl_loading ? 
            <Loader loading={college_appl_loading}/>
            :
            <>
                {
                  unpaid_datas.length > 0 ? <> <ListCollegeApplTable college_appl_data={college_data} unpaid_data={unpaid_datas}/> </>: 
                  <div className="row">
                      <StartCollegeApplication />
                      <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                          <div className="restart_simulation">
                              <div className="simulation_text">
                                {
                                college_data.length > 0 ? <Link to="/get-started-college">Restart<br /> Simulation</Link>: <Link to="/get-started-college">Start<br /> Simulation</Link>
                                }
                                </div>
                              <div className="path_arrow"><img src={pathArrow} alt="arrow_path" /></div>
                          </div>
                      </div>
                  </div> 
                }
            </>
          }
          </>
          
      {/* <CollegeApplicationIndexPage college_data={college_data} loading={college_appl_loading}/> */}
      </div>
      </Content>

      </Layout>
      </>
    )
}

export default SimulateCollegeApplication