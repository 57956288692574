import { GET_COLLEGE_APPL_LIST_FAIL, GET_COLLEGE_APPL_LIST_REQUEST, GET_COLLEGE_APPL_LIST_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplistsConstant";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";

export const getStudentCollegeApplist = () => async (dispatch,getState) => {
    try {
      dispatch({ type: GET_COLLEGE_APPL_LIST_REQUEST });
      const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
      const { data } = await axiosInstance.get(
        // "/highschools/aggregates/student_collegeappl",
        "/product/getUserUnpaidApplByCounselor",
        config
      );
      dispatch({ type: GET_COLLEGE_APPL_LIST_SUCCESS, payload: data });
      
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_COLLEGE_APPL_LIST_FAIL, payload: message });
    }
  };