import { Card, Row, Col } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { GetNotificationAction } from '../../../SimulatorPage/Redux/action/Notifications/GetResultNotificationAction';
import { ViewNotificationAction } from '../../../SimulatorPage/Redux/action/Notifications/ViewNotificationAction';
import moment from "moment";
import Loader from '../../../LoadersPage';
import styles from "./notification.module.css"

const gridStyle = {
  width: '100%',
  marginTop: '10px',
  padding: "13px",
  textAlign: "center",
  backgroundColor: "#ffffff",
};

const NotificationPage = () => {
   const dispatch = useDispatch();
   const {notifs_result = {}, get_notifs_loading} = useSelector(state => state.getNotification);
   // const {viewed_result = {}} = useSelector(state => state.viewedNotification);
   const {data = []} = notifs_result;
   console.log("datas", data)
   const handleViewed = (id) => {
      dispatch(ViewNotificationAction(id));
   }
   useEffect(() => {
      dispatch(GetNotificationAction());
   }, [dispatch])
   return  <>
    <div className="row">
        <div className="col-md-12">
            <Card.Grid style={gridStyle} hoverable={false}>
               <Row>
                  <Col span={6}>Name</Col>
                  <Col span={6}>Simulation Type</Col>
                  <Col span={6}>Date</Col>
                  <Col span={6}>Actions</Col>
                </Row>
            </Card.Grid>

            {
               get_notifs_loading ? <Loader loading={get_notifs_loading}/> :
               data && data?.map((user = {}, id) => 
                  <Card.Grid style={gridStyle} className={ user.is_viewed === 0 ? `${styles.notViewed}` : "viewed"}>
                     <Row>
                        <Col span={6}>{user.user.first_name} {user.user.last_name}</Col>
                        <Col span={6}>{user.notification_type}</Col>
                        <Col span={6}>{moment(user.created_at).format('DD-MM-YYYY')}</Col>
                        <Col span={6}> 
                        <a href={user.result_link} onClick={()=>handleViewed(user.id)} target="_blank" rel='noreferrer'><span className="mx-2" title="View result" style={{ color: "#0480FC", cursor: "pointer", fontSize: "15px"}}><EyeOutlined /></span></a>
                        </Col>
                     </Row>
                  </Card.Grid>
               )
            }

        </div>
    </div>
   </>
}

export default NotificationPage;