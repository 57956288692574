
  import { GET_CITY_FAIL, GET_CITY_REQUEST, GET_CITY_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CityConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const listMatchedCities = (countries_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_CITY_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        `/metadata/cities?countries=${countries_id}`,
        config
      );
      dispatch({ type: GET_CITY_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_CITY_FAIL, payload: message });
    }
  };
  