import {Link, useHistory} from "react-router-dom";
const SecondNavbar = ({user, highschools}) => {
    const {data={}} = user;
    const history = useHistory()
    const {first_name, last_name, highSchoolProfile = {}} = data;
    const {user_type, highschool_id} = highSchoolProfile;
    const highschool_name = highschools?.data?.find(school => school.id === +highschool_id)
    const goToCareerPage = () => {
        history.push("/dashboard/my-career")
        window.location.reload()
    }
    const goToCollegePage = () => {
        history.push("/dashboard/my-college")
        window.location.reload()
    }
    return (
        <div className="secondary_nav row">
            <div className="students_details_sec col-md-3 col-sm-3 col-xm-3 mb-3">
                <div className="row">
                    <div className="col-md-12 col-sm-6 col-lg-12">
                        <span className="students_details_name" style={{textTransform: "capitalize"}}>{first_name} {last_name}</span>
                    </div>
                    <div className="col-md-12 col-sm-6 col-lg-12">
                        <span className="students_school_detail"><span style={{textTransform: "capitalize"}}>{user_type}</span></span> 
                    </div>
                    <div className="col-md-12 col-sm-6 col-lg-12">
                        <span>{highschool_name?.school_name}</span>
                    </div>
                </div>
            </div>
            <div className="students_actions_btn col-md-9 col-sm-9">
                {
                    user_type === "student" ? <>
                        <Link to="/dashboard/my-career"><button  className="mb-3" onClick={goToCareerPage}>View Career Simulations</button></Link>
                        <Link to="/dashboard/my-college"><button onClick={goToCollegePage} className="view_college_simulations mb-3">View College Simulations</button></Link>
                        <Link to="/dashboard/profile/profile-update"><button className="mb-3">Update Profile</button></Link>
                    </> : <> 
                        <Link to="/dashboard/profile/profile-update"><button className="mb-3" >Update Profile</button></Link>
                    </>
                }
                {/* <Link to="/dashboard/my-career"><button>View Career Simulations</button></Link>
                <Link to="/dashboard/my-college"><button className="view_college_simulations">View College Simulations</button></Link>
                */}
            </div>
        </div>
    )
}

export default SecondNavbar;