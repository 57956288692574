import {GET_LIST_OF_CAREER_SIMULATION_STUDENTS_REQUEST, GET_LIST_OF_CAREER_SIMULATION_STUDENTS_SUCCESS, GET_LIST_OF_CAREER_SIMULATION_STUDENTS_FAIL} from "../../../../../../../Utils/constants/HighschoolConstants/Counsellor/GetListOfStudentsCareerSimulationsConstant"

export const StudentcareerSimulationDetailReducer = (state={}, action)  => {
    switch(action.type){
        case GET_LIST_OF_CAREER_SIMULATION_STUDENTS_REQUEST:
            return {loading_student_details: true}
        case GET_LIST_OF_CAREER_SIMULATION_STUDENTS_SUCCESS:
            return {
                ...state,
                loading_student_details: false,
                career_loading_detail: false,
                careerSimulationDetails: action.payload,
                career_detail_success: true
            }
        case GET_LIST_OF_CAREER_SIMULATION_STUDENTS_FAIL:
            return {
                ...state,
                career_loading_detail: false,
                loading_student_details: false,
                career_detail_error: action.payload,
                career_detail_success: false
            }
        default:
            return state
    }
}