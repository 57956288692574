const QuestionTwo = ({questions, handleAnswer, user_answers}) => {
    return (
        <div>
        {questions?.data && questions?.data?.stage_two?.map((question, index) => (
            // <>
                   <div className="all_questions" key={index}><span>{question.question}</span>
                    <div className="question_options"  >
                      {
                        question.options.map(({option, id, question_id}) => (
                           <div className="question_options" key={`${id}--${option}`} >
                               <label className="px-2">{option}</label>
                              <input type="radio" id="question_two" name={question_id} defaultChecked={user_answers.find(item => (item.option_id === id && item.question_id === question_id))} onChange={(e) => handleAnswer(e,question_id)} value={id} />
                           </div>
                          
                        ))
                      }
    
                    </div>
                  </div>
            
            // </>
          ))
           
    
        }
        
     </div>
                
     
    )
}


export default QuestionTwo