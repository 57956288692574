import { GET_STUDENT_COLLEGE_APPL_LIST_FAIL, GET_STUDENT_COLLEGE_APPL_LIST_REQUEST, GET_STUDENT_COLLEGE_APPL_LIST_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/getListOfCollegeApplistConstant"

export const getListOfStudentCollegeApplReducer = (state={}, action) => {
    switch(action.type){
        case GET_STUDENT_COLLEGE_APPL_LIST_REQUEST:
            return {
                loading: true
            }
        case GET_STUDENT_COLLEGE_APPL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                list_collegeAppl_success: true,
                listCollegeApplSimulation: action.payload
            }
        case GET_STUDENT_COLLEGE_APPL_LIST_FAIL:
             return {
                 ...state,
                 loading: false,
                 list_collegeAppl_success: false,
                 list_collegeAppl_error: action.payload
             }
         default:
             return state
    }
 }