import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
import { GET_DEGREE_REQUEST, GET_DEGREE_SUCCESS, GET_DEGREE_FAIL } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/DegreeConstants";
  
  export const getDegreesAction = () => async (dispatch) => {
    try {
      dispatch({ type: GET_DEGREE_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axiosInstance.get(
        `/metadata/degrees`,
        config
      );
      dispatch({ type: GET_DEGREE_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_DEGREE_FAIL, payload: message });
    }
  };
  
  