import { useState, useEffect } from "react";
import SimulatorHeader from "../SimulatorHeader";
import { useDispatch, useSelector } from "react-redux";
import "../simulator.css";
import ApplicationAnswerForm from "./Questionnaire/index";
import {Link, useHistory} from "react-router-dom"
import { listContinents } from "../../SimulatorPage/Redux/action/CollegeQuestionActions/GetContinentAction";
import { listMatchedCountries } from "../../SimulatorPage/Redux/action/CollegeQuestionActions/GetCountryAction";
import { getCourses } from '../../SimulatorPage/Redux/action/CollegeQuestionActions/GetCoursesAction';
import { HIGHSCHOOL_LIVE_URL } from "../../../../Utils/constants/Local_URL";
import { listColleges } from "../Redux/action/CollegeQuestionActions/GetCollegesAction";
import { getDegreesAction } from "../Redux/action/CollegeQuestionActions/GetDegreeAction";
// import { AuthContext } from "../../AuthContext/AuthContext";
import { PayWithPayStack } from "../../../../Redux/actions/Payments/PaymentAction";
import { GetCollegeProductAction } from "../../../../Redux/actions/HighschoolActions/GetProductAction";
import { SendResultAction } from "../Redux/action/Notifications/SendResultAction";
import { message } from "antd";
import { userAction } from "../../../../Redux/actions/userAction";

const CollegeApplicationChoices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  
  const { user = {} } = useSelector(state => state.getUser);
  const {data:user_data = {}} = user;
  const {id:student_id, highSchoolProfile = {}} = user_data;
  const {highschool_id, parent_email:guardian_email} = highSchoolProfile;
  const { loading, continents, error } = useSelector(
    (state) => state.continentLists
  );
  const { product_college = {}} = useSelector(
    (state) => state.college_product
  );
  const {data:college_product_data = []} = product_college;
  const filter_college_product = college_product_data.find(u => u);
  const product_id = filter_college_product?.id;
  const stringed_amount = filter_college_product?.amount;
  const { college_loading, college_list, college_error } = useSelector(
    (state) => state.colleges
  );
  const {payment_result = {}, payment_loading, payment_success} = useSelector(state => state.payment);
  const {data:payment_data = {}, status:initial_success} = payment_result;
  const {reference} = payment_data;
  const {result_loading} = useSelector(state => state.sendNotification);

  const {
    loading: countryLoad,
    countries,
    error: countryError,
  } = useSelector((state) => state.countryLists);
  const {courses,loading: loadCourses,error: courseError} = useSelector((state) => state.coursesSearch);
  const {degrees = {}} = useSelector(state => state.degree);
  const {data:degreeData = []} = degrees;
  const [page, setPage] = useState(1);
  const [getValue, setGetValue] = useState([]);
  const [country, setCountry] = useState([]);
  const [college, setCollege] = useState([]);
  const [tempCourse, setTempCourse] = useState([]);
  const [errorMessage, setErrorMessage] = useState("")
  const choooseConsultant = ["yes", "no"];
  const [speaktoconsultant, setSpeakToConsultant] = useState("")
  const [city, setCity] = useState([]);
  const [tempCity, setTempCity] = useState("");
  const [continent, setContinent] = useState([]);
  const [level, setLevel] = useState("");
  const [values, setValues] = useState([]);
  const [display, setDisplay] = useState(false);
  const [search, setSearch] = useState("")

  const selectedCountry = country;
  const selectedCollege = college;
  // console.log("college_list", college_list?.data.slice(0, 30));
  const handleChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData([...data, parseInt(e.target.value)]);
  };
  const handleInputChange = (e, data, setData) => {
    if (data.includes(e.target.value)) return;
    setData(e.target.value);
  };
  const remove = (name, data, setData) => {
    const cont = data.filter((c) => c !== name);
    setData(cont);
  };

  const getCourse = tempCourse && tempCourse.map(course => course.id)

  useEffect(() => {
  console.log(speaktoconsultant);
  }, [speaktoconsultant])

  useEffect(() => {
    dispatch(userAction())
  }, [dispatch])

const dataToSubmit = {
  product_id,
  highschool_id,
  countries: country,
  college_ids: speaktoconsultant === "yes" ? "nil" : college.toString(),
  first_choice_course: getCourse.toString(),
  second_choice_course: "nil",
  degree_id: level.toString(),
  extra_info: "nil",
  select_college_with_consultant: speaktoconsultant
};

const country_selected = country.toString();

const { loading: create_simulator_loading, error: create_simulator_error,  result={}} = useSelector(
  state => state.createSimulator
  );

  const {data:result_data = {}} = result;
  const {data:my_data = {}} = result_data;
  const {id:simulation_id} = my_data;
  
  useEffect(() => {
    if (payment_success) {
      let path = `/college_appl-simulator/result/${reference}`; 
      history.push(path);
    }
      // const {data:result_data = {}} = result;
      // const {data:my_data = {}} = result_data;
      // const {id:simulation_id} = my_data;
  }, [payment_success, history, reference])
  const handleSubmit = (e) => {
    e.preventDefault();
    if(!level || !countries || !college || !tempCourse){
      setErrorMessage("Fields must not be empty!")
    } else {
       if (!guardian_email){
        message.error('Please, update your profile to continue');
      } else {
        dispatch(PayWithPayStack(dataToSubmit))
      }
  
    }
  };
 
  useEffect(() => {
    if (payment_loading) {
      console.log('');
    } 
    else if (payment_success && guardian_email) {
      const sendPaymentToParents = {
        amount: stringed_amount,
        id: student_id,    
        notification_type: 'college application',    
        simulation_id: "",    
        guardian_type: "parent",   
        guardian_email: guardian_email,    
        result_link: "",
        type: 'message',    
        token: reference,    
      }
        dispatch(SendResultAction(sendPaymentToParents))
      } 
    }, [reference, dispatch, payment_loading, stringed_amount, student_id, guardian_email, payment_success, history])
    
  //   useEffect(() => {
  //     if (result_loading) {
  //       console.log('')
  //     } else if (result_success) {
  //       history.push('/dashboard/my-college-application')
  //   }
  // }, [result_loading, result_success, history])

  const filterName = (id, data) => {
    return data && data.filter((c) => c.id === id);
  };
  
  function onChange(value) {
    setGetValue(value);
  }

  function onAfterChange(value) {
    setGetValue(value);
  }

  const goToNextPage = () => {
    setPage(page + 1);
  };
  const goToPrevPage = () => {
    if (page < 1) {
      setPage(page);
    }
    setPage(page - 1);
  };
  
  const getUserToken = localStorage.getItem("tokenInfo");
  let getTokenJson = {};
  
  if (!getUserToken) {
    window.location.href = HIGHSCHOOL_LIVE_URL;
  } else {
    getTokenJson = getUserToken && JSON.parse(getUserToken);
  }
  
  useEffect(() => {
    dispatch(listContinents());
    dispatch(listColleges(country_selected));
    dispatch(getDegreesAction())
  }, [dispatch, country_selected]);
  
  useEffect(() => {
    dispatch(GetCollegeProductAction())
  }, [dispatch])

  useEffect(() => {
    dispatch(listMatchedCountries(continent.join(",")));
  }, [dispatch, continent]);

  useEffect(() => {
    dispatch(getCourses(search));
  }, [dispatch, search]);

  const setRecentValue = (val) => {
    
     setSearch(val)
     setDisplay(false)
  }

  const onChangeConsultant = (e) => {
    setSpeakToConsultant(e.target.value);
  };

  const getSelectedCourseData = (data) => {
    setTempCourse([...tempCourse, data])
  }
  return (
    <>
      {page === 6 ? (
        <div className="result__wrapper">
          <div className="result__page">
            {" "}
            <div className="container">
              <SimulatorHeader
                name="College Application test"
                logo="/trav4college_logo.png"
              />
              <ApplicationAnswerForm
                page={page}
                setPage={setPage}
                errorMessage={errorMessage}
                speaktoconsultant={speaktoconsultant}
                setSpeakToConsultant={setSpeakToConsultant}
                onChangeConsultant={onChangeConsultant}
                choooseConsultant={choooseConsultant}
                consultant={speaktoconsultant}
                city={city}
                setCity={setCity}
                level={level}
                selectedCountry={selectedCountry}
                setLevel={setLevel}
                country={country}
                setCountry={setCountry}
                college={college}
                setCollege={setCollege}
                college_list={college_list}
                getValue={getValue}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCity={tempCity}
                setTempCity={setTempCity}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                filterName={filterName}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                college_error={college_error}
                college_loading={college_loading}
                selectedCollege={selectedCollege}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
                result_loading={result_loading}
                college_product={filter_college_product}
                degrees={degreeData}
                initial_success={initial_success}
                payment_loading={payment_loading}
              />
            </div>
          </div>
            <Link to={`/college-simulator/result/${simulation_id}`}></Link>
          {/* <div className="recommended_college">

            <CollegeSimulationResult college_simulation_id={simulation_id}/>
          </div> */}
        </div>
      ) : (
        <div className="choices_wrapper">
          <div className="container">
            <SimulatorHeader
              name="College Simulator"
              logo="/trav4college_logo.png"
            />
             <ApplicationAnswerForm
                page={page}
                setPage={setPage}
                choooseConsultant={choooseConsultant}
                speaktoconsultant={speaktoconsultant}
                setSpeakToConsultant={setSpeakToConsultant}
                onChangeConsultant={onChangeConsultant}
                consultant={speaktoconsultant}
                city={city}
                setCity={setCity}
                level={level}
                selectedCountry={selectedCountry}
                setLevel={setLevel}
                country={country}
                setCountry={setCountry}
                college={college}
                setCollege={setCollege}
                college_list={college_list}
                getValue={getValue}
                continent={continent}
                continents={continents}
                setContinent={setContinent}
                tempCity={tempCity}
                setTempCity={setTempCity}
                loading={loading}
                handleChange={handleChange}
                handleInputChange={handleInputChange}
                remove={remove}
                onChange={onChange}
                onAfterChange={onAfterChange}
                goToNextPage={goToNextPage}
                goToPrevPage={goToPrevPage}
                error={error}
                filterName={filterName}
                values={values}
                setValues={setValues}
                countryLoad={countryLoad}
                countryError={countryError}
                selectedCollege={selectedCollege}
                countries={countries}
                tempCourse={tempCourse}
                setTempCourse={setTempCourse}
                loadCourses={loadCourses}
                courseError={courseError}
                options={courses}
                display={display}
                setDisplay={setDisplay}
                setRecentValue={setRecentValue}
                search={search}
                setSearch={setSearch}
                getSelectedCourseData={getSelectedCourseData}
                getTokenJson={getTokenJson}
                handleSubmit={handleSubmit}
                result={result}
                create_simulator_error={create_simulator_error}
                create_simulator_loading={create_simulator_loading}
                result_loading={result_loading}
                college_product={filter_college_product}
                degrees={degreeData}
                initial_success={initial_success}
                payment_loading={payment_loading}
              />
          </div>
        </div>
      )}
    </>
  );
};

export default CollegeApplicationChoices;
