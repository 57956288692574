import { COUNT_STUDENT_COLLEGE_SIMULATION_FAIL, COUNT_STUDENT_COLLEGE_SIMULATION_REQUEST, COUNT_STUDENT_COLLEGE_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/countCollegeSimulationConstant"
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const countCollegeSimulation = () => async (dispatch, getState) => {
  try {
      dispatch({type: COUNT_STUDENT_COLLEGE_SIMULATION_REQUEST})
      const {
          tokenAuth: { tokenInfo }
      } = getState();

      const config = {
          headers: {
              Authorization: `Bearer ${tokenInfo.data.userToken}`,
              "Content-Type": "application/json"
          }
      };
      const {data} = await axiosInstance.get(
          "/highschools/college_simulations/count_collegesimulations",
          config
      )
      dispatch({type: COUNT_STUDENT_COLLEGE_SIMULATION_SUCCESS, payload: data});
  } catch (error) {
      let message = error.response && error.response.data ? error.response.data : error.message;
      dispatch({type: COUNT_STUDENT_COLLEGE_SIMULATION_FAIL, payload: message});
  }
}