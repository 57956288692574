import "./helpdesk.css";
import { Layout, Modal } from "antd";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { helpDeskAction } from "../../../../../Redux/actions/HelpdeskAction";
const { Content } = Layout;

const HelpDesk = () => {
    const dispatch = useDispatch()
  
  const [helpdeskDetails, setHelpdeskDetails] = useState({subject: "", message: "", client: "app", deviceInfo: navigator.userAgent, platform:"web", appversion: "1.0", appBuildVersion: "1.0"})
    const [errorMessage, setErrorMessage] = useState();
    const {helpdeskresponse = {}, success, loading_helpdesk} = useSelector(state => state.helpdesk)
    const {data = {}} = helpdeskresponse;
    const {status} = data;
    const [visible, setVisible] = useState(false);
      
    const cancel = () => {
        setVisible(false)
      }
      
    useEffect(() => {
        if (loading_helpdesk) {
           console.log("loading...") 
        } else if (success) {
            setVisible(true)
        }
    }, [loading_helpdesk, success])  

    const handleChange = (e) => {
        const { name, value } = e.target
        setHelpdeskDetails({...helpdeskDetails, [name]: value});
   }

    const handleSubmit = (e) => { //this is  a update profile submit button
        e.preventDefault();
        if (!helpdeskDetails.subject || !helpdeskDetails.message) {
            setErrorMessage("All fields must be complete");
        } else {
            dispatch(helpDeskAction(helpdeskDetails))
            setErrorMessage(status)
        }
    }

    setTimeout(() => {
        setErrorMessage("")
    }, 3000);

    return (
        <Layout className="ant-layout">
          
               <Modal 
               title="Support message sent successfully!"
               visible={visible}
               width={350}
               onCancel={cancel}
               footer={false}
                >
                 <p style={{ fontWeight: "bolder" }}>Your support message has been sent, we will get back to you soon</p>
               </Modal>

            <Content style={{ margin: "24px 16px 0" }}>
              <div className="contain<Modal>r">
                <div className="help_desk">
       
                    <form>
                        <div className="mb-3 form-subject">
                            <label htmlFor="subject" className="form-label">Subject</label>
                            <input type="text" className="form-control" onChange={handleChange} id="subject" value={helpdeskDetails.subject} name="subject" aria-describedby="subject"/>
                            
                        </div>
                        <div className="mb-3 form">
                        <label htmlFor="message" className="form-label">Your Message</label><br />
                        <textarea id="message" onChange={handleChange} value={helpdeskDetails.message} name="message" rows="7" cols="62" placeholder="Enter message here"></textarea>
                        </div>
                        
                        <div className="mb-2">
                            <span className="text-danger">{errorMessage}</span>
                        </div>
                        <button type="submit" onClick={handleSubmit} className="btn btn-primary helpdesk_button">Submit</button>
                    </form>
                    <div className="helpdesk_details">
                        <p className="helpdesk_important_details">Need to speak to customer care representative </p>
                        <span className="helpdesk_contact">Call +2347063157060, +2349062547048<br /> or you can send a mail to <strong>highschools@trav4college.com</strong></span>
                    </div>
                </div>
              </div>
            </Content>

        </Layout>
    )
}


export default HelpDesk;