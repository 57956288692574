
// import { Link } from "react-router-dom";

const CollegeApplied = ({college_result = [], college_error = {}}) => {
    return (
        <>
        <div className="container college_result">
      <h5>COLLEGE(S) CHOSEN</h5>
             <div className="row">
         {
           college_result && college_result?.map(({logo, college_name, id, college_address, general_requirements, general_application_info}) => (
                <div className="col-12  recommended_col" key={id}>
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8 col-sm-8 col-lg-8">
                                    <div className="row">
                                        <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                                            <img className="school_logos" src={logo || "/unilogo.png"} alt="uni logo"/>
                                        </div>
                                        <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                                            <h5 className="recommended_school">
                                                {college_name}
                                            </h5>
                                            <p className="recommended_school_p">
                                                <img src="/location.png" alt="Location" /> &nbsp; 
                                                {college_address}
                                            </p>
                                            <p className="recommended_school_ps">
                                                {general_application_info}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-4 col-lg-4 result_page_general">
                                    <p>{general_requirements}</p>
                                    {/* <p>${college.price}/year</p> */}
                                    {/* <div className="btn" onClick={id}><Link to={`/simulate-college/colleges?id=${barData.college.id}`}>View Details</Link></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              ))
         }
         </div>
         {/* <div className="my-4 more__details">
            <Link to="/readmore">Read more details</Link>
         </div> */}
         </div>
        </>
    )
    
 }
 
 export default CollegeApplied