import axiosInstance from "../../../Utils/helper/axiosInstance";
import { GET_COLLEGE_PRODUCT_REQUEST, GET_COLLEGE_PRODUCT_SUCCESS, GET_COLLEGE_PRODUCT_FAIL, GET_SCHOLARSHIP_PRODUCT_REQUEST, GET_SCHOLARSHIP_PRODUCT_SUCCESS, GET_SCHOLARSHIP_PRODUCT_FAIL} from "../../../Utils/constants/HighschoolConstants/ProductConstants";
import { GET_SCHOOL_PRODUCT_SCHOLARSHIP_FAIL, GET_SCHOOL_PRODUCT_SCHOLARSHIP_REQUEST, GET_SCHOOL_PRODUCT_SCHOLARSHIP_SUCCESS } from "../../../Utils/constants/Payment/SchoolSchorships/SchoolScholarshipProductConstant";

export const GetCollegeProductAction = () => async(dispatch, getState) => {
    try {
        dispatch({type: GET_COLLEGE_PRODUCT_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/product_types/services`,
            config
        )
        dispatch({type: GET_COLLEGE_PRODUCT_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_COLLEGE_PRODUCT_FAIL, payload: message})
    }
}

export const GetScholarshipProductAction = () => async(dispatch, getState) => {
    try {
        dispatch({type: GET_SCHOLARSHIP_PRODUCT_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/product_types/scholarships`,
            config
        )
        dispatch({type: GET_SCHOLARSHIP_PRODUCT_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_SCHOLARSHIP_PRODUCT_FAIL, payload: message})
    }
}

export const GetSchoolScholarshipProductAction = () => async(dispatch, getState) => {
    try {
        dispatch({type: GET_SCHOOL_PRODUCT_SCHOLARSHIP_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/product_types/school_scholarship`,
            config
        )
        dispatch({type: GET_SCHOOL_PRODUCT_SCHOLARSHIP_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_SCHOOL_PRODUCT_SCHOLARSHIP_FAIL, payload: message})
    }
}