import { AiTwotoneStar } from "react-icons/ai";

const HeroPage = () => {
    return <>
      <div className="row">
        <div className="col-md-6">
            <span className="">
              <AiTwotoneStar size={'30px'} style={{color: '#FFDC64'}}/> <span className="mx-4 hero-star-content">Scholarships that match your profile</span>
            </span>
        </div>
      </div>
    </>
}

export default HeroPage;