import { AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAIL, USER_LOGOUT } from "../../Utils/constants/authConstant";

export const authTokenReducer = (state = {}, action) => {
    switch (action.type) {
        case AUTH_TOKEN_REQUEST:
            return {loading: true};
            
        case AUTH_TOKEN_SUCCESS: 
           return {
               ...state, 
               loading: false,
               tokenInfo: action.payload,
               success: true
           };
        case AUTH_TOKEN_FAIL:
            return {
                ...state,
                loading: false,
                success: false,
                error: action.payload.reason,
                status: action.payload.status
            };
        case USER_LOGOUT:
            return {};   
        default:
            return state;
    }
}
