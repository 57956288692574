import { createStore, combineReducers, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { composeWithDevTools } from "redux-devtools-extension";

import { authTokenReducer } from "../reducers/AuthReducer";
import { getStudentProfileReducer } from "../reducers/GetProfileReducer";
import { getNewsReducer } from "../reducers/GetNewsReducer";
import { singleNewsReducer } from "../reducers/SingleNewsReducer";
import { userReducer } from "../reducers/UserReducer";
import { registerUserReduer } from "../../components/Register/Redux/reducers/RegisterUserReducer";
import { listHighSchoolReducer } from "../reducers/HighschoolReducers/listHighschoolsReducer";
import { getCareerSimulationReducer } from "../reducers/SimulationReducers/CareerSimulationReducers/getCareerSimulationReducer";
import { countCareerSimulationReducer } from "../reducers/SimulationReducers/CareerSimulationReducers/countCareerSimulationReducer";
import { countCollegeSimulationReducer } from "../reducers/SimulationReducers/CollegeSimulationReducers/countCollegeSimulationReducer";
import { getCollegeSimulationReducer } from "../reducers/SimulationReducers/CollegeSimulationReducers/getCollegeSimulationReducer";
import { latestCareerSimulationReducer } from "../reducers/SimulationReducers/CareerSimulationReducers/latestCareerSimulationReduer";
import { getLatestCollegeSimulationReducer } from "../reducers/SimulationReducers/CollegeSimulationReducers/getCollegeLatestSimulationReducer";
import { profileUpdateReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ProfilePage/Redux/reducers/UpdateProfileReducer";
import { getProfileReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ProfilePage/Redux/reducers/GetProfileReducer";
import { studentCareerSimulationListReducer } from "../reducers/SimulationReducers/CareerSimulationReducers/careerSimulationListsReducer"
import { studentCollegeSimulationListReducer } from "../reducers/SimulationReducers/CollegeSimulationReducers/collegeSimulationListsReducer"
import { getCareerSimulationBySimulationId } from "../reducers/SimulationReducers/CareerSimulationReducers/getCareerSimulationBySimulationIdReducer"
import { getCollegeSimulationBySimulationId } from "../reducers/SimulationReducers/CollegeSimulationReducers/getCollegeSimulationBySimulationIdReducer"
import { careerQuestionReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CareerQuestionReducers/CareerQuestionReducer";
import { careerAnswerReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CareerQuestionReducers/CareerAnswerSubmitReducer";
import { listContinentsReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetContinentReducer";
import { listCountryReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetCountryReducer";
import { simulationReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/SubmitCollegeSimulationReducer";
import { searchCourses } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetCoursesReducer";
import { countStudentUnderCounsellorReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/NumberOfStudentsUnderCounsellorReducer";
import { countStudentsCareerSimulationReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/CountStudentCareerSimulationReducer";
import { StudentcareerSimulationDetailReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/GetListOfStudentsCareerSimulationsReducer";
import { countStudentsCollegeSimulationReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/CollegeReducers/CountStudentCollegeSimulationReducer";
import { collegeSimulationDetailReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/CollegeReducers/GetListOfStudentsCollegeSimulationReducer";
import { studentListReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/StudentListReducer";
import { ListOfCareerSimulationReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/getListOfStudentsCareerListReducer";
import { ListOfCollegeSimulationReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/CollegeReducers/getListOfStudentsCollegeListReducer";
import { ParentWardLinkReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/Redux/reducers/ParentWardReducer";
import { GetParentWardLinkReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/Redux/reducers/GetWardsReducer";
import { helpDeskReducer } from "../reducers/HelpdeaskReducer";
import { listCollegesReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetCollegesReducer";
import { SendResultReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Notifications/SendResutReducer";
import { GetNotificationReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Notifications/GetResultNotificationReducer";
import { CountNotificationReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Notifications/CountNotificationReducer";
import { ViewNotificationReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Notifications/ViewNotificationReducer";
import { HighschoolCounsellorReducer } from "../reducers/HighschoolReducers/HighschoolCounsellorReducer";
import { GetCollegeProductReducer, GetScholarshipProductReducer, GetSchoolScholarshipProductReducer } from "../reducers/HighschoolReducers/GetProductReducer";
import { getDegreesReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetDegreesReducer";
import { PayWithPayStackReducer } from "../reducers/Payments/PaymentReducer";
import { CompletePaymentWithPayStackReducer } from "../reducers/Payments/CompletePaymentReducer";
import { GetCollegeApplicationsReducer, GetCollegeApplUnpaidOrderReducer } from "../../components/Pages/SimulatorPage/CollegeApplicationSimulator/Redux/reducers/GetCollegeApplicationsReducer";
import { getStudentCollegeApplByIdReducer } from "../../components/Pages/SimulatorPage/CollegeApplicationSimulator/Redux/reducers/GetCollegeApplByIdReducer";
import { getScholarshipProfile } from "../reducers/SimulationReducers/ScholarshipReducers/GetScholarshipProfileReducer";
import { postScholarshipProfile } from "../reducers/SimulationReducers/ScholarshipReducers/SubmitScholarShipProfileReducer";
import { getScholarshipsReducer } from "../reducers/SimulationReducers/ScholarshipReducers/GetScholarshipsReducer";
import { ScholarInitialPaymentReducer, SchoolScholarInitialPaymentReducer } from "../reducers/Payments/ScholarPayReducer";
import { ScholarCompletePaymentReducer } from "../reducers/Payments/CompleteScholarshipPayReducer";
import { GetPageReducer } from "../reducers/GetPagesReducers/GetPageReducer";
import { listCitiesReducer } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/GetCityReducer";
import { checkExistingProfileReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ExistingAccount/Redux/exitingAccountReducer";
import { listCountriesReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ProfilePage/Redux/reducers/GetCountriesReducer";
import { countStudentsCollegeApplReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/countCollegeApplReducer";
import { getStudentsCollegeApplReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/collegeApplistReducer";
import { getListOfStudentCollegeApplReducer } from "../../components/Pages/DashboardLayoutPage/Contents/CounsellorDashboard/Redux/reducers/getListOfStudentsCollegeApplListReducer";
import { ParentWardCollegeApplCountReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/Redux/reducers/ParentWardColleApplCountRed";
import { ParentWardCollegeApplReducer } from "../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/Redux/reducers/ParentWardColleAppRed";
import { ViewMessageReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Messages/ViewMessageReducer";
import { GetMessageReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Messages/GetResultMessageReducer";
import { CountMessageReducer } from "../../components/Pages/SimulatorPage/Redux/redux/Messages/CountMessagesReducer";
import { UpdateReferenceReducer } from "../reducers/Payments/UpdateTokenReducer";
import { countUnpaidApplReducer } from "../reducers/Payments/CountUnpaidApplReducer";
import { unpaidApplReducer } from "../reducers/Payments/UnpaidApplReducer";
import { fetchTuitionRange } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/FetchTuitionReducer";
import { fetchTuitionInNaira } from "../../components/Pages/SimulatorPage/Redux/redux/CollegeQuestionReducers/FetchTuitionRangeInNaira";
import { SchoolScholarshipReducer } from "../reducers/Payments/SchoolScholarshipReducer";


let localData = localStorage.getItem("tokenInfo") && JSON.parse(localStorage.getItem("tokenInfo"));

const initialState = {
    tokenAuth: {
        tokenInfo: localData
    }   
}

let middleware = process.env.REACT_APP_NODE_ENV !== 'deployment' ? [thunk, logger] : [thunk];
// let middleware = [thunk, logger]
const reducers = combineReducers({

    tokenAuth: authTokenReducer,
    getStudentProfile: getStudentProfileReducer,
    getUser: userReducer,
    registerUser: registerUserReduer,
    listHighschools: listHighSchoolReducer,
    getStudentCareerSimulation: getCareerSimulationReducer,
    countStudentCareerSimulation: countCareerSimulationReducer,
    getStudentCollegeSimulation: getCollegeSimulationReducer,
    countStudentCollegeSimulation: countCollegeSimulationReducer,
    latestCareerSimulation: latestCareerSimulationReducer,
    latestCollegeSimulation: getLatestCollegeSimulationReducer,
    profileGet: getProfileReducer,
    updateProfile: profileUpdateReducer,
    studentCareerLists: studentCareerSimulationListReducer,
    newsLists: getNewsReducer,
    singleNews: singleNewsReducer,
    studentCollegeLists: studentCollegeSimulationListReducer,
    getStudentCareerbySimulationId: getCareerSimulationBySimulationId,
    getStudentCollegebySimulationId: getCollegeSimulationBySimulationId,
    careerChoices: careerQuestionReducer,
    careerAnswer: careerAnswerReducer,
    continentLists: listContinentsReducer,
    countryLists: listCountryReducer,
    createSimulator: simulationReducer,
    coursesSearch: searchCourses,
    colleges:listCollegesReducer,
    highschoolCounsellor:HighschoolCounsellorReducer,
    college_product: GetCollegeProductReducer,
    scholar_product: GetScholarshipProductReducer,
    degree: getDegreesReducer,
    college_appl: GetCollegeApplicationsReducer,
    unpaid_order: GetCollegeApplUnpaidOrderReducer,
    student_college_appl: getStudentCollegeApplByIdReducer,
    scholarshipProfile: getScholarshipProfile,
    submitScholarshipProfile: postScholarshipProfile,
    getScholarships: getScholarshipsReducer,
    getCities: listCitiesReducer,
    getCountries: listCountriesReducer,
    existingacc: checkExistingProfileReducer,
    tuition_ranges: fetchTuitionRange,
    range_converter: fetchTuitionInNaira,

    // counsellor store start here
    getStudentCount : countStudentUnderCounsellorReducer,
    studentsCareerSimulationCount: countStudentsCareerSimulationReducer,
    careerDetails: StudentcareerSimulationDetailReducer,
    collegeSimulationCount: countStudentsCollegeSimulationReducer,
    collegeDetails: collegeSimulationDetailReducer,
    studentLists: studentListReducer,
    listOfCareerStudents : ListOfCareerSimulationReducer,
    listOfCollegeStudents : ListOfCollegeSimulationReducer,
    countColegeAppl: countStudentsCollegeApplReducer,
    getCollegeApplist: getStudentsCollegeApplReducer,
    getStudentCollegeApplist: getListOfStudentCollegeApplReducer,
    school_scholr_product: GetSchoolScholarshipProductReducer,

    // parent store start here
    parentWard: ParentWardLinkReducer,
    getParentWard: GetParentWardLinkReducer,
    helpdesk: helpDeskReducer,
    collegeApplCount: ParentWardCollegeApplCountReducer,
    collegeApplist: ParentWardCollegeApplReducer,
    
    // Notification store
    sendNotification: SendResultReducer,
    getNotification: GetNotificationReducer,
    countNotification: CountNotificationReducer,
    viewedNotification: ViewNotificationReducer,
    viewedMessage: ViewMessageReducer,
    getMessage: GetMessageReducer,
    countMessage: CountMessageReducer,

    // Payment
    payment: PayWithPayStackReducer,
    completePayment: CompletePaymentWithPayStackReducer,
    scholarInitPayment: ScholarInitialPaymentReducer,
    scholarCompletePayment: ScholarCompletePaymentReducer,
    updateReference: UpdateReferenceReducer,
    countUnpaidAppl: countUnpaidApplReducer,
    unpaidAppl: unpaidApplReducer,
    schoolScholarShip: SchoolScholarshipReducer,
    initiateSchoolPayment: SchoolScholarInitialPaymentReducer,
    
    // pages
    pages: GetPageReducer,
});

const store = createStore(
    reducers, initialState, composeWithDevTools(applyMiddleware(...middleware)), 
);

export default store;
