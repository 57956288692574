import {useEffect} from "react"
import {Link} from "react-router-dom"
import arc from "../../../../../assets/career-arc.png";
import bag from "../../../../../assets/bag.png";
import { useDispatch, useSelector } from "react-redux"
import { latestStudentCareerSimulation } from "../../../../../Redux/actions/SimulationActions/CareerSimulationActions/getLatestCareerSImulationAction";
import Loader from "../../../LoadersPage";

const CareerSimulationResult = () => {
   const dispatch = useDispatch();
   const {loading_latest_career, latest_career_error, latestCareerSimulation = {}} = useSelector(state => state.latestCareerSimulation)
   const {data = {}} = latestCareerSimulation
   const {simulation_id, type = []} = data;
//    console.log("type", data);
   let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
   const student_id = localResult.data.id

   useEffect(() => {
    dispatch(latestStudentCareerSimulation(student_id))
    }, [dispatch, student_id])

    return (
        <>
            <div className="col-12 col-xs-12 col-sm-12 col-lg-6 col-md-6">
               {loading_latest_career ? 
                <Loader loading={loading_latest_career}/>
               : 
                <div className="card career_card">
                    <div className="card-body">
                        {/* {user_error && <p className="text-center text-danger">{user_error}</p> } */}
                        {latest_career_error && <p className="text-center text-danger">{latest_career_error.message}</p>}
                     <div className="career_simualte">
                            <h4 className="career_title">MY CAREER SIMULATION</h4>
                            <p className="result_header">RESULT</p>
    <h1 className="realistic_header">{type && type.map(type => type.personality_type).join(", ")}</h1>
                            <p className="career_text">View Career Reccommendations that can help you begin your career journey. </p>
                            <Link to={`/career-simulator/result/${simulation_id}`}  className="career_recommendations" >View full result</Link>
                            <Link to="/get-started-career" className="career_recommendations ms-4">Restart Simulation</Link>
                        </div>
                 
                    </div>
                    <img src={arc} alt="career_arc" className="bg_career_img"/>
                    <div className="career_bag"><img src={bag} alt="bag"/></div>
                </div>
                }
        </div>
        </>
    )
}

export default CareerSimulationResult