import {FaGraduationCap} from "react-icons/fa"
import  './register.css';
import Banner from './Banner';
import Register from './RegisterForm'

const RegisterPage = () => {
  return (
      <div className="register_container">
          <Banner>
              <div className="trav4College_logo"><FaGraduationCap style={{fontSize: "2rem", color: "black", marginRight: "10px", marginTop: "-2px"}}/><img src="/Trav4College.png" alt="logo"/></div>
              <div className="register_description">
                <h1>Hello!</h1>
                <p>Welcome to Trav4college.<br/> With us, all your college dreams are bound to come true.</p>
              </div>
          </Banner>
          <Register />
      </div>
              
  )
}

export default RegisterPage;
