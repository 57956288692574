import { GET_PAYSTACK_SCHOLARSHIP_PAYMENT_REQUEST, GET_PAYSTACK_SCHOLARSHIP_PAYMENT_SUCCESS, GET_PAYSTACK_SCHOLARSHIP_PAYMENT_FAIL, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_REQUEST, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_SUCCESS, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_FAIL } from "../../../Utils/constants/Payment/ScholarshipPaymentConstants";

export const ScholarInitialPaymentReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PAYSTACK_SCHOLARSHIP_PAYMENT_REQUEST:
        return { scholarPay_init_loading: true };
      case GET_PAYSTACK_SCHOLARSHIP_PAYMENT_SUCCESS:
        return {
          ...state,
          scholarPay_init_loading: false,
          scholarPay_init_complete: action.payload,
          scholar_init_success: true
        };
      case GET_PAYSTACK_SCHOLARSHIP_PAYMENT_FAIL:
        return {
          ...state,
          scholarPay_init_loading: false,
          scholar_payment_init_error: action.payload,
          scholar_init_success: false
        };
      default:
        return state;
    }
  };

  export const SchoolScholarInitialPaymentReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_REQUEST:
        return { school_scholar_init_loading: true };
      case GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_SUCCESS:
        return {
          ...state,
          school_scholar_init_loading: false,
          school_scholar_init_complete: action.payload,
          school_scholar_init_success: true
        };
      case GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_FAIL:
        return {
          ...state,
          school_scholar_init_loading: false,
          school_scholar_payment_init_error: action.payload,
          school_scholar_init_success: false
        };
      default:
        return state;
    }
  };