import "./career.css"
import {Link} from "react-router-dom"
import Paginate from "../../../../../Utils/helper/Paginate"
import { useState } from "react"
const ListCareerSimulationTable = ({career_simulation_list = []}) => {
    const newdata = career_simulation_list.find(newarray => newarray);
    const newArray = newdata?.data ? newdata?.data : [];
    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5);
    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = newArray.length > 0 ? newArray?.slice(indexOffirstStudent, indexOflastStudent) : [];
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
          <>
          <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-5 mb-5">
        {/* <div className="card career_card table-responsive mt-4"> */}
        <h4 className="career_title m-2 text-center">ALL CAREER SIMULATION LIST</h4>

        <table class="table table-hover">
            <thead>
                <tr align="middle" style={{ fontWeight: "700" }}>
                <td>TYPE(S)</td>
                <td>ACTION</td>
                </tr>
            </thead>
            <tbody>
             {
                currentStudents && currentStudents?.map(({type, simulation_id}) => {
                    return (
                        <tr align="middle" key={simulation_id} className="shadow-sm">
                            <td style={{textTransform: "capitalize"}}>{type && type.map(type => type.personality_type).join(", ")}</td>
                            <td className="message_status">
                            <Link to={`/career-simulator/result/${simulation_id}`}><span>View Simulation</span></Link>
                            </td>
                        </tr>
                    )
                })
             }
         </tbody>
         {
                newArray?.length > 5 ?
                <div className="m-3">
                 <Paginate studentPerPage={studentsPerPage} totalStudents={newArray?.length} paginate={paginate} />
                 </div>
                  : null
         }
            </table>

        {/* <table className="table">
         <thead className="tborder" align="middle">
             <tr className="align_student_table">
                 <th scope="col">Type</th>
                 <th scope="col">Actions</th>
             </tr>
         </thead>
         <tbody className="table_body">
             {
                currentStudents && currentStudents?.map(({type, simulation_id}) => {
                    return (
                        <tr align="middle" key={simulation_id}>
                            <td style={{textTransform: "capitalize"}}>{type && type.map(type => type.personality_type).join(", ")}</td>
                            <td className="message_status">
                            <Link to={`/career-simulator/result/${simulation_id}`}><span>View Simulation</span></Link>
                            </td>
                        </tr>
                    )
                })
             }
         </tbody>
         {
                newArray?.length > 5 ?
                <div className="m-3">
                 <Paginate studentPerPage={studentsPerPage} totalStudents={newArray?.length} paginate={paginate} />
                 </div>
                  : null
         }
      </table>  */}
    {/* </div> */}
    </div>
    </>
      )
}

export default ListCareerSimulationTable;