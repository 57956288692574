import {
    SEARCH_COURSES_FAIL,
    SEARCH_COURSES_REQUEST,
    SEARCH_COURSES_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CourseConstants";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const getCourses = (course) => async (dispatch) => {
    try {
      dispatch({ type: SEARCH_COURSES_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
  
      const { data } = await axiosInstance.get(
        `/metadata/fields?filter=${course}`,
        config
      );
      dispatch({ type: SEARCH_COURSES_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: SEARCH_COURSES_FAIL, payload: message });
    }
  };
  
  