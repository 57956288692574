// import CareerSimulation from "./CareerSimulation"
import ParentJumbotron from "./ParentJumbotron"
import WardDetails from "./WardDetails"
import "../css/parent.css";
import { useContext, useEffect } from 'react';
import { AuthContext } from "../../../../AuthContext/AuthContext";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ParentWardGetAction } from "../Redux/actions/GetWardsActions";
import Loader from "../../../../LoadersPage";
// import Alerts from './../../../../../Alerts/Alert';
// import CollegeSimulation from "./CollegeSimulation";

const { Content } = Layout;

const ParentOverview = () => {
    const {loading} = useContext(AuthContext)
    const dispatch = useDispatch()
    const {getward = {}} = useSelector(state => state.getParentWard)
    const {data = []} = getward
    useEffect(() => {
       dispatch(ParentWardGetAction())
    }, [dispatch])
    return (
        <Layout>
      
        <Content style={{ margin: "24px 16px 0" }}>
        {loading && <Loader loading={loading}/>}
        {loading && <Loader loading={loading}/>}

              {loading ?     
                 <Loader loading={loading}/> : 
                 <>
                    <ParentJumbotron jumbotron_header="Welcome to your Dashboard" jumbotron_img="/Saly-16.png"  jumbotron_btn="Add A Student" jumbotron_content=" Parents and counselors are not left out of their student's/wards career journeys. You can access your student's/wards information and personal data on our platform while providing the needed guidance and mentorship interactively. You will be carried along in your ward's career and university journey with us.
"/>
                    <WardDetails getWards={data}/>
                    <div className="container-simulator px-4 my-4">
                        <div className="row gx-5 mt-5">
                            {/* <CareerSimulation header="Career Simulation" btn_text="SEND STUDENT REMINDER" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum  dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." img_def="/perspaleta1_0005.png"/> */}

                            {/* <CollegeSimulation header2="College Simulation" btn_text2="SEND STUDENT REMINDER" text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore. Lorem ipsum  dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore." img_def2="/perspaleta1_0005.png"/> */}
                        </div>
                    </div>
                 </>
             }

             </Content>
            
        </Layout>
    )
}


export default ParentOverview;
