import { GET_COLLEGE_APPLICATIONS_REQUEST, GET_COLLEGE_APPLICATIONS_SUCCESS, GET_COLLEGE_APPLICATIONS_FAIL, GET_COLLEGE_APPL_UNPAID_ORDER_REQUEST, GET_COLLEGE_APPL_UNPAID_ORDER_SUCCESS, GET_COLLEGE_APPL_UNPAID_ORDER_FAIL } from "../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplicationConstants";

export const GetCollegeApplicationsReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLEGE_APPLICATIONS_REQUEST:
            return {
                college_appl_loading: true,
            }
        case GET_COLLEGE_APPLICATIONS_SUCCESS:
            return {
                ...state,
                college_appl_loading: false,
                collegeApplList: action.payload,
                college_appl_success: true 
            }    
        case GET_COLLEGE_APPLICATIONS_FAIL:
            return {
                ...state,
                college_appl_loading: false,
                collegeApplListError: action.payload,
                college_appl_success: false    
            }
        default:
            return state;
    }
}


export const GetCollegeApplUnpaidOrderReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLEGE_APPL_UNPAID_ORDER_REQUEST:
            return {
                unpaid_order_loading: true,
            }
        case GET_COLLEGE_APPL_UNPAID_ORDER_SUCCESS:
            return {
                ...state,
                unpaid_order_loading: false,
                unpaid_order: action.payload,
                unpaid_order_success: true 
            }    
        case GET_COLLEGE_APPL_UNPAID_ORDER_FAIL:
            return {
                ...state,
                unpaid_order_loading: false,
                unpaid_order_error: action.payload,
                unpaid_order_success: false    
            }
        default:
            return state;
    }
}