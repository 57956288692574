const BlogSnippet = () => {
    return (
      <div className="container blog_user_snippet">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
            <h2 className="blog_excerpts">
              Read the latest news or blogbosts on College Application,
              Scholarships, and events...
            </h2>
            <a href="http://blog.trav4college.com/" target="__blank" className="readmore">
              Click here
            </a>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
            <img src="/Saly-14.png" alt="Saly-14" className="saly_img" />
          </div>
        </div>
      </div>
    );
  };
  
  export default BlogSnippet;
  