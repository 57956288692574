import axiosInstance from "../../../../Utils/helper/axiosInstance";
import { STUDENT_CAREER_SIMULATION_SUCCESS, STUDENT_CAREER_SIMULATION_REQUEST, STUDENT_CAREER_SIMULATION_FAIL } from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/getCareerSimulationConstants";

// get career simulations
export const getCareerSimulations = () => async (dispatch, getState) => {
    try {
        dispatch({type: STUDENT_CAREER_SIMULATION_REQUEST})
       const { 
           tokenAuth: { tokenInfo }
        } = getState();

        const config = {
             headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
             }
         };

         const {data} = await axiosInstance.get(
             "/highschools/career_simulations/student_careers",
             config
         )
         dispatch({type: STUDENT_CAREER_SIMULATION_SUCCESS, payload: data});
     } catch (error) {
         let message = error.response && error.response.data ? error.response.data : error.message;
         dispatch({type: STUDENT_CAREER_SIMULATION_FAIL, payload: message}); 
     }
}


