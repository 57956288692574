import {
    GET_COUNTRIES_FAIL,
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CountryConstant";
  
  export const listCountryReducer = (state = { countries: [] }, action) => {
    switch (action.type) {
      case GET_COUNTRIES_REQUEST:
        return { loading: true };
      case GET_COUNTRIES_SUCCESS:
        return { loading: false, countries: action.payload, success: true };
      case GET_COUNTRIES_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };
  