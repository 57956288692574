import { Progress } from "antd";
import Level from "./Degree";
import "../../../simulator.css";
import { RiArrowRightFill, RiArrowLeftFill } from "react-icons/ri";
import Country from "./Country";
import Courses from "./Courses";
import CommunicationMode from "./Communication";
import Alerts from "../../../../../Alerts/Alert";
// import { Link } from "react-router-dom";

const ScholarshipAnswerForm = ({
  page,
  handleChange,
  handleInputChange,
  goToNextPage,
  goToPrevPage,
  countries,
  level,
  setLevel,
  remove,
  continent,
  selectedCountry,
  loading,
  // setCity,
  // city,
  tempCity,
  setTempCity,
  course,
  tempCourse,
  setTempCourse,
  filterName,
  country,
  setCountry,
  countryLoad,
  countryError,
  loadCourses,
  courseError,
  options,
  display,
  setDisplay,
  setRecentValue,
  search,
  setSearch,
  getSelectedCourseData,
  removeCourses,
  errorMessage,
  create_simulator_error,
  create_simulator_loading,
  handleSubmit,
  setCommMode,
  commMode,
  tempCommModes,
  setTempCommModes,
  communications,
  handleChangeCommunication,
  selectedCollege,
  college_list,
  college_product,
  filterCommunication,
  degrees,
  componentProps,
  initial_success,
  payment_loading
}) => {

  // const {simulation_id} = result;


  const pageSwitch = () => {
    switch (page) {
      case 1:
        return (
          <Country
            handleChange={handleChange}
            remove={remove}
            handleInputChange={handleInputChange}
            country={country}
            continent={continent}
            setCountry={setCountry}
            filterName={filterName}
            selectedCountry={selectedCountry}
            countries={countries}
            countryLoad={countryLoad}
            countryError={countryError}
            
          />
        );
        case 2:
        return (
            <Courses
              handleInputChange={handleInputChange}
              remove={remove}
              course={course}
              tempCourse={tempCourse}
              setTempCourse={setTempCourse}
              loadCourses={loadCourses}
              courseError={courseError}
              options={options}
              display={display}
              setDisplay={setDisplay}
              setRecentValue={setRecentValue}
              search={search}
              setSearch={setSearch}
              getSelectedCourseData={getSelectedCourseData}
              removeCourses={removeCourses}
            />
            );
      case 3:
          return (
            <Level
              handleInputChange={handleInputChange}
              remove={remove}
              setLevel={setLevel}
              level={level}
              tempCollege={tempCity}
              setTempCollege={setTempCity}
              loading={loading}
              degrees={degrees}
            />
          
        );
      case 4:
        return (
            <CommunicationMode
            setCommMode={setCommMode}
            commMode={commMode}
            tempCommModes={tempCommModes}
            setTempCommModes={setTempCommModes}
            handleInputChange={handleInputChange}
            remove={remove}
            filterName={filterName}
            filterCommunication={filterCommunication}
            handleChangeCommunication={handleChangeCommunication}
            communications={communications}
            />
        );
      default:
        return;
    }
  };
  const updateProgress = (page) => {
    if (page < 1) return;
    if (page === 1) {
      return (page = 20);
    } else if (page === 2) {
      return (page = 45);
    } 
    else if (page === 3) {
      return (page = 75);
    } else if (page === 4) {
      return (page = 100);
    } 
  };
  
  return (
    <>
      {pageSwitch()}
      {errorMessage && <div><Alerts description={errorMessage} message="Error" type="error" closable /></div>}
      {create_simulator_error && (
        <div>
          {" "}
          <Alerts description={create_simulator_error} type="error" message={create_simulator_error} closable />
        </div>
      )} 
       {/* {result && <div><Alerts description={result.status} message={result.status} type="success" closable /></div>} */}


      {page === 5 ? (
        ""
      ) : (
        <div className="progress_bg">
          {page === 1 ? " " :  <div className="btnPrev" onClick={goToPrevPage}>
            <button>
              <RiArrowLeftFill className="icon" />
            </button>
          </div>}
         
          <div className="progress_bar" style={{ width: "100%" }}>
            <Progress
              percent={updateProgress(page)}
              size="small"
              showInfo={false}
              status="active"
              max={100}
            />
            <small
              style={{
                position: "relative",
                color: "#E5E5E5",
                top: "-0.8rem",
                marginLeft: "5px",
              }}
            >
              {updateProgress(page)}% Complete Keep it up!
            </small>
          </div>
          {page === 4 ? ( <div className="btnNext"
          onClick={(e) => handleSubmit(e)}>
            <button>
            {create_simulator_loading ? (
            <div
              className="spinner-border text-dark"
              role="status"
              style={{ width: "2rem", height: "2rem" }}
            >
               <span className="visually-hidden">Loading...</span>
           </div>
            ) : <>
            {/* <Link to={`/college-simulator-result/${simulation_id}`}> */}
            <span className="next_question">SUBMIT</span>{" "}
            <RiArrowRightFill className="icon" />
            {/* </Link> */}
            </>
            }
              
            </button>
          </div>):( <div className="btnNext" onClick={goToNextPage}>
            <button>
              <span className="next_question">NEXT QUESTION</span>{" "}
              <RiArrowRightFill className="icon" />
            </button>
          </div>)}
         
        </div>
      )}
    </>
  );
};

export default ScholarshipAnswerForm;
