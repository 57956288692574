import {
    SEND_GUARDIAN_NOTIFICATION_REQUEST,
    SEND_GUARDIAN_NOTIFICATION_SUCCESS,
    SEND_GUARDIAN_NOTIFICATION_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/GuardianNotificationConstant";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const SendResultAction =
    (dataToSubmit) => async (dispatch, getState) => {
      try {
        dispatch({ type: SEND_GUARDIAN_NOTIFICATION_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.post(
          "/highschools/notifications/notify",
          dataToSubmit,
          config
        );
        dispatch({ type: SEND_GUARDIAN_NOTIFICATION_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: SEND_GUARDIAN_NOTIFICATION_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };