import { BiUserCircle } from "react-icons/bi";
import { BsFillCalendarDateFill, BsFillEyeFill } from "react-icons/bs";
import { MdCollectionsBookmark } from "react-icons/md";
import { FaMoneyCheck } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import { GetMessageAction } from '../../../SimulatorPage/Redux/action/Messages/GetResultMessageAction';
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import { ViewMessageAction } from '../../../SimulatorPage/Redux/action/Messages/ViewMessageAction';
import moment from "moment";
import Loader from '../../../LoadersPage';
import "./messages.css"
import { usePaystackPayment } from "react-paystack"
import { CompletePaymentWithPayStack } from "../../../../../Redux/actions/Payments/CompletePaymentAction";
import { AuthContext } from "../../../AuthContext/AuthContext";
import { UpdateReferenceToken } from "../../../../../Redux/actions/Payments/UpdateTokenAction";
import { UnpaidApplAction } from "../../../../../Redux/actions/Payments/UnpaidApplActions";
import { helpDeskAction } from "../../../../../Redux/actions/HelpdeskAction";
import { Modal } from "antd";
import { Link, useHistory } from "react-router-dom";

const MessagePage = () => {
   const dispatch = useDispatch();
   const {message = {}, loading_message} = useSelector(state => state.getMessage);
   const {data = []} = message;

   const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })
  const [displayOptions, setDisplayOptions] = useState({id: null})
  const [amount, setAmount] = useState(null)
  const [successMessage, setSuccessMessage] = useState("");
  const [visible, setVisible] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [helpdeskDetails, setHelpdeskDetails] = useState({subject: "Highschool College Application", message: "", client: "app", deviceInfo: navigator.userAgent, platform:"web", appversion: "1.0", appBuildVersion: "1.0"})
  const {helpdeskresponse = {}, success:help_desk_success, loading_helpdesk} = useSelector(state => state.helpdesk)
  const {user_email, local_user} = useContext(AuthContext);
  const {payment_complete, complete_success} = useSelector(state => state.completePayment)
  const {update_loading, updated_reference, update_success} = useSelector(state => state.updateReference)
  const getViewedId = localStorage.getItem('is_viewed');
  const token = updated_reference?.data?.token;
  const [reference, setReference] = useState(null)
  const {loading, result = {}, success} = useSelector(state => state.unpaidAppl);
  const {data:applResult = {}} = result;
  const {countries= [], colleges = [], courses = [], degree = {}} = applResult
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [user, setUser] = useState({first_name: '', last_name: ''});
  const history = useHistory();

  const completePayment = (reference) => {
      dispatch(CompletePaymentWithPayStack({reference: reference.reference}))
      setDisplayOptions({id: null})
    }

    
    useEffect(() => {
        if (complete_success) {
            dispatch(ViewMessageAction(+getViewedId));
        }
    }, [dispatch, complete_success, getViewedId])

    useEffect(() => {
        if (update_loading) {
            console.log('loading...')
        } else if (update_success) {
            console.log(updated_reference)
        }
    }, [update_loading, update_success, updated_reference])

    const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_DEVELOPMENT;

 useState(() => {
   console.log(payment_complete)
 }, [payment_complete])

   const payForApplication = (token, amount, id, first_name, last_name) => {
       setReference(token)
       setAmount(amount)
       setDisplayOptions({id: token})
       localStorage.setItem('is_viewed', id)
       setUser({first_name: first_name, last_name: last_name})
   }

   const viewApplication = token => {
        dispatch(UnpaidApplAction(token))
   }

  const handleCancel = () => {
    setIsModalVisible(false);
    setVisible(false)
  };

   useEffect(() => {
       if (loading) {
            console.log('loading...')
       } else if (success) {
            setIsModalVisible(true)
       }
   }, [loading, success, result])

   useEffect(() => {
      dispatch(GetMessageAction());
   }, [dispatch])
   
   useEffect(() => {
       if (reference) {
           dispatch(UpdateReferenceToken(local_user, reference))
       }
 }, [dispatch, local_user, reference])

   const initPaystackPayment = usePaystackPayment({
    reference: token,
    email: user_email,
    amount: (+amount * 100),
    publicKey: publicKey
});

 useEffect(() => {
    if (token) {
       
       initPaystackPayment(completePayment, () => {window.location.reload(); setDisplayOptions({id: null})});
    }
 },[token])


const speakToCounsellor = () => {
    history.push('/dashboard/help-desk')
    window.location.reload()
 };
 const handleChange = (e) => {
    const { name, value } = e.target
    setHelpdeskDetails({...helpdeskDetails, [name]: value});
}
 const handleOk = () => {
   dispatch(helpDeskAction(helpdeskDetails))
 };

 useEffect(() => {
    if (loading_helpdesk) {
        setConfirmLoading(true);
    } else if (help_desk_success) {
        // setVisible(false);
        setConfirmLoading(false);
        setSuccessMessage('Message sent Successfully')
    }
}, [loading_helpdesk, help_desk_success, helpdeskresponse, helpdeskDetails])  

   return  <>
        <div className='container'>
        {/* <div className="row mx-1 mt-3 float-end">
                                <button className="col-md-12 text-white mt-1" onClick={showModal}>Speak to counsellor <BsFillArrowRightCircleFill/></button>
                            </div> */}
            <div className='row'>
                <div className="col-md-12 mb-5">
                     <div className="card float-end">
                         
                     <Link to="/dashboard/help-desk"><button className="col-md-12 text-white mt-1 speak_to_counsellor p-2" onClick={speakToCounsellor}>Speak to counsellor <BsFillArrowRightCircleFill/></button></Link>
                     </div>
                </div>
            {loading_message && <Loader loading={loading_message}/>}
            
            {
                data && data.map(({user = {}, id, token = '', amount = '', created_at = '', notification_type = '', is_viewed}) => {
                  return  <>
                    <div className='col-md-6 mb-4' key={token}>
                        <div className='card card-style'>
                            <div className='card-body text-white'>
                                <h5 className='text-center mb-4 text-white name'><b><BiUserCircle className="mx-2"/>{user.first_name} {user.last_name}</b></h5>
                                <div className='row mb-3'>
                                    <div className='col-md-6 mb-3'>
                                            <span style={{textTransform: 'capitalize'}}><MdCollectionsBookmark className="mx-3"/>{notification_type}</span>
                                    </div>

                                    <div className='col-md-6'>
                                                    <span><BsFillCalendarDateFill className="mx-3"/>{moment(created_at).format('DD-MM-YYYY')}</span>
                                    </div>
                                </div>

                                <div className='row'>
                                    <div className='col-md-6 mb-3'>
                                    <span><FaMoneyCheck className="mx-3"/>&#8358;{formatter.format(amount).substring(1)}</span>
                                    </div>

                                    <div className='col-md-6'>
                                    <span style={{cursor: "pointer"}} onClick={()=>viewApplication(token)}><BsFillEyeFill className="mx-3"/>
                                    View Application
                                    </span>
                                    </div>
                                </div>
                            
                            <div className="row mx-4 mt-3 justify-content-center">
                            {
                                displayOptions.id === token ?  <button className='mx-3 mt-2 text-white col-md-6'>Processing...</button>    
                                :  
                                <button className={`${is_viewed===1 ? 'button_style text-danger mx-3 col-md-6 mt-2' : 'mx-3 text-white col-md-6 mt-2'}`} disabled={is_viewed===1} onClick={()=>payForApplication(token, amount, id, user.first_name, user.last_name)}>{is_viewed === 1 ? "PAID " : "PAY HERE"}</button>  
                            }
                            </div>
    
                            </div>
                        </div>
                    </div>
                    </>
                }
                )
            }


            </div>
        </div>

        <Modal title={`${user.first_name} ${user.last_name} college application`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel} bodyStyle={{height: '300px', overflow: 'scroll', overflowX: 'hidden'}} style={{textTransform: 'uppercase'}}
                            cancelButtonProps={{
                                style: {
                                  display: "block",
                                  height: "40px",
                                  background: "none !important"
                                },
                              }}
                            okButtonProps={{
                                style: {
                                  display: "none",
                                  height: "40px",
                                  marginLeft: "2px" ,
                                  marginTop: "10px",
                                  marginBottom: "40px"
                                },
                              }}
                              >
                        <div>
                        <h5><strong>COUNTRIES</strong></h5>

                            {countries && countries.map(({country}) => 
                                 <ul key={country}>
                                 <li><span style={{textTransform: 'uppercase'}}><strong>{country}</strong></span></li>
                             </ul>
                            )}
                        </div>
                        {
                            colleges && colleges.length >= 1 ?
                            <div>
                                <h5><strong>COLLEGE(S)</strong></h5>
                                {colleges && colleges.map(({college_name, general_application_info, offers_scholarship}) => 
                                    <ul key={college_name}>
                                        <li><span style={{textTransform: 'uppercase'}}><strong>{college_name}</strong></span>: <span>{general_application_info}</span></li>
                                    </ul>
                                )}
                            </div> : null
                        }
                             <div>
                                <h5><strong>COURSE(S)</strong></h5>
                                {courses && courses.map(({name}) => 
                                    <ul key={name}>
                                        <li><span style={{textTransform: 'uppercase'}}><strong>{name}</strong></span></li>
                                    </ul>
                                )}
                            </div>

                            <div>
                                <h5><strong>CERTIFICATE</strong></h5>
                                <span style={{textTransform: 'uppercase'}} className="mx-3"><strong>{degree.degree_name}</strong></span>
                            </div>
                    </Modal>


        <Modal
            title="Speak to a consultant"
            visible={visible}
            onOk={handleOk}
            confirmLoading={confirmLoading}
            onCancel={handleCancel}
            cancelButtonProps={{
                style: {
                  display: "block",
                  height: "40px",
                  background: "none !important"
                },
              }}
            okButtonProps={{
                style: {
                  display: "block",
                  height: "40px",
                  marginLeft: "2px" ,
                  marginTop: "10px",
                  marginBottom: "20px"
                },
              }}
        >
                <div className="">
                        <label htmlFor="message" className="form-label">Your Message</label><br />
                        <textarea id="message" onChange={handleChange} value={helpdeskDetails.message} name="message" rows="9" cols="62" style={{width: '100%', height: '100px', border: '2px solid #0476E8', borderRadius: '6px'}} placeholder="Enter message here"></textarea>
                        <p className="text-successfully">{successMessage}</p>
                </div>
      </Modal>
   </>
}

export default MessagePage;
