import {Link} from "react-router-dom"
import {useState} from "react"
import Paginate from "../../../../../../Utils/helper/Paginate"
// import "../../../../SimulatorPage/simulator.css"
import "../student.css"
import Loader from "../../../../LoadersPage"
import NoStudentPage from "../NoStudentFile"

const CareerSimulator = ({ studentCareers, loading }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5)

    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = studentCareers?.slice(indexOffirstStudent, indexOflastStudent);
    
    const paginate = (pageNumber) => setCurrentPage(pageNumber)
    
    return (
        <>
          {loading && <Loader loading={loading}/>}
            {
               loading ? <Loader loading={loading}/> :
               <>
                    {
                        studentCareers?.length >= 1 ?
                        <>

                        {/* <div className="simulator_info"> */}
                        <div className="card mt-5">
                            <div className="card-headers">
                                <div className="student-header">CAREER SIMULATIONS</div>
                            </div>
                      <div className="divider"></div>

                            <div className="card-body">
                                <div className="table-responsive">
                                <table className="table table-hover">
                                    <thead>
                                        <tr align="middle" style={{ fontWeight: "700" }}>
                                            <td>STUDENT NAME</td>
                                            <td>ACTION</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        currentStudents && currentStudents.map((career) => 
                                            <tr align="middle" className="shadow-sm align_student_table simulate_details_college" key={career.id}>
                                                <td>{career?.user?.first_name} {career?.user?.last_name}</td>
                                                <td className="view_results">
                                                <Link to={`/dashboard/counsellor/careerlists/${career?.user?.id}/career`}>
                                                    <span>View results</span> 
                                                </Link>
                                                </td>
                                            </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                                {
                                studentCareers.length > 5 ? <Paginate studentPerPage={studentsPerPage} totalStudents={studentCareers?.length} paginate={paginate} /> : null
                            }
                                </div>
                            </div>
                            {/* <div className="text-center parent_view_more pb-4"><Link to="/view-more">View more</Link></div> */}
                        </div>

                        </>
                        :
                        <NoStudentPage name={"CAREER SIMULATIONS"}/>
                    }
                    {/* </div> */}
               </>
            }
        </>
    )
}

export default CareerSimulator