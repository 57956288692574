import { GET_NEWS_REQUEST, GET_NEWS_SUCCESS, GET_NEWS_FAIL } from "../../Utils/constants/newsConstants";
import axiosInstance from "../../Utils/helper/axiosInstance";

export const singleNewsAction = (news_id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_NEWS_REQUEST});
        const {
            tokenAuth: { tokenInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            },
        };

        const { data } = await axiosInstance.get(
            `/news/${news_id}`,
            config
        );

        dispatch({type: GET_NEWS_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_NEWS_FAIL, payload: message});
    }
}