import {
    GET_COUNTRIES_REQUEST,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAIL,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CountryConstant";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const listMatchedCountries = (countries_id) => async (dispatch) => {
    try {
      dispatch({ type: GET_COUNTRIES_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        `/metadata/countries?continents=${countries_id}`,
        config
      );
      dispatch({ type: GET_COUNTRIES_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_COUNTRIES_FAIL, payload: [message] });
    }
  };
  