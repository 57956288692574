import { Link } from "react-router-dom"
import { Layout } from "antd";
import { FaSearch } from "react-icons/fa"
import "../../CounsellorDashboard/student.css"
// import Alerts from './../../../../../Alerts/Alert';
// import { useContext } from 'react';
// import { AuthContext } from "../../../../AuthContext/AuthContext";
import { ParentWardGetCollegeApplCountAction } from "../Redux/actions/GetWardsCollegeApplCount";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ParentWardGetCollegeApplAction } from "../Redux/actions/GetWardsCollegeApplist";
// import moment from "moment";
import Paginate from "../../../../../../Utils/helper/Paginate";

const {Content } = Layout;

const ApplicantTable = () => {
    const dispatch = useDispatch()
    const {loading_count} = useSelector(state => state.collegeApplCount)
    const {wardCollegeApplist = {}} = useSelector(state => state.collegeApplist)
    // const {data = []} = wardCollegeApplCount;
    const {data:collegeAppllist = []} = wardCollegeApplist;
    const [searchtext, setSearchtext] = useState("");
    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5);
    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = collegeAppllist?.slice(indexOffirstStudent, indexOflastStudent);
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

   useEffect(() => {
    dispatch(ParentWardGetCollegeApplCountAction())
    }, [dispatch])

    useEffect(() => {
       dispatch(ParentWardGetCollegeApplAction())
   }, [dispatch])

   return (
      <>
        {loading_count ?      
           <div
           className="spinner-border text-dark"
           role="status"
           style={{ width: "5rem", height: "5rem" }}
           >
           <span className="visually-hidden">Loading...</span>
           </div> :
              
              <> 
              
       <Layout>          
           <Content style={{ margin: "24px 16px 0" }}>
               {/* {error && 
                   <Alerts 
                       description={error.message}
                       type="error"
                       message={error.message}
                       closable 
                       />
                   } */}
                 {
                     currentStudents?.length < 1 ? 
                     <div className="card p-5 shadow">
                            <h2 className="text-center">EMPTY LIST</h2>
                     </div>  
                     :

                     <div className="card applicant_table">
                            <div className="card-headers">
                <div className="student_input_area">
                    <div className="student_input p-4">
                        <input type="text" onChange={(e) => setSearchtext(e.target.value)} value={searchtext} placeholder="Search Students" className="search-field"/>
                        <div className="search-icon"><FaSearch className="fa-search-icon"/></div>
                    </div>
                </div>
           </div>
                     <div className="card-body card__body">
                         <div className="card__title">
                             <span>College Applications</span>
                             <span>Number of Applications: <strong className="text-danger">{collegeAppllist?.length}</strong></span>
                         </div>
                         <hr />
      
                         <div className="table-responsive">
                             <table className="table table-hover">
                                 {/* <thead className="tborder">
                                     <tr className="align_student_table">
                                         <th scope="col">School</th>
                                         <th scope="col">Student Name</th>
                                         <th scope="col">Date of Application</th>
                                         <th scope="col">Status</th>
                                         <th scope="col">Action</th>
                                     </tr>
                                 </thead> */}
                                      <thead>
                                        <tr align="middle" style={{ fontWeight: "700" }}>
                                            <td>STUDENT NAME</td>
                                            <td>ACTION</td>
                                        </tr>
                                    </thead>
                                 <tbody>
                                 {
                                      currentStudents < 1 ? [] : currentStudents && currentStudents?.filter(({user = {}}) => {
                                        if (searchtext === "") {
                                            return user;
                                        } else if (user.first_name?.toLowerCase().includes(searchtext.toLowerCase()) || user.last_name?.toLowerCase().includes(searchtext.toLowerCase())){
                                            return user;
                                        }
                                        return false;
                                    })?.map(({user = {}}) => 
                                            <tr align="middle" className="shadow-sm align_student_table simulate_details_college" key={user.id}>
                                                <td>{user?.first_name} {user?.last_name}</td>
                                                <td className="view_results">
                                                <Link to={`/dashboard/counsellor/collegeApplist/${user?.id}/college-application`}>
                                                    <span>View results</span> 
                                                </Link>
                                                </td>
                                            </tr>
                                            )
                                        }
                                     {/* {
                                         currentStudents && currentStudents?.filter(({user = {}}) => {
                                            if (searchtext === "") {
                                                return user;
                                            } else if (user.first_name?.toLowerCase().includes(searchtext.toLowerCase()) || user.last_name?.toLowerCase().includes(searchtext.toLowerCase())){
                                                return user;
                                            }
                                            return false;
                                        })?.map(({user = {}, id, college_applied=[], stage, created_at}) => 
                                            <tr  className="align_student_table" key={id}>
                                                <td className="school__img">
                                                {college_applied?.length > 0 ? 
                                                  <>
                                                      {college_applied.slice(0,1).map((college = {}, created_at) =>  
                                                        <img src={college.college.logo} alt="School_logo" className="school_logo"/>
                                                      )}
                                                  </> : <span>Consultant</span>
                                                }
                                                </td>
                                                <td>{user.first_name} {" "} {user.last_name}</td>
                                                <td>{moment(created_at).format('DD-MM-YYYY')}</td>
                                                {
                                                    stage === 'pending' ? <td className="ongoing_status"><span>{stage}</span></td> : <td className="accepted_status"><span>{stage}</span></td>
                                                }
                                                <td>
                                                <Link to={`/college_appl-simulator/result/${id}`}>View result
                                                </Link>
                                                </td>
                                                
                                            </tr>
                                         )
                                     } */}
                                 </tbody>
                             </table> 
                                 {
                collegeAppllist?.length > 5 ?
                <div className="m-3">
                 <Paginate studentPerPage={studentsPerPage} totalStudents={collegeAppllist?.length} paginate={paginate} />
                 </div>
                  : null
         }
                         </div>
                     </div>
                 </div>
                  }
      </Content>

      </Layout>
      </>
       }
    </>
   )
}

export default ApplicantTable
