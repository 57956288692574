import { GET_SCHOLARSHIP_REQUEST, GET_SCHOLARSHIP_SUCCESS, GET_SCHOLARSHIP_FAIL } from "../../../../Utils/constants/SimulationConstants/Scholarships/GetScholarShipsConstants";
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getScholarshipsAction = () => async (dispatch, getState) => {
    try {
     dispatch({type: GET_SCHOLARSHIP_REQUEST});
     const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
 
       const { data } = await axiosInstance.get(
         `/scholarships`,
         config
       );
 
       dispatch({type: GET_SCHOLARSHIP_SUCCESS, payload: data})
        
    } catch (error) {
     const message =
     error.response && error.response.data
       ? error.response.data
       : error.message;
       dispatch({type: GET_SCHOLARSHIP_FAIL, payload: message})
    }
 }