
import React from 'react'
import { Link } from 'react-router-dom'
import './style.css'

const NotFound = () => {
  return (
    <>
       <div className='container'>
            <div className='row'>
                    <div className='col-md-7 img_div'>
                        <img src='/astro_black.jpg' alt='img' srcSet=''/>
                    </div>

                    <div className='col-md-5'>
                        <div className='text_design'>
                            <div className='header'>404</div>
                            <h5 className='semi_header'>UH OH! You're lost.</h5>
                            <p>The page you are looking for does not exist.
                        How you got here is a mystery. But you can click the button below
                        to go back to the homepage.</p>
                            <div className='button_home'>
                            <Link  to="/dashboard/">
                               HOME
                            </Link>
                            </div>
                        </div>
                    </div>
            </div>
       </div>
    </>
  )
}

export default NotFound

