import { FaRegBell, FaRegEnvelope } from "react-icons/fa";
import { Badge } from 'antd';
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CountNotificationAction } from "../SimulatorPage/Redux/action/Notifications/CountNotificationAction";
import { CountMessageAction } from "../SimulatorPage/Redux/action/Messages/CountMessagesAction";

const Navbar = ({ name, username, role, profile_pic, text }) => {
  const dispatch = useDispatch();
  const {count_notifs = {}} = useSelector(state => state.countNotification);
  const {count_message = {}} = useSelector(state => state.countMessage);
  const {data} = count_notifs;
  const {data: message_count} = count_message;
  useEffect(() => {
    dispatch(CountNotificationAction())
    dispatch(CountMessageAction())
  }, [dispatch])
  return (
    <div className="header_nav">
      <h1 className="username">{text} {name}</h1>
      <div className="user_details">
        <div>
          {
           ( role === "parent" || role === "counsellor") ? <> <Link to="/dashboard/messages"> <Badge style={{ marginRight: "32px" }} count={message_count}>
              <FaRegEnvelope className="icons mr-2" />
            </Badge></Link> </> :  <FaRegEnvelope className="icons mr-2" />
          }
     
        </div>
        <div>
          {
           ( role === "parent" || role === "counsellor") ? <> <Link to="/dashboard/notifications"> <Badge style={{ marginRight: "32px" }} count={data}>
              <FaRegBell className="icons"/>
            </Badge></Link> </> : <FaRegBell className="icons"/>
          }
         
        </div>
        <div className="more_details">
          <img src={profile_pic || '/Saly-16.png'} alt="" className="profile_pics" />
          <div className="name_role">
            <span className="user_name">{username}</span>
            <br />
            <span className="user_role">{role}</span>
          </div>
        </div>

      </div>
    </div>
  );
};
export default Navbar;
