import {Route} from "react-router-dom"
import StudentDashboard from "../../../components/Pages/DashboardLayoutPage/Contents/StudentDashboard/index"
import CareerSimulatorFirstPage from "../../../components/Pages/DashboardLayoutPage/Contents/CareerPage/CareerSimulator";
// import ProfileUpdate from "../../../components/Pages/DashboardLayoutPage/Contents/ProfilePage/StudentProfile/ProfileUpdates/ProfileUpdate";
import SimulateCollege from "../../../components/Pages/DashboardLayoutPage/Contents/CollegePage/CollegeSimulator";
import SimulateCollegeApplication from "../../../components/Pages/DashboardLayoutPage/Contents/CollegeApplicationPage/CollegeApplicationSimulator";
import ScholarshipIndexPage from "../../../components/Pages/DashboardLayoutPage/Contents/ScholarshipPage";
import ScholarshipLists from "../../../components/Pages/DashboardLayoutPage/Contents/ScholarshipPage/ListScholarships";
import ScholarPaymentPage from "../../../components/Pages/DashboardLayoutPage/Contents/ScholarshipPage/ScholarShipPaymentPage";
import PayWithStack from "../../../components/Pages/DashboardLayoutPage/Contents/ScholarshipPage/PayWithPaystackPage";
import ViewScholarship from "../../../components/Pages/DashboardLayoutPage/Contents/ScholarshipPage/ViewScholarship";
import NewsPage from "../../../components/Pages/DashboardLayoutPage/Contents/NewsPage/index";
import SingleNews from "../../../components/Pages/DashboardLayoutPage/Contents/NewsPage/SingleNews";

const StudentRoutes = ({user, hasScholarshipProduct}) => {
    return (
        <>
        {
            user ? <>
             <Route exact path="/dashboard/"><StudentDashboard/></Route>
             <Route exact path="/dashboard/my-career"><CareerSimulatorFirstPage/></Route>
             <Route exact path="/dashboard/my-college-application"><SimulateCollegeApplication/></Route>
             <Route exact path="/dashboard/my-scholarships"><ScholarshipIndexPage/></Route>
             <Route exact path="/dashboard/my-college"><SimulateCollege/></Route>
             <Route exact path="/dashboard/news"><NewsPage/></Route>
             <Route exact path="/dashboard/news/:news_id"><SingleNews/></Route>
             <Route exact path="/dashboard/my-scholarshipslists"><ScholarshipLists/></Route>
             <Route exact path="/dashboard/subscriptions"><ScholarPaymentPage/></Route>
             <Route exact path="/dashboard/subscriptions/:amount_paid/:prod_id"><PayWithStack/></Route>
             <Route exact path="/dashboard/scholarships/view-scholarship/:id" render={(props) => <ViewScholarship {...props} /> }></Route>

        </> : null
        }
        
        </>
    )
}

export default StudentRoutes;