import "../../../../SimulatorPage/simulator.css";
import { FaTimes } from "react-icons/fa";
import { Alert } from "antd";

const CommunicationMode = ({
    handleChangeCommunication,
  remove,
  filterCommunication,
  communications,
  setCommMode,
  commMode,
  countryLoad,
  countryError,
  selectedCountry
}) => {
  const countryLength = selectedCountry?.length
  // console.log('comm', communications)
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 4</p>
      <h2>Select communication mode</h2>
      {countryError && <div>{countryError}</div>}
      {countryLoad ? (
        <div
          className="spinner-border text-dark"
          role="status"
          style={{ width: "5rem", height: "5rem" }}
        >
          <span className="visually-hidden">Loading...</span>
        </div>
      ) : (
        <select
          className="form-select form-select-lg"
          aria-label=".form-select-lg example"
          onChange={(e) => handleChangeCommunication(e, communications, setCommMode)}
        >
          <option disabled>Select communication mode</option>
          {commMode &&
            commMode?.map((cont, index) => (
              <option key={index} value={cont} style={{textTransform: 'uppercase'}}>
                {cont}
              </option>
            ))}
        </select>
      )}
      <ul className="my-4 list_tags">
      {communications.map((item, index) => {
          const values = filterCommunication(item,commMode);
          // console.log("values", values);
          
          return (
            <li key={index}>
              {values && values.map((c) => c)}{" "}
              <span onClick={() => remove(item, communications, setCommMode)}>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          );
        })}
      </ul>

     {countryLength === 3 ? <Alert
      description="You can't select more than three countries"
      type="warning"
      showIcon
      closable
    /> : ""
} 
      <span className="text-center text-def">
        You can select up to <strong>2 modes</strong>
      </span>
    </div>
  );
};

export default CommunicationMode;
