// import { useContext } from "react";
// import { AuthContext } from "../../../../../AuthContext/AuthContext";
// import { useState } from "react";
// import { Link } from "react-router-dom";
// import { IoIosArrowForward } from "react-icons/io";

const FirstChoiceCourse = ({course_result, course_error = {}}) => {
    // const {user_error} = useContext(AuthContext);
    let dollarUSLocale = Intl.NumberFormat('en-US');

   return (
    <>
     <div className="container college_result">
      <h5>COURSE(S) CHOSEN</h5>
     <div className="row">
            {course_result && course_result.map(({name, school,description, field_tuition, study_mode, id}) => (
                <div className="col-md-12  recommended_col" key={id}>
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 col-sm-8">
                        <div div className="row">
                            <div className="col-sm-3 col-md-3">
                            <img src="/unilogo.png" alt="uni logo" className="school_logos"/>
                            </div>
                            <div className="col-sm-9 col-md-9 ">
                            <h5 className="recommended_school">
                               {name}
                            </h5>
                            <p className="recommended_school_p">
                                {school}
                            </p>
                            {/* <p className="recommended_school_p">
                                Aberdeen, Scotland, United Kingdom
                            </p> */}
                            <p className="recommended_school_p">
                               {description === null ? description : ""}
                            </p>
                            <div>
                                {/* <Link to="/details">View Details</Link> */}
                            </div>
                            </div>
                        </div>
                        </div>
                        <div className="col-md-2 col-sm-2 col-lg-2 result_page_button">
                        {/* <p>${field_tuition?.slice(0, 1)?.map(tuition_range => dollarUSLocale.format(tuition_range.tuition_range))}</p> */}
                        <p>{field_tuition ? `$${field_tuition?.slice(0, 1)?.map(tuition_range => dollarUSLocale.format(tuition_range.tuition_range))}` : null}</p>
                        <div className="text-left">{study_mode}</div>
                        {/* <Link to="/get-started-college"><div className="btn mt-2">Start Application</div></Link> */}
                        </div>
                    </div>
                    </div>
                </div>
            </div> 
            ))}
            </div>
     {/* <div className="my-4 more__details">
        <Link to="#">Read more details</Link>
     </div> */}
     </div>
    </>
)
}

export default FirstChoiceCourse