import ApplicantDetails from "./ApplicantsDetail"
import Simulators from "./Simulators"
import { useContext, useEffect } from "react"
import { AuthContext } from "../../../../AuthContext/AuthContext"
import Loader from "../../../../LoadersPage"
import { useDispatch, useSelector } from "react-redux"
import { numberOfStudentsUnderACounsellor } from "../Redux/actions/NumberOfStudentsUnderCounsellor"

const CounsellorOverview = () => {
    const {loading_college_latest_simulation} = useContext(AuthContext)
    const dispatch = useDispatch()

    const {count_loading, number_of_students} = useSelector(state => state.getStudentCount);

    const info = number_of_students && number_of_students?.data;

    useEffect(() => {
        dispatch(numberOfStudentsUnderACounsellor())
    }, [dispatch])
    return (
        <div className="container container-width">
            {/* {loading_college_latest_simulation && <Loader loading={loading_college_latest_simulation}/>}
            {loading_college_latest_simulation && <Loader loading={loading_college_latest_simulation}/>} */}
            {count_loading && <Loader loading={loading_college_latest_simulation}/>}
            {
            loading_college_latest_simulation ? <Loader loading={loading_college_latest_simulation}/> :
                <>
            <ApplicantDetails info={info}/>
            <Simulators />
                </>
            }
        </div>
    )
}



export default CounsellorOverview