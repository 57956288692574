import React from 'react';
import ReactDOM from 'react-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import './index.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from "react-redux";
import store from "./Redux/store/store";
import  AuthProvider from "./components/Pages/AuthContext/AuthContext"
// import { BrowserRouter as Router } from "react-router-dom"
import Routers from "./Utils/Routes/Router";

ReactDOM.render(
  <React.StrictMode>
     <Provider store={store}>
         <AuthProvider>
                 <Routers/>
         </AuthProvider>
     </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
