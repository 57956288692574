import { GET_NEWS_REQUEST, GET_NEWS_SUCCESS, GET_NEWS_FAIL } from "../../Utils/constants/newsConstants";

export const singleNewsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_NEWS_REQUEST:
        return { news_loading: true };
      case GET_NEWS_SUCCESS:
        return {
          ...state,
          news_loading: false,
          news: action.payload,
          success: true,
        };
      case GET_NEWS_FAIL:
        return {
          ...state,
          news_loading: false,
          success: false,
          error: action.payload,
        };
     default:
         return state
    }
  };