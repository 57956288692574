// import { useEffect } from "react";
import { Spin } from "antd";
import "../../../simulator.css";
import { FaTimes } from "react-icons/fa";
import { useState } from "react";
const City = ({
  city,
  setCity,
  tempCity,
  cities,
  remove,
  setTempCity,
  loading,
  filterName,
  handleChange,
  search,
  setSearch,
  setRecentValue,
  getSelectedCityData,
  display,
  setDisplay,
}) => {
  const [searchtext, setSearchtext] = useState("");

  // useEffect(() => {
  //   setCity(tempCity.split(","));
  // }, [setCity, tempCity]);

  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 2</p>
      <h2>Select City/Cities you are interested in Studying </h2>
      {loading ? (
        <Spin tip="Please wait data is loading..."></Spin>
      ) : 
      (
        // <select
        //   className="form-select form-select-lg"
        //   aria-label=".form-select-lg example"
        //   onChange={(e) => handleChange(e, city, setCity)}
        // >
        //   <option>Select City</option>
        //   {cities.data &&
        //     cities.data.map((city, index) => (
        //       <option key={index} value={city.id}>
        //         {city.city}
        //       </option>
        //     ))}
        // </select>
                <form className="city-form ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Select City/Cities"
                  onClick={() => setDisplay(!display)}
                  onChange={(e) => setSearchtext(e.target.value)} value={searchtext}
                />

                {display && (
                      <div className="card">
                      <div className="card-body">
                      {cities?.data?.filter((city) => {
                        if (searchtext === "") {
                            return city;
                        } else if (city.city?.toLowerCase().includes(searchtext.toLowerCase()) || city.city?.toLowerCase().includes(searchtext.toLowerCase())){
                            return city;
                        }
                        return false;
                    }).map((city, index) => (
                            <div key={index} className="option">
                              <span className="courses_list" onClick={() => 
                                  {
                                    setRecentValue(city.city);
                                    getSelectedCityData(city)
                                    setSearch("")
                                  }}
                                >
                                  {city.city}
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                )}

              
              </form>
      )}

      {/* {tempCity.length > 0 ? ( */}
        {tempCity.length > 0 ? (
        <ul className="my-4 list_tags">
          {tempCity.map((item, index) => (
            <li key={index}>
              {item.city}{" "}
              <span onClick={() => {
                remove(item, tempCity, setTempCity)}
                  
                }>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
        {/* <ul className="my-4 list_tags">
           {tempCity.map((item, index) => {
          const values = filterName(item, cities.data);
          return (
            <li key={index}>
              {values && values.map((c) => c.city)}{" "}
              <span onClick={() => remove(item, city, setCity)}>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          );
        })}
        </ul> */}


      <span className="text-center text-def">
        You can select up to <strong>4 cities</strong>
      </span>
      <span>
        <small>Seperate each city with a comma</small>
      </span>
    </div>
  );
};

export default City;
