import { GET_SCHOLARSHIP_PROFILE_FAIL, GET_SCHOLARSHIP_PROFILE_SUCCESS, GET_SCHOLARSHIP_PROFILE_REQUEST } from "../../../../Utils/constants/SimulationConstants/Scholarships/ScholarshipProfileConstants";

export const getScholarshipProfile = (state = {}, action) => {
    switch (action.type) {
      case GET_SCHOLARSHIP_PROFILE_REQUEST:
        return { scholar_loading: true };
      case GET_SCHOLARSHIP_PROFILE_SUCCESS:
        return {
          ...state,
          scholar_loading: false,
          scholar_profile: action.payload,
          scholar_success: true,
        };
      case GET_SCHOLARSHIP_PROFILE_FAIL:
        return {
          ...state,
          scholar_loading: false,
          scholar_success: false,
          scholar_error: action.payload,
        };
     default:
         return state
    }
  };