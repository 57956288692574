import {
    SEND_GUARDIAN_NOTIFICATION_FAIL,
    SEND_GUARDIAN_NOTIFICATION_REQUEST,
    SEND_GUARDIAN_NOTIFICATION_SUCCESS,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/GuardianNotificationConstant";
  
  export const SendResultReducer = (state = {}, action) => {
    switch (action.type) {
      case SEND_GUARDIAN_NOTIFICATION_REQUEST:
        return { result_loading: true };
      case SEND_GUARDIAN_NOTIFICATION_SUCCESS:
        return {
          ...state,
          result_loading: false,
          sent_result: action.payload,
          success: true
        };
      case SEND_GUARDIAN_NOTIFICATION_FAIL:
        return {
          ...state,
          result_loading: false,
          sent_result_error: action.payload,
          success: false
        };
      default:
        return state;
    }
  };