import "../../../DashboardLayoutPage/Contents/CareerPage/career.css";
import { RiArrowRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";
const CollegeApplicationContent = () => {
  return (
    <div className="career_simulator_content">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-12 col-md-7 col-lg-7 col-xl-7 ">
          <h2>
          Welcome to the 

            <br /> College Application Test
          </h2>
          <p>
          Welcome to the college application assessment. As you prepare for college life after high school, the college simulator helps you find out the best schools to continue your career journey. 
       Regardless of your academic prowess, family financial status, and career choice, there is always a school for everyone.
          </p>
        <p>
    Here you can add your details, school preferences, and other biodata on the go. Then, you can apply to any school abroad with just a click.
        </p>
          <div className="mt-4 button_getstarted_go">
            <Link to="/college-application/questionnaire">
              Get Started {""}{" "}
              <span>
                <RiArrowRightFill />
              </span>
            </Link>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-12 col-md-5 col-lg-5 col-xl-5 image_part_career">
          <img src="/Saly-16.png" alt="career-simulator-img" />
        </div>
      </div>
    </div>
  );
};

export default CollegeApplicationContent;

