import { Slider, Spin } from "antd";
import numbro from "numbro";

const PriceSelection = ({ getValue, onChange, onAfterChange, loading,  amount_in_naira_max,
  amount_in_naira_min,
  maximum,
  minimum, range_min, range_max }) => {
    console.log('range', range_min);
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 4</p>
      <h2>Select Tuition Range you are willing to pay</h2>
      {loading ? (
        <Spin tip="Please wait data is loading..."></Spin>
      ) : (
        <form className="city-form">
          <div className="default-prices mb-2">
            <span className="min_price">
              Min ${numbro(minimum).format({ thousandSeparated: true })} (N{numbro(amount_in_naira_min === undefined ? 0 : amount_in_naira_min).format({ thousandSeparated: true })})
            </span>
            <span className="max_price">
              Max ${numbro(maximum).format({ thousandSeparated: true })} (N{numbro(amount_in_naira_max === undefined ? 0 : amount_in_naira_max).format({ thousandSeparated: true })})
            </span>
          </div>

          <div className="price-range">
            <Slider
              range
              step={Number(minimum)}
              min={Number(minimum)}
              max={Number(maximum)}
              onChange={onChange}
              defaultValue={getValue}
              onAfterChange={onAfterChange}
            />
          </div>
        </form>
      )}
      <h4 className="price-value">
        <div className="price-value">
          {getValue.length ? (
            <span>
              ${`${numbro(getValue[0] == null ? getValue[1] : getValue[0]).format({ thousandSeparated: true })}`}
               (N{`${numbro(range_min === undefined ? 0 : range_min).format({ thousandSeparated: true })}`})- {" "}
            </span>
          ) : (
            null
          )}
          {getValue.length ? (
            <span>
              ${`${numbro(getValue[1]).format({ thousandSeparated: true })}`} (N{`${numbro(range_max === undefined ? 0 : range_max).format({ thousandSeparated: true })}`})
            </span>
          ) : (
            null
          )}
        </div>
        {/* ))} */}
      </h4>

      <span className="text-center price-def">Tuition fee per Annum</span>
    </div>
  );
};
export default PriceSelection;
//&#8358;