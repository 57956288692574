import { useEffect, useState } from "react";
import {RiArrowLeftFill, RiArrowRightFill} from "react-icons/ri"
import { useDispatch, useSelector } from "react-redux";
import {Progress, Spin} from "antd"
import {listQuestions} from "../../Redux/action/CareerQuestionActions/CareerQuestionAction";
import SimulatorHeader from "../../SimulatorHeader";
import QuestionOne from "./QuestionOne";
import QuestionTwo from "./QuestionTwo";
import QuestionThree from "./QuestionThree";
import QuestionFour from "./QuestionFour";
import QuestionFive from "./QuestionFive";
import QuestionSix from "./QuestionSix";
import { careerSimulationAnswerAction } from "../../Redux/action/CareerQuestionActions/CareerAnswersSubmitAction";
import { useHistory } from "react-router-dom";

const CareerChoices = () => {

  const [ user_answers, setUserAnswers ] = useState([{ question_id: null, option_id: null }]);
  const history = useHistory()

  const handleAnswer = (e, id) => {
    let { value } = e.target;
    const newOption_id = Number(value)
    setUserAnswers([...user_answers.filter(answer =>  answer.option_id !== null), { option_id: newOption_id, question_id : id }]);  
  }
  
  const {loading: submitLoading, error: submitError, success, answers: submitAnswers = {}} = useSelector(state => state.careerAnswer)

  // const {data = {}} = submitAnswers;
  // const {id:simulation_id} = data
  // console.log("simulation_id", simulation_id);
  
  useEffect(() => {
    if (submitLoading) {
       console.log("...loading");
    } else if (success){
      const {data = {}} = submitAnswers;
      const {id:simulation_id} = data;
      let path = `/career-simulator/result/${simulation_id}`; 
      history.push(path);
    }
  }, [submitLoading, success, history, submitAnswers])
  
  const handleAnswerSubmit = (e) => {
      e.preventDefault(); 
      const things = {answers: user_answers.filter(answer =>  answer.option_id !== null && answer.question_id !== null)}    
      let result = things.answers.reduce((unique, o) => {
        if(!unique.some(obj => obj.question_id === o.question_id)) {
          unique.push(o);
        }
        return unique;
    },[]);
      dispatch(careerSimulationAnswerAction({answers: result}));
  }
  const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const {loading, error, questions} = useSelector(state => state.careerChoices);

    const goToNextPage = () => {
      setPage(page + 1);
    };
    const goToPrevPage = () => {
      if (page < 1) {
        setPage(page);
      }
      setPage(page - 1);
    };

    const pageSwitch = () => {
      switch (page) {
        case 1:
          return (
           <QuestionOne questions={questions} user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
          );
        case 2:
          return (
            <QuestionTwo questions={questions}  user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
          );
        case 3: 
            return (
              <QuestionThree questions={questions}  user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
            )
        case 4: 
            return (
              <QuestionFour questions={questions}  user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
            ) 
        case 5: 
            return (
              <QuestionFive questions={questions}  user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
            ) 
        case 6: 
            return (
              <QuestionSix questions={questions}  user_answers={user_answers} setUserAnswers={setUserAnswers} handleAnswer={handleAnswer}/>
            )      
        
        default:
          return (
            <QuestionOne />
          );
      }
    };

    const updateProgress = (page) => {
      if (page === 0) return;

      if (page === 1 ) {
        return (page = 16);
      } else if (page === 2) {
        return (page = 32);
      } else if (page === 3) {
        return (page = 48);
      }else if (page === 4) {
        return (page = 64);
      } else if (page === 5) {
        return (page = 80);
      }else if (page === 6) {
        return (page = 100);
      }
    };

    useEffect(() => {
         dispatch(listQuestions())
    }, [dispatch])
    return (
        <> 
         {/* {simulation_id  && simulation_id !== undefined ? <CareerResultRecommendation career_simulation_id={simulation_id}/> :  */}

       <div className="choices_wrapper">
          <div className="container">
              <SimulatorHeader  name="Career Simulator" logo="/trav4college_logo.png" />
              <div className="card my-4">
              <div className="card__header">Questions</div>
                  
                   <div className="card-body">
                    {loading && <div
                    className="text-dark d-flex justify-content-center"
                    role="status"
                  >
                    <Spin size="large" />
                  </div>}
                    <p className="text-danger text-center">{error && <div>{error}</div>}</p>

                    {pageSwitch()}

                    {submitLoading && <div
                    className="spinner-border text-dark d-flex justify-content-center"
                    role="status"
                    style={{ width: "5rem", height: "5rem"}}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>}
                  {submitError && <div>{submitError.message}</div>}
                   <div className="progress_bg">

          {page === 1 ? '' : <div className="btnPrev" onClick={goToPrevPage} >
            <button disabled={page === 1}>
              <RiArrowLeftFill className="icon" />
            </button>
          </div>}

          <div className="progress_bar" style={{ width: "100%" }}>
            <Progress
              percent={updateProgress(page)}
              size="small"
              showInfo={false}
              status="active"
              max={100}
            />
            <small
              style={{
                position: "relative",
                color: "#E5E5E5",
                top: "-0.8rem",
                marginLeft: "5px",
              }}
            >
              {updateProgress(page)}% Complete Keep it up!
            </small>
          </div>
          {page ===  6? <div className="btnNext" onClick={(e) => handleAnswerSubmit(e)}>
            <button>
              <span className="next_question">SUBMIT ANSWERS</span>{" "}
              <RiArrowRightFill className="icon" />
            </button>
          </div>: <div className="btnNext" onClick={goToNextPage}>
            <button>
              <span className="next_question">NEXT QUESTION</span>{" "}
              <RiArrowRightFill className="icon" />
            </button>
          </div>}
          
        </div>
                            
              </div>
          </div>
        </div>
        </div>
      
        
        </>
    )
}


export default CareerChoices