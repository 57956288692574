import { Link } from "react-router-dom";
const StartCollegeApplication = ({start_college}) => {
      return (
        <div className={`col-xs-12 col-12  col-sm-12 col-md-12  essential_new_user ${start_college}`}>
        <Link to="/get-started-college">
          <img
            src="/perspaleta1_0001.png"
            alt="over_essential"
            className="essential"
          />
          <h3 className="my-2">College Application</h3>
          <p>
          The college application simulator helps you decide the schools that align with your career choice. Upon graduation from high school, we would handle your college application process at a subsidized fee.
          </p>
          <p>
            <button className="get_started_link">Get Started</button>
          </p>
        </Link>
      </div>
      )
}

export default StartCollegeApplication