import { GET_PROFILE_REQUEST, GET_PROFILE_SUCCESS, GET_PROFILE_FAIL } from "../../Utils/constants/getProfileConstants";

export const getStudentProfileReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PROFILE_REQUEST:
        return { loading: true };
      case GET_PROFILE_SUCCESS:
        return {
          ...state,
          loading: false,
          profile: action.payload,
          success: true,
        };
      case GET_PROFILE_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
     default:
         return state
    }
  };