import { GET_COLLEGE_APPLICATIONS_REQUEST, GET_COLLEGE_APPLICATIONS_SUCCESS, GET_COLLEGE_APPLICATIONS_FAIL } from "../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplicationConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";

export const GetCollegeApplicationsAction = () => async(dispatch, getState) => {
    try {
        dispatch({type: GET_COLLEGE_APPLICATIONS_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/product/college_applications`,
            config
        )
        dispatch({type: GET_COLLEGE_APPLICATIONS_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_COLLEGE_APPLICATIONS_FAIL, payload: message})
    }
}
// export const GetCollegeApplUnpaidOrderAction = (id) => async(dispatch, getState) => {
//     try {
//         dispatch({type: GET_COLLEGE_APPL_UNPAID_ORDER_REQUEST});
//         const {
//             tokenAuth: {tokenInfo}
//         } = getState()

//         const config = {
//             headers: {
//                 Authorization: `Bearer ${tokenInfo.data.userToken}`,
//                 "Content-Type": "application/json"
//             }
//         };

//         const {data} = await axiosInstance.get(
//             `/product/getUsersUnpaidAppl/${id}`,
//             config
//         )
//         console.log('hhhhhhh', data);
//         dispatch({type: GET_COLLEGE_APPL_UNPAID_ORDER_SUCCESS, payload: data})
//     } catch (error) {
//         const message = error.response && error.response.data ? error.response.data : error.message;
//         dispatch({type: GET_COLLEGE_APPL_UNPAID_ORDER_FAIL, payload: message})
//     }
// }
