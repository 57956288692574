import { Radio } from 'antd';

const Level = ({setLevel, level, degrees = []}) => {
   return  <> 
       <div className="choice_form1">
      <p className="questions_tag">Question 3</p>
      <h2>Select a degree type</h2>
      <Radio.Group>
      {
         degrees?.map((level) => (
            <>
<Radio.Button value={level.id} onChange={(e) => setLevel(e.target.value)}>{level.degree_name}</Radio.Button>
               {/* <Radio.Button value="PHD">PHD</Radio.Button>
               <Radio.Button value="Masters">Masters</Radio.Button>
               <Radio.Button value="Diploma">Diploma</Radio.Button>
               <Radio.Button value="Others">Others</Radio.Button> */}
               </>
         ))
      }
      </Radio.Group>
      </div>
   </>   
}

export default Level;

