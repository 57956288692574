import axios from "axios";

let baseURL;

if(process.env.REACT_APP_NODE_ENV === "deployment"){
    baseURL = process.env.REACT_APP_BACKEND_URL
}else {
    baseURL = process.env.REACT_APP_BACKEND_TEST_URL
}
const axiosInstance = axios.create({
    baseURL: baseURL
})

export default axiosInstance;
