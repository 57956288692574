import { GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_REQUEST, GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_SUCCESS, GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_FAIL } from "../../../Utils/constants/HighschoolConstants/HighschoolCounsellorConstants";
import axiosInstance from "../../../Utils/helper/axiosInstance";

export const HighschoolCounsellorAction = () => async(dispatch, getState) => {
    try {
        dispatch({type: GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_REQUEST});
        const {
            tokenAuth: {tokenInfo}
        } = getState()

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        };

        const {data} = await axiosInstance.get(
            `/highschools/list_highschools`,
            config
        )
        dispatch({type: GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_HIGHSCHOOL_COUNSELLOR_OF_HIGHSCHOOL_FAIL, payload: message})
    }
}