import { GET_PAYSTACK_PAYMENT_REQUEST, GET_PAYSTACK_PAYMENT_SUCCESS, GET_PAYSTACK_PAYMENT_FAIL } from "../../../Utils/constants/Payment/PaymentConstants";

export const PayWithPayStackReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_PAYSTACK_PAYMENT_REQUEST:
        return { payment_loading: true };
      case GET_PAYSTACK_PAYMENT_SUCCESS:
        return {
          ...state,
          payment_loading: false,
          payment_result: action.payload,
          payment_success: true
        };
      case GET_PAYSTACK_PAYMENT_FAIL:
        return {
          ...state,
          payment_loading: false,
          payment_error: action.payload,
          payment_success: false
        };
      default:
        return state;
    }
  };