import {STUDENT_LIST_REQUEST, STUDENT_LIST_SUCCESS, STUDENT_LIST_FAIL} from '../../../../../../../Utils/constants/HighschoolConstants/Counsellor/StudentListConstants';
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";

// for getting list of students in a particular highschool
export const listOfStudentsInAhighschool = () => async  (dispatch, getState) => {
    try {
        dispatch({type: STUDENT_LIST_REQUEST})
        const {
            tokenAuth: { tokenInfo },
          } = getState();

        const config = {
            headers: {
              Authorization: `Bearer ${tokenInfo.data.userToken}`,
              "Content-Type": "application/json",
            },
          };

        const { data } = await axiosInstance.get(
            "/highschools/studentlist/students_underhighschool",
            config
          );
          dispatch({ type: STUDENT_LIST_SUCCESS, payload: data });

    }  catch (error) {
        dispatch({
          type: STUDENT_LIST_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
}