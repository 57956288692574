
import { useEffect, useState } from "react";
import { PaystackButton, usePaystackPayment } from "react-paystack";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom"
import {GetSchoolScholarshipProductAction } from "../../../../../Redux/actions/HighschoolActions/GetProductAction";
import { ScholarCompletePaymentWithPayStack } from "../../../../../Redux/actions/Payments/CompleteScholarshipPayAction";
import { InitiateSchoolScholarPayment } from "../../../../../Redux/actions/Payments/ScholarshipPayAction";

const StarterPage = ({userType}) => {
 
     const history = useHistory();
     const dispatch = useDispatch();
     const [ref, setRef] = useState(false);
     const [page, setPage] = useState(false);
     const [amount, setAmount] = useState("");
     let dollarUSLocale = Intl.NumberFormat('en-US');
     const localUser = JSON.parse(localStorage.getItem('tokenInfo'));
     const {data: localData = {}} = localUser
     const {email: localEmail} = localData;
     const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY_DEVELOPMENT;
     const myamount = amount?.toString().length <= 4 ? "0" + amount : amount;
     const padded_amount = myamount?.toString().padEnd(7, '000');
     const amounts = +padded_amount
 
     const handleRoute = () => {
               history.push(`/scholarship-questionnaire/${'create'}`) 
               window.location.reload(false)
     }

     const {school_scholar_init_complete = {}, school_scholar_init_loading} = useSelector(state => state.initiateSchoolPayment);
     const {data:payment_data = {}} = school_scholar_init_complete;
     const {reference} = payment_data;

     const {school_scholar_product_college = {}} = useSelector(state => state.school_scholr_product);
     const {data:scholar_product = {}} = school_scholar_product_college;

     const {scholarPay_complete = {}} = useSelector(state => state.scholarCompletePayment);
     const {data: success_data} = scholarPay_complete

      const config = {
          reference: reference,
          email: localEmail,
          amount: amounts, //Amount is in the country's lowest currency. E.g Kobo, so 20000 kobo = N200
          publicKey,
      };


          // you can call this function anything
  const onSuccess = (reference) => {
     // Implementation for whatever you want to do with reference and after success call.
     if (reference.reference) {
          setRef(false)
          dispatch(ScholarCompletePaymentWithPayStack({reference: reference.reference}))
     window.location.reload();

        } else{
          return false;
        }
   };
 
   // you can call this function anything
   const onClose = () => {
     // implementation for  whatever you want to do when the Paystack dialog closed.
     window.location.reload();
   }

     useEffect(() => {
          dispatch(GetSchoolScholarshipProductAction())
      }, [dispatch]);

      const initiatePayment = (id, amount) => {
           dispatch(InitiateSchoolScholarPayment({product_id: id}));
           setRef(true)
           setAmount(amount)
     }
     
     const initializePayment = usePaystackPayment(config);
     useEffect(()=> {
          return ref === true ? initializePayment(onSuccess, onClose) : null
     }, [ref, reference])

     useEffect(() => {
          // callback function to call when event triggers
          const onPageLoad = () => {
            setPage(true)
          };
      
          if (document.readyState === 'complete') {
            onPageLoad();
          } else {
            window.addEventListener('load', onPageLoad, false);
            return () => window.removeEventListener('load', onPageLoad);
          }
        }, []);

    return <>
    {
     page ?
     
     <div className="col-md-9 row2-container mb-5">
                     <div className="shadow p-4" style={{borderTop: '3px solid #047BF2'}}>
                          <div className="row imagess">
                             <div className="col-md-10 col-sm-6">
                                  <img src="/scholarship/student_PNG126.png" className="mb-3" alt="" style={{borderRadius: '50px'}}/>
                             </div>
                             <div className="col-md-2 col-sm-6">
                                  <img src="/scholarship/student_PNG62531.png" className="mb-3" alt="" style={{borderRadius: '50px'}}/>
                             </div>
                          </div>
                          <div className="row mx-auto justify-content-center">
                             <div className="p-2 col-md-8 text-center"><h5><b>WELCOME TO TRAV4COLLEGE SCHOLARSHIP INFORMATION PORTAL</b></h5></div>
                          </div>
                          <div className="row mx-auto justify-content-center">
                             <div className="p-2 col-md-8 text-center"><p>{userType !== 'student' ? 'Your school gets access to one full year subscription once subscribed' : 'Get access to full tuition scholarships suited for your financial needs'}</p></div>
                          </div>
                          <div className="text-center row">
                            {
                                 userType !== 'student' 
                                 ?
                                 <div className="col-md-12"><button style={{ border: "none" }}>
                                 <button className="btn">
                                           {
                                           
                                              <button className="btn" onClick={()=> initiatePayment(scholar_product[0]?.id, scholar_product[0]?.amount)}>
                                                {
                                                     school_scholar_init_loading ? <div class="spinner-border" role="status">
                                                   </div> : 'Make Payment'
                                                }
                                                </button>
                                           }
                                      </button> 
                         
                                 </button>
                                 </div> 
                               : 

                          <div className="col-md-12"><button style={{ border: "none" }}>
                             <button className="btn" onClick={handleRoute}>Select your scholarship preference</button>
                          </button>
                          </div> 
                            }
                       
                          </div>
                         <div className="row imagess mt-3">
                             <div className="col-md-10">
                                  <img src="/scholarship/student_PNG62532.png" className="mt-3" alt="" style={{borderRadius: '50px'}}/>
                             </div>
                             <div className="col-md-2">
                                  <img src="/scholarship/student_PNG62555.png" className="mt-3" alt="" style={{borderRadius: '50px'}}/>
                             </div>
                          </div>
                     </div>
             </div>
 :

   <div className="container">
     <div className="row mx-auto justify-content-center">
        <div className="spinner-border" role="status"></div>
     </div>
   </div>

    }
    
</>
}

export default StarterPage;