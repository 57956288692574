import {GET_COLLEGE_SIMULATION_LIST_FAIL, GET_COLLEGE_SIMULATION_LIST_REQUEST, GET_COLLEGE_SIMULATION_LIST_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/CollegeSimulationListsConstants"

export const studentCollegeSimulationListReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_COLLEGE_SIMULATION_LIST_REQUEST:
            return {
                college_list_loading: true,
            }
        case GET_COLLEGE_SIMULATION_LIST_SUCCESS:
            return {
                ...state,
                college_list_loading: false,
                collegeSimulationList: action.payload,
                college_list_success: true 
            }    
        case GET_COLLEGE_SIMULATION_LIST_FAIL:
            return {
                ...state,
                college_list_loading: false,
                collegeSimulationList_error: action.payload,
                college_list_success: false    
            }
        default:
            return state;
    }
}