import { GET_USER_FAIL, GET_USER_REQUEST, GET_USER_SUCCESS } from "../../Utils/constants/userConstants";

export const userReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_USER_REQUEST:
        return { loading: true };
      case GET_USER_SUCCESS:
        return {
          ...state,
          loading: false,
          user: action.payload,
          success: true,
        };
      case GET_USER_FAIL:
        return {
          ...state,
          loading: false,
          success: false,
          error: action.payload,
        };
     default:
         return state
    }
  };
  