import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "./careerResult.css";
import SimulatorHeader from "../SimulatorHeader";
import { useEffect, useContext, useState } from 'react';
import {getStudentCareerBySimulationId} from "../../../../Redux/actions/SimulationActions/CareerSimulationActions/getCareerSimulationBySimulationId"
import { Spin, Modal, Alert, message } from "antd";
import { AuthContext } from "../../AuthContext/AuthContext";
import { getStudentProfileAction } from "../../../../Redux/actions/getProfileAction";
import { HighschoolCounsellorAction } from "../../../../Redux/actions/HighschoolActions/HighschoolCounsellorAction";
import { SendResultAction } from "../Redux/action/Notifications/SendResultAction";
import { getProfileAction } from "../../DashboardLayoutPage/Contents/ProfilePage/Redux/actions/GetProfileAction";
import { MdWork } from "react-icons/md";
// import Alerts from "../../../Alerts/Alert";

const CareerResultRecommendation = ({career_simulation_id}) => {
    const {user = {}} = useContext(AuthContext)
    const dispatch = useDispatch()
    // const history = useHistory()
    const { simulation_id } = useParams();
    const {loading_simulationId, loading_simulationId_error, careerResult = {}, careerResult_error} = useSelector(state => state.getStudentCareerbySimulationId)
    const {data = {}} = careerResult;
    console.log('data', careerResult_error)
    const {type = [], student_details = {}, suggested_careers = []} = data;
    const {user:userDetails = {}} = student_details;
    const {first_name, id} = userDetails;
    const {profile = {}} = useSelector(state => state.profileGet)
    const {data:profile_data = {}} = profile;
    const myprofile = profile_data?.high_school_profile
    const result_link = window.location.href;
    const {data:user_data = {}} = user;
    const {highSchoolProfile = {}} = user_data;
    const {user_type} = highSchoolProfile;
    const tokenInfo = JSON.parse(localStorage.getItem("tokenInfo"));
    const {data:local_info = {}} = tokenInfo;
    const {highSchoolProfile:highschoolprofile_info = {}} = local_info;
    const {user_type:profile_type} = highschoolprofile_info
    const {highschool_counsellor = {}} = useSelector(state => state.highschoolCounsellor)
    const {data:counsellor = []} = highschool_counsellor;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [counsellors, setCounsellors] = useState("");
    const {sent_result = {}, result_loading} = useSelector(state => state.sendNotification)
    const {profile:student_user = {}} = useSelector(state => state.profileGet)
    const {data:student_user_data = {}} = student_user;
    const highshool_student_prof = student_user_data && student_user_data?.high_school_profile
    console.log(user_type)
    const handleChange = (e) => {
      setCounsellors(e.target.value);
    }
    useEffect(() => {
      dispatch(getProfileAction(id))
  }, [dispatch, id])

  const handlePageChange = (e) => {
    e.preventDefault();
    window.location.href="/career-simulator/questionnaire"
    //  history.push('/career-simulator/questionnaire')
  }
    const handleResultShareToCounsellor = () => {
        const names = counsellor && counsellor?.find(emails => emails.user.email === counsellors);

        const dataToSubmit = {
          "guardian_name": names.user.first_name,
          "link_to_be_sent": result_link,
          "guardian_email": counsellors,
          "notification_type": "career",
          "guardian_type": "counsellor",
          "simulation_id": simulation_id,
          "type": "notification",
          "amount": "",
          "token": "",
        }
        dispatch(SendResultAction(dataToSubmit))
        setIsModalVisible(false);
      };
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    const handleResultShare = (type) => {
        if (type === "counsellor") {
            setIsModalVisible(true);
         } else if (type === "student_parent") {
              if (!highshool_student_prof?.parent_email || !highshool_student_prof?.parent_name) {
                message.error('Student profile is not yet complete!');
              } else {
                  const dataToSubmit = {
                    "guardian_name": highshool_student_prof?.parent_name,
                    "link_to_be_sent": result_link,
                    "guardian_email": highshool_student_prof?.parent_email,
                    "notification_type": "career",
                    "guardian_type": "parent",
                    "simulation_id": simulation_id,
                    "type": "notification",
                    "amount": "",
                    "token": "",
                  }
                  dispatch(SendResultAction(dataToSubmit));
                  console.log("sent_result", sent_result);
              }
         } else {
          if (!myprofile?.parent_name || !myprofile?.parent_email) {
            message.error('Update your profile!');
          } else {
              const dataToSubmits = {
                "guardian_name": myprofile?.parent_name,
                "link_to_be_sent": result_link,
                "guardian_email": myprofile?.parent_email,
                "notification_type": "career",
                "guardian_type": "parent",
                "simulation_id": simulation_id,
                "type": "notification",
                  "amount": "",
                  "token": "",
              }
            dispatch(SendResultAction(dataToSubmits));
            console.log("sent_result", sent_result);
         }
    }
  }

    useEffect(() => {
        dispatch(getStudentProfileAction(id))
        dispatch(HighschoolCounsellorAction())
    }, [dispatch, id])

    useEffect(() => {
        if (!simulation_id) {
            dispatch(getStudentCareerBySimulationId(career_simulation_id))
        } else {
            dispatch(getStudentCareerBySimulationId(simulation_id))
        }
    }, [dispatch, simulation_id, career_simulation_id])

    return (
        <div className="container regions_result">
         
            <div className="container">
                <SimulatorHeader  name="Career Simulator" logo="/trav4college_logo.png" />
                {careerResult_error ?<div className="card shadow p-5" style={{display: 'grid', placeItems: 'center', marginTop:'15%'}}> <h4 style={{textTransform: 'uppercase'}} className="text-center text-danger"><b>{careerResult_error === "No simulation for this user" ? "...Oops, no simulation result for you. Maybe, you can try restarting" : "...Oops, no simulation result for you. Maybe, you can try restarting"}</b></h4>
                 <Link to="#" onClick={handlePageChange}  style={{border: '1px solid #047BF2'}} className="p-2 mt-3"><b>RESTART SIMULATION</b></Link>
                </div> : 
                
              <>
                 {
                     loading_simulationId ? <div className="mx-auto justify-content-center text-center mt-5"><Spin size="large"/></div>
                     :
                     <div className="success_result">
                     {loading_simulationId &&
                             <div className="d-flex justify-content-center text-center"  role="status" style={{ width: "5rem", height: "5rem"}}
                             >
                             <Spin size="large"/>
                             </div>
                         }
                         {loading_simulationId_error && <p className="text-center text-danger">{loading_simulationId_error && loading_simulationId_error}</p>}
                         {/* {user_error && <p className="text-center text-danger">{user_error && user_error}</p>} */}
                 
                 {
                     profile_type && (profile_type === "counsellor" || profile_type === "parent") ? 
                     <>
                     {first_name && <h4 className="username_message">Here are {first_name}'s result</h4>}
                     </> : <>{first_name && <h4 className="username_message">{first_name}, here are your results</h4>}</>
                 }
                     
                 
                     <div className="row">
                       {
                         type.length > 1 ? <>
                            { type && type?.map((career)=> (
                                <>
                            <div className="col-md-6 mb-5">
                              <div className="card" style={{ background: "none", border: "none" }}>
                                <div className="card-body">
                                  <h2 className="success_message" style={{ textTransform: "capitalize" }}>{career.personality_type}</h2>
                              <span className="card-text success_message">{career.personality_description}</span>
                                </div>
                              </div> 
                            </div> 
                              
                                </>
                            ))
                            }
                         
                         </> : <>
                         { type && type?.map((career)=> (
                                <>
                            <div className="col-md-12 text-center mb-5">
                              <div className="card" style={{ background: "none", border: "none" }}>
                                <div className="card-body">
                                  <h2 className="success_message" style={{ textTransform: "capitalize" }}>{career.personality_type}</h2>
                              <span className="card-text success_message">{career.personality_description}</span>
                                </div>
                              </div> 
                            </div> 
                              
                                </>
                            ))
                            }
                         </>
                       }
                     </div>
                     
                     <p className="success_note_career">
                         Think of your interests as work you like to do.
                         Your interests can help you find careers you might like to explore. The more a career meets your interests, the more likely it will be satisfying and rewarding to you.
                     </p>
 
 
                     <p className="success_note_career my-4">Here are our Career Reccommendations to help you begin your career journey. </p>
                     
                     <ul className="success_item">
                       <div className="row">
                       
                         { suggested_careers && suggested_careers?.slice(0, 4)?.map((career, index)=> (
                           <div className="col-md-3 text-center">
                             <div className="card text-center" style={{ background: "none", border: "none", fontSize: "25px", color: '#0476E8'}}>
                               {/* <div>
                                  <span><MdWork size='15px'/></span>
                              <span className="ml-1">{`${career.careers}`}</span>
                               </div> */}
                               <div className="grid-container">
                                  <div className="grid-child">
                                      <MdWork size='15px'/>
                                  <div className="grid-child">
                                      {`${career.careers}`}
                                  </div>
                                  </div>


                                  </div>
                             </div>
                       </div>
                             
                             ))
                           }
                           </div>
                     </ul>
                      {
                        profile_type === "student" ? <Link to={`/view-more-careers/${simulation_id}`}> <p className="text-center" style={{ fontSize: "20px" , fontWeight: "bold", cursor: "pointer", color: '#0476E8' }}>View more career choices </p></Link> : null
                      }
                     
                     {
                     result_loading ? <Spin className="mt-3 mb-3"/> : sent_result?.status && <div className="mx-auto mt-3 mb-3" style={{width: "50%"}}>
                             
                     <Alert
           description="Notification sent!"
           type="success"
           showIcon
           closable
           />
                   </div>
                   }
                     <div className="cta-links">
                     {
                       profile_type ? 
                       <>
                           {
                             profile_type === "counsellor" ?  <Link to="#" onClick={()=>handleResultShare('student_parent')} className="btn_success"> {result_loading ? <Spin/> : "SEND RESULT TO STUDENT PARENTS" }
                       
                     </Link>  : <>
                     {
                         profile_type === "parent" ? " " :
                         <>
                         {
                           profile_type === "student" ? 
                           <>
                       
                             <Link to="#" onClick={()=>handleResultShare('counsellor')} className="btn_primary">
                               SPEAK TO A COUNSELOR
                             </Link>
 
                             <Link to="#" onClick={()=>handleResultShare('parent')} className="btn_success">
                               SEND RESULT TO PARENTS
                             </Link>
 
                             <Link to="#" onClick={handlePageChange} className="btn_default">
                               RESTART SIMULATION
                             </Link>
                           </> : null
                         }
                         </>
                     }
                     </>
                           }
                   
                     </>
                     : ""
                     }
 
     <Modal title="Counsellors" width={350} visible={isModalVisible} onOk={handleResultShareToCounsellor} onCancel={handleCancel}
                     cancelButtonProps={{
                       style: {
                         display: "block",
                         height: "40px",
                         background: "none !important"
                       },
                     }}
                   okButtonProps={{
                       style: {
                         display: "block",
                         height: "40px",
                         marginLeft: "2px" ,
                         marginTop: "10px",
                         marginBottom: "10px"
                       },
                     }} 
                     >
                   <select class="form-select" style={{ width: "100%" }} aria-label="Default select example" onChange={handleChange}>
                       <option selected>Choose a counsellor to send result to</option>
                       {
                         counsellor && counsellor?.map((user = {}) => 
                           <>
                             <option value={user.user.email}>{user.user.first_name} {user.user.last_name}</option>
                           </>
                         )
                       }
                     
                     </select>
                       </Modal>
                     </div>
             
                 </div> 
                 }
              </>
                }
       {/* <--------ENDS--------> */}

            </div>
            </div>
    )
}


export default CareerResultRecommendation;