import axiosInstance from "../../../Utils/helper/axiosInstance";
import { GET_PAYSTACK_SCHOLARSHIP_PAYMENT_REQUEST, GET_PAYSTACK_SCHOLARSHIP_PAYMENT_SUCCESS, GET_PAYSTACK_SCHOLARSHIP_PAYMENT_FAIL, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_REQUEST, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_SUCCESS, GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_FAIL } from "../../../Utils/constants/Payment/ScholarshipPaymentConstants";

export const ScholarPayWithPayStack =
    (dataToSubmit) => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_PAYSTACK_SCHOLARSHIP_PAYMENT_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.post(
          "/payment/initiate",
          dataToSubmit,
          config
        );
        dispatch({ type: GET_PAYSTACK_SCHOLARSHIP_PAYMENT_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: GET_PAYSTACK_SCHOLARSHIP_PAYMENT_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };

    export const InitiateSchoolScholarPayment =
    (dataToSubmit) => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.post(
          "/payment/initiate",
          dataToSubmit,
          config
        );
        dispatch({ type: GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: GET_INITIATE_PAYSTACK_SCHOOL_SCHOLARSHIP_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };