import axiosInstance from "../../../../Utils/helper/axiosInstance";
import { GET_LATEST_CAREER_SIMULATION_SUCCESS, GET_LATEST_CAREER_SIMULATION_REQUEST, GET_LATEST_CAREER_SIMULATION_FAIL } from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/latestCareerSimulationConstants"

export const latestStudentCareerSimulation = (id) => async (dispatch, getState) => {
   try {
       dispatch({type: GET_LATEST_CAREER_SIMULATION_REQUEST})
       const {
           tokenAuth: { tokenInfo }
       } = getState();

       const config = {
           headers: {
               Authorization: `Bearer ${tokenInfo.data.userToken}`,
               "Content-Type": "application/json"
           }
       }

       const {data} = await axiosInstance.get(
           `/highschools/student/latestCareerSimulation/${id}`,
           config
       );
      dispatch({type: GET_LATEST_CAREER_SIMULATION_SUCCESS, payload: data});
      if(data.status === "failed"){
        dispatch({
          type: GET_LATEST_CAREER_SIMULATION_FAIL,
          payload: data.reason.message
        });
       }
   } catch (error) {
    dispatch({
        type: GET_LATEST_CAREER_SIMULATION_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
   }
}