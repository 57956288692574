import {
    GET_GUARDIAN_MESSAGE_REQUEST,
    GET_GUARDIAN_MESSAGE_SUCCESS,
    GET_GUARDIAN_MESSAGE_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Message/GetGuardianMessageConstant";

  export const GetMessageReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_GUARDIAN_MESSAGE_REQUEST:
        return { loading_message: true };
      case GET_GUARDIAN_MESSAGE_SUCCESS:
        return {
          ...state,
          loading_message: false,
          message: action.payload,
          message_success: true
        };
      case GET_GUARDIAN_MESSAGE_FAIL:
        return {
          ...state,
          loading_message: false,
          message_error: action.payload,
          message_success: false
        };
      default:
        return state;
    }
  };