import {useState, useEffect} from "react"
import { useDispatch, useSelector } from 'react-redux';
import {Form,Input,Button,Select,DatePicker, Alert} from 'antd';
import { updateProfileAction } from "../../Redux/actions/UpdateProfileAction";
import Alerts from "../../../../../../Alerts/Alert";
import { userAction } from "../../../../../../../Redux/actions/userAction";
import "../../../ParentsDashboard/css/profile.css";
import { useHistory } from "react-router-dom";
import { listCountries } from "../../Redux/actions/GetCountriesAction";

const { Option } = Select


const PersonalProfileUpdate = ({user_detail = {}}) => {
  const [message,setMessage] = useState("");
  const dispatch = useDispatch();
  
  const {data = {}} = user_detail;
  const {first_name, last_name, phone, email, highSchoolProfile = {}} = data;
  const {age, city, country, gender, parent_name, parent_email, parent_number, user_type} = highSchoolProfile;
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("")
  const [parentNumber, setParentNumber] = useState("")
  const [myCity, setCity] = useState("")
  const [myCountry, setCountry] = useState("")
  const [myAge, setAge] = useState("")
  const [myGender, setGender] = useState("");
  const history = useHistory()
  // console.log("age", myAge);

    const {profile_update_loading,  update_profile_error, updated_profile} = useSelector(state => state.updateProfile);
    const {loading, error} = useSelector(state => state.getUser)

    const {countries = {}} = useSelector(state => state.getCountries);
    const {data: data_countries = []} = countries

    const dataToSubmit = {parent_name: parentName?parentName:parent_name, parent_email: parentEmail?parentEmail:parent_email, parent_number: parentNumber?parentNumber:parent_number, city: myCity?myCity:city, country: myCountry?myCountry:country, age: myAge?myAge:age, gender: myGender?myGender:gender}

    const [updated, setUpdated] = useState(updated_profile)

    const handleDataSubmit = (e) => { //this is a profile update button
        e.preventDefault();
        dispatch(updateProfileAction(dataToSubmit)) 
        const parent_ = {parent_name: parentName?parentName:parent_name, parent_email: parentEmail?parentEmail:parent_email}
        localStorage.setItem('profile_', JSON.stringify(parent_))
        setTimeout(() => {
          history.push("/dashboard/profile") 
        }, 3000);
    }
    setTimeout(() => {
      setMessage("")    
    }, 3000)
    setTimeout(() => {
      setUpdated("")
    }, 3000)
    function handleSelectChange(value) {
        setCountry(value)
      }
    function handleGenderChange(value){
        setGender(value)
    }
    function onChange(value, dateString) {
      setAge(dateString)
    }

    useEffect(() => {
        dispatch(userAction())
    }, [dispatch])

    useEffect(() => {
      dispatch(listCountries())
  }, [dispatch])
    
    return (
    <div className="personal_profile_form my-4"> 
       {error && <Alerts description={error} type="error" message={error.status} closable />}
      {loading ?   
          <div
                className="spinner-border text-dark"
                role="status"
                style={{ width: "5rem", height: "5rem", marginLeft: "auto", marginRight:"auto", textAlign: "center", display: "block" }}
                >
              <span className="visually-hidden">Loading...</span>
            </div> : 
                  <Form
                  layout="vertical"
                  className="container-fluid"
                >
                    <div className="row mx-auto">
                            <Form.Item className="col-md-6">
                                <label><span>First Name</span><span className="required_field">*</span></label>
                            <Input value={first_name} disabled className="input" type="text"/>
                            </Form.Item>

                            <Form.Item className="col-md-6">
                                <label><span>Last Name</span><span className="required_field">*</span></label>
                            <Input disabled className="input" value={last_name} type="text"/>
                            </Form.Item>
                  </div>

                  <div className="row mx-auto">

                  {/* <Col span="11"> */}
                      <Form.Item className="col-md-6">
                         <label><span>Date of Birth</span></label>
                         <DatePicker className="input" placeholder={age} onChange={onChange} />
                      </Form.Item>
                  {/* </Col> */}
          
                  {/* <Col span="11" style={{marginLeft: "2rem"}}> */}
                  <Form.Item className="col-md-6">
                      <label><span>Gender</span></label>
                    <Select className="input" defaultValue={gender} placeholder={gender} onChange={handleGenderChange}>
                      <Option value="Male">Male</Option>
                      <Option value="Female">Female</Option>
                      <Option value="Others">Others</Option>
                    </Select>
                  </Form.Item>
                  {/* </Col> */}

                  </div>

                  <div className="row mx-auto">

                  {
                    user_type && user_type === "student" && 
                    <>
                    {/* <Col span="11"> */}
                      <Form.Item className="col-md-6">
                      <label><span>Parent’s Name</span></label>
                      <Input className="input" type="text" value={parentName} placeholder={parent_name} onChange={(e) => setParentName(e.target.value)}/>
                      </Form.Item>
                  {/* </Col> */}
                  {/* <Col span="11" style={{marginLeft: "2rem"}}> */}
                      <Form.Item className="col-md-6">
                      <label><span>Parent’s Email</span></label>
                      <Input className="input"  type="email" value={parentEmail} placeholder={parent_email} onChange={(e) => setParentEmail(e.target.value)}/>
                      </Form.Item>
                  {/* </Col> */}
                    </>
                  }

                  </div>

                  <div className="row mx-auto">
                  {/* <Col span="24" style={{width:"100%"}}> */}
                      <Form.Item className="col-md-6">
                      <label><span>Parent’s Number</span></label>
                      <Input className="input" type="text" value={parentNumber} placeholder={parent_number}  onChange={(e) => setParentNumber(e.target.value)}  style={{width:"96.2%"}}/>
                      </Form.Item>
                      <Form.Item className="col-md-6">
                      <label><span>Country</span></label>
                         <Select defaultValue={country} className="select_country" placeholder={country} onChange={handleSelectChange}>
                           {data_countries && data_countries?.map(({country}) => 
                            <Option  value={country}>{country}</Option>
                           )}
                      </Select>
                      </Form.Item>
                  {/* </Col> */}
                  </div>

                  <div className="row mx-auto">

                      <Form.Item className="col-md-6">
                      <label><span>City</span><span className="required_field">*</span></label>
                      <Input className="input" type="text" value={myCity} placeholder={city} onChange={(e) => setCity(e.target.value)}/>
                      </Form.Item>
                      <Form.Item className="col-md-6">
                          <label><span>Email</span></label>
                          <Input className="input" disabled value={email} type="email"/>
                      </Form.Item>
                  {/* <Col span="11"> */}
                  {/* </Col> */}
                  {/* <Col span="11" style={{marginLeft: "2rem"}}> */}
                  {/* </Col> */}

                  </div>

                  <div className="row mx-auto">

                  {/* <Col span="11"> */}
                  {/* </Col> */}
          
                  {/* <Col span="11" style={{marginLeft: "2rem"}}> */}
                      <Form.Item className="col-md-6">
                         <label>Phone Number</label>
                          <Input className="input" value={phone} type="text"/>
                      </Form.Item>
                  {/* </Col> */}

                  </div>
          
              <div className="row mx-auto">
                  <Form.Item className="col-md-6">
                  {update_profile_error && <div style={{width: "75%"}}>
                          <Alerts
                            description={update_profile_error}
                            type="error"
                            message={update_profile_error}
                            closable
                          />
                        </div>}
                  {message && <p className="text-danger my-3">{message}</p>}
                  {(updated || updated_profile) &&  <Alert
                    message="Profille updated successfully!"
                    type="success"
                    showIcon
                    closable
                  />}

                  {/* {updated_profile && <div className="mx-auto mb-3" style={{width: "100%"}}>
                  <Alert
                    message="Profille updated successfully!"
                    type="success"
                    showIcon
                    closable
                  />
                        </div>} */}
                 <Button className="personal_button" onClick={handleDataSubmit}> {profile_update_loading ? (
                  <div
                    className="spinner-border text-white"
                    role="status"
                    style={{ width: "2rem", height: "2rem" }}
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>) : "Update Profile"}</Button>

                      
                  </Form.Item>
                  </div>
                </Form>
          }

    </div>
  );
};


export default PersonalProfileUpdate