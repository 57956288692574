import { Layout, message } from "antd"
import Navbar from "./Header";
import SideBar from "../../Sidebar/Sidebar";
import ProfileLayout from "../DashboardLayoutPage/Contents/ProfilePage/StudentProfile/index"
import HelpDesk from "../DashboardLayoutPage/Contents/HelpAndSupportPage/index";
import {Redirect, Route, Switch, useLocation } from "react-router-dom";
import ParentRoutes from "../../../Utils/Routes/User_Routes/ParentRoutes"
import StudentRoutes from "../../../Utils/Routes/User_Routes/StudentRoutes"
import CounsellorRoutes from "../../../Utils/Routes/User_Routes/CounsellorRoutes"
import ProfileUpdate from "../DashboardLayoutPage/Contents/ProfilePage/StudentProfile/ProfileUpdates/ProfileUpdate";
import StudentProfilePage from "../DashboardLayoutPage/Contents/ViewStudentProfilePage/index";
import CareerSimulationLists from "./Contents/CounsellorDashboard/Students/Career/StudentCareerSimulationList";
import CollegeSimulationLists from "./Contents/CounsellorDashboard/Students/College/StudentCollegeSimulationList";
import { HIGHSCHOOL_LIVE_URL } from "../../../Utils/constants/Local_URL";
import { AuthContext } from "../AuthContext/AuthContext";
import { useContext } from "react";
import { useEffect } from "react";
import { getScholarshipsAction } from "../../../Redux/actions/SimulationActions/ScholarshipActions/GetScholarshipsAction";
import { useDispatch, useSelector } from "react-redux";
import CollegeApplSimulationLists from "./Contents/CounsellorDashboard/Students/CollegeAppl/StudentCollegeApplist";
import NotFound from "../../404";
// import { useContext, useEffect } from "react";
// import { AuthContext } from "../AuthContext/AuthContext";

const { Header, Content, Footer } = Layout;

const DashboardLayout = () => {
    const location = useLocation();
    const currentLocation = location.pathname;
    let jsonInfo = JSON.parse(localStorage.getItem("tokenInfo"));
    const {highschools = {}, user = {}} = useContext(AuthContext)
    const {data:highschool_data = []} = highschools;
    const newUserInfo = jsonInfo?.data;
    const {data = {}} = user;

    // if (newUserInfo?.highSchoolProfile === null) window.location.href = HIGHSCHOOL_LIVE_URL;
    useEffect(() => {
      if (data?.highSchoolProfile === null)
      {
       window.location.href = HIGHSCHOOL_LIVE_URL
      }
    }, [data])
      const jsonType = jsonInfo?.data?.highSchoolProfile?.user_type && jsonInfo?.data?.highSchoolProfile === null ?message.error("You don't have a highschool account yet") : jsonInfo?.data?.highSchoolProfile?.user_type
      const highschool_id = jsonInfo?.data?.highSchoolProfile && jsonInfo?.data?.highSchoolProfile !== null ? jsonInfo?.data?.highSchoolProfile?.highschool_id : <Redirect to="/signup"/>;
      const userPrivateKey = jsonInfo?.data?.highSchoolProfile && jsonInfo?.data?.highSchoolProfile ? jsonInfo?.data?.highSchoolProfile?.profile_code : <Redirect to="/signup"/>;
      const findHighschool = highschool_data && highschool_data ? highschool_data?.find(highschool => highschool.id === +highschool_id) : <Redirect to="/signup"/>;
    
    if (!jsonInfo) {
      window.location.href = HIGHSCHOOL_LIVE_URL;
    } 
 
    const dispatch = useDispatch();
    const {get_scholar_profile = {}} = useSelector(state => state.getScholarships);
    const {data: scholar_data = {}} = get_scholar_profile
    const {hasScholarshipProduct} = scholar_data;

    useEffect(() => {
        dispatch(getScholarshipsAction())
    }, [dispatch])
    

    const getNavbarText = (currentLocation) => {
        switch (currentLocation) {
          case "/dashboard/":
            localStorage.setItem("keys", "overview");
            return `Welcome ${newUserInfo.first_name ? newUserInfo.first_name : ""}`;
          case "/dashboard/profile":
            localStorage.setItem("keys", "profile");
            return "Profile"
          case "/dashboard/my-career":
            localStorage.setItem("keys", "my-career");
            return "My Career"
          case "/dashboard/help-desk":
            localStorage.setItem("keys", "help-desk");
            return "Help & Support"    
          case "/dashboard/profile/profile-update":
            localStorage.setItem("keys", "profile");
            return "Update Profile"    
          case "/dashboard/my-college":
            localStorage.setItem("keys", "my-college");
            return "My College" 
          case "/dashboard/my-college-application":
            localStorage.setItem("keys", "my-college-applications");
            return "My College Application" 
          case "/dashboard/my-scholarships":
            localStorage.setItem("keys", "my-scholarships");
            return "My Scholarship Profile"  
          case "/dashboard/my-scholarshipslists":
              localStorage.setItem("keys", "my-scholarshipslists");
              return "My Scholarship Lists"  
          case "/dashboard/subscriptions":
                localStorage.setItem("keys", "subscriptions");
              return "Scholarship Subscriptions"          
                  //Parents starts here
          case "/dashboard/parent/":
            return "Dashboard"  

                  //Counsellor starts here
            case "/dashboard/counsellor/students":
              localStorage.setItem("keys", "student");
              return "Students"  
            case "/dashboard/counsellor/career":
              localStorage.setItem("keys", "career");
              return "Career"  
            case "/dashboard/counsellor/college":
              localStorage.setItem("keys", "college");
                return "College"
            case "/dashboard/view-student-profile":
                return "Student Profile" 
            case "/dashboard/counsellor/collegeapplpage":
                return "College Applications" 

                  //Parent starts here
            case "/dashboard/parent/student":
              localStorage.setItem("keys", "parent-student");
              return "Students"  
            case "/dashboard/parent/applications":
              localStorage.setItem("keys", "student-application");
                return "College Applications" 
          
                //Notifications & Messages
                case "/dashboard/notifications":
                  localStorage.setItem("keys", "notifications");
                  if (jsonType === "student") {
                    return 
                  } else {
                    return "Notifications";     
                  }
                case "/dashboard/messages":
                    localStorage.setItem("keys", "messages");
                    if (jsonType === "student") {
                      return 
                    } else {
                      return "Messages";     
                    }
          default:
            break;
        }
    }

    return (
       <>
    
          {/* {
              (jsonInfo.data.highSchoolProfile === null)  ? <div class="center">
               <Link to="/signup" style={{textDecoration: "none"}}>Get Started</Link>
              </div> : null
          } */}
            <SideBar user_type={jsonType ? jsonType : ""} userPrivateKey={userPrivateKey}/>
            
              <Layout className="ant-layout">
              <Header
                className="ant-layout-header site-layout-sub-header-background"
      
                >
              <Navbar
                  username={`${newUserInfo.first_name ? newUserInfo.first_name : ""} ${newUserInfo.last_name ? newUserInfo.last_name : ""}`}
                  text={getNavbarText(currentLocation)}
                  role={jsonType ? jsonType : ""}
                  profile_pic={findHighschool?.profile_photo_url ? findHighschool?.profile_photo_url : ""}
                  /> 
              </Header>
              
              <Content style={{ margin: "2em 16px 0" }}>
                  <Switch>
                    <Route exact path="/dashboard/profile"><ProfileLayout/></Route>
                    <Route exact path="/dashboard/profile/profile-update"><ProfileUpdate/></Route>
                    <Route exact path="/dashboard/help-desk"><HelpDesk/></Route>
                    <Route exact path="/dashboard/view-student-profile/:student_id"><StudentProfilePage/></Route>
                    <Route exact path="/dashboard/counsellor/careerlists/:user_id/:type" render={(props) => <CareerSimulationLists {...props} /> }></Route>
                    <Route exact path="/dashboard/counsellor/collegeApplist/:user_id/:type" render={(props) => <CollegeApplSimulationLists {...props} /> }></Route>
                    <Route exact path="/dashboard/counsellor/collegelists/:user_id/:type" render={(props) => <CollegeSimulationLists {...props} /> }></Route>
                    {
                      (currentLocation === "/dashboard/subscriptions" && hasScholarshipProduct) && <Redirect to="/dashboard/my-scholarshipslists"/> 
                    }
                    {jsonType && jsonType === "student" && <StudentRoutes user={jsonType} hasScholarshipProduct={hasScholarshipProduct}/>}
                    {jsonType && jsonType === "parent" && <ParentRoutes/>}
                    {jsonType && jsonType === "counsellor" && <CounsellorRoutes/>}
                    <Route component={NotFound}/>
                  </Switch>
              </Content>
              <Footer style={{ textAlign: "center" }} className="ant-layout-footer">
                @Trav4College ©{new Date().getFullYear()} All Rights Reserved.
              </Footer>
            </Layout>
     
       </>
    )
} 

export default DashboardLayout;