import "../student.css"
import { FaSearch } from "react-icons/fa"
import { Link } from "react-router-dom";
import Paginate from "../../../../../../Utils/helper/Paginate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { listOfStudentsInAhighschool } from "../Redux/actions/StudentListAction";
// import Modal from "react-bootstrap/Modal";
import { Modal, Card } from "antd"
import Loader from "../../../../LoadersPage";
import NoStudentPage from "../NoStudentFile";

// import {getCareerSimulationByStudentId} from "../../../actions/getCareerSimulation";
// import {getCollegeSimulationByStudentId } from "../../../actions/collegeSimulationAction";

const AllStudents = () => {
    const dispatch = useDispatch();

    const {student_loading, student_list_success} = useSelector(state => state.studentLists);

    const studentList = student_list_success && student_list_success.data;
    console.log(studentList)
    const [searchtext, setSearchtext] = useState("");
    // const [isOpen, setIsOpen] = useState(false);
    const [firstname, setFirstname] = useState();
    const [lastname, setLastname] = useState();
    const [studentId, setStudentId] = useState();
    // const [title, setTitle] = useState("View Simulations");
    const [isModalVisible, setIsModalVisible] = useState(false);

    const handleCancel = () => {
        setIsModalVisible(false);
      };
    // for paginations
    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5)

    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = studentList?.slice(indexOffirstStudent, indexOflastStudent);

    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    useEffect(() => {
        dispatch(listOfStudentsInAhighschool())
    }, [dispatch])

    const showModal = (firstname ,lastname, id) => {
        // setIsOpen(true);
        setIsModalVisible(true);
        setFirstname(firstname)
        setLastname(lastname)
        setStudentId(id)
    };

    return (
        <div className="applicants_info_style">
          {student_loading && <Loader loading={student_loading}/>}
          {student_loading ?    
              <Loader loading={student_loading}/>
              :
              <>
              {
                studentList?.length >= 1 ?
                <>
                          <div className="card">
           <div className="card-headers">
               <div className="student-header">STUDENTS</div>
                <div className="student_input_area">
                    <div className="student_input">
                        <input type="text" onChange={(e) => setSearchtext(e.target.value)} value={searchtext} placeholder="Search Students" className="search-field"/>
                        <div className="search-icon"><FaSearch className="fa-search-icon"/></div>
                    </div>
                </div>
           </div>
           <div className="divider"></div>

           <div className="table-responsive">
                   <table class="table table-hover">
                    <thead>
                        <tr align="middle" style={{ fontWeight: "700" }}>
                            <td>STUDENT NAME</td>
                            {/* <th scope="col">No. of Applications</th> */}
                            <td>ACTIONS</td>
                            {/* <th scope="col"></th> */}
                            {/* <th scope="col"></th>
                            <th scope="col"></th> */}
                        </tr>
                    </thead>
                    <tbody className="table_body">
                    {currentStudents?.filter((student) => {
                        if (searchtext === "") {
                            return student;
                        } else if (student.user.first_name?.toLowerCase().includes(searchtext.toLowerCase()) || student.user.last_name?.toLowerCase().includes(searchtext.toLowerCase())){
                            return student;
                        }
                        return false;
                    }).map((student) => {
                                return (

                                <tr align="middle" key={student.id} className="shadow-sm">
                                    <td>{student.user.first_name} {student.user.last_name}</td>
                                    {/* <td>2</td> */}

                                    <td className="simulation_status">
                                       <Link to={`/dashboard/view-student-profile/${student.user.id}`}><span className="profile_status m-2">View Profile</span></Link>  
                                        <span className="application_status m-1" onClick={()=>showModal(student.user.first_name, student.user.last_name, student.user.id)}>View simulations</span> 
                                    </td>
                                       {/* <Link to={`/career-simulator/result/${student?.user?.id}`} className="view_status m-2">
                                       </Link> */}
                                       {/* <Link to={`/career-simulator/result/${student?.user?.id}`} className="application_status m-1">
                                        <span>Career Simulations</span> 
                                       </Link>
                                       <Link to={`/career-simulator/result/${student?.user?.id}`} className="message_status m-1">
                                        <span>College Simulations</span> 
                                       </Link> */}
                                   {/* <td className="application_status"><span>Applications</span></td>
                                   <td className="message_status"><span>Send Message</span></td> */}
                                </tr>
                                )
                            }
                              )
                            }    
                         
                    </tbody>
                 </table> 
               </div>

    <Modal title={`${firstname} ${lastname}`}  className="simulation-modal" visible={isModalVisible}  footer={null}
       width={700}
       onCancel={handleCancel}
        cancelButtonProps={{
            style: {
              display: "block",
              height: "40px",
            },
          }}
        okButtonProps={{
            style: {
              display: "block",
              height: "40px",
              marginLeft: "2px"
            },
          }} 
        >
          <div className="row">
             <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <Card title="Career Simulation(s)" className="mb-2 shadow" extra={<Link to={`/dashboard/counsellor/careerlists/${studentId}/career`}>View all</Link>} style={{ width: 320 }}>
                    <img src="/perspaleta1_0005.png" alt="essential" className="essential"/>
                    <h5 className="py-2">List career simulation(s)</h5>
                    <p>
                      The Career Simulator helps you decide what kinds of careers you might want to explore.
                    </p>
                </Card>
              </div>

              <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <Card title="College Simulation(s)" className="shadow" extra={<Link to={`/dashboard/counsellor/collegelists/${studentId}/college`}>View all</Link>} style={{ width: 320 }}>
                        <img src="/perspaleta1_0001.png" alt="essential" className="essential"/>
                        <h5 className="py-2">List college simulation(s)</h5>
                        <p>
                        The College Simulator helps you decide what kinds of careers you might want to explore.
                        </p>
                </Card>
              </div>
          </div>
      </Modal>
         
            </div>
                </> 
                : 
               <NoStudentPage name={"STUDENTS"}/>
              }
           
              </>
          }
          {
            studentList?.length >= 5 ?
            <Paginate studentPerPage={studentsPerPage} totalStudents={studentList?.length} paginate={paginate} />
            : ""
          }
        </div>
    )
}

export default AllStudents