import SuccessResult from "../SuccessResult";

const Result = () => {
  return (
    <div className="results">
      <div className="success_message_container">
        <SuccessResult />
      </div>
    </div>
  );
};
export default Result;
