import SimulatorHeader from "../../SimulatorHeader";
import "../../simulator.css";
import CareerSimulatorContent from "./CareerSimulatorContent";

const CareerSimulatorGettingStarted = () => {
  return (
    <div className="simulator_wrapper">
      <SimulatorHeader logo="trav4college_logo.png" />
      <CareerSimulatorContent />
    </div>
  );
};

export default CareerSimulatorGettingStarted;
