import "../../../DashboardLayoutPage/Contents/CareerPage/career.css";
import { RiArrowRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";
const CareerSimulatorContent = () => {
  return (
    <div className="career_simulator_content">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-12 col-md-7 col-lg-7 col-xl-7 ">
          <h2>
          Welcome to the 

            <br /> Career Simulator
          </h2>
          <p>
          Welcome to career simulator. As you prepare for a great career ahead, the simulators help you relate your interest to the world of work and, in turn, help you decide which colleges and careers you might want to explore after high school. The simulators help you determine the best school and program for you after graduation by answering simple questions accurately. Here you would most likely be confident of a bright and compelling future.
          </p>
          <p>
          Here you would most likely find out what you like to do in the future.
          </p>
          <div className="mt-4 button_getstarted_go">
            <Link to="/career-simulator/questionnaire">
              Get Started {""}{" "}
              <span>
                <RiArrowRightFill />
              </span>
            </Link>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-12 col-md-5 col-lg-5 col-xl-5 image_part_career">
          <img src="/career-simulator-img.png" alt="career-simulator-img" />
        </div>
      </div>
    </div>
  );
};

export default CareerSimulatorContent;
