import { useContext } from "react";
import { AuthContext } from "../../../../AuthContext/AuthContext";
import StarterPage from "../../ScholarshipPage/StarterPage";
import SubscribedPage from "./SubscribedPage";

const SchoolScholarShipPage = () => {
    const {user} = useContext(AuthContext);
    const subscribed_status = user?.data?.highSchoolProfile;
    return (
        <>
         <div className="container">
            <div className="row mx-auto justify-content-center">
                {
                    subscribed_status?.subscription_status === 1 ? <SubscribedPage/> :
                <StarterPage userType={'counsellor'}/>

                }
            </div>
         </div>
        </>
    )
}

export default SchoolScholarShipPage;