import { GET_COLLEGE_TUITION_RANGE_FAIL, GET_COLLEGE_TUITION_RANGE_REQUEST, GET_COLLEGE_TUITION_RANGE_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/GetTuitionRangeConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
    
export const GetUserTuitionRangeInNaira = (ranges) => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_COLLEGE_TUITION_RANGE_REQUEST });
        const {
            tokenAuth: { tokenInfo },
          } = getState();
          const config = {
            headers: {
              Authorization: `Bearer ${tokenInfo.data.userToken}`,
              "Content-Type": "application/json",
            },
          };
        const { data } = await axiosInstance.post(
            `/highschools/college_simulations/fetch_amount_naira`,
            ranges,
            config
        );
        dispatch({ type: GET_COLLEGE_TUITION_RANGE_SUCCESS, payload: data });
      } catch (error) {
        const message =
          error.response && error.response.data
            ? error.response.data
            : error.message;
        dispatch({ type: GET_COLLEGE_TUITION_RANGE_FAIL, payload: message });
      }
    };
    