import { Alert, Progress } from "antd";
import Region from "./Region";
import "../../../simulator.css";
import { RiArrowRightFill, RiArrowLeftFill } from "react-icons/ri";
import Country from "./Country";
import City from "./City";
import Courses from "./Courses";
import PriceSelection from "./PriceSelection";
import Result from "./Result";
// import Alerts from '../../../../../Alerts/Alert';
const AnswerForm = ({
  page,
  handleChange,
  handleInputChange,
  goToNextPage,
  goToPrevPage,
  countries,
  continents,
  remove,
  continent,
  setContinent,
  loading,
  setCity,
  setLoading,
  city,
  tempCity,
  setTempCity,
  course,
  tempCourse,
  setTempCourse,
  getValue,
  amount_in_naira_max,
  amount_in_naira_min,
  maximum,
  minimum,
  range_min,
  range_max,
  setGetValue,
  onChange,
  onAfterChange,
  error,
  filterName,
  continentName,
  country,
  setCountry,
  countryLoad,
  countryError,
  loadCourses,
  courseError,
  options,
  display,
  setDisplay,
  setRecentValue,
  search,
  setSearch,
  getSelectedCourseData,
  removeCourses,
  errorMessage,
  getSelectedCityData,
  create_simulator_error,
  create_simulator_loading,
  handleSubmit,
  cities,
   result
}) => {

  // const {simulation_id} = result;

  const pageSwitch = () => {
    switch (page) {
      case 1:
        return (
          <Region
            handleChange={handleChange}
            continents={continents}
            setContinent={setContinent}
            continent={continent}
            remove={remove}
            loading={loading}
            setLoading={setLoading}
            error={error}
            filterName={filterName}
            continentName={continentName}
            handleSubmit={handleSubmit}
            
          />
        );
      case 2:
        return (
          <Country
            handleChange={handleChange}
            remove={remove}
            handleInputChange={handleInputChange}
            country={country}
            continent={continent}
            setCountry={setCountry}
            filterName={filterName}
            countries={countries}
            countryLoad={countryLoad}
            countryError={countryError}
          />
        );
      case 3:
        return (
          <City
            handleChange={handleChange}
            handleInputChange={handleInputChange}
            remove={remove}
            setCity={setCity}
            city={city}
            tempCity={tempCity}
            setTempCity={setTempCity}
            loading={loading}
            filterName={filterName}
            cities={cities}
            setRecentValue={setRecentValue}
            search={search}
            setSearch={setSearch}
            getSelectedCityData={getSelectedCityData}
            display={display}
            setDisplay={setDisplay}
          />
        );
      case 4:
        return (
          <Courses
            handleInputChange={handleInputChange}
            remove={remove}
            course={course}
            tempCourse={tempCourse}
            setTempCourse={setTempCourse}
            loadCourses={loadCourses}
            courseError={courseError}
            options={options}
            display={display}
            setDisplay={setDisplay}
            setRecentValue={setRecentValue}
            search={search}
            setSearch={setSearch}
            getSelectedCourseData={getSelectedCourseData}
            removeCourses={removeCourses}
          />
        );
      case 5:
        return (
          <PriceSelection
            getValue={getValue}
            setGetValue={setGetValue}
            onChange={onChange}
            onAfterChange={onAfterChange}
            loading={loading}
            amount_in_naira_max={amount_in_naira_max}
            amount_in_naira_min={amount_in_naira_min}
            maximum={maximum}
            minimum={minimum}
            range_min={range_min}
            range_max={range_max}
          />
        );
      case 6:
        return <Result/>;
      default:
        return;
    }
  };
  const updateProgress = (page) => {
    if (page < 1) return;
    if (page === 1 || page === 2) {
      return (page = 20);
    } else if (page === 3) {
      return (page = 40);
    } else if (page === 4) {
      return (page = 60);
    } else if (page === 5) {
      return (page = 100);
    }
  };
  return (
    <>
      {pageSwitch()}
      {errorMessage && <div><Alert description="Please, fill out the forms" message="Form error" type="warning" closable /></div>}
      {create_simulator_error && (
        <div>
          {" "}
          <Alert description="Please, fill out the forms" message="Form error"  type="error" closable />
        </div>
      )} 
       {/* {result && <div><Alerts description={result.status} message={result.status} type="success" closable /></div>} */}


      {page === 6 ? (
        ""
      ) : (
        <div className="progress_bg">
          <div className="btnPrev" onClick={goToPrevPage}>
            <button>
              <RiArrowLeftFill className="icon" />
            </button>
          </div>
          <div className="progress_bar" style={{ width: "100%" }}>
            <Progress
              percent={updateProgress(page)}
              size="small"
              showInfo={false}
              status="active"
              max={100}
            />
            <small
              style={{
                position: "relative",
                color: "#E5E5E5",
                top: "-0.8rem",
                marginLeft: "5px",
              }}
            >
              {updateProgress(page)}% Complete Keep it up!
            </small>
          </div>
          {page === 5 ? ( <div className="btnNext" onClick={(e) => handleSubmit(e)}>
            <button>
            {create_simulator_loading ? (
            <div
              className="spinner-border text-dark"
              role="status"
              style={{ width: "2rem", height: "2rem" }}
            >
               <span className="visually-hidden">Loading...</span>
           </div>
            ) : <>
            {/* <Link to={`/college-simulator-result/${simulation_id}`}> */}
            <span className="next_question">SUBMIT</span>{" "}
            <RiArrowRightFill className="icon" />
            {/* </Link> */}
            </>
            }
              
            </button>
          </div>):( <div className="btnNext" onClick={goToNextPage}>
            <button>
              <span className="next_question">NEXT QUESTION</span>{" "}
              <RiArrowRightFill className="icon" />
            </button>
          </div>)}
         
        </div>
      )}
    </>
  );
};

export default AnswerForm;
