import { Card, Skeleton, Row, Col } from 'antd';
const Loader = ({loading}) => {
    return <>
         <Row gutter={16}>
            <Col span={12}>
                <Card loading={loading}>
                    <Skeleton loading={loading} avatar active>
                    </Skeleton>
                </Card>
            </Col>
      <Col span={12}>
                <Card loading={loading}>
                    <Skeleton loading={loading} avatar active>
                    </Skeleton>
                </Card>
      </Col>

    </Row>

    </>
}

export default Loader;