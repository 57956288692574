import CountCareerForStudents from "./CountStudentCareerSimulations"
import CareerSimulator from "./CareerSimulatorLists";
import "./career.css";
import { useDispatch, useSelector } from "react-redux";
import {useEffect} from "react";
import {StudentCareerSimulationsDetails} from "../Redux/actions/GetListOfStudentsCareerSimulations";
import {countStudentCareerSimulations} from "../Redux/actions/CountStudentCareerSimulations";

const CareerlandingPage = () => {
    const dispatch = useDispatch();

    const {careerSimulationDetails = {},loading_student_details} = useSelector(state => state.careerDetails);
    let {data = []} = careerSimulationDetails;

    const {career_loading_count, careerSimulationsCounts = {}} = useSelector(state => state.studentsCareerSimulationCount);
    const {data:count_career} = careerSimulationsCounts;

    useEffect(() => {
        dispatch(StudentCareerSimulationsDetails())
        dispatch(countStudentCareerSimulations())
    }, [dispatch])

    return (
        <div className="container container-width">
            <CountCareerForStudents  career_loading_count={career_loading_count} info={count_career}/>
            <CareerSimulator studentCareers={data} loading={loading_student_details}/>
        </div>
    )
}

export default CareerlandingPage