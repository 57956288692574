import "./collegeAppl.css"
import "../../CounsellorDashboard/student.css"
// import { FaSearch } from "react-icons/fa"
import { useState } from "react"
import { useHistory, Link } from "react-router-dom"
// import { Modal } from "antd"
import OngoingAplication from "../../StudentDashboard/OngoingApplication"
import Paginate from "../../../../../../Utils/helper/Paginate"

const ListCollegeApplTable = ({college_appl_data = [], unpaid_data = []}) => {
    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(6);
    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = unpaid_data?.slice(indexOffirstStudent, indexOflastStudent);
    const count_paid = unpaid_data.filter(appl => appl.status === "paid")
    const history = useHistory()
    // const [searchtext, setSearchtext] = useState("");
    const gotoCollegeAppl = () => {
        history.push("/get-started-college")
    }
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
          <>
 
<div className="container">
    <div className="row">
        <div className="col-md-8 mt-4">
            <div className="list">
                    <h5 style={{ color: "#0480FC", fontWeight: "800", letterSpacing: "2px" }}>MY COLLEGE APPLICATION LISTS</h5>
            </div>
        </div>

        {/* <div className="col-md-4 mt-4">
             <div className="search-box" title="search schools">
                    <button className="btn-search"><FaSearch/></button>
                    <input type="text"  onChange={(e) => setSearchtext(e.target.value)} value={searchtext} placeholder="Search schools" className="input-search"/>
                </div>
        </div> */}
    </div>
</div>

<div className="container" style={{ zIndex: "10" }}>
    <div className="row mx-auto mb-3 mt-5 shadow float-end">
        <div className="col-md-6 fixed-bottom ">
               <button className="btn btn-success shadow float-end m-5" onClick={gotoCollegeAppl} style={{ backgroundColor: "#0480FC", border: "none" }}>Restart College Application</button>
        </div>
    </div>
</div>

<div className="container">
    <div className="row mx-auto">
        <div className="col-md-12">
            {count_paid?.length > 0 ? <OngoingAplication data={count_paid}/> : null}
              
        </div>
    </div>
</div>

<div className="container mt-5 mb-3">
    <div className="row college_appl_result">
        {
            // college_appl_data && college_appl_data?.map(({college_applied = [], first_choice_course = [], id, stage }) => 
            
            currentStudents && currentStudents?.map(({colleges = [], courses = [], id, degree, status }) => 
            <div className="col-md-4 mb-2" key={id}>
            <div className="card p-3 mb-2" style={{height: '350px'}}>
                <div className="d-flex justify-content-between mb-2">
                    <div className="d-flex flex-row align-items-center">
                        <div className="icon"> <i className="bx bxl-mailchimp"></i> </div>
                        <div className="ms-2 c-details">
        <h6 className="mb-0">Status</h6>
                        </div>
                    </div>
                    {
                        status === "pending" ? <div className="badge"> <span>{status}</span> </div>
                        : <div className="paid"> <span>{status}</span> </div>
                    }
                </div>

                <div className="d-flex justify-content-between">
                    <div className="d-flex flex-row align-items-center">
                        <div className="icon"> <i className="bx bxl-mailchimp"></i> </div>
                        <div className="ms-2 c-details">
        <h6 className="mb-0">Desired level of study</h6>
                        </div>
                    </div>
                    {
                    degree && <div className="study_level"> <span>{degree.degree_name}</span> </div>
                    }
                </div>
                <div className="mt-3 mb-2 mx-2">
                    <h6 style={{ fontWeight: "bold" }}>COLLEGE(S)</h6>
                    <h6 className="heading">
                    {
                        colleges.map(({college_name = ''}) => 
                      <li>{college_name}</li>
                            )
                        }
                     </h6>
                  
                </div>

                <div className="mt-2 mb-2 mx-2">
                    <h6 style={{ fontWeight: "bold" }}>COURSE(S)</h6>
                    <h6 className="heading">
                      {
                        courses && courses?.map(({name}) => 
                      <li>{name}</li>
                            )
                        }
                     </h6>
                </div>
                <div className="d-flex flex-row justify-content-center mt-auto view_result" style={{width: '100%'}}>
                    <Link to={`/college_appl-simulator/result/${id}`} style={{backgroundColor: '#0476E8'}}>
                        <div className="mt-auto text-center">
                        {/* <div className="mt-3" style={{ cursor: "pointer" }}> */}
                            <span className="text2 p-5 m-3" style={{ cursor: "pointer", color:'#fff' }}>VIEW FULL RESULT</span> 
                            {/* </div> */}
                        </div>
                    </Link>
            </div>
            </div>
        </div>
            )
        }
                
    </div>
       {
                unpaid_data?.length >= 5 ?<Paginate studentPerPage={studentsPerPage} totalStudents={unpaid_data?.length} paginate={paginate} /> : null
            }

</div>

    </>
      )
}

export default ListCollegeApplTable;