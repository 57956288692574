import "../../../../SimulatorPage/simulator.css"

const ApplicantDetails = ({info}) => {
    return (
        <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                    <div className="card application_details">
                        <div className="card-body">
                            <div className="detail_no">
                                {info ? info : "0"}
                            </div>
                            <div className="application_info">
                               NUMBER OF STUDENTS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                    <div className="card application_details">
                        <div className="card-body">
                            <div className="detail_no">
                                N/A
                            </div>
                            <div className="application_info">
                              ONGOING APPLICATIONS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                    <div className="card application_details">
                        <div className="card-body">
                            <div className="detail_no">
                                N/A
                            </div>
                            <div className="application_info">
                                accepted APPLICATIONS
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mt-3">
                    <div className="card application_details">
                        <div className="card-body">
                            <div className="detail_no">
                                N/A
                            </div>
                            <div className="application_info">
                                rejected APPLICATIONS
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    )
}

export default ApplicantDetails;