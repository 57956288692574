import { GET_STUDENT_COLLEGE_APPL_LIST_FAIL, GET_STUDENT_COLLEGE_APPL_LIST_REQUEST, GET_STUDENT_COLLEGE_APPL_LIST_SUCCESS } from "../../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/getListOfCollegeApplistConstant";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance"

export const getListOfStudentCollegeApplSimulation = (student_id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_STUDENT_COLLEGE_APPL_LIST_REQUEST});
        const {
         tokenAuth: { tokenInfo },
       } = getState();
       const config = {
         headers: {
           Authorization: `Bearer ${tokenInfo.data.userToken}`,
           "Content-Type": "application/json",
         },
       };
       const { data } = await axiosInstance.get(
        //  `/product/college_applications/getStudentCollegeApplist/${student_id}`,
        `/product/getUsersUnpaidAppl/${student_id}`,
         config
       );
       dispatch({type: GET_STUDENT_COLLEGE_APPL_LIST_SUCCESS, payload: data});
       if(data.status === "failed"){
        dispatch({
          type: GET_STUDENT_COLLEGE_APPL_LIST_FAIL,
          payload: data.reason.message
        });
       }
    } catch (error) {
     dispatch({
         type: GET_STUDENT_COLLEGE_APPL_LIST_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }    
  }