import { UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAIL } from "../../../../../../../Utils/constants/StudentProfileContants/updateProfileConstants";

export const profileUpdateReducer = (state={}, action) => {
    switch(action.type){
        case UPDATE_PROFILE_REQUEST:
            return {
                profile_update_loading: true
            }
        case UPDATE_PROFILE_SUCCESS:
            return {
                ...state,
                profile_update_loading: false,
                updated_profile: action.payload
            }
        case UPDATE_PROFILE_FAIL:
            return {
                ...state,
                profile_update_loading: false,
                update_profile_error: action.payload
            }
        default:
            return state;
    }
}