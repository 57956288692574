import SimulatorHeader from "../../SimulatorHeader";
import "../../simulator.css";
import CollegeApplicationContent from "./GetStartedContent";

const CollegeApplicationGettingStarted = () => {
  return (
    <div className="simulator_wrapper">
      <SimulatorHeader logo="trav4college_logo.png" />
      <CollegeApplicationContent />
    </div>
  );
};

export default CollegeApplicationGettingStarted;
