import { GET_PARENT_WARD_CONNECTION_REQUEST, GET_PARENT_WARD_CONNECTION_SUCCESS, GET_PARENT_WARD_CONNECTION_FAIL } from "../../../../../../../Utils/constants/HighschoolConstants/ParentConstants/GetWardConstants";

export const GetParentWardLinkReducer = (state={}, action) => {
    switch (action.type) {
        case GET_PARENT_WARD_CONNECTION_REQUEST:
          return {
              get_link_loading: true
          }
        case GET_PARENT_WARD_CONNECTION_SUCCESS:
            return {
                ...state,
                get_link_loading: false,
                getward: action.payload
             }  
        case GET_PARENT_WARD_CONNECTION_FAIL:
                return {
                    ...state,
                    get_link_loading: false,
                    get_link_error: action.payload
                 }      
    
        default:
            return state;
    }
}