import {AppstoreOutlined, DatabaseOutlined} from "@ant-design/icons";
import { HiOutlineUser } from "react-icons/hi";
import { IoBriefcaseOutline } from "react-icons/io5";
import { CgPhone } from "react-icons/cg";
// import { FaRegEnvelope } from "react-icons/fa";
import { Link } from "react-router-dom"
import { Menu } from "antd";
import { useState } from "react";

const ParentSidebar = () => {
    let localKeys = localStorage.getItem("keys");
    const [keys, setKeys] = useState({current: localKeys});
    const handleClick = e => {
        setKeys({current: e.key})
         localStorage.setItem("keys", e.key)
    };
    const {current} = keys;

    return (
      <Menu theme="light" mode="inline"  selectedKeys={[current]}>
            <Menu.Item key="overview" onClick={handleClick} icon={<AppstoreOutlined />}>
            <Link to="/dashboard/">Overview </Link>
            </Menu.Item>
            <Menu.Item key="profile" onClick={handleClick} icon={<HiOutlineUser />}>
                <Link to="/dashboard/profile">Profile</Link>
            </Menu.Item>
            <Menu.Item key="parent-student" onClick={handleClick} icon={<IoBriefcaseOutline />}>
                <Link to="/dashboard/parent/student">Students</Link>
            </Menu.Item>
            <Menu.Item key="student-application" onClick={handleClick} icon={<DatabaseOutlined />}>
                <Link to="/dashboard/parent/applications">College Applications</Link>
            </Menu.Item> 
            {/* <Menu.Item key="6" icon={<FaRegEnvelope />}>
                <Link to="/parent/chat">Chat <span className="badges_chat">2</span></Link>
            </Menu.Item> */}
              <Menu.Item onClick={handleClick} key="help-desk" icon={<CgPhone />}>
                <Link to="/dashboard/help-desk">Help & Support</Link>
              </Menu.Item> 
        </Menu>
    )
}

export default ParentSidebar;
