import { Modal, Form, Input } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ParentWardLinkAction } from '../Redux/actions/ParentWardAction';
import "../css/modal.css"
const ParentJumbotron = ({
    jumbotron_header,
    jumbotron_content,
    jumbotron_btn,
    jumbotron_img,
  }) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const [code, setCode] = useState()
    const {ward = {}} = useSelector(state => state.parentWard)
    const [visible, setVisible] = useState(false);
    const [message, setMessage] = useState("");
    const {status} = ward;
    const [confirmLoading, setConfirmLoading] = useState(false);
    console.log("ward", status) 
    
    const showModal = () => {
      setVisible(true);
    };
    
    const handleChange = (e) => {
      const profile_code = e.target.value
      setCode(profile_code);
    }
    
    const dataTosubmit = {profile_code: code, affiliation_type: "parent"}
    
    const handleOk = (e) => {
      e.preventDefault();
      setConfirmLoading(true);
      form.validateFields().then(values => { 
        dispatch(ParentWardLinkAction(dataTosubmit))
        form.resetFields();
        hideModal();
          })
          .catch((err) => {
            if (err) {
              setConfirmLoading(false);
            }
            setVisible(true);
          });
    };

    const hideModal = () => {
      if (status === "success") {
         setMessage("Successfully affiliated!");
        setConfirmLoading(false);
        setVisible(false);

      } else {
        setMessage("Failed");
        setConfirmLoading(false);
        setVisible(false);

      }   
    }

    // setTimeout(() => {
    //   setMessage("")
    // }, 3000);
  
    const handleCancel = () => {
      setVisible(false);
    };

    return (
        <>
      <div className="new_jumbotron">
        <div className="row">
          <div className="col-md-8 col-12">
            <h2>{jumbotron_header}</h2>
            <p>{jumbotron_content}</p>
            <div className="btns">
              <div onClick={showModal} style={{color: '#0480fc'}} className="btn btn-secondary btn-application">
                {jumbotron_btn}
              </div>{" "}
            </div>
          </div>
          <div className="col-md-4 col-12 new_user">
            <img
              src={jumbotron_img}
              alt="dashboard-pics"
              className="d-xs-none d-sm-none d-md-block "
            />
          </div>
        </div>
      </div>

      <Modal
        title="WARD/STUDENT RELATIONSHIP"
        visible={visible}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        width={350}
        onCancel={handleCancel}
        cancelButtonProps={{
            style: {
              display: "block",
              height: "40px",
              background: "none !important"
            },
          }}
        okButtonProps={{
            style: {
              display: "block",
              height: "40px",
              marginLeft: "2px" ,
              marginTop: "10px",
              marginBottom: "10px"
            },
          }}  
      >
  <p className="text-center" style={{ fontWeight: "bolder", fontSize: "17px" }}>{message}</p>
            <Form
                form={form}
                layout="vertical"
                name="form_in_modal"
                initialValues={{
                modifier: 'public',
                }}
            >
                <Form.Item
                name="profile_code"
                label="Student ID"
                rules={[
                    {
                    required: true,
                    message: 'Please input the student ID!',
                    },
                ]}
                >
                    <Input type="text" onChange={handleChange}  value={code} name="code"/>
                </Form.Item>
            </Form>
      </Modal>

      </>
    );
  };
  
  export default ParentJumbotron;


