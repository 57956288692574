import {Link} from "react-router-dom";
import arc from "../../../../../assets/career-arc.png";
import bag from "../../../../../assets/bag.png";
import pathArrow from "../../../../../assets/Path-arrow.png";
import "./career.css";
import StartCareerSimulation from "../StudentDashboard/StartCareerSimulation";
import { useSelector, useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import {getStudentCareerSimulationLists} from "../../../../../Redux/actions/SimulationActions/CareerSimulationActions/careerSimulationListsAction"
import {latestStudentCareerSimulation} from "../../../../../Redux/actions/SimulationActions/CareerSimulationActions/getLatestCareerSImulationAction"
import ListCareerSimulationTable from "./CareerSimulationLists";
import { AuthContext } from "../../../AuthContext/AuthContext";
import { Layout } from "antd";
import Loader from "../../../LoadersPage";
const { Content } = Layout;

const CareerSimulatorFirstPage = () => {
      const dispatch = useDispatch()
      const {careerSimulationsCounts} = useContext(AuthContext)
    //   const {data:userDetail={}} = user;
    //   const {id} = userDetail;
      const {data} = careerSimulationsCounts  !== undefined && careerSimulationsCounts;
      const {career_list_loading, careerSimulationList_error, careerSimulationList = []} = useSelector(state => state.studentCareerLists)
      const {latestCareerSimulation = {}} = useSelector(state => state.latestCareerSimulation)
      const {data:latestCareer = {}} = latestCareerSimulation;
      const {simulation_id, type = [], suggested_careers = []} = latestCareer;
      let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
      // let checkCareerResultType;
      const checkCareerResult = localResult?.data.latestCareerSimulation;
      const student_id = localResult?.data.id;
      if (checkCareerResult?.message) {
        // console.log(checkCareerResult);
      } else {
        // checkCareerResultType = localResult?.data.latestCareerSimulation.type;

      }
     
   useEffect(() => {
       dispatch(getStudentCareerSimulationLists(student_id))
   }, [dispatch, student_id])
   useEffect(() => {
    dispatch(latestStudentCareerSimulation(student_id))
}, [dispatch, student_id])

    return (
        <Layout className="ant-design-guide">

        <Content style={{ margin: "24px 16px 0" }}>
        <div
        className="site-layout-background-career"
        style={{
          minHeight: "100vh",
        }}
      >
          {career_list_loading ? 
              <Loader loading={career_list_loading}/>
                    : 
                  <>
                  {/* {user_error && <p className="text-center text-danger">{user_error}</p> } */}
                  {careerSimulationList_error && <p className="text-center text-danger">{careerSimulationList_error.message}</p>}
          <div className="row">
              {(data > 0 || !checkCareerResult?.message) ? 
              <> 
                <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12">
                    <div className="card career_card">
                        <div className="card-body">
                            <div className="career_simualte">
                                <h4 className="career_title">MY CAREER SIMULATION</h4>
                                <p className="result_header">RESULT</p>
                                <h1 className="realistic_header" style={{textTransform: "capitalize"}}>
                                  {
                                        type && type?.slice(0, 4)?.map(type => type.personality_type).join(", ")}
                                 </h1>
                                {
                                    suggested_careers?.slice(0,4)?.map((career, index) =>
                                <p className="career_text" key={index}><b>{career.careers}</b></p>
                                      )
                                }
                                <Link to={`/career-simulator/result/${simulation_id}`} className="career_recommendations">View Full Result</Link>
                                <Link to="/get-started-career" className="career_recommendations ms-4">{data > 0 ?"Restart Simulation" : "Start Simulation"}</Link>
                            </div>
                        </div>
                        <img src={arc} alt="career_arc" className="bg_career_img"/>
                        <div className="career_bag"><img src={bag} alt="bag"/></div>
                    </div>
                </div>

                {/* <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                  <div className="restart_simulation">
                      <div className="simulation_text">{data > 0 ? <Link to="/get-started-career">Restart<br /> Simulation</Link>: <Link to="/get-started-career">Start<br /> Simulation</Link>}</div>
                      <div className="path_arrow"><Link to="/get-started-career"><img src={pathArrow} alt="arrow_path" /></Link></div>
                  </div>
              </div> */}
              {career_list_loading ? <Loader loading={career_list_loading}/> : <ListCareerSimulationTable career_simulation_list={careerSimulationList}/>}
              
                </>
                :
            <>
                <StartCareerSimulation start_career="start_career"/>

                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <div className="restart_simulation shadow">
                    <div className="simulation_text">{data > 0 ? <Link to="/career-simulator">Restart<br /> Simulation</Link>: <Link to="/get-started-career">Start<br /> Simulation</Link>}</div>
                    <div className="path_arrow"><Link to="/get-started-career"><img src={pathArrow} alt="arrow_path" /></Link></div>
                </div>
            </div>
              </>
             }
            
       
          </div> 
                  </>
                  }
           
          
      </div>
      </Content>
      </Layout>
    )
}

export default CareerSimulatorFirstPage
