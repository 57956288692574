import {STUDENT_COLLEGE_LATEST_SIMULATION_FAIL, STUDENT_COLLEGE_LATEST_SIMULATION_REQUEST, STUDENT_COLLEGE_LATEST_SIMULATION_SUCCESS} from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/collegeLatestSimulationConstants";
import axiosInstance from "../../../../Utils/helper/axiosInstance";

export const getCollegeLatestSimulation = (student_id) => async (dispatch, getState) => {
    try {
       dispatch({type: STUDENT_COLLEGE_LATEST_SIMULATION_REQUEST})
       const {
           tokenAuth: { tokenInfo }
       } = getState();

    const config = {
        headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json"
        }
    };

    const data = await axiosInstance.get(
        `/highschools/student/latestCollegeSimulation/${student_id}`,
        config
    )
    if(data.status === "failed"){
        return dispatch({type: STUDENT_COLLEGE_LATEST_SIMULATION_FAIL, payload: data.reason.message})
    }
        dispatch({type: STUDENT_COLLEGE_LATEST_SIMULATION_SUCCESS, payload: data})
    
    } catch (error) {
        dispatch({type: STUDENT_COLLEGE_LATEST_SIMULATION_FAIL, payload: error.response && error.response.data.message
            ? error.response.data.message
            : error.message})
    }
}