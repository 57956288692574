import { useContext, useEffect } from "react";
import { Layout } from "antd";
import PersonalInfo from "./PersonalInfo";
import SecondNavbar from './SecondNavbar';
// import EducationalInfo from './EducationalInfo';
// import StudentDocuments from './StudentsDocument';
import Alerts from "../../../../../Alerts/Alert";
import { AuthContext } from "../../../../AuthContext/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "../../../../../../Redux/actions/userAction";
import { getProfileAction } from "../Redux/actions/GetProfileAction";
import Loader from "../../../../LoadersPage";
// import { Redirect } from 'react-router-dom';

const { Content } = Layout;

const ProfileLayout = () => {
const dispatch = useDispatch()
   const {user_loading, userError, highschools} = useContext(AuthContext);
   let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
   const {data: local_data = {}} = localResult;
   const {id:local_id} = local_data;
   const { user = {}} = useSelector(state => state.getUser)
  //  const {profile = {}} = useSelector(state => state.profileGet)
    useEffect(() => {
      dispatch(userAction())
      dispatch(getProfileAction(local_id))
    }, [dispatch, local_id])

    return (  
      <Layout className="ant-layout">
             {/* {user && user === "parent" && <Redirect to="/parent" />}
             {user && user === "counsellor" && <Redirect to="/counsellor" />} */}
             {user_loading && <Loader loading={user_loading}/>}
             {user_loading && <Loader loading={user_loading}/>}
               <Content  style={{ margin: "24px 16px 0" }}>
               { user_loading && user_loading ?  (
                 <Loader loading={user_loading}/>
         ) :  userError ? <Alerts description={userError} type="error" message={userError} closable /> : 
            <>
                  {
                      (user || localResult) ? <> 
                      <SecondNavbar user={user ? user : localResult} highschools={highschools}/>
                      <PersonalInfo user={user ? user : localResult} />
                      </> : 
                      null
                  } 
                    {/* <SecondNavbar user={user} highschools={highschools}/>
                    <PersonalInfo user={user} /> */}
                    {/* <EducationalInfo  info={user} />
                    <StudentDocuments profile={user} /> */}
                  </>
               }
              </Content>
         </Layout>
              )
}

export default ProfileLayout