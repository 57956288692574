import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import {AuthContext} from "../../../AuthContext/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { getStudentProfileAction } from "../../../../../Redux/actions/getProfileAction";
import { HighschoolCounsellorAction } from "../../../../../Redux/actions/HighschoolActions/HighschoolCounsellorAction";
import { Modal, Alert, Spin, message} from "antd";

import { SendResultAction } from "../../Redux/action/Notifications/SendResultAction";
const ResultDetails = ({user_details = {}, simulation_id, profile_type}) => {
    const {user_loading, user = {}} = useContext(AuthContext)
    const {first_name, last_name} = user_details;
    const dispatch = useDispatch();
    const user_id = user_details?.id;
    const {profile = {}} = useSelector(state => state.profileGet)
    const {highschool_counsellor = {}} = useSelector(state => state.highschoolCounsellor)
    const {data = {}} = profile;
    const myprofile = data?.high_school_profile
    const result_link = window.location.href;
    const {data:user_data = {}} = user;
    const {highSchoolProfile = {}} = user_data;
    const {user_type} = highSchoolProfile;
    const {data:counsellor = []} = highschool_counsellor;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [counsellors, setCounsellors] = useState("");
    const {sent_result = {}, result_loading} = useSelector(state => state.sendNotification)
    const {profile:student_user = {}} = useSelector(state => state.profileGet)
    const {data:student_user_data = {}} = student_user;
    const highshool_student_prof = student_user_data && student_user_data?.high_school_profile
    console.log(user_type)
    const handleChange = (e) => {
      setCounsellors(e.target.value);
    }
    const handlePageChange = (e) => {
      e.preventDefault();
      window.location.href="/college-simulator/questionnaire"
      //  history.push('/career-simulator/questionnaire')
    }
    const handleResultShareToCounsellor = () => {
      const names = counsellor && counsellor?.find(emails => emails.user.email === counsellors);
      const dataToSubmit = {
        "guardian_name": names.user.first_name,
        "link_to_be_sent": result_link,
        "guardian_email": counsellors,
        "notification_type": "college",
        "guardian_type": "counsellor",
        "simulation_id": simulation_id,
        "type": "notification",
        "amount": "",
        "token": "",
      }
      dispatch(SendResultAction(dataToSubmit))
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    
    const handleResultShare = (type) => {
      if (type === "counsellor") {
          setIsModalVisible(true);
       } else if (type === "student_parent") {
            if (!highshool_student_prof?.parent_email || !highshool_student_prof?.parent_name) {
              message.error('Student profile is not yet complete!');
            } else {
                const dataToSubmit = {
                  "guardian_name": highshool_student_prof?.parent_name,
                  "link_to_be_sent": result_link,
                  "guardian_email": highshool_student_prof?.parent_email,
                  "notification_type": "college",
                  "guardian_type": "parent",
                  "simulation_id": simulation_id,
                  "type": "notification",
                  "amount": "",
                  "token": "",
                }
                dispatch(SendResultAction(dataToSubmit));
                console.log("sent_result", sent_result);
            }
       } else {
        if (!myprofile?.parent_name || !myprofile?.parent_email) {
          message.error('Update your profile!');
        } else {
            const dataToSubmits = {
              "guardian_name": myprofile?.parent_name,
              "link_to_be_sent": result_link,
              "guardian_email": myprofile?.parent_email,
              "notification_type": "college",
              "guardian_type": "parent",
              "simulation_id": simulation_id,
              "type": "notification",
              "amount": "",
              "token": "",
            }
          dispatch(SendResultAction(dataToSubmits));
          console.log("sent_result", sent_result);
       }
  }
}

    useEffect(() => {
      dispatch(getStudentProfileAction(user_id))
      dispatch(HighschoolCounsellorAction())
    }, [dispatch, user_id])

  return (
    <div className="container">
      {user_loading ? <div
                className="spinner-border text-dark"
                role="status"
                style={{ width: "5rem", height: "5rem", marginLeft: "auto", marginRight:"auto", textAlign: "center", display: "block" }}
                >
              <span className="visually-hidden">Loading...</span>
            </div> :
            <>
            {/* {
            userError && <p className="text-danger text-center">{userError}</p>} */}
            <div className="success_result">
              <h4 className="username_message">{first_name} {last_name} College Simulations</h4>
              <h2 className="success_message">Completed Successfully</h2>
              <p className="success_note">
                Here are our Reccommendations to help you begin your college journey.
                Based on what you've shared there might be some areas you want to
                boost and we have some fantastic new tools to help you do that!
              </p>
              {
                result_loading ? <Spin className="mt-3 mb-3"/> : sent_result?.status && <div className="mx-auto mt-3 mb-3" style={{width: "50%"}}>
                        
                <Alert
      description="Notification sent!"
      type="success"
      showIcon
      closable
      />
              </div>
              }
              
              {/* {} */}
              <div className="cta-links">
                {
                   profile_type ? 
                   <>
                      {
                         profile_type === "counsellor" ?  <Link to="#" onClick={()=>handleResultShare('student_parent')} className="btn_success">
                   SEND RESULT TO STUDENT PARENTS
                 </Link>  : <>
                 {
                    profile_type === "parent" ? " " :
                    <>
                    {
                      profile_type === "student" ? 
                      <>
                   
                        <Link to="#" onClick={()=>handleResultShare('counsellor')} className="btn_primary">
                          SPEAK TO A COUNSELOR
                        </Link>

                        <Link to="#" onClick={()=>handleResultShare('parent')} className="btn_success">
                          SEND RESULT TO PARENTS
                        </Link>

                        <Link to="#" onClick={handlePageChange} className="btn_default">
                          RESTART SIMULATION
                        </Link>
                      </> : null
                    }
                    </>
                 }
                 </>
                      }
               
                 </>
                 : ""
                }

                <Modal title="Counsellors" width={350} visible={isModalVisible} onOk={handleResultShareToCounsellor} onCancel={handleCancel}
                 cancelButtonProps={{
                  style: {
                    display: "block",
                    height: "40px",
                    background: "none !important"
                  },
                }}
              okButtonProps={{
                  style: {
                    display: "block",
                    height: "40px",
                    marginLeft: "2px" ,
                    marginTop: "10px",
                    marginBottom: "10px"
                  },
                }} 
                >
               <select class="form-select" style={{ width: "100%" }} aria-label="Default select example" onChange={handleChange}>
                  <option selected>Choose a counsellor to send result to</option>
                  {
                    counsellor && counsellor?.map((user = {}) => 
                      <>
                         <option value={user.user.email}>{user.user.first_name} {user.user.last_name}</option>
                      </>
                    )
                  }
                 
                </select>
                  </Modal>

                  
              </div>
              <div className="scroll_down">
                <span>Scroll down</span>
                <span>
                  <IoIosArrowDown />
                </span>
              </div>
            </div>
            </>
          }
  
    </div>
  );
};

export default ResultDetails;