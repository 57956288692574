import "./dashboardLayout.css";
const OngoingAplication = ({data = []}) => {
    // const counts = data.reduce( (acc, val) => acc + val.college_applied.length, 0);
    return (
        <div className="card card-width col-md-4">
              <div className="card-body">
                  <span>{data?.length}</span>
                  <p style={{ fontSize: "15px" }}>Ongoing Paid College Applications</p>
              </div>
        </div>
    )
}

export default OngoingAplication;