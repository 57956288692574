import {GET_HIGHSCHOOL_STUDENT_COUNT_REQUEST, GET_HIGHSCHOOL_STUDENT_COUNT_SUCCESS, GET_HIGHSCHOOL_STUDENT_COUNT_FAIL} from '../../../../../../../Utils/constants/HighschoolConstants/Counsellor/StudentsUnderCounsellorConstants';

export const countStudentUnderCounsellorReducer = (state={}, action) => {
    switch(action.type){
        case GET_HIGHSCHOOL_STUDENT_COUNT_REQUEST:
            return {
                count_loading: true
            }
        case GET_HIGHSCHOOL_STUDENT_COUNT_SUCCESS:
            return {
                ...state,
                count_loading: false,
                number_of_students: action.payload
            }
        case GET_HIGHSCHOOL_STUDENT_COUNT_FAIL:
            return {
                ...state,
                count_loading: false,
                number_of_students_error: action.payload
            }
        default:
            return state
    }
}