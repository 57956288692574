import { GET_LATEST_CAREER_SIMULATION_FAIL, GET_LATEST_CAREER_SIMULATION_REQUEST, GET_LATEST_CAREER_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/latestCareerSimulationConstants"

export const latestCareerSimulationReducer = (state = {}, action) => {
     switch (action.type) {
         case GET_LATEST_CAREER_SIMULATION_REQUEST:
            return {
                loading_latest_career: true
            }
        case GET_LATEST_CAREER_SIMULATION_SUCCESS:
            return {
                ...state,
                loading_latest_career: false,
                latest_career_success: true,
                latestCareerSimulation: action.payload
            }
        case GET_LATEST_CAREER_SIMULATION_FAIL:
             return {
                 ...state,
                 loading_latest_career: false,
                 latest_career_success: false,
                 latest_career_error: action.payload
             }
         default:
             return state
     }
}