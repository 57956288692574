import { GET_WARD_COLLEGE_APPL_FAIL, GET_WARD_COLLEGE_APPL_REQUEST, GET_WARD_COLLEGE_APPL_SUCCESS } from "../../../../../../../Utils/constants/ParentConstant/GetListOfCollegeApplConstant";

export const ParentWardCollegeApplReducer = (state={}, action) => {
    switch (action.type) {
        case GET_WARD_COLLEGE_APPL_REQUEST:
          return {
              loading: true
          }
        case GET_WARD_COLLEGE_APPL_SUCCESS:
            return {
                ...state,
                loading: true,
                wardCollegeApplist: action.payload
             }  
        case GET_WARD_COLLEGE_APPL_FAIL:
                return {
                    ...state,
                    loading: true,
                    error: action.payload
                 }      
    
        default:
            return state;
    }
}