import {GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_REQUEST, GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_SUCCESS, GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_FAIL} from "../../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CollegeConstants/GetListOfStudentsCollegeSimulations"

export const collegeSimulationDetailReducer = (state={}, action)  => {
    switch(action.type){
        case GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_REQUEST:
            return {loading_student_details: true}
        case GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_SUCCESS:
            return {
                ...state,
                college_loading_detail: false,
                collegeSimulationDetails: action.payload,
                college_detail_success: true
            }
        case GET_LIST_OF_COLLEGE_SIMULATION_STUDENTS_FAIL:
            return {
                ...state,
                college_loading_detail: false,
                college_detail_error: action.payload,
                college_detail_success: false
            }
        default:
            return state
    }
}