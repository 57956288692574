import {
    GET_GUARDIAN_MESSAGE_REQUEST,
    GET_GUARDIAN_MESSAGE_SUCCESS,
    GET_GUARDIAN_MESSAGE_FAIL,
  } from "../../../../../../Utils/constants/HighschoolConstants/Message/GetGuardianMessageConstant";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const GetMessageAction =
    () => async (dispatch, getState) => {
      try {
        dispatch({ type: GET_GUARDIAN_MESSAGE_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.get(
          "/highschools/notifications/get_message",
          config
        );
        dispatch({ type: GET_GUARDIAN_MESSAGE_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: GET_GUARDIAN_MESSAGE_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };