import "../../simulator.css";
import { RiArrowRightFill } from "react-icons/ri";
import { Link } from "react-router-dom";
const CollegeSimulatorContent = () => {
  return (
    <div className="college_simulator_content">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-12 col-md-7 col-lg-7 col-xl-7 ">
          <h2>
            Welcome to the
            <br /> College Simulator
          </h2>
          <p>
          By answering simple questions accurately, the college simulator helps you determine the best school for you after graduation from high school.
          </p>
          <div className="mt-4 button_getstarted_go">
            <Link to="/college-simulator/questionnaire">
              Get Started {""}{" "}
              <span>
                <RiArrowRightFill />
              </span>
            </Link>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-12 col-md-5 col-lg-5 col-xl-5 image_part">
          <img src="/Saly-15.png" alt="Saly 15" />
        </div>
      </div>
    </div>
  );
};

export default CollegeSimulatorContent;
