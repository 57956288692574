import "../../../simulator.css";
import {Spin} from "antd";
import { FaTimes } from "react-icons/fa";
const Region = ({
  handleChange,
  continents,
  continent,
  remove,
  setContinent,
  loading,
  error,
  filterName,
}) => {
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 1</p>
      <h2>Select a Region you are interested in Studying</h2>
      {error && <div>{error}</div>}
      {loading ? (
        <Spin size="large" />
      ) : (
        <select
          className="form-select form-select-lg"
          aria-label=".form-select-lg example"
          onChange={(e) => handleChange(e, continent, setContinent)}
        >
          <option>Select region</option>
          {continents?.data &&
            continents?.data?.map((cont, index) => (
              <option key={index} value={cont.id}>
                {cont.continent}
              </option>
            ))}
        </select>
      )}
      <ul className="my-4 list_tags">
        {continent && continent?.map((item, index) => {
          const values = filterName(item, continents.data);
          return (
            <li key={index}>
              {values && values.map((c) => c.continent)}{" "}
              <span onClick={() => remove(item, continent, setContinent)}>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          );
        })}
      </ul>

      <span className="text-center text-def">
        You can select up to <strong>2 regions</strong>
      </span>
    </div>
  );
};

export default Region;
