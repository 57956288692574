import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
// import {Link} from "react-router-dom"
import { HIGHSCHOOL_LIVE_URL } from "../../Utils/constants/Local_URL";
// import { FaGraduationCap } from "react-icons/fa";
// import { CgPhone } from "react-icons/cg";
import { BiLogIn } from "react-icons/bi";
import { logout } from "../../Redux/actions/authActions";
import StudentSidebar from "./User_Sidebars/Student_Sidebar";
import ParentSidebar from "./User_Sidebars/Parent_Sidebar";
import CounsellorSidebar from "./User_Sidebars/Counsellor_Sidebar";

const { Sider } = Layout;

const SideBar = ({user_type, userPrivateKey}) => {
  const dispatch = useDispatch();
  
  const logoutHandler = () => {
    dispatch(logout())
    window.location.href = HIGHSCHOOL_LIVE_URL;
    localStorage.setItem("keys", "overview");
    localStorage.removeItem('parent_')
  }

  return (
    <Sider breakpoint="lg" collapsedWidth="0" style={{ zIndex: "2" }}>
      <div className="dashboard_logo">
        {/* <FaGraduationCap style={{ fontSize: "20px", marginLeft: "15px" }} /> */}
        <img
          src="/trav4college_logo.png"
          alt="company_logo"
          className="company_icon_logo"
          style={{ width: '180px' }}
        />
      </div>

      {
        userPrivateKey && userPrivateKey ? <div className="dashboard_identity">
        <p style={{ fontSize: "18px", marginLeft: "15px", color: "#000000" }}>ID: <span style={{ color: "#0480FC" }}> {userPrivateKey} </span> </p>
      </div> : null
      }    
      <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
        {/* Sidebars */}
        {user_type && (user_type === "student" || user_type === "") && <StudentSidebar/>}
        {user_type && (user_type === "parent" || user_type === "") && <ParentSidebar/>}
        {user_type && (user_type === "counsellor" || user_type === "") && <CounsellorSidebar/>}

      </Menu>
      <div className="logout" onClick={logoutHandler}>
        <BiLogIn /> Logout
      </div>
    </Sider>
  );
};

export default SideBar;
