import SimulatorHeader from "../../SimulatorHeader";
import "../../simulator.css";
import CollegeSimulatorContent from "./CollegeSimulatorContent";

const CollegeSimulatorGettingStarted = () => {
  return (
    <div className="simulator_wrapper">
      <SimulatorHeader logo="trav4college_logo.png" />
      <CollegeSimulatorContent />
    </div>
  );
};

export default CollegeSimulatorGettingStarted;
