import { IoBriefcaseOutline, IoSchoolSharp } from "react-icons/io5";
import {AppstoreOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom"
import { Menu } from "antd";
import { CgPhone } from "react-icons/cg";
import { HiOutlineUser } from "react-icons/hi";
import { useState } from "react";
import { AiFillRead } from "react-icons/ai";
import { BiBookBookmark } from "react-icons/bi";
import { FaRegNewspaper } from "react-icons/fa";

const StudentSidebar = () => {
    let localKeys = localStorage.getItem("keys");
    const [keys, setKeys] = useState({current: localKeys});
    const handleClick = e => {
        setKeys({current: e.key})
         localStorage.setItem("keys", e.key)
    };
   const {current} = keys;

  return (
      <Menu theme="light"
      selectedKeys={[current]}
      mode="inline">

        <Menu.Item key={current && current === "overview" ? current : "overview"} onClick={handleClick} icon={<AppstoreOutlined />}>
            <Link to="/dashboard/">Overview </Link>
        </Menu.Item>
        <Menu.Item key={current && current === "profile" ? current : "profile"} onClick={handleClick} icon={<HiOutlineUser />}>
           <Link to="/dashboard/profile">Profile</Link>
        </Menu.Item>  
        <Menu.Item key={current && current === "my-career" ? current : "my-career"} onClick={handleClick} icon={<IoBriefcaseOutline />}>
            <Link to="/dashboard/my-career">My Career</Link>
        </Menu.Item> 
        <Menu.Item key={current && current === "my-college" ? current : "my-college"} onClick={handleClick} icon={<IoSchoolSharp />}>
           <Link to="/dashboard/my-college">My College</Link>
        </Menu.Item> 
        <Menu.Item key={current && current === "my-college-applications" ? current : "my-college-applications"} onClick={handleClick} icon={<BiBookBookmark />}>
           <Link to="/dashboard/my-college-application">College Applications</Link>
        </Menu.Item> 
        <Menu.Item key={current && current === "my-scholarships" ? current : "my-scholarships"} onClick={handleClick} icon={<AiFillRead />}>
           <Link to="/dashboard/my-scholarships">Scholarships</Link>
        </Menu.Item> 
        <Menu.Item icon={<CgPhone />} onClick={handleClick} key={current && current === "help-desk" ? current : "help-desk"}>
            <Link to="/dashboard/help-desk">Help & Support</Link>
        </Menu.Item> 
        <Menu.Item icon={<FaRegNewspaper />} onClick={handleClick} key={current && current === "news" ? current : "news"}>
            <Link to="/dashboard/news">News</Link>
        </Menu.Item> 
        </Menu>
    )
}

export default StudentSidebar;