import { GET_COLLEGE_TUITION_RANGE_FAIL, GET_COLLEGE_TUITION_RANGE_REQUEST, GET_COLLEGE_TUITION_RANGE_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/GetTuitionRangeConstants";

export const fetchTuitionInNaira = (state = {}, action) => {
    switch (action.type) {
      case GET_COLLEGE_TUITION_RANGE_REQUEST:
        return { tuitions_loading: true };
      case GET_COLLEGE_TUITION_RANGE_SUCCESS:
        return { tuitions_loading: false, range_converter: action.payload, tuitions_success: true };
      case GET_COLLEGE_TUITION_RANGE_FAIL:
        return { tuitions_loading: false, tuitions_error: action.payload, tuitions_success: false };
      default:
        return state;
    }
  };
  