import { GET_LIST_OF_HIGHSCHOOL_REQUEST, GET_LIST_OF_HIGHSCHOOL_SUCCESS, GET_LIST_OF_HIGHSCHOOL_FAIL } from "../../../Utils/constants/HighschoolConstants/listHighschoolsConstant";

export const listHighSchoolReducer = (state = { highschools: [] }, action) => {
    switch (action.type) {
      case GET_LIST_OF_HIGHSCHOOL_REQUEST:
        return { 
            loading: true 
            };
      case GET_LIST_OF_HIGHSCHOOL_SUCCESS:
        return { 
            loading: false, 
            highschools: action.payload, 
            success: true 
           };
      case GET_LIST_OF_HIGHSCHOOL_FAIL:
        return { 
            loading: false, 
            error: action.payload, 
            success: false 
          };
      default:
        return state;
    }
  };
  