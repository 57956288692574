import {Route} from "react-router-dom"
import ParentOverview from "../../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/DashboardLayout/index";
import StudentParentPage from "../../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/StudentLayout/index";
import ApplicantTable from "../../../components/Pages/DashboardLayoutPage/Contents/ParentsDashboard/Applications/Index";
import NotificationPage from "../../../components/Pages/DashboardLayoutPage/Contents/NotificationPage";
import MessagePage from "../../../components/Pages/DashboardLayoutPage/Contents/messages";

const ParentRoutes = () => {
    return (
        <>
        <Route exact path="/dashboard/"><ParentOverview/></Route>
        <Route exact path="/dashboard/parent/student"><StudentParentPage/></Route>
        <Route exact path="/dashboard/parent/applications"><ApplicantTable/></Route>
        <Route exact path="/dashboard/notifications"><NotificationPage/></Route>
        <Route exact path="/dashboard/messages"><MessagePage/></Route>
        </>
    )
}

export default ParentRoutes;