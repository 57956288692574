import { AUTH_TOKEN_REQUEST, AUTH_TOKEN_SUCCESS, AUTH_TOKEN_FAIL, USER_LOGOUT } from "../../Utils/constants/authConstant"
import axiosInstance from '../../Utils/helper/axiosInstance';

export const getAuthToken = (platform_token) => async (dispatch) => {
    try {
        dispatch({type: AUTH_TOKEN_REQUEST});
        const config = {
             headers: {
                 "Content-Type": "application/json"
             }
        };

        const { data } = await axiosInstance.post(
            `platformAuth/${platform_token}/highschool`,
            config
        );
       if (data.status === "success") {
           localStorage.setItem("tokenInfo", JSON.stringify(data))
           setTimeout(() => dispatch({ type: AUTH_TOKEN_SUCCESS, payload: data}), 500);
       } else {
           dispatch({ type: AUTH_TOKEN_FAIL, payload: data })
       } 
    } catch (error) {
        dispatch({ type: AUTH_TOKEN_FAIL, payload: error })
    }
};

export const logout = () => async (dispatch) => {
    localStorage.removeItem("tokenInfo");
    dispatch({type: USER_LOGOUT})
}
