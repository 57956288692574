import { GET_WARD_COLLEGE_APPL_COUNT_FAIL, GET_WARD_COLLEGE_APPL_COUNT_REQUEST, GET_WARD_COLLEGE_APPL_COUNT_SUCCESS } from "../../../../../../../Utils/constants/ParentConstant/GetCountOfCollegeAppl";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";

export const ParentWardGetCollegeApplCountAction = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_WARD_COLLEGE_APPL_COUNT_REQUEST})
        const {
            tokenAuth: { tokenInfo }
        } = getState();
 
        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        }
 
        const {data} = await axiosInstance.get(
            "/highschools/wards/collegeApplCount",
            config
        );
       dispatch({type: GET_WARD_COLLEGE_APPL_COUNT_SUCCESS, payload: data});
       if(data.status === "failed"){
         dispatch({
           type: GET_WARD_COLLEGE_APPL_COUNT_FAIL,
           payload: data.reason.message
         });
        }
    } catch (error) {
     dispatch({
         type: GET_WARD_COLLEGE_APPL_COUNT_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }
 }