import { GET_COLLEGE_APPLICATION_BY_ID_REQUEST, GET_COLLEGE_APPLICATION_BY_ID_SUCCESS, GET_COLLEGE_APPLICATION_BY_ID_FAIL } from "../../../../../../Utils/constants/SimulationConstants/CollegeApplicationConstants/GetCollegeApplByIdConstants";
import axiosInstance from "../../../../../../Utils/helper/axiosInstance";

export const getStudentCollegeApplById = (id) => async (dispatch, getState) => {
    try {
        dispatch({type: GET_COLLEGE_APPLICATION_BY_ID_REQUEST})
        const {
            tokenAuth: { tokenInfo }
        } = getState();
 
        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        }
 
        const {data} = await axiosInstance.get(
            // `/product/college_applications/${id}`,
            `/product/getUserUnpaidAppl/${id}`,
            config
        );
       dispatch({type: GET_COLLEGE_APPLICATION_BY_ID_SUCCESS, payload: data});
       if(data.status === "failed"){
         dispatch({
           type: GET_COLLEGE_APPLICATION_BY_ID_FAIL,
           payload: data.reason.message
         });
        }
    } catch (error) {
     dispatch({
         type: GET_COLLEGE_APPLICATION_BY_ID_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }
 }