import { CAREER_QUESTION_FAIL, CAREER_QUESTION_REQUEST, CAREER_QUESTION_SUCCESS } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CareerQuestionnaire/CareerQuestionConstants";

export const careerQuestionReducer = (state={questions: []}, action) => {
      switch(action.type){
          case CAREER_QUESTION_REQUEST:
              return {loading: true}
          case CAREER_QUESTION_SUCCESS:
              return {...state, loading: false, questions: action.payload, success: true}
          case CAREER_QUESTION_FAIL:
              return {...state, loading: false, error: action.payload, success: false}
          default:
              return state;
      }
}