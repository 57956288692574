import axiosInstance from "../../../../../../../../Utils/helper/axiosInstance"
import {STUDENT_COLLEGE_SIMULATION_COUNT_REQUEST, STUDENT_COLLEGE_SIMULATION_COUNT_SUCCESS, STUDENT_COLLEGE_SIMULATION_COUNT_FAIL} from "../../../../../../../../Utils/constants/HighschoolConstants/Counsellor/CollegeConstants/CountStudentCollegeSimulationConstant"

export const getStudentsCollegeSimulationCount = () => async (dispatch, getState) => {
    try {
      dispatch({ type: STUDENT_COLLEGE_SIMULATION_COUNT_REQUEST });
      const {
        tokenAuth: { tokenInfo },
      } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${tokenInfo.data.userToken}`,
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        `/highschools/aggregates/count_studentcollegesimulations`,
        config
      );
      dispatch({ type: STUDENT_COLLEGE_SIMULATION_COUNT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: STUDENT_COLLEGE_SIMULATION_COUNT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };