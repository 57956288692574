import {GET_USER_REQUEST, GET_USER_SUCCESS, GET_USER_FAIL} from "../../Utils/constants/userConstants";
import axiosInstance from "../../Utils/helper/axiosInstance";

export const userAction = () => async (dispatch, getState) => {
    try {
        dispatch({type: GET_USER_REQUEST});

        const {
            tokenAuth: { tokenInfo },
        } = getState();

        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            },
        };

        const { data } = await axiosInstance.get(
            "/user",
            config
        );

        dispatch({type: GET_USER_SUCCESS, payload: data})
    } catch (error) {
        const message = error.response && error.response.data ? error.response.data : error.message;
        dispatch({type: GET_USER_FAIL, payload: message});
    }
}