import { PaystackButton } from "react-paystack";
import "./style.css"
import { useDispatch, useSelector } from "react-redux";
import { useState, useContext } from "react";
import { AuthContext } from "../../../AuthContext/AuthContext";
import { useParams } from "react-router-dom";
import { PayWithPayStack } from "../../../../../Redux/actions/Payments/PaymentAction";
import { Spin } from "antd";

const PayWithStack = () => {
  const dispatch = useDispatch();
  const {amount_paid, prod_id} = useParams();
  const {payment_result = {}, payment_loading, payment_success:initial_success} = useSelector(state => state.payment);
  const {data:payment_data = {}} = payment_result;
  const {reference} = payment_data;
  const {user = {}} = useContext(AuthContext)
  const {data:user_data = {}} = user;
  const {phone, email, first_name:name} = user_data;
  const padded_amount = amount_paid?.toString().padEnd(+amount_paid.length, '000');
  const amounts = +padded_amount
  // let dollarUSLocale = Intl.NumberFormat('en-US');
  // const history = useHistory()
  const [codeReference, setCodeReference] = useState("")
  const publicKey = process.env.REACT_APP_PAYSTACK_PUBLIC_KEY;
  
  const handleCompletePayment = (reference) => {
      return reference
  }
  let myReference = reference;
// console.log("reference", myReference);
  
  const componentProps = {
    publicKey,
    text: `Pay N${amount_paid?.toLocaleString()}`,
    email,
    amount: amounts,
    reference: myReference ,
    metadata: {
      name,
      phone,
    },
    onSuccess: (reference) => handleCompletePayment(reference)
    ,
    onClose: console.log("button closed!"),
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
      dispatch(PayWithPayStack({product_id: Number(prod_id)}))
      if (payment_loading) {
        return codeReference;
      } else {
        setCodeReference(reference)
        
      }
    };

    return (
        <>
          <h5>Welcome here!</h5>
          { 
              initial_success ? <PaystackButton {...componentProps} className="paystack-button"/>
              : 
              <button style={{backgroundColor: "#0480FC", color: "#ffffff", border: "1px solid #0480FC"}} onClick={(e)=>handleSubmit(e)}>
                  { 
                      payment_loading ? <Spin style={{color: "#ffffff"}}/>
                      : 
                      "Subscribe Now"
                  }
              </button>
          }
          {/* <PaystackButton {...componentProps} className="mb-5 paystack-button"/> */}
        </>
    )
}

export default PayWithStack