import { useSelector, useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getScholarshipsAction } from "../../../../../Redux/actions/SimulationActions/ScholarshipActions/GetScholarshipsAction";
import "./style.css";
import Loader from "../../../LoadersPage";
import { Link } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { Button, Input, Pagination } from "antd";
import { GetPageAction } from "../../../../../Redux/actions/GetPageActions/GetPageAction";
// import SearchScholarships from "./SearchScholarships";
import HeroPage from "./Hero";
import { AiOutlineClockCircle, AiFillInstagram, AiOutlineTwitter } from "react-icons/ai";
import { FcStackOfPhotos, FcGraduationCap } from "react-icons/fc";
import { BsFillBookmarkFill, BsFacebook, BsLinkedin } from "react-icons/bs";

const ScholarshipLists = () => {
    const dispatch = useDispatch();
    const {get_scholar_loading, get_scholar_profile = {}} = useSelector(state => state.getScholarships);
    const {get_page = {}, get_page_loading} = useSelector(state => state.pages);
    const {data: scholar_data = {}} = get_scholar_profile
    const {data:unsubscribed_scholarship = [], total, hasScholarshipProduct, per_page} = scholar_data;
    const {data: page_data = {}} = get_page
    const {data: subscribed_scholarship = [], current_page} = page_data
    const [searchtext, setSearchtext] = useState("");
    useEffect(() => {
        dispatch(getScholarshipsAction())
    }, [dispatch])

    const onChange = useCallback((pageNumber) => {
        dispatch(GetPageAction(pageNumber))
    }, [dispatch])

    useEffect(() => {
        onChange(1)
    }, [onChange])

    return (
        <>
         <div className="container">
             <div className="row mb-5">
                    <div className="col-md-6">
                    <span>
                    <Input placeholder="Search..." prefix={<AiOutlineSearch />} onChange={(e) => setSearchtext(e.target.value)} value={searchtext} style={{height: '40px', borderRadius: '50px'}}/>
                    </span>
                    </div>
                    <div className="col-md-4">

                    </div>
                    <div className="col-md-2">
                    <span className="bg-white">  
                            <Button className="bg-white" style={{backgroundColor:'white', height: '40px', color: 'white', borderRadius: '50px', textTransform: 'capitalize'}}>
                            <BsFillBookmarkFill /> {" "} My Scholarships
                            </Button>
                    </span>
                    </div>
             </div>
         {/* <SearchScholarships/> */}
         <HeroPage/>

             <div className="row p-4 mt-4">
                
                 {
                     get_scholar_loading ? <Loader loading={get_scholar_loading}/>
                     :
                     hasScholarshipProduct ? <>
                            {
                                subscribed_scholarship && subscribed_scholarship?.filter((scholarship) => {
                                    if (searchtext === "") {
                                        return scholarship;
                                    } else if (scholarship.college_name?.toLowerCase().includes(searchtext.toLowerCase()) || scholarship.scholarship_name?.toLowerCase().includes(searchtext.toLowerCase())){
                                        return scholarship;
                                    }
                                    return false;
                                })?.map(({college_name, id, compensation, external_link, deadline,needs_admission, contact_details, scholarship_name, program_types, scholarship_type, information}) => 
                        <div className="col-md-12 scholarship_card mb-5" key={id}>
                        {/* <div className="card shadow"> */}
                               <div style={{width: '100%', display: 'table'}} className="p-3">
                                   <div style={{display: 'table-row'}}>
                                       <div style={{width: '70%', display: 'table-cell'}} className="scholarship_header_tag mb-5">
                                       {scholarship_name && scholarship_name}
                                       {/* <Link to={`/dashboard/scholarships/view-scholarship/${id}`}>{scholarship_name && scholarship_name}</Link>  */}
                                       </div>
                                       <div style={{float:'right',display: 'table-cell'}}>
                                       
                                       <div className="row">
                                                   <div className="col-md-12">
                                                           <span className="ongoing_scholars"><BsFillBookmarkFill size="20px"/></span>
                                                   </div>
                                               </div>
                                          
                                       </div>
                                   </div>
                                   </div> 
   
                                   <div style={{width: '100%', display: 'table'}} className="p-3">
                                       <div style={{display: 'table-row'}}>
                                           <div style={{width: '70%',display: 'table-cell'}} className="scholarship__p">
                                           {information && information}
                                           </div>
                                           <div style={{float:'right',display: 'table-cell'}}>
                                               <div className="row">
                                                   <div className="col-md-12">
                                                           <span className="ongoing_scholars">Admission: {needs_admission && needs_admission}</span>
                                                   </div>
                                               </div>
                                               <div className="row mt-3">
                                                   <div className="col-md-12">
                                                           <AiOutlineClockCircle style={{color: '#047BF2'}}/><span className="ongoing_scholars_days mx-2">{deadline && deadline}</span>
                                                   </div>
                                               </div>
                                           </div>
                                       </div>
                                   </div> 
   
                                   <div className="row p-3">
                                        {/* <div className="col-md-3">
                                             <FcStackOfPhotos/> <span className="mx-3">Product Design</span>
                                        </div> */}
                                        <div className="col-md-4">
                                             <FcGraduationCap/> <span className="mx-3" style={{textTransform: 'capitalize'}}>{program_types && program_types}</span>
                                        </div>
                                        <div className="col-md-4">
                                             <FcStackOfPhotos/> <span className="mx-3">{college_name && college_name}</span>
                                        </div>
                                        {/* <div className="col-md-3">
                                             <FcGraduationCap/> <span className="mx-3">Undergraduate</span>
                                        </div> */}
                                        
                                   </div>  
   
                                   <div className="row p-3">
                                     <div className="col-md-12">
                                           Share
                                           <div className="row">
                                               <div className="col-md-10 mb-4">
                                                   <span className="mx-1 share_facebook"> <BsFacebook/></span>
                                                   <span className="mx-1 share_instagram" > <AiFillInstagram/></span>
                                                   <span className="mx-1 share_twitter"> <AiOutlineTwitter/></span>
                                                   <span className="mx-1 share_linkedIn"> <BsLinkedin/></span>
                                               </div>
   
                                               <div className="col-md-2">
                                                   <a href={external_link} rel="noreferrer" target="_blank">Scholarship details</a>
                                               </div>
                                           </div>
                                     </div>
                                       </div>                    
                    </div>
                        )
                            }
                             
                             {
                                 get_page_loading ? <Loader loading={get_page_loading}/> 
                                 :

                                <Pagination style={{  display: "flex", justifyContent: "center" }} simple showQuickJumper className="mb-5 mt-4" defaultPageSize={per_page} defaultCurrent={current_page} total={total} onChange={onChange} />
                             }
                     </> :
                        <>
                        {
                                           unsubscribed_scholarship && unsubscribed_scholarship?.filter((scholarship) => {
                                            if (searchtext === "") {
                                                return scholarship;
                                            } else if (scholarship.college_name?.toLowerCase().includes(searchtext.toLowerCase()) || scholarship.scholarship_name?.toLowerCase().includes(searchtext.toLowerCase())){
                                                return scholarship;
                                            }
                                            return false;
                                        })?.map(({college_name, id, compensation, external_link, deadline,needs_admission, contact_details, scholarship_name, program_types, scholarship_type, information}) => 
                                <div className="col-md-12 scholarship_card mb-5" key={id}>
                                {/* <div className="card shadow"> */}
                                       <div style={{width: '100%', display: 'table'}} className="p-3">
                                           <div style={{display: 'table-row'}}>
                                               <div style={{width: '70%', display: 'table-cell'}} className="scholarship_header_tag mb-5">
                                               {scholarship_name && scholarship_name}
                                               {/* <Link to={`/dashboard/scholarships/view-scholarship/${id}`}>{scholarship_name && scholarship_name}</Link>  */}
                                               </div>
                                               <div style={{float:'right',display: 'table-cell'}}>
                                               
                                               <div className="row">
                                                           <div className="col-md-12">
                                                                   <span className="ongoing_scholars"><BsFillBookmarkFill size="20px"/></span>
                                                           </div>
                                                       </div>
                                                  
                                               </div>
                                           </div>
                                           </div> 
           
                                           <div style={{width: '100%', display: 'table'}} className="p-3">
                                               <div style={{display: 'table-row'}}>
                                                   <div style={{width: '70%',display: 'table-cell'}} className="scholarship__p">
                                                   {information && information}
                                                   </div>
                                                   <div style={{float:'right',display: 'table-cell'}}>
                                                       <div className="row">
                                                           <div className="col-md-12">
                                                                   <span className="ongoing_scholars">Admission: {needs_admission && needs_admission}</span>
                                                           </div>
                                                       </div>
                                                       <div className="row mt-3">
                                                           <div className="col-md-12">
                                                                   <AiOutlineClockCircle style={{color: '#047BF2'}}/><span className="ongoing_scholars_days mx-2">{deadline && deadline}</span>
                                                           </div>
                                                       </div>
                                                   </div>
                                               </div>
                                           </div> 
           
                                           <div className="row p-3">
                                                {/* <div className="col-md-3">
                                                     <FcStackOfPhotos/> <span className="mx-3">Product Design</span>
                                                </div> */}
                                                <div className="col-md-3">
                                                     <FcGraduationCap/> <span className="mx-3" style={{textTransform: 'capitalize'}}>{program_types && program_types}</span>
                                                </div>
                                                <div className="col-md-3">
                                                     <FcStackOfPhotos/> <span className="mx-3">{college_name && college_name}</span>
                                                </div>
                                                {/* <div className="col-md-3">
                                                     <FcGraduationCap/> <span className="mx-3">Undergraduate</span>
                                                </div> */}
                                                
                                           </div>  
           
                                           <div className="row p-3">
                                             <div className="col-md-12">
                                                   Share
                                                   <div className="row">
                                                       <div className="col-md-10 mb-4">
                                                           <span className="mx-1 share_facebook"> <BsFacebook/></span>
                                                           <span className="mx-1 share_instagram" > <AiFillInstagram/></span>
                                                           <span className="mx-1 share_twitter"> <AiOutlineTwitter/></span>
                                                           <span className="mx-1 share_linkedIn"> <BsLinkedin/></span>
                                                       </div>
           
                                                       <div className="col-md-2">
                                                           <a href={external_link} rel="noreferrer" target="_blank">Scholarship details</a>
                                                       </div>
                                                   </div>
                                             </div>
                                               </div>                    
                            </div>
                        )
                        }

                        <div className="col-md-12 row2-container mb-5">
                            <div className="box orange">
                               <Link to="/dashboard/subscriptions" style={{ textDecoration: "none" }}>  <button className="btn col-md-12 mb-2">Subscribe to receive more scholarships</button></Link>
                                <p>Get access to full tuition scholarships suited for your financial needs</p>
                            </div>
                    </div>
                    </>
                 }

                 </div>
         </div>
        </>
    )
}

export default ScholarshipLists;