import OngoingAplication from "./OngoingApplication";
import CompletedAplication from "./CompletedApplications";
import MySimulation from "./MySimulation";
import CareerSimulationResult from "./CareerSimulationResult";
import StartCareerSimulation from "./StartCareerSimulation";
import NewUserBlogSnippet from "./BlogSnippet";
import Overview from "./Overview";
import {useContext, useEffect} from "react";
import { AuthContext } from "../../../AuthContext/AuthContext";
// import CollegeApplicationResult from "./CollegeApplicationResult";
import StartCollegeApplication from "./StartCollegeApplication"
// import NoCollegeSimulation from "./NoCollegeSimulation";
import StartCollegeSimulation from "./StartCollegeSimulation";
import { useDispatch, useSelector } from "react-redux";
import { GetCollegeApplicationsAction } from "../../../SimulatorPage/CollegeApplicationSimulator/Redux/actions/GetCollegeApplicationsAction";
import CollegeApplicationResult from "./CollegeApplicationResult";
import Loader from "../../../LoadersPage";

const StudentDashboard = () => {
    const {user = {}, careerSimulationsCounts = {}, collegeSimulations,loading, profile, success, error, collegeSimulationsCounts = {}, user_loading } = useContext(AuthContext)
    const dispatch = useDispatch();
    const {college_appl_loading, collegeApplList = {}} = useSelector(state => state.college_appl)
    const {data:college_applied = {}} = collegeApplList;
    const {data:college_data = []} = college_applied;
    let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
    const checkCollege = localResult.data.latestCollegeSimulation
    const checkCareer = localResult.data.latestCareerSimulation
    const {data:college_counts} = collegeSimulationsCounts
    const {data:career_counts} = careerSimulationsCounts
    
    useEffect(() => {
       dispatch(GetCollegeApplicationsAction())
    }, [dispatch])

    return (
    <>
    {
        (user_loading) ? <Loader loading={user_loading}/> 
        :
        <div className="container"> 
                {
                
                ((college_counts && college_counts > 0) || (career_counts && career_counts > 0) || (checkCollege && !checkCollege.message ) || (checkCareer && !checkCareer.message)) ? <>
                {
                   ((college_counts && college_counts > 0) || (checkCollege && !checkCollege.message)) ?<> <div className="simulate-app mb-4">
                    <div className="ongoing-application">
                        <OngoingAplication data={college_data}/>
                    </div>
                    <div className="completed-application">
                        <CompletedAplication />
                    </div>
                  </div> </> : null
                }
                  
                  <div className="my__simulation_result mb-4">
                        {
                        (checkCollege && !checkCollege?.message) || (college_counts > 0) ? <MySimulation  collegeSimulations={collegeSimulations}/> : 
                        <StartCollegeSimulation start_college="start_college_overview"/> 

                        }
                    
                   </div>
                   <div className="row career_simulate_college">
                      {
                        (checkCareer && !checkCareer?.message) || (career_counts > 0) ? <> <CareerSimulationResult/> </>  : <StartCareerSimulation/>
                        }
                   
                        {
                                  college_data && college_data.length >= 1 ? <CollegeApplicationResult loading={college_appl_loading} college_data={college_data}/>
                                  :
                                  <StartCollegeApplication start_college="start_college_overview"/>
                        }

                        {/* {
                            (checkCollege.message) || (college_counts < 0) ? <>  
                                <StartCollegeSimulation start_college="start_college_overview"/> 
                           
                            </>  :  
                                ""
                            
                                    
                        } */}
                         
                    </div>
                
                </> : collegeSimulationsCounts && collegeSimulationsCounts.data > 0 ? <>
                  <div className="simulate-app">
                    <div className="ongoing-application">
                        <OngoingAplication data={college_data}/>
                    </div>
                    <div className="completed-application">
                        <CompletedAplication />
                    </div>
                  </div>
                  <div className="my__simulation_result">
                     <MySimulation  collegeSimulations={collegeSimulations}/>
                   </div>
                   <div className="row career_simulate_college">
                        {
                            careerSimulationsCounts && careerSimulationsCounts?.data > 0 ? 
                            <CareerSimulationResult /> : <StartCareerSimulation />
                        }
                        
                        {
                        (collegeSimulationsCounts && collegeSimulationsCounts.data > 0) ?
                            <> 
                            {
                                college_data && college_data.length > 0 ? <CollegeApplicationResult college_data={college_data}/>
                                 :
                                <StartCollegeApplication start_college="start_college_overview"/>
                            }
                            
                            </>  
                          :  
                            <>
                            <StartCollegeSimulation start_college="start_college_overview"/> 
                            </>
                        }
                        
                    </div>
                
                </>  : 
                <Overview info={user?.data} college_data={college_data} loading={loading} success={success} profile={profile} error={error} collegeSimulationsCounts={collegeSimulationsCounts} careerSimulationsCounts={careerSimulationsCounts} user_detail={user}/>

                }
                  
                    <NewUserBlogSnippet />
                </div>
    }
    </>
    )
}

export default StudentDashboard