import { Link } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { useState, useContext } from "react";
import { AuthContext } from "../../../AuthContext/AuthContext"

const SuccessResult = () => {
  const {user = {}, user_error} = useContext(AuthContext)
  const [userError, setUserError] = useState(user_error);
  const {data = {}} = user;
  const {first_name} = data

  setTimeout(() => {
    setUserError("");
}, 3000);

  return (
    <div className="container">
      {userError ?  <p className="text-danger text-center">{userError}</p>: 
      <div className="success_result">
        <h4 className="username_message">{first_name}, here are your results</h4>
        <h2 className="success_message">Completed Successfully</h2>
        <p className="success_note">
          Here are our Reccommendations to help you begin your college journey.
          Based on what you've shared there might be some areas you want to
          boost and we have some fantastic new tools to help you do that!
        </p>
        <div className="cta-links">
          <Link to="/speak-to-a-counsellor" className="btn_primary">
            SPEAK TO A COUNSELOR
          </Link>

          <Link to="/send-result" className="btn_success">
            SEND RESULT TO PARENTS
          </Link>

          <Link to="/simulate-college/choices" className="btn_default">
            RESTART SIMULATION
          </Link>
        </div>
        <div className="scroll_down">
          <span>Scroll down</span>
          <span>
            <IoIosArrowDown />
          </span>
        </div>
      </div>
      }
    </div>
  );
};

export default SuccessResult;
