import { STUDENT_COLLEGE_SIMULATION_FAIL, STUDENT_COLLEGE_SIMULATION_REQUEST, STUDENT_COLLEGE_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CollegeSimulationConstants/getCollegeSimulationConstant"

export const getCollegeSimulationReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_COLLEGE_SIMULATION_REQUEST:
            return {
                college_loading: true
            }
        case STUDENT_COLLEGE_SIMULATION_SUCCESS:
            return {
                ...state,
                 college_loading: false, collegeSimulations: action.payload, college_success: true
                }
        case STUDENT_COLLEGE_SIMULATION_FAIL:
            return {
                ...state, 
                loading: false,
                college_error: action.payload, college_success: false
                }
        default:
            return state;
    }
}