import { GET_SCHOLARSHIP_REQUEST, GET_SCHOLARSHIP_SUCCESS, GET_SCHOLARSHIP_FAIL } from "../../../../Utils/constants/SimulationConstants/Scholarships/GetScholarShipsConstants";

export const getScholarshipsReducer = (state = {}, action) => {
    switch (action.type) {
      case GET_SCHOLARSHIP_REQUEST:
        return { get_scholar_loading: true };
      case GET_SCHOLARSHIP_SUCCESS:
        return {
          ...state,
          get_scholar_loading: false,
          get_scholar_profile: action.payload,
          get_scholar_success: true,
        };
      case GET_SCHOLARSHIP_FAIL:
        return {
          ...state,
          get_scholar_loading: false,
          get_scholar_success: false,
          get_scholar_error: action.payload,
        };
     default:
         return state
    }
  };