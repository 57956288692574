import {
    COUNT_GUARDIAN_NOTIFICATION_FAIL,
    COUNT_GUARDIAN_NOTIFICATION_REQUEST,
    COUNT_GUARDIAN_NOTIFICATION_SUCCESS,
  } from "../../../../../../Utils/constants/HighschoolConstants/Notification/CountGuardianNotificationConstants";
  
  export const CountNotificationReducer = (state = {}, action) => {
    switch (action.type) {
      case COUNT_GUARDIAN_NOTIFICATION_REQUEST:
        return { count_notifs_loading: true };
      case COUNT_GUARDIAN_NOTIFICATION_SUCCESS:
        return {
          ...state,
          count_notifs_loading: false,
          count_notifs: action.payload,
          count_notifs_success: true
        };
      case COUNT_GUARDIAN_NOTIFICATION_FAIL:
        return {
          ...state,
          count_notifs_loading: false,
          count_notifs_error: action.payload,
          count_notifs_success: false
        };
      default:
        return state;
    }
  };