import "../css/parent.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParentWardGetAction } from "../Redux/actions/GetWardsActions";
import { Link } from "react-router-dom";
import { Modal, Card } from "antd"
import Loader from "../../../../LoadersPage";

const StudentParentPage = () => {
    const dispatch = useDispatch()
    const {getward = {}, get_link_loading} = useSelector(state => state.getParentWard)
    const {data = []} = getward;

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [studentId, setStudentId] = useState();

    const showModal = (firstname, lastname, studentid) => {
      setIsModalVisible(true);
      setFirstName(firstname)
      setLastName(lastname)
      setStudentId(studentid)
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };

    useEffect(() => {
       dispatch(ParentWardGetAction())
    }, [dispatch])
    return (
        <> 
        {get_link_loading && <Loader loading={get_link_loading}/>}
        {get_link_loading && <Loader loading={get_link_loading}/>}
         {get_link_loading ?
           <Loader loading={get_link_loading}/> : 
           <>
          <div className="parent_page container">
            <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                    <div className="card parent_student">
                        <div className="card-body">
                            <p className="number_of_ward">{data.length}</p>
                            <p className="number_of_ward_placeholder">NUMBER OF STUDENTS</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row student_parent_detail">
                    {
                      data.length >= 1 ?
                        data && data.map(({first_name, last_name, id, highschool_college}) => 
                            <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-2">
                                <div className="card student_parent_card_view">
                                    <div className="card-body">
                                        <p className="card_view_header">{first_name} {last_name}</p>
                    <p className="card_view_text">No. of College Simulations: {highschool_college.length}</p>
                                        <div className="btn_actions">
                                            <Link to={`/dashboard/view-student-profile/${id}`}>
                                                <button className="card_view_profile">View Profile</button>
                                            </Link> 
                                            {/* <button className="card_view_application">Applications</button> */}
                                            <button className="card_view_simulation" onClick={()=>showModal(first_name,last_name, id)}>View Simulations</button>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        ) : <>
                          <Card title="NO STUDENT HERE" className="text-center">
                              <h5>PLEASE, GO TO YOUR DASHBOARD AND ADD A STUDENT TO CONTINUE</h5>
                          </Card>
                        </>
                    }

            </div>
        </div>
           </>
             
             }

      <Modal title={`${firstName} ${lastName}`}  className="simulation-modal" visible={isModalVisible}  footer={null}
       width={700}
       onCancel={handleCancel}
        cancelButtonProps={{
            style: {
              display: "block",
              height: "40px",
            },
          }}
        okButtonProps={{
            style: {
              display: "block",
              height: "40px",
              marginLeft: "2px"
            },
          }} 
        >
          <div className="row">
             <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <Card title="Career Simulation(s)" className="mb-2 shadow" extra={<Link to={`/dashboard/counsellor/careerlists/${studentId}/career`}>View all</Link>} style={{ width: 320 }}>
                    <img src="/perspaleta1_0005.png" alt="essential" className="essential"/>
                    <h5 className="py-2">List career simulation(s)</h5>
                    <p>
                      The Career Simulator helps you decide what kinds of careers you might want to explore.
                    </p>
                </Card>
              </div>

              <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <Card title="College Simulation(s)" className="shadow" extra={<Link to={`/dashboard/counsellor/collegelists/${studentId}/college`}>View all</Link>} style={{ width: 320 }}>
                        <img src="/perspaleta1_0001.png" alt="essential" className="essential"/>
                        <h5 className="py-2">List college simulation(s)</h5>
                        <p>
                        The College Simulator helps you decide what kinds of careers you might want to explore.
                        </p>
                </Card>
              </div>
          </div>
      </Modal>

        </>
    )
}

export default StudentParentPage