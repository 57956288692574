import {RiArrowRightFill} from "react-icons/ri";
import arc from "../../../../../assets/career-arc.png";
import bag from "../../../../../assets/books.png";
import {Link} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from 'react';
import Alerts from '../../../../Alerts/Alert';
import { getCollegeLatestSimulation } from "../../../../../Redux/actions/SimulationActions/CollegeSimulationAction/getCollegeLatestSimulation";
import { useContext } from "react";
import { AuthContext } from "../../../AuthContext/AuthContext";
import Loader from "../../../LoadersPage";

const MySimulation = () => {
    const dispatch = useDispatch()
    const {user = {}} = useContext(AuthContext)
    const {loading_college_latest_simulation, college_latest_simulation_error,collegeLatestSimulationsResult = {}} = useSelector(state => state.latestCollegeSimulation);
    const {data:user_data = {}} = user;
    const {id} = user_data;
    let localResult = JSON.parse(localStorage.getItem("tokenInfo"))
    const checkCollege = localResult.data.latestCollegeSimulation
    const {simulation_id:mysimulation_id, country:mycountry, course:mycourse, regions:myregions} = checkCollege
    const {data = {}} =  collegeLatestSimulationsResult;
    const {data:collegeSimulatons_data = {}} =  data;
    // console.log("collegeSimulatons_data", checkCollege)
    const {country , course , regions , simulation_id} = collegeSimulatons_data;
    // mycountry.map(({country}) => console.log("cities",country));
    
    useEffect(() => {
         dispatch(getCollegeLatestSimulation(id))
    },[dispatch,id])

    return (
        <>
            {loading_college_latest_simulation ?  
               <Loader loading={loading_college_latest_simulation}/>            
                   :
         <div className="card simulated-result-panel">

         {college_latest_simulation_error && <Alerts description={college_latest_simulation_error} type="error" message={college_latest_simulation_error.status} closable />}
         {/* {error &&  <Alerts description={error} type="error" message={error} closable />} */}
             <div className="card-body simulated-career-body">
                   <h4 className="simulated-result-header">MY COLLEGE SIMULATION</h4>
                  <div className="simulated-content row">
                      <div className="col-12 col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
                            <div className="all-city-simulated-container">
                                    <span className="all-city-simulated-header">REGION(S)</span>
                                    <span className="all-city-simulated text-uppercase">
                                      {
                                        regions ? regions && regions?.map(({continent = {}}) => continent.continent).join(", ") : myregions && myregions?.map(({continent = {}}) => continent.continent).join(", ")
                                       }
                                     </span>
                            </div>
                            {/* <div className="all-city-simulated-container">
                                <span className="all-city-simulated-header">CITY</span>
                                    <span className="all-city-simulated text-uppercase">
                                    {
                                        cities ? cities && cities.map(({city}) => city.city).join(", ") :
                                        mycities && mycities.map(({city}) => city.city).join(", ")
                                    }  
                                </span>       
                            </div> */}
                            <div className="all-city-simulated-container">
                                    <span className="all-city-simulated-header">COURSE(S)</span>
                                    <span className="all-city-simulated text-uppercase">
                                        {
                                           course ? course && course?.map(({field = {}}) => field.name).join(", "):
                                            mycourse && mycourse?.map(({field = {}}) => field.name).join(", ")
                                        }
                                    </span>
                                  
                            </div>
                            {
                                mysimulation_id && mysimulation_id ? 
                                <>
                                    <div className="simulator-buttons">
                                        <div className="recommended-btn-simulate"><Link to={`/college-simulator/result/${mysimulation_id}`}><button>VIEW FULL RESULT<RiArrowRightFill /></button></Link></div><div className="restart-btn-simulate"><button><Link to="/get-started">RESTART SIMULATION</Link></button></div>
                                    </div>
                                </> : <div className="simulator-buttons">
                                        <div className="recommended-btn-simulate"><Link to={`/college-simulator/result/${simulation_id}`}><button>VIEW FULL RESULT<RiArrowRightFill /></button></Link></div><div className="restart-btn-simulate"><button><Link to="/get-started">RESTART SIMULATION</Link></button></div>
                                    </div>
                            }
                       </div>
                       <div className="col-12 col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                       <div className="all-city-simulated-container">
                                <span className="all-city-simulated-header">COUNTRY</span>
                                <span className="all-city-simulated text-uppercase">  {
                                    country ? country && country?.map(({country = {}}) => country.country).join(", "):
                                    mycountry && mycountry?.map(({country = {}}) => country.country).join(", ")
                                }</span>
                            </div>
                            {/* <div className="all-city-simulated-container">
                                <span className="all-city-simulated-header">TUITION RANGE</span>
                                {
                                tuition_range ? tuition_range && <span className="all-city-simulated">N{tuition_range.from}-N{tuition_range.to}</span> : mytuition_range && <span className="all-city-simulated">N{mytuition_range.from}-N{mytuition_range.to}</span>
                                }
                                
                                <span className="simulated-card-placeholder">Tuition fee per Annum</span>
                            </div> */}
                            
                       </div>
                  </div>
                 
             </div>
             <img src={arc} alt="career_arc" className="bg_career_img"/>
             <div className="career_bag"><img src={bag} alt="bag"/></div>
         </div>
         }
        </>
    )
}

export default MySimulation;
