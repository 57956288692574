import { Radio } from "antd";
import "../../simulator.css";

const SpeakToACounsellor = ({
    choooseConsultant,
    speaktoconsultant,
    setSpeakToConsultant,
    onChangeConsultant,
}) => {
//   const collegeLength = selectedCollege.length
//   console.log("collegeLength", collegeLength);
  
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 3</p>
      <h2>DO YOU WISH TO SPEAK TO AN EDUCATIONAL CONSULTANT?</h2>
      <div> 
          <Radio.Group onChange={(e) => onChangeConsultant(e, setSpeakToConsultant)} value={speaktoconsultant}>
          {
              choooseConsultant.map((option, index) => 
                    <Radio value={option} key={index}>{option}</Radio>
                    )
                }
        </Radio.Group>
      </div>
    </div>
  );
};

export default SpeakToACounsellor;
