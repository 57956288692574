import { POST_SCHOLARSHIP_PROFILE_REQUEST, POST_SCHOLARSHIP_PROFILE_SUCCESS, POST_SCHOLARSHIP_PROFILE_FAIL } from "../../../../Utils/constants/SimulationConstants/Scholarships/SubmitScholarShipProfileConstant";

export const postScholarshipProfile = (state = {}, action) => {
    switch (action.type) {
      case POST_SCHOLARSHIP_PROFILE_REQUEST:
        return { scholar_loading: true };
      case POST_SCHOLARSHIP_PROFILE_SUCCESS:
        return {
          ...state,
          scholar_loading: false,
          scholar_profile: action.payload,
          scholar_success: true,
        };
      case POST_SCHOLARSHIP_PROFILE_FAIL:
        return {
          ...state,
          scholar_loading: false,
          scholar_success: false,
          scholar_error: action.payload,
        };
     default:
         return state
    }
  };