import "../CareerPage/career.css"
import {Link} from "react-router-dom"
import { useState } from "react";
import Paginate from "../../../../../Utils/helper/Paginate";

const ListCollegeSimulationTable = ({college_simulation_list = []}) => {
    const {data = []} = college_simulation_list;
    const [currentPage, setCurrentPage] = useState(1)
    const [studentsPerPage] = useState(5)
    const collegeData = data ? data : [];
    const indexOflastStudent = currentPage * studentsPerPage;
    const indexOffirstStudent = indexOflastStudent - studentsPerPage;
    const currentStudents = collegeData.length > 0 ? collegeData?.slice(indexOffirstStudent, indexOflastStudent) : [];
    // console.log("currentStudents", collegeData);
    
    const paginate = (pageNumber) => setCurrentPage(pageNumber)
    return (
          <>
          <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-5">
        {/* <div className="card career_card table-responsive mt-4"> */}
        <h4 className="career_title m-2 text-center">ALL COLLEGE SIMULATION LISTS</h4>
        <table className="table table-hover">
         <thead>
             <tr align="middle" style={{ fontWeight: "700" }}>
                 <td>COURSE(S)</td>
                 <td>ACTION</td>
             </tr>
         </thead>
         <tbody className="table_body">
             {
                currentStudents && currentStudents?.map(({course, simulation_id}) => {
                    return (
                        <tr align="middle" key={simulation_id} className="shadow-sm">
                            <td style={{textTransform: "capitalize"}}>{course && course.map(course => course.field.name).join(", ")}</td>
                            <td className="message_status">
                            <Link to={`/college-simulator/result/${simulation_id}`}><span>View Simulation</span></Link>
                            </td>
                        </tr>
                    )
                })
             }
         </tbody>
      </table> 
         {
                collegeData?.length > 5 ?
                <div className="m-3">
                 <Paginate studentPerPage={studentsPerPage} totalStudents={collegeData?.length} paginate={paginate} />
                 </div>
                  : null
         }
    {/* </div> */}
    </div>
    </>
      )
}

export default ListCollegeSimulationTable;