import { Link, useHistory } from "react-router-dom";

const WardDetails = ({getWards}) => {
     const history = useHistory()
    const goToStudentPage = () => {
        history.push("/dashboard/parent/student");
        window.location.reload()
    }
    return (
        <div className="ward_container">
           <div className="row">
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card ward_card">
                        <div className="card-body">
                            <h4>N/A</h4>
                            <p>Ongoing Applications</p>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                    <div className="card ward_card">
                        <div className="card-body">
                           <h4>N/A</h4>
                            <p>Completed Application</p>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="card ward_card mt-4">
                        <div className="card-body">
    <div className="number_student"><span className="header">STUDENTS</span><span className="header_number">No. of Students: {getWards.length}</span></div>
                            <hr />
                            {
                                getWards && getWards.slice(0,4).map(({profile_photo_url, first_name, last_name, id}) => 
                                
                                    <div className="student_detail">
<p className="student_name"><img src={profile_photo_url ? profile_photo_url : "/profile_pic.png"} style={{ borderRadius: "50px" }} alt="student pic" /> &nbsp; <span>{first_name} {last_name}</span></p>
                                     <Link to={`/dashboard/view-student-profile/${id}`}>
                                         <p className="profile__link"><button >View Profile</button></p>
                                     </Link>   
                                    </div>
                                )
                            }

                        </div>
                        {
                            getWards.length > 1 ?
                            <>
                            <Link to="" onClick={goToStudentPage}>
                            <p className="text-center" style={{ fontWeight: "800", color:"#0480FC", textDecorationLine: "underline" }}>View more</p>
                            </Link>
                            </> : null
                        }
                         
                    </div>
                </div>
    
           </div>
        </div>
    )
}

export default WardDetails;