import { STUDENT_CAREER_SIMULATION_FAIL, STUDENT_CAREER_SIMULATION_REQUEST, STUDENT_CAREER_SIMULATION_SUCCESS } from "../../../../Utils/constants/SimulationConstants/CareerSimulationConstants/getCareerSimulationConstants";

export const getCareerSimulationReducer = (state = {}, action) => {
    switch (action.type) {
        case STUDENT_CAREER_SIMULATION_REQUEST:
            return{
                loading_career: true
            }
        case STUDENT_CAREER_SIMULATION_SUCCESS:
            return{
                ...state,
                 loading_career: false,
                careerSimulations: action.payload, career_success: true
                }
        case STUDENT_CAREER_SIMULATION_FAIL:
            return{
                ...state, 
                loading_career: false, 
                career_error: action.payload, career_success: false
            }        
        default:
            return state;
    }
}