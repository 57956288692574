import "../../simulator.css";
import { FaTimes } from "react-icons/fa";
import { Alert } from "antd";

const College = ({
  handleChange,
  remove,
  filterName,
  college, //college
  setCollege, // setCollege
  college_list, // college_list
  college_load, //college_load
  college_error, //college_error
  selectedCollege, // selectedCollege
  consultant,
}) => {
  const collegeLength = selectedCollege.length
  // console.log("collegeLength", consultant);
  
  return (
    <>
    {
      consultant === "yes" ? <>
        <div className="choice_form1">
          <h2>One of our educational consultants will contact you after you are done with your application</h2>
          <p>Kindly, continue with other questions</p>
        </div>
      </> :
        <div className="choice_form1">
          <p className="questions_tag">Question 3</p>
          <h2>Select College(s) you are interested in Studying </h2>
          {college_error && <div>{college_error}</div>}
          {college_load ? (
            <div
              className="spinner-border text-dark"
              role="status"
              style={{ width: "5rem", height: "5rem" }}
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            <select
              className="form-select form-select-lg"
              aria-label=".form-select-lg example"
              onChange={(e) => handleChange(e, college, setCollege)}
            >
              <option>Select College</option>
              {college_list?.data &&
                college_list?.data?.slice(0, 30)?.map((college, index) => (
                  <option key={index} value={college.id}>
                    {college.college_name }
                  </option>
                ))}
            </select>
          )}
          <ul className="my-4 list_tags">
            {college?.map((item, index) => {
              const values = filterName(item, college_list?.data);
              return (
                <li key={index}>
                  {collegeLength >= 4 ? remove(item, college, setCollege) :  `${values && values.map((c) => c.college_name)+" "}`}
                
                  <span onClick={() => remove(item, college, setCollege)}>
                    <FaTimes className="delete_btn" />
                  </span>
                </li>
              );
            })}
          </ul>

        {collegeLength === 3 ? <Alert
          description="You can't select more than three colleges"
          type="warning"
          showIcon
          closable
        /> : ""
    } 
          <span className="text-center text-def">
            You can select up to <strong>3 colleges</strong>
          </span>
        </div>

    }
    </>
  );
};

export default College;
