// import { IoIosArrowForward } from "react-icons/io";
// import { Link } from "react-router-dom";
import { useState, useContext } from 'react';
import {AuthContext} from "../../../AuthContext/AuthContext";

const RecommendedCourses = ({course_result, course_error}) => {
    const {user_error} = useContext(AuthContext);
    const { recommended_courses  } = course_result;
    const [userError, setUserError] = useState(user_error);
    const [simulationError, setSimulationError] = useState(course_error)
    let dollarUSLocale = Intl.NumberFormat('en-US');
    
    // console.log("course_result", recommended_courses);

  setTimeout(() => {
      setUserError("");
      setSimulationError("");
  }, 3000);

  return (
    <div className="container college_result">
      <h5>Courses Recommended to you</h5>
      {userError && <p className="text-danger text-center">{userError}</p>}
         {simulationError ? <p className="text-danger text-center">{simulationError}</p>:
      
            <>
            <div className="row">
            {recommended_courses && recommended_courses.map(({name, school,description, field_tuition, study_mode, id}) => (
                <div className="col-12  recommended_col" key={id}>
                <div className="card">
                    <div className="card-body">
                    <div className="row">
                        <div className="col-md-8 col-sm-8 col-lg-8">
                        <div div className="row">
                            {" "}
                            {/* <div className="col-2 col-sm-2 col-md-2 col-lg-2">
                            <img src="/unilogo.png" alt="uni logo" className="school_logos"/>
                            </div> */}
                            <div className="col-8 col-sm-8 col-md-8 col-lg-8">
                            <h5 className="recommended_school">
                               {name}
                            </h5>
                            <p className="recommended_school_p">
                                {school}
                            </p>
                            <p className="recommended_school_p">
                               {description === null ? description : ""}
                            </p>
                            {/* <div>
                                <Link to="/details">View Details</Link>
                            </div> */}
                            </div>
                        </div>
                        </div>
                        <div className="col-md-2 col-sm-2 col-lg-2 result_page_button">
                        <p>
                            {
                                field_tuition ? 
                                <>
                                <strong>Price/</strong>${field_tuition?.slice(0, 1)?.map(tuition_range => dollarUSLocale.format(tuition_range.tuition_range))}
                                </>
                                : null
                            }
                        </p>
                        <div className="text-left">{study_mode}</div>
                        {/* <div className="btn mt-2">Start Application</div> */}
                        </div>
                    </div>
                    </div>
                </div>
            </div> 
            ))}
            </div>
            {/* <div className="my-3 pagination__section">
                <span className="pages_count">Showing 1-4 of 12</span>
                <span className="d-flex ">
                <span className="active_page pagination">1</span>
                <span className="pagination">2</span>
                <span className="pagination">3</span>
                </span>
                <span className="next_btn">
                Next <IoIosArrowForward />
                </span>
            </div> */}
        </>
      }
    </div>
  );
};

export default RecommendedCourses;
