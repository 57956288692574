import {
    COLLEGE_SIMULATION_FAIL,
    COLLEGE_SIMULATION_REQUEST,
    COLLEGE_SIMULATION_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CollegeQuestionConstants";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const collegeSimulationCreate =
    (dataToSubmit) => async (dispatch, getState) => {
      try {
        dispatch({ type: COLLEGE_SIMULATION_REQUEST });
        const {
          tokenAuth: { tokenInfo },
        } = getState();
        const config = {
          headers: {
            Authorization: `Bearer ${tokenInfo.data.userToken}`,
            "Content-Type": "application/json",
          },
        };
        const { data } = await axiosInstance.post(
          "/highschools/college_simulations",
          dataToSubmit,
          config
        );
        dispatch({ type: COLLEGE_SIMULATION_SUCCESS, payload: data });
      } catch (error) {
        dispatch({
          type: COLLEGE_SIMULATION_FAIL,
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };