import { GET_COUNTRIES_REQUEST, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAIL } from "../../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/CountryConstant";

  export const listCountriesReducer = (state = { }, action) => {
    switch (action.type) {
      case GET_COUNTRIES_REQUEST:
        return { loading: true };
      case GET_COUNTRIES_SUCCESS:
        return { loading: false, countries: action.payload, success: true };
      case GET_COUNTRIES_FAIL:
        return { loading: false, error: action.payload, success: false };
      default:
        return state;
    }
  };
  