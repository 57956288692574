import { GET_DEGREE_REQUEST, GET_DEGREE_SUCCESS, GET_DEGREE_FAIL } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/DegreeConstants";

  export const getDegreesReducer = (state = { degrees: [] }, action) => {
    switch (action.type) {
      case GET_DEGREE_REQUEST:
        return { degree_loading: true };
      case GET_DEGREE_SUCCESS:
        return { ...state, degree_loading: false, degrees: action.payload };
      case GET_DEGREE_FAIL:                                                        
        return { ...state, degree_loading: false, degree_error: action.payload };
      default:
        return state;
    }
  };
  