import "../../../simulator.css";
import { FaTimes } from "react-icons/fa";
import Alerts from "../../../../../Alerts/Alert";
const Courses = ({
  tempCourse,
  setTempCourse,
  options,
  remove,
  courseError,
  display,
  setDisplay,
  search,
  setSearch,
  setRecentValue,
  getSelectedCourseData,

}) => {
  return (
    <div className="choice_form1">
      <p className="questions_tag">Question 3</p>
      <h2>Select Course/Degree you are interested in Studying</h2>
      {courseError && (
        <div>
          {" "}
          <Alerts
            description={courseError}
            type="error"
            message={courseError}
            closable
          />
        </div>
      )}
      <form className="city-form ">
        <input
          type="text"
          className="form-control"
          placeholder="Select Course/Courses"
          onClick={() => setDisplay(!display)}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />

        {display && (
               <div className="card">
               <div className="card-body">
                 {options &&
                   options.data &&
                   options.data.map((course, index) => (
                     <div key={index} className="option">
                       <span className="courses_list" onClick={() => 
                          {
                            setRecentValue(course.name);
                            getSelectedCourseData(course)
                            setSearch("")
                           }}
                        >
                          {course.name}
                       </span>
                     </div>
                   ))}
               </div>
             </div>
        )}

      
      </form>
      {/* )} */}

      {tempCourse.length > 0 ? (
        <ul className="my-4 list_tags">
          {tempCourse.map((item, index) => (
            <li key={index}>
              {item.name}{" "}
              <span onClick={() => {
                remove(item, tempCourse, setTempCourse)}
                  
                }>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}

      {/* <ul className="my-4 list_tags">
        {course.map((item, index) => {
          const values = filterName(item, courses.data);
          return (
            <li key={index}>
              {values && values.map((c) => c.course)}{" "}
              <span onClick={() => remove(item, course, setCourse)}>
                <FaTimes className="delete_btn" />
              </span>
            </li>
          );
        })}
      </ul> */}

      <span
        className="text-center text-def"
        onClick={() => console.log("Clicked!!!")}
      >
        You can select up to <strong>3 courses</strong>
      </span>
      <span>
        <small>Seperate each course with a comma</small>
      </span>
    </div>
  );
};

export default Courses;
