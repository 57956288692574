import { PARENT_WARD_CONNECTION_REQUEST, PARENT_WARD_CONNECTION_SUCCESS, PARENT_WARD_CONNECTION_FAIL } from "../../../../../../../Utils/constants/HighschoolConstants/ParentConstants/ParentWardConstants";
import axiosInstance from "../../../../../../../Utils/helper/axiosInstance";

export const ParentWardLinkAction = (userData) => async (dispatch, getState) => {
    try {
        dispatch({type: PARENT_WARD_CONNECTION_REQUEST})
        const {
            tokenAuth: { tokenInfo }
        } = getState();
 
        const config = {
            headers: {
                Authorization: `Bearer ${tokenInfo.data.userToken}`,
                "Content-Type": "application/json"
            }
        }
 
        const {data} = await axiosInstance.post(
            "/highschools/wards/create-ward", userData,
            config
        );
       dispatch({type: PARENT_WARD_CONNECTION_SUCCESS, payload: data});
       if(data.status === "failed"){
         dispatch({
           type: PARENT_WARD_CONNECTION_FAIL,
           payload: data.reason.message
         });
        }
    } catch (error) {
     dispatch({
         type: PARENT_WARD_CONNECTION_FAIL,
         payload:
           error.response && error.response.data.message
             ? error.response.data.message
             : error.message,
       });
    }
 }