import {
    GET_CONTINENTS_FAIL,
    GET_CONTINENTS_REQUEST,
    GET_CONTINENTS_SUCCESS,
  } from "../../../../../../Utils/constants/SimulationConstants/QuestionnaireConstant/CollegeQuestionnaire/ContinentsConstant";
  import axiosInstance from "../../../../../../Utils/helper/axiosInstance";
  
  export const listContinents = () => async (dispatch) => {
    try {
      dispatch({ type: GET_CONTINENTS_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axiosInstance.get(
        "/metadata/continents",
        config
      );
      dispatch({ type: GET_CONTINENTS_SUCCESS, payload: data });
    } catch (error) {
      const message =
        error.response && error.response.data
          ? error.response.data
          : error.message;
      dispatch({ type: GET_CONTINENTS_FAIL, payload: message });
    }
  };
  