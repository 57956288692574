// import { Link } from "react-router-dom";
import { useState, useContext } from 'react';
import {AuthContext} from "../../../AuthContext/AuthContext";

const RecommendedCollege = ({college_result, college_error}) => {
    const {user_error} = useContext(AuthContext);
    const { recommeded_colleges = {} } = college_result;
    const [userError, setUserError] = useState(user_error);
    const [simulationError, setSimulationError] = useState(college_error)
    // console.log("recommeded_colleges", recommeded_colleges);
    // let dollarUSLocale = Intl.NumberFormat('en-US');
    const {colleges = [], Note = ''} = recommeded_colleges;
    // const new_college_recommendation = recommeded_colleges?.find(u => u);

  setTimeout(() => {
      setUserError("");
      setSimulationError("");
  }, 3000);

  return (
    <div className="container college_result">
      <h5>College/University Recommended</h5>
         {userError && <p className="text-danger text-center">{userError}</p>}
         {simulationError ? <p className="text-danger text-center">{simulationError}</p>:
        <>
      <h5 style={{color: '#000000', fontSize: '20px'}} className="mb-3">{Note}</h5>
      <div className="row">
      {colleges && colleges?.slice(0, 4)?.map(college => (
        <div className="col-12  recommended_col" key={college.id}>
          <div className="card">
            <div className="card-body">
              <div className="row">

                <div className="col-md-8 col-sm-8 col-lg-8">
                  <div div className="row">
                    <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                      <img src={college.logo || "/unilogo.png"} alt="uni logo" className="school_logos"/>
                    </div>{" "}
                    <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                      <h5 className="recommended_school">
                        {college.college_name}
                      </h5>
                      <p className="recommended_school_p">
                        <img src="/location.png" alt="Location" /> &nbsp; {college.college_address}
                      </p>
                      <p className="recommended_school_p">
                          {college.general_application_info}
                      </p>
                    </div>
                  </div>
                </div>
               
                <div className="col-md-4 col-sm-4 col-lg-4 result_page_general">
                  {/* {
                    college.tuitions ?
                    <p><strong className='text-danger'>Price - {" "}</strong>${college.tuitions?.slice(0, 1)?.map(tuition_range => tuition_range.tuition_range !== "nil" ? dollarUSLocale.format(tuition_range.tuition_range): "N/A")}/year</p>
                    : null
                  } */}
                  {/* <div className="btn" onClick={college.id}><Link to={`/simulate-college/colleges?id=${college.id}`}>View Details</Link></div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        ))}
      </div> 
      </>
      }
      {/* <div className="my-4 more__details">
        <Link to="/readmore">Read more details</Link>
      </div> */}
    </div>
  );
};
export default RecommendedCollege;
