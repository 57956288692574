import { useParams } from 'react-router-dom';
import {useEffect} from 'react'
import { useDispatch, useSelector } from "react-redux";
import SimulationResultsPage from "../../ViewSimulationList/Index";
import { getListOfStudentCollegeApplSimulation } from '../../Redux/actions/getListOfCollegeApplists';

const CollegeApplSimulationLists = () => {
    let { user_id, type } = useParams();
    const dispatch = useDispatch();
    
    const {listCollegeApplSimulation, loading} = useSelector(state => state.getStudentCollegeApplist)
    console.log("listCareerSimulation", listCollegeApplSimulation);

    useEffect(() => {
        if(type === "college-application"){
            dispatch(getListOfStudentCollegeApplSimulation(user_id))
        } else {
            return;
        }
    }, [dispatch, type, user_id])

   return (
       <>
            <SimulationResultsPage simulations={listCollegeApplSimulation} loading={loading} type={type}/>
       </>
   )
}

export default CollegeApplSimulationLists
