import "./profile.css";
import {AuthContext} from "../../../AuthContext/AuthContext"
import {useContext} from "react"
const ProfileSecondaryNav = ({student_data}) => {
    const {highschools = {}} = useContext(AuthContext)
    const {data = []} = highschools;
    const school_name = data.find((school) => school.id === student_data?.high_school_profile?.highschool_id)
    return (
        <div className="secondary_nav_profile">
            <div className="profile_details_sec">
                <span className="students_details_name">{student_data?.first_name} {student_data?.last_name}</span>
    <span className="students_school_detail">Student, {school_name?.school_name}</span>
            </div>
            {/* <div className="view-simulation-btn ">
                <button>View Simulations</button>
            </div> */}
        </div>
    )
}


export default ProfileSecondaryNav